import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { Success_login, Failure_login } from "./User_Slicer";
import {
  failure_api_call_add_user,
  success_api_call_add_user,
} from "./add_User_slicer";
import {
  failure_api_call_edit_user,
  success_api_call_edit_user,
} from "./edit_User_slicer";
import {
  failure_api_call_user,
  success_api_call_user,
} from "./list_User_slicer";
import { failure_api_call_login, success_api_call_login } from "./Login_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
// import {
//   failure_api_call_add_user,
//   success_api_call_add_user,
// } from "./add_User_slicer";
// import {
//   failure_api_call_edit_user,
//   success_api_call_edit_user,
// } from "./edit_User_slicer";
// import { failure_api_call_login, success_api_call_login } from "./Login_slicer";

//add user
const addUserCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);
  const add = {
    // ...a.payload,
    firstname: a.payload.firstname,
    lastname: a.payload.lastname,
    username: a.payload.username,
    phone: a.payload.phone,
    email: a.payload.email,
    password: a.payload.password,
    role: a.payload.role,
    active: a.payload.active,
    created_by: adminId,
  };
  // console.log(add);

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/user/registration`,
    add,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddUserApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addUserCall, e);
    // console.log(response.data);

    yield put(success_api_call_add_user(response.data));
  } catch (e) {
    // console.log(e);
    yield put(failure_api_call_add_user(e));
    yield put(expireSignature(e));
  }
}

//login
const loginCall = async (a: any) => {
  const add = {
    email: a.payload.email,
    password: a.payload.password,
  };

  return Axios.post(`${process.env.REACT_APP_api_url}/v1/api/user/login`, add);
};
export function* LoginApi(e: any) {
  try {
    const response: AxiosResponse = yield call(loginCall, e);
    // console.log("adsfdbvhbshvbvhsvsh", response.data);

    localStorage.setItem("token", response?.data?.data?.token);
    localStorage.setItem("firstname", response?.data?.data?.firstname);
    localStorage.setItem("id", JSON.stringify(response?.data?.data?.id));
    localStorage.setItem(
      "role_id",
      JSON.stringify(response?.data?.data?.role_id)
    );
    localStorage.setItem("role", JSON.stringify(response?.data?.data?.role));

    yield put(success_api_call_login(response.data));
  } catch (e) {
    // console.log(e);
    yield put(failure_api_call_login(e));
    yield put(expireSignature(e));
  }
}

//Edit user

const editUserCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);

  const edit =
    a.payload.password === ""
      ? {
          id: a.payload.id,
          firstname: a.payload.firstname,
          lastname: a.payload.lastname,
          username: a.payload.username,
          phone: a.payload.phone,
          email: a.payload.email,
          role: a.payload.role,
          active: a.payload.active,
          updated_by: adminId,
        }
      : {
          ...a.payload,
          updated_by: adminId,
        };
  // console.log(edit);
  // console.log(a.payload.password === "");

  return Axios.put(`${process.env.REACT_APP_api_url}/v1/api/user/`, edit, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* editUserApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editUserCall, e);
    // console.log(response.data);

    yield put(success_api_call_edit_user(response.data));
  } catch (e) {
    yield put(failure_api_call_edit_user(e));
    yield put(expireSignature(e));
  }
}

//list user
const listUserCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/user/?limit=100`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* listUserApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listUserCall, e);

    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    console.log(res);
    yield put(success_api_call_user(res));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_user(e));
    yield put(expireSignature(e));
  }
}
