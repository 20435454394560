//    For dropdown list items
import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import {
  Success_list,
  Failure_list,
  Add_QuestionImages_success,
  Add_QuestionImages_failure,
} from "./QuestionImages_Slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import { ImageQuestionData } from "../../../interfaces/IImageQuestions";
import {
  failure_api_call_delete_matrixQuestionLayer,
  success_api_call_delete_matrixQuestionLayer,
} from "./delete_matrixQuestionLayer_slicer";
import { PayloadAction } from "@reduxjs/toolkit";

interface IQuestionDelete {
  layer_id: string;
}

//add questionImages
const addQuestionImagesCall = async (a: any) => {
  console.log(a);

  let token = localStorage.getItem("token");
  // console.log(a.payload);
  const adminId = JSON.parse(localStorage.getItem("id")!);
  console.log(a.payload);

  const questionImages = a.payload;

  const formData = new FormData();

  formData.append("question", JSON.stringify(questionImages));
  formData.append("project_id", localStorage.getItem("Project_Id")!);
  questionImages.forEach((element: ImageQuestionData) => {
    formData.append("image_url", element.image_url);
  });
  formData.append("layer_id", a.payload[0].layer_id);

  return await Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/question_image_matrix/update`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddQuestionImagesApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addQuestionImagesCall, e);

    yield put(Add_QuestionImages_success(response.data));
  } catch (e) {
    console.log(e);
    yield put(Add_QuestionImages_failure(e));
    yield put(expireSignature(e));
  }
}

//list questionID
const listQuestionIDCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/project_questions/filter?project_id=${a.payload.project_id}&q_type=${a.payload.q_type}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* listQuestionIDApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listQuestionIDCall, e);

    // let res = response?.data?.data?.map((x: any, i: any) => ({
    //   ...x,
    //   //   active: x.active ? "Active" : "Inactive",
    //   field_id: i + 1,
    // }));
    yield put(Success_list(response.data.data));
  } catch (e) {
    console.log(e);
    yield put(Failure_list(e));
    yield put(expireSignature(e));
  }
}

//    Delete matrixQuestionLayer
export function* deleteMatrixQuestionLayerApi(
  e: PayloadAction<IQuestionDelete>
) {
  try {
    const response: AxiosResponse = yield call(
      deleteMatrixQuestionLayerCall,
      e
    );
    yield put(success_api_call_delete_matrixQuestionLayer(response.data));
  } catch (error) {
    console.log(error);
    yield put(failure_api_call_delete_matrixQuestionLayer(error));
    yield put(expireSignature(e));
  }
}
const deleteMatrixQuestionLayerCall = (
  action: PayloadAction<IQuestionDelete>
) => {
  const token = localStorage.getItem("token");
  const layerId = action.payload;
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/question_image_matrix/delete`,
    {
      layer_id: layerId,
      project_id: localStorage.getItem("Project_Id"),
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/x-www-form-urlencoded",
      },
    }
  );
};
