import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_send_url,
  success_api_call_send_url,
} from "./shopify_redirect_url_send_slicer";
import { PayloadAction } from "@reduxjs/toolkit";
import { IAPIResponse } from "../../../interfaces/IReduxState";
import {
  failure_api_call_get_url,
  success_api_call_get_url,
} from "./shopify_redirect_url_slicer";

/*    send shopify url     */
const SendShopifyUrlCall = (action: PayloadAction<string>) => {
  const token = localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/shopify/auth`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { codeUrl: action.payload },
  });
};

export function* SendURLApiCall(event: PayloadAction<string>) {
  try {
    const response: AxiosResponse = yield call(SendShopifyUrlCall, event);
    yield put(success_api_call_send_url(response.data));
  } catch (error) {
    console.log(error);
    yield put(failure_api_call_send_url(error));
    yield put(expireSignature(error));
  }
}

/*    get shopify redirect url     */
const GetShopifyUrlCall = () => {
  const token = localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/shopify/connect`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export function* GetShopifyUrlApiCall(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<IAPIResponse<string>> = yield call(
      GetShopifyUrlCall
    );
    /*    get the location and replace it    */
    const currentLocation = window.location;
    const redirectUri = `${currentLocation.protocol}//${currentLocation.hostname}:${currentLocation.port}${action.payload}`;
    console.log(redirectUri);
    if (response.data.data) {
      const modifiedUrl = response.data.data.replace(
        "https%3A%2F%2Faagnia.com%2F",
        `${redirectUri}`
      );
      window.location.href = modifiedUrl;
    }

    yield put(success_api_call_get_url(response.data));
  } catch (error) {
    console.log(error);
    yield put(failure_api_call_get_url(error));
    yield put(expireSignature(error));
  }
}
