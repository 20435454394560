import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";

export const GetShopifyRedirectUrl = createSlice({
  name: "GetShopifyRedirectUrl",
  initialState: {
    isLoading: false,
    data: {},
  } as IReduxState<string>,
  reducers: {
    get_url: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_get_url: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_call_get_url: (state, _) => {
      state.isLoading = false;
    },
  },
});
export default GetShopifyRedirectUrl.reducer;
export const { get_url, success_api_call_get_url, failure_api_call_get_url } =
  GetShopifyRedirectUrl.actions;
