import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { number } from "yup";

export const DetailEdit = createSlice({
  name: "DetailEdit",
  initialState: {
    isrender: "",
    isEdit: "",
    isOption: "",
    isInput: "",
    isImage: "",
    isCanvas: "",
    isIndex: "",
    isIndexx: "",
    isLibraryOpen: "",
    isDragDrop: "",
    isobjectvalue: "",
    isObjectsele_boolean: false,
    isBold: "",
    isItalic: "",
    isUnderline: "",
    iscolor: "",
    isupdated: "",
    isselecttextvalue: "",
    istextPTS: "",
    isfontfamily: "",
    iscustomfont: "",
    isfontsize: "",
    isfontselectedvalue: 0,
    istextAlignment: "",
    isCurve: false,
    isUpdateCurve: false,
    isFlipCurve: false,
    isupdateFlip: false,
    isCurving: 1,
    isUpdateCurving: 1,
    curveinputValue: "",
    iseditable: false,
    issavee: false,
    isselecclr: "",
    isUpdateColor: "",
    pageclick_loadjson: false,
    layername: "",
    capital: false,
    capitalreply: true,
    small: false,
    x_axis: 0,
    x_return: 0,
    y_axis: 0,
    y_return: 0,
    angle: 0,
    angle_return: 0,
    width: 0,
    width_return: 0,
    line: 0,
    line_return: 0,
    height: 0,
    height_return: 0,
    zoomvalue: 0,
    isCurveextra: "",
    layer_name: "",
    jsonid_dropdown: "",
    jsonid_dropdown_reurn: "",
    uplayer: false,
    downlayer: false,
    toplayer: false,
    endlayer: false,
    noimage: false,
    updateimage: "",
    questionopen: false,
    unselect: false,
    textquestion_id: [],
    backg_colr: "",
    layer_id: "",
    space: 0,
    spacereturn: 0,
    autoscalebool: false,
    autoscalebool_return: false,
  },
  reducers: {
    SpaceReturn: (state, action: PayloadAction<any>) => {
      state.spacereturn = action.payload;
    },
    Space: (state, action: PayloadAction<any>) => {
      state.space = action.payload;
    },
    Layer_id: (state, action: PayloadAction<any>) => {
      state.layer_id = action.payload;
    },
    Backg_colr: (state, action: PayloadAction<any>) => {
      state.backg_colr = action.payload;
    },
    Textquestion_id: (state, action) => {
      state.textquestion_id = action.payload;
    },
    Unselect: (state, action) => {
      state.unselect = action.payload;
    },
    QuestionOpen: (state, action) => {
      state.questionopen = action.payload;
    },
    NoImage: (state, action) => {
      state.noimage = action.payload;
    },
    TopLayer: (state, action) => {
      state.toplayer = action.payload;
    },
    EndLayer: (state, action) => {
      state.endlayer = action.payload;
    },
    UpLayer: (state, action) => {
      state.uplayer = action.payload;
    },
    DownLayer: (state, action) => {
      state.downlayer = action.payload;
    },
    Jsonid_Dropdown: (state, action) => {
      state.jsonid_dropdown = action.payload;
    },
    Jsonid_Dropdown_return: (state, action) => {
      state.jsonid_dropdown_reurn = action.payload;
    },
    Layer_name: (state, action) => {
      state.layer_name = action.payload;
    },
    Zoom: (state, action) => {
      state.zoomvalue = action.payload;
    },
    Width: (state, action) => {
      state.width = action.payload;
    },
    Widthreturn: (state, action) => {
      state.width_return = action.payload;
    },
    Line: (state, action) => {
      state.line = action.payload;
    },
    LineReturn: (state, action) => {
      state.line_return = action.payload;
    },
    Height: (state, action: PayloadAction<any>) => {
      state.height = action.payload;
    },
    Heightreturn: (state, action) => {
      state.height_return = action.payload;
    },
    Angle: (state, action) => {
      state.angle = action.payload;
    },
    Anglereturn: (state, action) => {
      state.angle_return = action.payload;
    },
    Xaxis: (state, action) => {
      state.x_axis = action.payload;
    },
    Xreturn: (state, action) => {
      state.x_return = action.payload;
    },
    Yaxis: (state, action) => {
      state.y_axis = action.payload;
    },
    Yreturn: (state, action) => {
      state.y_return = action.payload;
    },
    Small: (state, action) => {
      state.small = action.payload;
    },
    CapitalReply: (state, action) => {
      state.capitalreply = action.payload;
    },
    Capital: (state, action) => {
      state.capital = action.payload;
    },
    // screnn render
    LayerName: (state, action) => {
      state.layername = action.payload;
    },
    Pageclick_Loadjson: (state, action) => {
      state.pageclick_loadjson = action.payload;
    },
    SelectedClr: (state, action) => {
      state.isselecclr = action.payload;
    },
    Editable: (state, action) => {
      state.iseditable = action.payload;
    },
    Screen_render: (state, action: PayloadAction<any>) => {
      state.isrender = action.payload;
    },
    // image,text,upload,option maintains
    Edit_text: (state, action: PayloadAction<any>) => {
      // console.log("testistext", action.payload);

      state.isEdit = action.payload;
    },
    // undo ,redo,delete
    Edit_option: (state, action: PayloadAction<any>) => {
      state.isOption = action.payload;
    },
    Enter_Input: (state, action: PayloadAction<any>) => {
      // console.log("text value", action.payload);

      state.isInput = action.payload;
    },
    textUpdate: (state, action: PayloadAction<any>) => {
      // console.log("textUpdate", textUpdate);

      state.isupdated = action.payload;
    },
    Image_Upload: (state, action: PayloadAction<any>) => {
      state.isImage = action.payload;
    },
    // maintain canvas value
    Get_Canvas: (state, action: PayloadAction<any>) => {
      state.isCanvas = action.payload;
    },
    // maintain object selection index value
    Get_Index: (state, action: PayloadAction<any>) => {
      state.isIndex = action.payload;
    },
    // maintain layer based index value
    Get_Index2: (state, action: PayloadAction<any>) => {
      state.isIndexx = action.payload;
    },
    // image libraby and comics image open or close
    Image_library: (state, action: PayloadAction<any>) => {
      state.isLibraryOpen = action.payload;
    },
    // drag and drop index value save
    Drag_Drop: (state, action: PayloadAction<any>) => {
      state.isDragDrop = action.payload;
    },
    ObjectSelecterValue: (state, action: PayloadAction<any>) => {
      state.isobjectvalue = action.payload;
    },

    ObjectSelection_boolean: (state, action: PayloadAction<any>) => {
      state.isObjectsele_boolean = action.payload;
    },
    // selected bold value
    textBold: (state, action: PayloadAction<any>) => {
      state.isBold = action.payload;
    },
    textitalic: (state, action: PayloadAction<any>) => {
      state.isItalic = action.payload;
    },
    textunderline: (state, action: PayloadAction<any>) => {
      state.isUnderline = action.payload;
    },
    textcolor: (state, action: PayloadAction<any>) => {
      state.iscolor = action.payload;
    },
    // selected object text value
    textvalue: (state, action: PayloadAction<any>) => {
      state.isselecttextvalue = action.payload;
    },
    textPTS: (state, action: PayloadAction<any>) => {
      state.istextPTS = action.payload;
    },
    fontFamily: (state, action: PayloadAction<any>) => {
      state.isfontfamily = action.payload;
    },
    CustomFont: (state, action: PayloadAction<any>) => {
      // console.log("costomfontredux", action.payload);

      state.iscustomfont = action.payload;
    },
    fontSize: (state, action: PayloadAction<any>) => {
      state.isfontsize = action.payload;
    },

    fontselectedValue: (state, action) => {
      state.isfontselectedvalue = action.payload;
    },
    textAlignment: (state, action: PayloadAction<any>) => {
      state.istextAlignment = action.payload;
    },
    clickCurve: (state, action) => {
      state.isCurve = action.payload;
      // return { ...state, isCurve: action.payload };
    },
    clickCurveextra: (state, action) => {
      state.isCurveextra = action.payload;
    },
    updateCurve: (state, action) => {
      state.isUpdateCurve = action.payload;
      // return { ...state, isCurve: action.payload };
    },
    flipCurve: (state, action) => {
      state.isFlipCurve = action.payload;
      // return { ...state, isFlipCurve: action.payload };
    },
    updateFlip: (state, action) => {
      state.isupdateFlip = action.payload;
      // return { ...state, isupdateFlip: action.payload };
    },
    curving: (state, action) => {
      state.isCurving = action.payload;
    },
    updateCurving: (state, action) => {
      state.isUpdateCurving = action.payload;
    },
    curveinput: (state, action) => {
      state.curveinputValue = action.payload;
    },
    Savee: (state, action: PayloadAction<any>) => {
      state.issavee = action.payload;
    },
    updataColor: (state, action: PayloadAction<any>) => {
      state.isUpdateColor = action.payload;
    },
    UpdatedImage: (state, action: PayloadAction<any>) => {
      state.updateimage = action.payload;
    },
    AutoScaleBool: (state, action: PayloadAction<any>) => {
      state.autoscalebool = action.payload;
    },
    AutoScaleBool_Return: (state, action: PayloadAction<any>) => {
      state.autoscalebool_return = action.payload;
    },
  },
});
export default DetailEdit.reducer;
export const {
  AutoScaleBool_Return,
  AutoScaleBool,
  UpdatedImage,
  clickCurveextra,
  Width,
  Widthreturn,
  Line,
  LineReturn,
  Height,
  Heightreturn,
  Angle,
  Anglereturn,
  Xaxis,
  Xreturn,
  Yaxis,
  Yreturn,
  Small,
  CapitalReply,
  Capital,
  LayerName,
  Pageclick_Loadjson,
  Savee,
  Editable,
  textAlignment,
  fontSize,
  textPTS,
  Edit_text,
  Edit_option,
  Enter_Input,
  Image_Upload,
  Get_Canvas,
  Get_Index,
  Get_Index2,
  Image_library,
  Drag_Drop,
  ObjectSelection_boolean,
  textBold,
  textitalic,
  textunderline,
  textcolor,
  ObjectSelecterValue,
  textUpdate,
  textvalue,
  Screen_render,
  fontFamily,
  CustomFont,
  fontselectedValue,
  SelectedClr,
  clickCurve,
  flipCurve,
  curving,
  curveinput,
  updataColor,
  updateFlip,
  updateCurving,
  updateCurve,
  Zoom,
  Layer_name,
  Jsonid_Dropdown,
  Jsonid_Dropdown_return,
  UpLayer,
  DownLayer,
  TopLayer,
  EndLayer,
  NoImage,
  QuestionOpen,
  Unselect,
  Textquestion_id,
  Backg_colr,
  Layer_id,
  Space,
  SpaceReturn,
} = DetailEdit.actions;
