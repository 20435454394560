import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  failure_api_call_Projectcategory,
  success_api_call_Projectcategory,
} from "./list_ProjectCategory_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";

//list image
const listProjectCategoryCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/project/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* listProjectCategoryApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listProjectCategoryCall, e);

    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      //   active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    console.log(res);
    yield put(success_api_call_Projectcategory(res));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_Projectcategory(e));
    yield put(expireSignature(e));
  }
}
