import { createSlice } from "@reduxjs/toolkit";

export const AddComicsBookPageJsonValue = createSlice({
  name: "AddComicsBookPageJsonValue",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
    data1: [],
  },
  reducers: {
    add_ComicsBookPage_jsonvalue: (state) => {
      state.isLoading = true;
    },
    success_api_call_add_ComicsBookPage_jsonvalue: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_add_ComicsBookPage_jsonvalue: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      // FAILED(action.payload);
    },
    add_ComicsBookPage_is_success_jsonvalue: (state, action) => {
      state.isSuccess = false;
    },
    create_ComicsBookPage_complete_api_jsonvalue: (state, action) => {
      state.api_completed = false;
    },
    Imagegenartion: (state, action) => {
      console.log("action", action.payload);
      state.data1 = action.payload;
      state.isLoading = false;
    },
  },
});
export default AddComicsBookPageJsonValue.reducer;
export const {
  add_ComicsBookPage_jsonvalue,
  success_api_call_add_ComicsBookPage_jsonvalue,
  failure_api_call_add_ComicsBookPage_jsonvalue,
  create_ComicsBookPage_complete_api_jsonvalue,
  add_ComicsBookPage_is_success_jsonvalue,
  Imagegenartion,
} = AddComicsBookPageJsonValue.actions;
