import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  failure_api_call_menuItem,
  success_api_call_menuItem,
} from "./list_menuItem_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
//list menuItem
const listMenuItemCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/user_permission/get_menus`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* ListMenuItemApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listMenuItemCall, e);
    // let res = response?.data?.data?.map((x: any, i: any) => ({
    //   ...x,
    //   active: x.active ? "Active" : "Inactive",
    //   field_id: i + 1,
    // }));
    yield put(success_api_call_menuItem(response?.data?.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_menuItem(e));
    yield put(expireSignature(e));
  }
}
