import { FunctionComponent } from "react";
import EditIcon from "@mui/icons-material/Edit";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import "./Page.css";
import { add_role } from "../Redux/Reducers/Role/add_Role_slicer";
import { edit_role } from "../Redux/Reducers/Role/edit_Role_slicer";
import { Backdrop, Button, CircularProgress, IconButton } from "@mui/material";
import { list_role } from "../Redux/Reducers/Role/list_Role_slicer";
import Grids from "../Component/Grides";
import Dialog from "../Component/DialogForForm";
import DialogComponent from "../Component/Dialog";
import {
  clear_questions,
  list_Question,
} from "../Redux/Reducers/QuestionPage/list_Question_slicer";
import { add_Question } from "../Redux/Reducers/QuestionPage/add_Question_slicer";
import { edit_Question } from "../Redux/Reducers/QuestionPage/edit_Question_slicer";
import DialogQuestion from "../Component/DialogForFormQuestion ";
import { list_Projectcategory } from "../Redux/Reducers/Project Category/list_ProjectCategory_slicer";
import { ListQuestions, Options } from "../interfaces/IQuestion";
import IsLoading from "../Component/isLoading";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteQuestion,
  falsePopup,
  updateQuestionId,
} from "../Redux/Reducers/QuestionPage/delete_Question_slice";

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

interface QuestionProps {}

const Question: FunctionComponent<any> = (props: any) => {
  const dispatcher = useAppDispatch();
  const [closeDio, setCloseDio] = React.useState(true);
  const nav = useNavigate();
  const breadcrumbs = [
    // <Typography key="3" color="text.primary" style={{ display: "flex" }}>
    <Link
      key="1"
      color="black"
      onClick={() => nav("/ComicsBook")}
      sx={{ cursor: "pointer" }}
      underline="none"
    >
      <b className="heading"> Comics Book</b>
    </Link>,
    <Link
      underline="none"
      key="1"
      color="inherit"
      onClick={() => nav("/Question")}
      style={{
        fontWeight: "500",
        fontSize: "15px ",
        lineHeight: "16px ",
        color: "#BDBDBD",
      }}
      sx={{ cursor: "pointer" }}
    >
      Project Questions
    </Link>,

    // <Typography key="3" color="text.primary">
  ];

  //    datas of user role
  const Questions = useAppSelector((state) => state?.Questions?.data);

  /*    modified question    */
  const modifiedQuestion = (Questions ?? []).map((question: ListQuestions) => ({
    ...question,
    q_type:
      (question.q_type === "T" && "Text") ||
      (question.q_type === "I" && "Image"),
    text_type:
      (question.text_type === "T" && "Short Text") ||
      (question.text_type === "P" && "Paragraph") ||
      (question.text_type === "S" && "Sentence") ||
      "---",
    // Options:question.Options.map((option:Options)=>({...option,}))
  }));

  const isLoading = useAppSelector((state) => state?.Questions?.isLoading);
  const addData: any = useAppSelector((state) => state?.AddQuestion?.data);
  const Projectidd = useAppSelector((state) => state.Projectid);

  const addDataIsLoading = useAppSelector(
    (state) => state?.AddQuestion?.isLoading
  );

  const editData = useAppSelector((state) => state?.editQuestion?.data);

  const editDataIsLoading = useAppSelector(
    (state) => state?.editQuestion?.isLoading
  );

  /*    Delete     */
  const {
    data: deleteResponse,
    isLoading: deleteLoading,
    openPopup: deletePopup,
    openSuccessPopup: responseDeletion,
    questionId: deleteQuestionId,
  } = useAppSelector((state) => state.DeleteQuestion);

  const Projectids = Projectidd.projectname;

  //    userpermission menuList
  const pageName = "Comics Book / Project Question";
  const menuList: any = JSON.parse(
    localStorage?.getItem("permission") as string
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageName;
  });

  const MenuOfUserpermission = menuList[index];

  const closeFunction = (e: boolean) => {
    // console.log("Sfd", e);

    setCloseDio(false);
  };

  const column = [
    // { headerName: , field: ,  },
    {
      sortable: false,
      field: "field_id",
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          S.No
        </p>
      ),
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
      // renderCell: (params: GridRenderCellParams<any>) =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Questions
        </p>
      ),
      field: "question",
      flex: 1,
      headerAlign: "center",
    },

    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Question Type
        </p>
      ),
      field: "q_type",
      flex: 1,
      align: "center",

      headerAlign: "center",
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Text Type
        </p>
      ),
      field: "text_type",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   headerName: (
    //     <p
    //       className="headingnamebold"
    //       style={{ color: "black", fontWeight: "bolder" }}
    //     >
    //       Length
    //     </p>
    //   ),
    //   field: "length",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   headerName: (
    //     <p
    //       className="headingnamebold"
    //       style={{ color: "black", fontWeight: "bolder" }}
    //     >
    //       Keyword
    //     </p>
    //   ),
    //   field: "keyword",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    // },
    MenuOfUserpermission?.edit
      ? {
          headerName: (
            <p
              className="headingnamebold"
              style={{ color: "black", fontWeight: "bolder" }}
            >
              Action
            </p>
          ),
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <div style={{ display: "flex", flexWrap: "wrap", gap: "0.5rem" }}>
                <DialogQuestion
                  // Dialogcontenttext
                  // getValues={(v: any) => console.log(v)}
                  successMessage={editData}
                  isLoading={editDataIsLoading}
                  Buttondialog={["edit"]}
                  Dialogtitle={"Edit Questions "}
                  getValues={(v: any) => {
                    dispatcher(edit_Question(v));
                    // console.log(values);
                  }}
                  placeholder="Role"
                  sx={{
                    backgroundColor: "transparent",
                    minWidth: "0",
                    padding: "0",
                    borderRadius: "50%",
                    height: "60%",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  edit={
                    // params.row
                    {
                      ...params.row,
                      q_type:
                        (params.row.q_type === "Text" && "T") ||
                        (params.row.q_type === "Image" && "I") ||
                        "",
                      text_type:
                        (params.row.text_type === "Short Text" && "T") ||
                        (params.row.text_type === "Paragraph" && "P") ||
                        (params.row.text_type === "Sentence" && "S") ||
                        "",
                    }
                  }
                  Buttonname={
                    <EditIcon
                      sx={{
                        backgroundColor: "#F5F5F5",
                        color: modifiedQuestion[0]?.is_launched
                          ? "#c7c7c7"
                          : "black",
                        height: "1.3em",
                        width: "1.3em",
                        padding: "15%",
                        borderRadius: "50%",
                        "&:hover": {
                          color: "#F5F5F5",
                          backgroundColor: "black",
                        },
                      }}
                      onClick={() => console.log(params.row)}
                    />
                  }
                  disabled={modifiedQuestion[0]?.is_launched}
                />
                <IconButton
                  onClick={() =>
                    dispatcher(
                      updateQuestionId({
                        question_id: params.row.question_id,
                        project_id: params.row.project_id,
                      })
                    )
                  }
                  sx={{
                    backgroundColor: "#F5F5F5",
                    width: "1.2em",
                    height: "1.2em",
                    color: "black",
                    padding: "6px",
                    fontSize: "1.7rem",
                    cursor: "pointer",
                    borderRadius: "50%",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "black",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            );
          },
        }
      : {
          field: "",
        },
  ].filter((column) => column.field !== "");

  // console.log(load);

  // React.useEffect(() => {
  //   dispatcher(list_Question(""));
  //   // console.log(editData);
  // }, [addData, editData]);

  React.useEffect(() => {
    return () => {
      dispatcher(clear_questions());
    };
  }, []);

  React.useEffect(() => {
    dispatcher(list_Question(parseInt(localStorage.getItem("Project_Id")!)));
    // dispatcher(list_Projectcategory(""));
    // axios
  }, [addData, editData, deleteResponse]);

  console.log(modifiedQuestion[0]?.is_launched);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <IsLoading isLoading={isLoading || deleteLoading} />
      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={modifiedQuestion ?? []}
        column={column}
        Users={localStorage.getItem("Project_Name")}
        open
        pagination
        dialog={
          MenuOfUserpermission?.add && (
            <DialogQuestion
              // error={errorMessage}
              successMessage={addData}
              isLoading={addDataIsLoading}
              sx={{
                padding: "15px",
                backgroundColor: "black",
                color: "white",
                width: "160px",
                height: "37px",
                borderRadius: "0px",
                border: "1px solid #BDBDBD",
                "&:hover": { backgroundColor: "black" },
                "&:disabled": {
                  color: " rgba(0, 0, 0, 0.26)",
                  boxShadow: "none",
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}
              placeholder="Role"
              getValues={(v: any) => {
                dispatcher(add_Question(v));
                // console.log(v);
              }}
              Buttondialog={["Cancel"]}
              Dialogtitle={"Add Question"}
              Buttonname={"Add Question"}
              disabled={modifiedQuestion[0]?.is_launched}
            />
          )
        }
        Userpermissions={undefined}
      ></Grids>

      {/*     delete dialog    */}
      <DialogComponent
        open={deletePopup}
        setOpen={() => {
          dispatcher(falsePopup());
        }}
        maxWidth="xs"
        sx={{ width: "auto" }}
        Dialog_Content="Are you sure you want to delete?"
        Dialog_Content_sx={{
          display: "flex",
          fontSize: "16px",
          justifyContent: "center",
          padding: "1.5em 1.5em 0 !important",
        }}
        Dialog_Actions={
          <>
            <Button
              variant="outlined"
              onClick={() => {
                dispatcher(falsePopup());
              }}
              sx={{
                width: "110px",
                height: "40px",
                borderRadius: "0px",
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                dispatcher(deleteQuestion(deleteQuestionId));
              }}
              sx={{
                width: "110px",
                height: "40px",
                backgroundColor: "rgb(0, 0, 0)",
                color: "#FFFFFF",
                display: "flex",
                borderRadius: "0px",
              }}
            >
              Yes
            </Button>
          </>
        }
        Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
      />
      {/*     success message    */}
      <DialogComponent
        open={responseDeletion}
        setOpen={() => {
          dispatcher(falsePopup());
        }}
        maxWidth="xs"
        sx={{ width: "auto" }}
        Dialog_Content_sx={{
          padding: "1.5em 1.5em 0 !important",
          fontSize: "16px",
        }}
        Dialog_Content={
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <div>{deleteResponse?.message}</div>

            {deleteResponse?.status ? (
              <img
                src={require("../Pages/Image/success.png")}
                alt="success"
                width={"20%"}
              />
            ) : (
              <>
                {deleteResponse?.message
                  ?.toLowerCase()
                  .includes("already exists") ? (
                  // console.log("exisite")
                  <img
                    src={require("../Pages/Image/mdi_file-alert-outline.png")}
                    alt="error"
                    width={"20%"}
                  />
                ) : (
                  <img
                    src={require("../Pages/Image/error.png")}
                    alt="error"
                    width={"20%"}
                  />
                )}
              </>
            )}
          </section>
        }
        Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
        Dialog_Actions={
          <Button
            variant="contained"
            onClick={() => {
              dispatcher(falsePopup());
            }}
            sx={{
              width: "110px",
              height: "40px",
              backgroundColor: "rgb(0, 0, 0)",
              color: "#FFFFFF",
              display: "flex",
              borderRadius: "0px",
            }}
          >
            Ok
          </Button>
        }
      />
    </div>
  );
};

export default Question;
