import { createSlice } from "@reduxjs/toolkit";
import { States } from "../../../interfaces/Slicers";

interface extraData extends States {
  extraDataIsloading: boolean;
}

export const TextOptions = createSlice({
  name: "TextOptions",
  initialState: {
    isLoading: false,
    isSuccess: null,
    isError: null,
    data: [],
    extraData: [],
    extraDataIsloading: false,
  } as extraData,
  reducers: {
    List_textOptions: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    Success_list: (state, action) => {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isError: null,
      };
    },
    Failure_list: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
        isSuccess: null,
      };
    },
    Get_Question_By_Id: (state, _) => {
      state.isLoading = true;
    },
    Success_get_Question_By_Id: (state, action) => {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isError: null,
      };
    },
    Failure_get_Question_By_Id: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
        isSuccess: null,
      };
    },
    Add_dropdown: (state) => {
      state.extraData = [
        ...state.extraData!,
        { id: state.extraData!.length + 1, length: -1, value: null },
      ];
    },
    Update_length: (state, action) => {
      const copyData = [...state.extraData!];
      const index = state.extraData!.findIndex(
        (state: any) => state.id === action.payload.id
      );
      copyData[index] = {
        ...copyData[index],
        id: action.payload.id,
        length: action.payload.length.data[0].length,
      };
      return {
        ...state,
        extraData: copyData,
      };
    },
    Delete_dropdown: (state, action) => {
      const copyData = [...state.extraData!];
      const index = state.extraData!.findIndex(
        (state: any) => state.id === action.payload
      );
      copyData.splice(index, 1);
      return {
        ...state,
        extraData: copyData,
      };
    },
    dropdownValueUpdate: (state, action) => {
      const copyData = [...state.extraData!];
      const index = state.extraData!.findIndex(
        (state: any) => state.id === action.payload.dropdownId
      );
      copyData[index] = {
        id: action.payload.dropdownId,
        length: action.payload.length,
        value: action.payload.updateValue,
      };
      return {
        ...state,
        extraData: copyData,
      };
    },
    listDropdownById: (state, action) => {
      state.extraData = [
        { id: state.extraData!.length + 1, length: -1, value: action.payload },
      ];
      state.extraDataIsloading = true;
    },
    responseOfListDropdown: (state) => {
      state.extraDataIsloading = false;
    },
    clearExtraData: (state) => {
      state.extraData = [];
    },
  },
});
export default TextOptions.reducer;
export const {
  List_textOptions,
  Success_list,
  Failure_list,
  Get_Question_By_Id,
  Success_get_Question_By_Id,
  Failure_get_Question_By_Id,
  Add_dropdown,
  Update_length,
  dropdownValueUpdate,
  Delete_dropdown,
  listDropdownById,
  responseOfListDropdown,
  clearExtraData,
} = TextOptions.actions;
