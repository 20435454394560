import { createSlice } from "@reduxjs/toolkit";

export const ExpireSignature = createSlice({
  name: "ExpireSignature",
  initialState: {
    data: [],
  },
  reducers: {
    expireSignature: (state, action?) => {
      state.data = action?.payload;
      // console.log(action?.payload?.response?.status);
    },
    updateexpireSignature: (state, action?) => {
      state.data = action?.payload;
      // console.log(action?.payload?.response?.status);
    },
  },
});
export default ExpireSignature.reducer;
export const { expireSignature, updateexpireSignature } =
  ExpireSignature.actions;
