// Editor Main page
import left_arrow from "./accets/left_arrow.png";
import "./editor.css";
import delet from "./accets/delete.svg";
import undo from "./accets/undo.svg";
import redo from "./accets/redo.svg";
import pause from "./accets/pause.png";
import AddLayer from "./layer";
import EditFabric from "./fabric_page";
import ToolPage from "./TextTools/tools";
import React, {
  useState,
  useEffect,
  useContext,
  FunctionComponent,
} from "react";
import { useSelector } from "react-redux";
import ImageEdit from "./Edit-component/imgeditor";
import { useDispatch } from "react-redux";
import fox_load_1 from "./accets/run_fotball_1.gif";

import {
  DownLayer,
  Edit_option,
  Edit_text,
  Savee,
  TopLayer,
  UpLayer,
  EndLayer,
  Unselect,
} from "../Redux/Reducers/Editor_value";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SyncIcon from "@mui/icons-material/Sync";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import Question from "./Pages/QuestionsForEditor";
import { BackupOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import IsLoading from "../Component/isLoading";
// import { Dialog } from "@mui/material";
// import Dialogs from "../Component/Dialog";
import {
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomSelect from "./Edit-component/zoom";
import TextQuestion from "./Pages/TextTypeQuestion";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Gif_KO from "../Editor/accets/GIF_KO.gif";
import { red } from "@mui/material/colors";
import Backg_clr from "./Edit-component/background_color";
interface DialogsProps {
  isLoading?: boolean;
}
const Editor: FunctionComponent<DialogsProps> = () => {
  const nav = useNavigate();

  const [active, setActive] = useState(false);
  const [activeredo, setactiveredo] = useState(false);
  const [activedelete, setactivedelete] = useState(false);
  const [savee, setSave] = useState(false);
  const [size, setsize] = useState<any>(null);
  const [sizeload, setsizeload] = useState<any>(false);
  const [open, setOpen] = useState(false);
  const [comicbookopen, comicbooksetOpen] = useState(false);
  const [isload, setIsload] = useState(false);
  const [close, setClose] = useState(false);
  const [unselect, setUnselect] = useState(false);

  const dispatch = useDispatch();
  const checkEditPage = useSelector((state: any) => state.DetailEdit);
  const pagesizeeee: any = useSelector(
    (state: any) => state.AddComicsBookPageSize
  );
  const addjsonvalue: any = useSelector(
    (state: any) => state.AddComicsBookPageJsonValue
  );
  // console.log("checkeditpage", checkEditPage, checkEditPage.isobjectvalue);

  // useEffect(() => {
  //   if (!addjsonvalue.isLoading) {
  //     nav("/Comicbookpages");
  //     setOpen(false);
  //   }
  // }, [addjsonvalue.data, addjsonvalue.isSuccess]);

  useEffect(() => {
    // if (!pagesizeeee.isLoading) {
    // console.log("pagesizeeee", pagesizeeee.data.data[0].page_name);
    setsize(pagesizeeee.data);
    setsizeload(true);
    // }
  }, [pagesizeeee.data]);

  const name: any = savee;
  const handleClick = (e: any) => {
    if (e === "undo") {
      setActive(!active);
      setTimeout(() => {
        setActive(false);
      }, 100);
    } else if (e === "redo") {
      setactiveredo(!active);
      setTimeout(() => {
        setactiveredo(false);
      }, 100);
    } else if (e === "delete") {
      setactivedelete(!active);
      setTimeout(() => {
        setactivedelete(false);
      }, 100);
    }
  };

  const comicbookdeleteOpen = () => {
    comicbooksetOpen(!comicbookopen);
  };

  const comicsbookdeleteClose = () => {
    nav("/Comicbookpages");

    comicbooksetOpen(false);
  };
  const handleClickk = () => {
    dispatch(Unselect(unselect));
  };
  const dd = () => {
    close ? dispatch(Edit_option("unselect")) : dispatch(Edit_option("select"));
  };

  useEffect(() => {
    if (close) {
      dispatch(Unselect(true));
    } else {
      dispatch(Unselect(false));
    }
  }, [close]);
  // const unsele = () = {
  //   close?dispatch(Edit_option("unselect")):"";
  // }

  return (
    <>
      {pagesizeeee.isLoading ? (
        <div
          style={{
            marginBlock: "31vh",
          }}
        >
          <img src={Gif_KO} alt="loading..." width={360} height={270} />
        </div>
      ) : pagesizeeee?.data?.data ? (
        <div
          style={{
            height: "99.9vh",
            overflow: "hidden",
          }}
        >
          {/* header in editor */}
          <div className="header" style={{ zIndex: 1 }}>
            <div
              className="head_align"
              style={{
                marginLeft: "3%",
              }}
            >
              <div className="head_input">
                {pagesizeeee.data.data[0].page_name}
              </div>
            </div>
            <div className="head_align" style={{ marginRight: "2%" }}>
              <img src={pause} alt="invalid" className="head_leftarrow" />

              <button
                className="head_button"
                onClick={() => {
                  setsize(null);
                  comicbooksetOpen(true);
                }}
              >
                Cancel
              </button>
              <Dialog
                sx={{
                  width: "100%",
                  height: "100%",
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      borderRadius: "0px",
                      width: "27%",
                      // backgroundColor: "gray",
                    },
                  },
                }}
                open={comicbookopen}
                // TransitionComponent={Transition}
                keepMounted
                // onClose={comicsbookdeleteClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle
                  sx={{
                    backgroundColor: "#000000",
                    color: "white",
                  }}
                >
                  {"Cancel"}
                </DialogTitle>
                <DialogContent sx={{}}>
                  <DialogContentText
                    id="alert-dialog-slide-description"
                    sx={{
                      paddingTop: "40px",
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    Do you want to cancel this page?
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "25px",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={comicbookdeleteOpen}
                    sx={{
                      width: "110px",
                      height: "40px",
                      marginRight: "20px",
                      // fontSize: "13px",
                      // alignItems: "center",
                      // marginRight: "20px",
                      borderRadius: "0px",
                    }}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      // dispatch(delete_Image(deleteId));
                      // setPopoup(true);
                      comicsbookdeleteClose();
                    }}
                    sx={{
                      width: "110px",
                      height: "40px",
                      backgroundColor: "rgb(0, 0, 0)",
                      color: "#FFFFFF",
                      display: "flex",
                      fontSize: "13px",
                      alignItems: "center",

                      borderRadius: "0px",
                      justifyContent: "space-evenly",
                    }}
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>

              <button
                className="head_button Savebutton"
                onClick={() => {
                  setOpen(true);
                  dispatch(Edit_option("reset"));
                  dispatch(Edit_option("deactive"));
                }}
              >
                Save
              </button>
              {addjsonvalue.isLoading ? (
                <IsLoading isLoading={true}></IsLoading>
              ) : !isload ? (
                <Dialog
                  sx={{
                    width: "100%",
                    height: "100%",
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        borderRadius: "0px",
                        width: "27%",
                        // backgroundColor: "gray",
                      },
                    },
                  }}
                  open={open}
                  // TransitionComponent={Transition}
                  keepMounted
                  // onClose={comicsbookdeleteClose}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle
                    sx={{
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                  >
                    {"Save"}
                  </DialogTitle>
                  <DialogContent sx={{}}>
                    <DialogContentText
                      id="alert-dialog-slide-description"
                      sx={{
                        paddingTop: "40px",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      Do you want to save this page?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "25px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setOpen(false);
                        setIsload(false);
                      }}
                      sx={{
                        width: "110px",
                        height: "40px",
                        marginRight: "20px",
                        // fontSize: "13px",
                        // alignItems: "center",
                        // marginRight: "20px",
                        borderRadius: "0px",
                      }}
                    >
                      No
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        // dispatch(delete_Image(deleteId));
                        // setPopoup(true);
                        // comicsbookdeleteClose();
                        setIsload(true);
                        dispatch(Savee(true));
                        setTimeout(() => {
                          setOpen(false);
                          setIsload(false);
                          setSave(false);
                        }, 10);
                        // setOpen(false);
                      }}
                      sx={{
                        width: "110px",
                        height: "40px",
                        backgroundColor: "rgb(0, 0, 0)",
                        color: "#FFFFFF",
                        display: "flex",
                        fontSize: "13px",
                        alignItems: "center",

                        borderRadius: "0px",
                        justifyContent: "space-evenly",
                      }}
                    >
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* left nav */}
          <div style={{ display: "flex", height: "100%" }}>
            <div
              style={{ zIndex: 1, width: "22%", backgroundColor: "#464646" }}
            >
              <AddLayer></AddLayer>
              <div style={{ display: "flex", width: "99%", columnGap: "5px" }}>
                <button
                  className="layer_button"
                  onClick={() => {
                    dispatch(UpLayer(true));
                  }}
                >
                  <span className="material-icons-outlined">
                    <ArrowUpwardIcon />
                  </span>
                </button>
                <button
                  className="layer_button"
                  onClick={() => {
                    dispatch(DownLayer(true));
                  }}
                >
                  <span className="material-icons-outlined">
                    <ArrowDownwardIcon />
                  </span>
                </button>
                <button
                  className="layer_button"
                  onClick={() => {
                    dispatch(TopLayer(true));
                  }}
                >
                  <span className="material-icons-outlined">
                    <VerticalAlignTopIcon />
                  </span>
                </button>
                <button
                  className="layer_button"
                  onClick={() => {
                    dispatch(EndLayer(true));
                  }}
                >
                  <span className="material-icons-outlined">
                    <VerticalAlignBottomIcon />
                  </span>
                </button>
              </div>
            </div>
            {/* main view editor */}
            <div style={{ width: "55vw", backgroundColor: "#000000" }}>
              <div className="fabricpage">
                <div className="fabricposition">
                  <div
                    style={{
                      zIndex: 2,
                      marginLeft: "41%",
                      position: "absolute",
                      top: "15%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      backgroundColor: "#414141",
                    }}
                  >
                    {/* <div
                      style={{
                        fontSize: "20px",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      +
                    </div>
                    <button
                      style={{
                        fontSize: "20px",
                        color: "black",
                        cursor: "pointer",
                      }}
                    >
                      -
                    </button> */}

                    <button
                      title="Undo"
                      onClick={() => {
                        handleClick("undo");
                        dispatch(Edit_option("undo"));
                      }}
                    >
                      <span className="material-icons-outlined">
                        <UndoIcon />
                      </span>
                    </button>
                    <button
                      title="Redo"
                      onClick={() => {
                        handleClick("redo");
                        dispatch(Edit_option("redo"));
                      }}
                    >
                      <span className="material-icons-outlined">
                        <RedoIcon />
                      </span>
                    </button>
                    {/* <button
                      title="Delete"
                      onClick={() => {
                        handleClick("delete");
                        dispatch(Edit_option("delete"));
                      }}
                    >
                      <span className="material-icons-outlined">
                        <DeleteOutlineIcon />
                      </span>
                    </button> */}

                    <button
                      title="Drag / Resize"
                      onClick={() => {
                        dispatch(Edit_option("toggle"));

                        // dd();
                        setClose(!close);
                      }}
                      style={{
                        backgroundColor: close ? "white" : "#474a47",
                        color: close ? "black" : "white",
                      }}
                    >
                      <span className="material-icons-outlined">
                        {!close ? (
                          <div title="Drag">
                            <ToggleOffIcon />
                          </div>
                        ) : (
                          <div title="Resize">
                            <ToggleOnIcon />
                          </div>
                        )}
                        {/* {close ? "Resize" : "Drag"} */}
                      </span>
                    </button>
                    {close ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "13px",
                        }}
                      >
                        <button
                          title="Select / Unselect"
                          onClick={() => {
                            setUnselect(!unselect);
                            handleClickk();
                          }}
                        >
                          <span className="material-icons-outlined">
                            {checkEditPage.unselect ? (
                              <div title="select">
                                <CheckCircleOutlineIcon />
                              </div>
                            ) : (
                              <div title="Unselect">
                                <BlockIcon />
                              </div>
                            )}
                            {/* {!checkEditPage.unselect ? "unselect" : "select"} */}
                          </span>
                        </button>
                        <button
                          title="Left"
                          onClick={() => {
                            dispatch(Edit_option("left"));
                          }}
                        >
                          <span className="material-icons-outlined">
                            <KeyboardDoubleArrowLeftIcon />
                          </span>
                        </button>
                        <button
                          title="Right"
                          onClick={() => {
                            dispatch(Edit_option("right"));
                          }}
                        >
                          <span className="material-icons-outlined">
                            <KeyboardDoubleArrowRightIcon />
                          </span>
                        </button>
                        <button
                          title="Up"
                          onClick={() => {
                            dispatch(Edit_option("top"));
                          }}
                        >
                          <span className="material-icons-outlined">
                            <KeyboardDoubleArrowUpIcon />
                          </span>
                        </button>
                        <button
                          title="Down"
                          onClick={() => {
                            dispatch(Edit_option("bottom"));
                          }}
                        >
                          <span className="material-icons-outlined">
                            <KeyboardDoubleArrowDownIcon />
                          </span>
                        </button>
                        <button
                          title="Reset"
                          onClick={() => {
                            dispatch(Edit_option("reset"));
                          }}
                        >
                          <span className="material-icons-outlined">
                            <SyncIcon />
                          </span>
                        </button>

                        {/* 
                        <button
                          onClick={() => {
                            dispatch(Edit_option("selector"));
                          }}
                        >
                          Unselect
                        </button> */}
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <ZoomSelect></ZoomSelect> */}
                  </div>
                  <div>
                    {/* <EditFabric /> */}
                    <EditFabric data={pagesizeeee.data.data} />
                  </div>
                </div>
              </div>
            </div>
            {/* right nav */}
            <div
              style={{ width: "23%", backgroundColor: "#464646", zIndex: 1 }}
            >
              {checkEditPage.isobjectvalue === "textbox" ||
              checkEditPage.isobjectvalue === "group" ? (
                // && checkEditPage.isobjectvalue === "i-text"
                <ToolPage></ToolPage>
              ) : checkEditPage.isobjectvalue === "image" ? (
                <ImageEdit></ImageEdit>
              ) : checkEditPage.isEdit === "ImageQuestions" ? (
                <Question></Question>
              ) : checkEditPage.isEdit === "TextQuestions" ? (
                <TextQuestion />
              ) : checkEditPage.isobjectvalue === "empty" ? (
                ""
              ) : (
                ""
                // <Backg_clr></Backg_clr>
              )}
            </div>
          </div>
        </div>
      ) : (
        nav("/Comicbookpages")
      )}
    </>
  );
};
export default Editor;
