import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RoutingPages from "../Routers/Routerpages";
import LogoutIcon from "@mui/icons-material/Logout";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsSharpIcon from "@mui/icons-material/NotificationsSharp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Footer from "./Footer";
import Logo from "./Image/Logo.jpg";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import SettingsIcon from "@mui/icons-material/Settings";
import CottageIcon from "@mui/icons-material/Cottage";

import {
  Backdrop,
  CircularProgress,
  Link,
  Slide,
  Snackbar,
  SnackbarCloseReason,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { list_menuItem } from "../Redux/Reducers/menuItem/list_menuItem_slicer";
import { list_userPermission } from "../Redux/Reducers/UserPermission/list_UserPermission_slicer";
import { expireSignature } from "../Redux/Reducers/ExpireSignature/ExpireSignature_slicer";
import { clear_data } from "../Redux/Reducers/User-Register&Login/Login_slicer";
import UserProfile from "../Component/Menuuserprofile";
import { list_AnthorUserPermission } from "../Redux/Reducers/UserPermission/list_anthoruserpermission";
import IsLoading from "../Component/isLoading";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // backgroundColor: "red",
  // color: "white",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

//  style of scroll bar

const useStyles = makeStyles({
  drawer: {
    "& .MuiDrawer-paper": {
      "&::-webkit-scrollbar": {
        width: "0px",
        height: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "grey",
        borderRadius: "5px",
      },
    },
  },
});

export default function Sidenavbar(props: any) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [hoverActive, setHoverActive] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //    Logout functionality

  const nav = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("firstname");
    localStorage.removeItem("role_id");
    localStorage.removeItem("permission");
    localStorage.clear();
    if (!localStorage.getItem("token")) {
      nav("/login");
    }
  };

  //   Alert
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [openAlert, setOpenAlert] = React.useState<boolean>(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  //    Success message
  const log_in: any = useAppSelector((state) => state.Login.data);
  const roleId = localStorage.getItem("role_id");

  React.useEffect(() => {
    dispatch(list_AnthorUserPermission(roleId));
    dispatch(list_menuItem(""));
    log_in?.message?.length !== undefined &&
      log_in?.status &&
      setOpenAlert(true);
    return () => {
      dispatch(clear_data());
    };
  }, []);
  const ExpireSignature: any = useAppSelector(
    (state) => state.ExpireSignature.data
  );

  React.useEffect(() => {
    if (ExpireSignature?.response?.status === 401) {
      nav("/ErrorDialog");
      dispatch(expireSignature(null));
    }
  }, [ExpireSignature, dispatch, nav]);

  // login page username display
  const firstname: any = localStorage.getItem("firstname"); // Assuming localStorage contains the email address

  //    api loading
  const isLoading = useAppSelector((state) => state.MenuItem.isLoading);
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionComponent={Slide}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {log_in?.message}
        </Alert>
      </Snackbar>
      <AppBar
        position="fixed"
        open={open}
        style={{
          boxShadow: "none",
          backgroundColor: "white",
          border: "1px solid #d5d5d5",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              handleDrawerOpen();
              setHoverActive(false);
            }}
            edge="start"
            sx={{
              color: "black",
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h3"
            noWrap
            component="div"
            style={{
              // width: "221px",

              height: "29px",
              color: "black",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "25px",
              lineHeight: "21px",
            }}
          ></Typography>
          <Typography
            noWrap
            component="div"
            style={{ marginLeft: "auto" }}
          ></Typography>
          <span
            style={{
              color: "#756D85",
              margin: "10px 0px 0px 10px",
              fontSize: "20px",
            }}
          >
            {/* <NotificationsSharpIcon
                style={{
                  color: "black",
                }}
              /> */}
          </span>
          <UserProfile
            userName={firstname}
            manuItems={[
              {
                title: "Home",
                path: "/#",
                icon: <CottageIcon />,
                onclick: () => nav("/"),
              },
              // {
              //   title: "Profile",
              //   path: "/userprofile",
              //   icon: <AccountCircleIcon />,
              //   // onclick: goUserProfile,
              // },
              {
                title: "Settings",
                path: "/#",
                icon: <SettingsIcon />,
              },
              {
                title: "Logout",
                path: "/Login",
                onclick: logout,
                icon: <LogoutIcon />,
              },
            ]}
          />
        </Toolbar>
        {/* <LogoutIcon
              onClick={logout}
              style={{
                color: "black",
              }}
            ></LogoutIcon>
          </Toolbar> */}
      </AppBar>

      <div className={classes.drawer}>
        <Drawer
          variant="permanent"
          open={open}
          sx={{ border: "1px solid #d5d5d5" }}
          onMouseEnter={() => {
            hoverActive ? setOpen(true) : setOpen(open);
          }}
          // onMouseLeave={() => {
          //   hoverActive ? setOpen(false) : setOpen(open);
          // }}
        >
          <DrawerHeader>
            <span
              style={{
                fontSize: "30px",
                lineHeight: "16px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={Logo} alt="" width="150" height="66" />
            </span>

            <IconButton
              onClick={() => {
                handleDrawerClose();
                setHoverActive(true);
              }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider sx={{ borderColor: "#d5d5d5" }} />
          <List>
            {props.list?.map((value: any, key: number) => {
              return <Children value={value} key={key} />;
            })}
          </List>
        </Drawer>
      </div>
      <IsLoading isLoading={isLoading} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#FAFAFA",
          height: "100%",
          width: "78%",
        }}
      >
        <DrawerHeader />
        <RoutingPages />
        {/* <Footer></Footer> */}
      </Box>
    </Box>
  );
}

//   subnavbar sidenavbar

const Children = ({ value }: any) => {
  const [active, inactive] = React.useState(false);
  // const dispatch = useAppDispatch();
  const location = useLocation();
  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <>
      <NavLink
        to={value?.path}
        style={{
          textDecoration: "none",
          color: "white",
        }}
        onClick={() => {
          value?.options && inactive(!active);
        }}
      >
        <ListItem
          disableGutters
          dense
          sx={{
            "& .Mui-selected": {
              backgroundColor: "#F5F5F5 !important",
            },
          }}
        >
          <ListItemButton alignItems="center" selected={isActive(value.path)}>
            <ListItemIcon style={{ color: "#000000" }}>
              {value?.icon}
            </ListItemIcon>
            <ListItemText
              primary={value?.menuname}
              style={{
                color: "#000000",
              }}
            />
            {!value?.path ? (
              active ? (
                <KeyboardArrowDownIcon style={{ color: "#756D85" }} />
              ) : (
                <ChevronRightIcon style={{ color: "#756D85" }} />
              )
            ) : undefined}
            {value?.icon2}
          </ListItemButton>

          {/* <ListItemIcon style={{ color: "white" }}>{value?.icon}</ListItemIcon> */}
        </ListItem>
      </NavLink>

      {active &&
        value?.options?.map((e: any, index: number) => {
          return (
            <>
              <NavLink
                key={index}
                to={e.path}
                style={{
                  textDecoration: "none",
                  color: "#000000",
                }}
              >
                <ListItem
                  disableGutters
                  dense
                  sx={{
                    "& .Mui-selected": {
                      backgroundColor: "#F5F5F5 !important",
                    },
                  }}
                >
                  <ListItemButton
                    alignItems="center"
                    selected={isActive(e.path)}
                  >
                    <ListItemIcon
                      style={{ color: "#000000", justifyContent: "center" }}
                    >
                      {e.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={e.menuname}
                      style={{
                        color: "#000000",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </>
          );
        })}
      {/* </List> */}
    </>
  );
};
