import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";

interface ExtendState extends IReduxState<null> {
  isSuccess: boolean;
  launch: boolean;
  pause: boolean;
  switch: {
    popup: boolean;
    switch: boolean;
  };
  launchAndPauseId: number;
  projectId: number | null;
}
export const LaunchAndPauseCampaign = createSlice({
  name: "LaunchAndPauseCampaign",
  initialState: {
    isLoading: false,
    data: {},
    isSuccess: false,
    launch: false,
    pause: false,
    switch: {
      popup: false,
      switch: false,
    },
    launchAndPauseId: 0,
    projectId: null,
  } as ExtendState,
  reducers: {
    launchAndPass_Campaign: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_launchAndPass_Campaign: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    failure_api_call_launchAndPass_Campaign: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    false_success_launchAndPass_Campaign: (state) => {
      state.isSuccess = false;
      state.pause = false;
      state.launch = false;
      state.switch.popup = false;
    },
    /*      launch popup         */
    launch_popup_true: (state) => {
      state.launch = true;
    },
    launch_popup_false: (state) => {
      state.launch = false;
    },
    /*      pause popup         */
    pause_popup_true: (state) => {
      state.pause = true;
    },
    pause_popup_false: (state) => {
      state.pause = false;
    },
    /*    switch popup     */
    switch_popup_true: (state, action: PayloadAction<boolean>) => {
      state.switch.popup = true;
      state.switch.switch = action.payload;
    },
    switch_popup_false: (state) => {
      state.switch.popup = false;
    },
    /*    update launchAndPauseId    */
    updata_launchAndPauseId: (state, action: PayloadAction<number>) => {
      state.launchAndPauseId = action.payload;
    },
    /*    update projectId    */
    updata_project_Id: (state, action: PayloadAction<number | null>) => {
      state.projectId = action.payload;
    },
  },
});
export default LaunchAndPauseCampaign.reducer;
export const {
  launchAndPass_Campaign,
  success_api_call_launchAndPass_Campaign,
  failure_api_call_launchAndPass_Campaign,
  false_success_launchAndPass_Campaign,
  launch_popup_true,
  launch_popup_false,
  pause_popup_true,
  pause_popup_false,
  updata_launchAndPauseId,
  updata_project_Id,
  switch_popup_true,
  switch_popup_false,
} = LaunchAndPauseCampaign.actions;
