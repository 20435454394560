import { FunctionComponent } from "react";
import Grids from "../Component/Grides";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import "./Page.css";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { list_user } from "../Redux/Reducers/User-Register&Login/list_User_slicer";
import { list_role } from "../Redux/Reducers/Role/list_Role_slicer";
import { add_user } from "../Redux/Reducers/User-Register&Login/add_User_slicer";
import { edit_user } from "../Redux/Reducers/User-Register&Login/edit_User_slicer";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "../Component/DialogFormForUser";
import IsLoading from "../Component/isLoading";

interface UserProps {}

const User: FunctionComponent<UserProps> = () => {
  const dispatcher = useAppDispatch();
  const [closeDio, setCloseDio] = React.useState(true);
  const nav = useNavigate();
  const breadcrumbs = [
    // <Typography key="3" color="text.primary" style={{ display: "flex" }}>
    <Link key="1" color="black" underline="none">
      <b className="heading">Masters</b>
    </Link>,

    // <Typography key="3" color="text.primary">
    <Link
      underline="none"
      key="1"
      color="inherit"
      onClick={() => nav("/user")}
      style={{
        fontWeight: "500",
        fontSize: "15px ",
        lineHeight: "16px ",
        color: "#BDBDBD",
        cursor: "pointer",
      }}
    >
      User
    </Link>,
  ];
  React.useEffect(() => {}, [closeDio]);
  //    userpermission menuList
  const pageName = "Masters / Users";
  const menuList: any = JSON.parse(
    localStorage?.getItem("permission") as string
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageName;
  });

  const MenuOfUserpermission = menuList[index];

  const closeFunction = (e: boolean) => {
    // console.log("Sfd", e);

    setCloseDio(false);
  };

  const column = [
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          S.No
        </p>
      ),
      field: "field_id",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Name
        </p>
      ),
      field: "firstname",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Email
        </p>
      ),
      field: "email",
      flex: 1,
      headerAlign: "center",
    },

    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Mobile Number
        </p>
      ),
      field: "phone",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Role
        </p>
      ),
      field: "role",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Status
        </p>
      ),
      field: "active",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    MenuOfUserpermission?.edit
      ? {
          headerName: (
            <p
              className="headingnamebold"
              style={{ color: "black", fontWeight: "bolder" }}
            >
              Action
            </p>
          ),
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <Dialog
                successMessage={editData}
                isLoading={editDataIsLoading}
                Buttondialog={["Edit"]}
                Dialogtitle={" Edit User Details"}
                getValues={(v: any) => dispatcher(edit_user(v))}
                editusers={{
                  ...params.row,
                  active: params.row.active === "Active",
                }}
                sx={{
                  backgroundColor: "transparent",
                  minWidth: "0",
                  padding: "0",
                  borderRadius: "50%",
                  height: "60%",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                Buttonname={
                  <EditIcon
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "black",
                      height: "1.3em",
                      width: "1.3em",
                      padding: "15%",
                      borderRadius: "50%",
                      "&:hover": {
                        color: "#F5F5F5",
                        backgroundColor: "black",
                      },
                    }}
                    onClick={() => {
                      const index = Roledata.findIndex(
                        (i: any) => i?.name === params.row.role
                      );
                      // const roleId = Roledata[index]?.id;
                      // console.log({
                      //   ...params.row,
                      //   active: params.row.active === "Active" ? true : false,
                      //   role: roleId,
                      // });
                    }}
                  />
                }
              />
            );
          },
        }
      : {
          width: 0,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  const data: any = useAppSelector((state) => state.User.data);
  console.log("dddd", data);

  const isLoading: any = useAppSelector((state) => state.User.isLoading);
  const addData: any = useAppSelector((state) => state?.AddUser?.data);
  const addDataIsLoading = useAppSelector((state) => state?.AddUser?.isLoading);
  const editData: any = useAppSelector((state) => state?.editUser?.data);
  const editDataIsLoading = useAppSelector(
    (state) => state?.editUser?.isLoading
  );
  const RoleaddData = useAppSelector((state) => state?.AddRole?.data);
  const RoleeditData = useAppSelector((state) => state?.editRole?.data);
  const Roledata: any = useAppSelector((state) => state?.Role?.data);

  const userRole = localStorage
    .getItem("role")
    ?.toLowerCase()
    .replace(/\s/g, "");

  //    modifying data
  const datas = data
    .map((data: any, i: number) => {
      const index = Roledata.findIndex((i: any) => i?.id === data?.role);
      const roleName = Roledata[index]?.name;
      return { ...data, role: roleName, field_id: i };
    })
    .filter((user: any) => {
      const role = user?.role?.toLowerCase().replace(/\s/g, "");
      if (JSON.parse(userRole ?? "") === "superadmin") {
        return true;
      }
      return role !== "superadmin";
    });

  console.log(datas);

  React.useEffect(() => {
    dispatcher(list_user(""));
    dispatcher(list_role(""));
  }, [addData, editData, RoleaddData, RoleeditData]);
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <IsLoading isLoading={isLoading} />
      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={datas}
        column={column}
        // Users={"User"}
        open
        pagination
        dialog={
          MenuOfUserpermission?.add && (
            <Dialog
              successMessage={addData}
              isLoading={addDataIsLoading}
              sx={{
                padding: "15px",
                backgroundColor: "black",
                color: "white",
                width: "150px",
                height: "37px",
                borderRadius: "0px",
                border: "1px solid #BDBDBD",
                "&:hover": { backgroundColor: "black" },
              }}
              getValues={(v: any) => dispatcher(add_user(v))}
              Buttondialog={["Cancel"]}
              Dialogtitle={"Add  User"}
              // Dialogcontenttext={"hi"}
              Buttonname={"Add User"}
            />
          )
        }
        Userpermissions={undefined}
      ></Grids>
    </div>
  );
};

export default User;
