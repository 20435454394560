import { FunctionComponent } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
// import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../Pages/Page.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  FormHelperText,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BackupIcon from "@mui/icons-material/Backup";
import TextField from "@mui/material/TextField";
import Autocomplete from "./Autocomplete";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "../Pages/Page.css";
import IsLoading from "./isLoading";
// import IsLoading from "./isLoading";
// import { useAppDispatch } from "../redux/store/hooks";
// import { edit_bay } from "../redux/Bay/edit_Bay_slicer";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogsProps {
  sx?: SxProps<Theme> | undefined;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any | number;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onclick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties | undefined;
  getValues?: any;
  closeDio?: any;
  // error?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;

  // errors?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const Dialogfont: FunctionComponent<DialogsProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [val, setVal] = React.useState({});

  //    Popup messages
  const [popup, setPopoup] = React.useState(false);

  //    success dialog
  const [dialog, setDialog] = React.useState(false);

  // console.log(props.closeFunction);
  const { getValues, placeholder, successMessage, isLoading }: any = props;

  //   const dispatch = useAppDispatch();

  const initialValues: {
    name: string;
    font_reference_name: string;
    file_url: File | string;
    status: boolean;
  } = {
    name: props?.edit?.name || "",
    font_reference_name: props?.edit?.font_reference_name || "",
    file_url: props?.edit?.file_url || "",

    status: props?.edit?.status || (props?.edit?.status === undefined && true),
  };

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    // closeFunction(true);
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    setDialog(false);
    // dispatch(edit_bay('' as any));
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    setDialog(false);
    setPopoup(false);
  }, [open]);
  React.useEffect(() => {
    if (isLoading) {
      setDialog(isLoading);
    }
  }, [isLoading]);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        color={props.color}
        size={props.size}
        startIcon
        // disabled
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        // onClose={handleClose}
        // scroll={scroll}
        PaperProps={{
          sx: {
            borderRadius: "0",

            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            fontWeight: "bold",
            backgroundColor: "black",
            color: "white",
          }}
        >
          {props.Dialogtitle}
          <ClearOutlinedIcon
            className="clear"
            onClick={handleClose}
            sx={{ color: "white", float: "right" }}
          ></ClearOutlinedIcon>
        </DialogTitle>
        <DialogContent sx={{ padding: "1.5em 24px 0 !important" }}>
          <Formik
            initialValues={initialValues}
            // validationSchema={SignupSchema}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Please enter font name"),
              font_reference_name: Yup.string().required(
                "Please enter font reference name "
              ),
              status: Yup.boolean().required("Please select a status"),
              file_url: Yup.mixed().required("Please upload font"),
            })}
            onSubmit={(values) => {
              // getValues(values);
              setDialog(true);
              console.log("font", values);
            }}
          >
            {(props) => {
              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />

                  {/*     for submit when enter button click     */}
                  <button type="submit" style={{ display: "none" }}>
                    submit
                  </button>

                  {/* <PopupMessage
                    open={dialog}
                    submitFunction={props.handleSubmit}
                    successMessage={successMessage}
                    isLoading={isLoading}
                    Dialogtitle={"Are you sure you want to proceed?"}
                  /> */}
                  <div className="lableInputDiv">
                    <b
                      style={{
                        color: "#000000",
                      }}
                    >
                      Font Name <span style={{ color: "red" }}>*</span>
                    </b>
                    <TextField
                      id="outlined-basic"
                      // required
                      variant="outlined"
                      name="name"
                      sx={{
                        "& fieldset": {
                          borderRadius: "0px",
                        },
                        width: "clamp(20rem,30vw,30rem)",
                      }}
                      autoComplete="off"
                      helperText={
                        props.errors.name &&
                        props.touched.name &&
                        props.errors.name
                        // : "Enter your Email."
                      }
                      error={
                        props.errors.name && props.touched.name ? true : false
                      }
                      onChange={(event) => {
                        props.setFieldValue(
                          "name",
                          event.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                        );
                      }}
                      onBlur={props.handleBlur}
                      value={props.values.name}
                    />
                  </div>
                  <div className="lableInputDiv">
                    <b
                      style={{
                        color: "#000000",
                      }}
                    >
                      Font Reference Name{" "}
                      <span style={{ color: "red" }}>*</span>
                    </b>
                    <TextField
                      id="outlined-basic"
                      // required
                      variant="outlined"
                      name="font_reference_name"
                      sx={{
                        "& fieldset": {
                          borderRadius: "0px",
                        },
                        width: "clamp(20rem,30vw,30rem)",
                      }}
                      autoComplete="off"
                      helperText={
                        props.errors.font_reference_name &&
                        props.touched.font_reference_name &&
                        props.errors.font_reference_name
                        // : "Enter your Email."
                      }
                      error={
                        props.errors.font_reference_name &&
                        props.touched.font_reference_name
                          ? true
                          : false
                      }
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.font_reference_name}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "9.5em",
                    }}
                  >
                    <b
                      style={{
                        color: "#000000",
                      }}
                    >
                      Font Upload <span style={{ color: "red" }}>*</span>
                    </b>
                    <div
                      style={{
                        border:
                          props.touched.file_url && props.errors.file_url
                            ? "1px solid #d32f2f"
                            : "1px solid rgba(0, 0, 0, 0.23)",
                      }}
                    >
                      <label
                        htmlFor="upload-button"
                        className="custom-file-upload"
                        style={{
                          width: "clamp(20rem,30vw,30rem)",
                          height: "50px",
                          backgroundColor: "gray",
                          // border: "1px solid gray",
                          color: "white",
                          display: "flex",
                          fontSize: "13px",
                          alignItems: "center",
                          // marginRight: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <BackupIcon
                          style={{ color: "white", marginRight: "10px" }}
                        ></BackupIcon>
                        Upload Font
                      </label>
                      <div
                        id="font-preview"
                        style={{
                          border: "1px solid gray",
                          width: "clamp(20rem,30vw,30rem)",
                          height: "50px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        Font Preview
                      </div>
                      <input
                        type="file"
                        name="file_url"
                        id="upload-button"
                        onChange={(e: any) => {
                          const file = e.target.files[0];

                          const updateFile = new File(
                            [file],
                            file.name.replace(/[^\w.\s]/gi, ""),
                            { type: file.type }
                          );

                          console.log(updateFile.name);

                          // Check if the selected file has a .ttf extension
                          if (file && file.name.endsWith(".ttf")) {
                            // Valid font file, proceed with handling
                            props.setFieldValue("file_url", updateFile);
                            console.log(updateFile);

                            const reader = new FileReader();
                            reader.onload = (event: any) => {
                              const fontUrl = event.target.result.trim(); // Trim the font URL
                              const fontPreview = new FontFace(
                                "FontPreview",
                                `url(${fontUrl})`
                              );

                              fontPreview
                                .load()
                                .then((loadedFont) => {
                                  document.fonts.add(loadedFont);
                                  const previewElement =
                                    document.getElementById("font-preview");
                                  if (previewElement) {
                                    previewElement.style.fontFamily =
                                      "FontPreview";
                                  }
                                })
                                .catch((error) => {
                                  console.error("Error loading font:", error);
                                });
                            };

                            reader.readAsDataURL(file);
                          } else {
                            // Invalid file type, display an error message
                            alert("Please select a valid .ttf font file.");
                          }
                        }}
                        style={{ display: "none" }}
                        accept=".ttf"
                      />
                    </div>
                    <FormHelperText error sx={{ marginLeft: "13px" }}>
                      {props.touched.file_url &&
                        props.errors.file_url &&
                        "Please upload font"}
                    </FormHelperText>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <b
                      style={{
                        color: "#000000",
                      }}
                    >
                      Status <span style={{ color: "red" }}>*</span>
                    </b>
                    <Autocomplete
                      Listitem={[
                        { label: "Active", value: true },
                        { label: "Inactive", value: false },
                      ]}
                      // width={200}
                      // required
                      variant={"outlined"}
                      name={"status"}
                      value={props.values}
                      onBlur={props.handleBlur}
                      sx={{ width: "clamp(20rem,30vw,30rem)" }}
                      onchange={(e: any) => {
                        // console.log(e);

                        props.setFieldValue("status", e.value);
                      }}
                      error={props.errors.status && props.touched.status}
                      helperText={
                        props.errors.status &&
                        props.touched.status &&
                        props.errors.status
                      }
                    ></Autocomplete>
                  </div>
                  {!isLoading && (
                    <Dialogs
                      open={!isLoading ? dialog : false}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        sx: {
                          borderRadius: "0",
                          // height: height || "80vh",
                          "&::-webkit-scrollbar": { display: "none" },
                        },
                      }}
                    >
                      <DialogContent
                        sx={{
                          padding: "1.5em 1.5em 0 !important",
                        }}
                      >
                        {!popup ? (
                          <DialogContentText
                            sx={{ color: "black", fontSize: "16px" }}
                          >
                            Are you sure you want to proceed?
                            {/* <div className="submitPopup">
                              <div
                                style={{
                                  fontSize: "20px",
                                  textAlign: "center",
                                }}
                              >
                              </div>
                            </div> */}
                          </DialogContentText>
                        ) : (
                          // isLoading && (
                          <DialogContentText
                            sx={{ color: "black", fontSize: "16px" }}
                            id="alert-dialog-description"
                          >
                            <div className="submitPopup">
                              <div
                                style={{
                                  fontSize: "16px",
                                  color: "black",
                                  textAlign: "center",
                                  margin: "0px 40px 0px 40px ",
                                }}
                              >
                                {successMessage.message}
                              </div>
                              <div
                                style={{
                                  fontSize: "20px",
                                  textAlign: "center",
                                  margin: "0px 40px 0px 40px ",
                                }}
                              >
                                {successMessage?.status ? (
                                  <img
                                    src={require("../Pages/Image/success.png")}
                                    className="imageSize"
                                    alt="success"
                                  />
                                ) : (
                                  <img
                                    src={require("../Pages/Image/error.png")}
                                    className="imageSize"
                                    alt="error"
                                  />
                                )}
                              </div>
                            </div>
                          </DialogContentText>
                          // )
                        )}
                      </DialogContent>

                      {!popup ? (
                        <DialogActions
                          sx={{
                            justifyContent: "center",
                            gap: "0.5em",
                            padding: "1.5em",
                          }}
                        >
                          <Button
                            onClick={() => setDialog(false)}
                            style={{
                              padding: "15px",
                              // backgroundColor: "#1976D2",
                              color: "black",
                              width: "100px",
                              height: "37px",
                              borderRadius: "0px",
                              border: "1px solid black",
                            }}
                            sx={{
                              backgroundColor: "white",
                              "&:hover": { backgroundColor: "white" },
                            }}
                          >
                            No
                          </Button>
                          <Button
                            form="form"
                            type="submit"
                            onClick={() => {
                              // props.handleSubmit();
                              // setTimeout(() => setPopoup(true), 1000);
                              getValues(props.values);
                              setPopoup(true);
                            }}
                            style={{
                              // backgroundColor: "#1976D2",
                              color: "white",
                              width: "100px",
                              height: "37px",
                              borderRadius: "0px",
                              border: "1px solid black",
                            }}
                            autoFocus
                            sx={{
                              backgroundColor: "black",
                              "&:hover": { backgroundColor: "black" },
                            }}
                          >
                            Yes
                          </Button>
                        </DialogActions>
                      ) : (
                        // !props.isLoading && (
                        <DialogActions sx={{ justifyContent: "center" }}>
                          <Button
                            onClick={() => {
                              successMessage?.status && setOpen(false);
                              !successMessage?.status && setPopoup(false);
                              setDialog(false);
                            }}
                            style={{
                              // backgroundColor: "#1976D2",
                              color: "white",
                              width: "100px",
                              height: "37px",
                              borderRadius: "0px",
                              border: "1px solid #BDBDBD",
                            }}
                            autoFocus
                            sx={{
                              backgroundColor: "black",
                              "&:hover": { backgroundColor: "black" },
                            }}
                          >
                            OK
                          </Button>
                        </DialogActions>
                        // )
                      )}
                    </Dialogs>
                  )}
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",
            gap: "0.5em",
            padding: "1.5em",
          }}
        >
          <Button
            onClick={handleClose}
            // disabled={!props.isValid}
            style={{
              width: "100px",
              height: "40px",
              border: "1px solid black",
              color: "black",
              backgroundColor: "white",
              borderRadius: "0px",
            }}
          >
            Cancel
          </Button>
          <Button
            // onClick={() => {
            //   setDialog(true);
            // }}
            form="form"
            type="submit"
            // disabled={!props.isValid}
            // disabled={
            //   (Object.keys(props.errors ?? {}).length === 0 &&
            //     Object.keys(props.touched ?? {}).length === 0) ||
            //   Object.keys(props.errors ?? {}).length !== 0
            // }
            style={{
              // border: "1px solid black",
              width: "100px",
              height: "40px",
              border: "1px solid black",
              color: "white",
              // backgroundColor: "#C00A30",
              backgroundColor: "black",
              borderRadius: "0px",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialogs>
    </>
  );
};

export default Dialogfont;
