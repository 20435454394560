import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

interface UserProfileProps {
  manuItems?: any[];
  userImage?: string;
  userName?: any;
}

const UserProfile: FunctionComponent<UserProfileProps> = (props) => {
  const { manuItems, userImage, userName } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ textTransform: "none", color: "#756D85" }}
      >
        <AccountCircleIcon sx={{ color: "gray", marginRight: "6px" }} />
        <span style={{ color: "black" }}>
          <>Hi {userName}</>
        </span>
        <span
          style={{ paddingLeft: "10px", fontWeight: "20px", marginTop: "9px" }}
        >
          <LogoutIcon sx={{ color: "black" }}></LogoutIcon>
        </span>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disableAutoFocusItem
      >
        {manuItems?.map((item, index) => {
          return (
            <MenuItem
              onClick={() => {
                handleClose();
                item?.onclick();
              }}
              component={Link}
              to={item?.path}
              key={index}
              sx={{
                textDecoration: "none",
                color: "black",
                "&:active": { textDecoration: "none" },
                "&:hover": { textDecoration: "none", color: "black" },
                "&:focus": { textDecoration: "none", color: "black" },
              }}
            >
              {item?.icon} {item?.title}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default UserProfile;
