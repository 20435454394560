import { createSlice } from "@reduxjs/toolkit";

export const AddComicsBook = createSlice({
  name: "AddComicsBook",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    add_ComicsBook: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_add_ComicsBook: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_add_ComicsBook: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      // FAILED(action.payload);
    },
    add_ComicsBook_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_ComicsBook_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default AddComicsBook.reducer;
export const {
  add_ComicsBook,
  success_api_call_add_ComicsBook,
  failure_api_call_add_ComicsBook,
  create_ComicsBook_complete_api,
  add_ComicsBook_is_success,
} = AddComicsBook.actions;
