import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  failure_api_call_PageSize,
  success_api_call_PageSize,
} from "./list_PageSize_slicer";
import {
  failure_api_call_add_PageSize,
  success_api_call_add_PageSize,
} from "./add_PageSize_slicer";
import {
  failure_api_call_edit_PageSize,
  success_api_call_edit_PageSize,
} from "./edit_PageSize_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";

//add PageSize
const addPageSizeCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  // console.log(a.payload);
  const adminId = JSON.parse(localStorage.getItem("id")!);
  const add = {
    id: a.payload.id,
    height: a.payload.height,
    width: a.payload.width,
    status: a.payload.status,
    bleed_margin: a.payload.bleed_margin,

    name: a.payload.name,
    created_by: adminId,
  };

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/page_size/`,
    add,

    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddPageSizeApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addPageSizeCall, e);

    yield put(success_api_call_add_PageSize(response.data));
  } catch (e) {
    yield put(failure_api_call_add_PageSize(e));
    yield put(expireSignature(e));
  }
}

//Edit  PageSize

const editPageSizeCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  const adminId = JSON.parse(localStorage.getItem("id")!);

  // const id = a.payload.id;
  const edit = {
    id: a.payload.id,
    name: a.payload.name,
    height: a.payload.height,
    width: a.payload.width,
    bleed_margin: a.payload.bleed_margin,

    status: a.payload.status,
    // page_type_name: a.payload.page_type_name,

    updated_by: adminId,
  };
  // console.log(edit);

  return Axios.put(`${process.env.REACT_APP_api_url}/v1/api/page_size/`, edit, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* editPageSizeApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editPageSizeCall, e);
    // console.log(response.data);

    yield put(success_api_call_edit_PageSize(response.data));
  } catch (e) {
    yield put(failure_api_call_edit_PageSize(e));
    yield put(expireSignature(e));
  }
}

//list PageSize
const listPageSizeCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/page_size/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* listPageSizeApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listPageSizeCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    yield put(success_api_call_PageSize(res));
  } catch (e) {
    yield put(failure_api_call_PageSize(e));
    yield put(expireSignature(e));
  }
}
