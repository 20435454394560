function PrivateRoute({ children }: any) {
  const token = localStorage.getItem("token");
  if (token !== null && token !== "undefined") {
    return children;
  } else {
    console.log(token);

    window.location.pathname = "/login";
  }
}
export default PrivateRoute;
