// ADD new Layer button page click to open side tool image,text,upload,questions...
import { New_layer } from "./Edit-component/new-layer";
import img1 from "./accets/img(5).svg";
import text from "./accets/text.svg";
import uploads from "./accets/upload.svg";
import question from "./accets/question.svg";
import line from "./accets/line.svg";
import arrow from "./accets/menuarrowleft.svg";
import React, { useState, useEffect, useRef, createContext } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  DownLayer,
  Edit_option,
  Edit_text,
  Enter_Input,
  Get_Canvas,
  Image_library,
  NoImage,
  UpLayer,
  clickCurve,
  textPTS,
  textUpdate,
} from "../Redux/Reducers/Editor_value";
import Dialogs from "../Component/Dialog";
import ImageUpload from "../Editor/Pages/ImageLibrary";
import ImageLibraryForEditor from "./Pages/ComicsLibrary";
import { add_PageQuestions } from "../Redux/Reducers/PageQuestions/add_PageQuestions_slicer";
import DialogBody from "./Pages/ComicsLibrary";
import { List_questionID } from "../Redux/Reducers/QuestionID/QuestionID_Slicer";
import { list_PageQuestions } from "../Redux/Reducers/PageQuestions/list_PageQuestions_slicer";
import { List_textOption } from "../Redux/Reducers/TextOptions/listOfTextOption_Slicer";
import { clearExtraData } from "../Redux/Reducers/TextOptions/TextOptions_Slicer";
import { cleartextOptionsList } from "../Redux/Reducers/TextOptions/textOptionsList_Slicer";

const AddLayer = () => {
  const dispatch = useDispatch();
  const [sideopen, setSideopen] = useState<any>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [textopen, settextOpen] = useState(false);
  const [shorttext, setshorttextOpen] = useState(false);
  const [comicsimageopen, setcomicsimageopen] = useState(false);
  const [questionss, setquestionss] = useState(false);
  const [count, setLayers] = useState<any>([]);
  const checkEditPage = useSelector((state: any) => state.DetailEdit);

  const canvas_value = checkEditPage.isCanvas
    ? JSON.parse(checkEditPage.isCanvas)
    : "";

  const handleAddLayer = () => {
    setLayers([...count, ""]);
  };

  const subtoolside = () => {
    setSideopen((prevCheck: any) => !prevCheck);
  };

  const position: any = useSelector(
    (state: any) => state.AddComicsBookPageSize.data
  );
  // console.log(position);

  return (
    <>
      <button
        className="layer_button"
        onClick={() => {
          handleAddLayer();
          subtoolside();
          dispatch(Edit_option("deactive"));
        }}
      >
        Add a New Layer
      </button>

      {sideopen == true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "15px",
            zIndex: 1,
          }}
        >
          <div className="sidearrow">
            <img className="sidearrowimg" src={arrow} alt="invalid" />
          </div>
          <div className="side-menu">
            <div>
              <div
                onClick={() => {
                  dispatch(Edit_text("image"));
                  setshorttextOpen(false);
                  setquestionss(false);
                  // dispatch(Image_library(true));
                  // setOpen(!isopen);
                  settextOpen(false);
                  setIsOpen(!isOpen);
                  dispatch(Edit_option("deactive"));
                }}
              >
                <div>
                  {isOpen && checkEditPage.isEdit === "image" ? (
                    <div>
                      <div className="sidearroww">
                        <img
                          className="sidearrowimgg"
                          src={arrow}
                          alt="invalid"
                        />
                      </div>
                      <div
                        style={{
                          backgroundColor: "#343434",
                          zIndex: 1,
                          height: "7vh",
                          display: "flex",
                          flexDirection: "column",
                          position: "absolute",
                          left: "121%",
                          width: "130px",
                          top: "10%",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          className="butn_img"
                          style={{
                            backgroundColor: "#343434",
                            color: "white",
                            border: "none",
                            height: "50%",
                            fontSize: "12px",
                          }}
                          onClick={() => {
                            setOpen(!open);
                            // setOpen(true);
                            // dispatch(Image_library(false));
                          }}
                        >
                          Image Library
                        </button>

                        <button
                          className="bth_img"
                          style={{
                            backgroundColor: "#343434",
                            color: "white",
                            border: "none",
                            height: "50%",
                            fontSize: "12px",
                          }}
                          onClick={() => {
                            dispatch(NoImage(true));
                            // dispatch(
                            //   List_questionID({
                            //     project_id: parseInt(
                            //       localStorage.getItem("Project_Id")!
                            //     ),
                            //     q_type: "I",
                            //   })
                            // );
                            // setcomicsimageopen(!comicsimageopen);
                            // dispatch(
                            //   add_PageQuestions({
                            //     page_id: parseInt(
                            //       localStorage.getItem("Page_Id")!
                            //     ),
                            //     project_id: parseInt(
                            //       localStorage.getItem("Project_Id")!
                            //     ),
                            //   })
                            // );

                            // setIsOpen(true);
                            // dispatch(Image_library(false));
                          }}
                        >
                          Comics Image
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <img className="sideimag" src={img1} alt="invalid" />
                <div className="sidetext"> Image</div>
                <img src={line} alt="invalid" />
              </div>
              {open ? (
                <div
                  onClick={() => {
                    // setOpen(false);
                  }}
                >
                  <Dialogs
                    open={open}
                    setOpen={setOpen}
                    DialogBody={<ImageUpload setOpen={setOpen} />}
                    maxWidth={"lg"}
                  />
                </div>
              ) : (
                // setOpen(false)
                ""
              )}
              {comicsimageopen ? (
                <div
                  onClick={() => {
                    // setcomicsimageopen(false);
                  }}
                >
                  <Dialogs
                    open={comicsimageopen}
                    setOpen={setcomicsimageopen}
                    DialogBody={
                      <DialogBody
                        setOpenDialog={setcomicsimageopen}
                        openDialog={open}
                      />
                    }
                    maxWidth={"md"}
                  />
                </div>
              ) : (
                ""
              )}
              <div
                onClick={() => {
                  // textcall();
                  dispatch(Edit_text(""));
                  setIsOpen(false);
                  setshorttextOpen(!shorttext);
                  dispatch(Edit_text("text"));
                  setquestionss(false);
                  dispatch(Edit_option("deactive"));

                  // dispatch(Get_Canvas(""));

                  // settextOpen(false);
                }}
              >
                <img className="sideimag" src={text} alt="invalid" />
                <div className="sidetext">Text</div>
                {position.data[0].type === "PAGE" && (
                  <img src={line} alt="invalid" />
                )}
              </div>
              <div>
                {shorttext && checkEditPage.isEdit === "text" ? (
                  <div>
                    <div className="sidearroww2">
                      <img
                        className="sidearrowimgg"
                        src={arrow}
                        alt="invalid"
                      />
                    </div>
                    <div
                      style={{
                        backgroundColor: "#343434",
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        left: "121%",
                        width: "130px",
                        top: position.data[0].type === "PAGE" ? "49%" : "49%",
                        justifyContent: "center",
                        alignItems: "center",
                        // paddingBlock: "10px",
                        zIndex: 1,
                      }}
                    >
                      <button
                        className="butn_img"
                        style={{
                          paddingBlock: "10px",
                          width: "100%",
                          backgroundColor: "#343434",
                          color: "white",
                          border: "none",
                          height: "33.3%",
                          fontSize: "12px",
                        }}
                        onClick={() => {
                          settextOpen(!textopen);
                          dispatch(Edit_text("text"));
                          // dispatch(Edit_text("TextQuestions"));

                          dispatch(Enter_Input("Default text"));
                          setshorttextOpen(!shorttext);
                          dispatch(textPTS("T"));
                          dispatch(textUpdate(""));
                          dispatch(clickCurve(false));
                          dispatch(clearExtraData());
                          dispatch(cleartextOptionsList());
                        }}
                      >
                        Text
                      </button>

                      {/* <button
                        className="bth_img"
                        style={{
                          backgroundColor: "#343434",
                          color: "white",
                          border: "none",
                          height: "33.3%",
                          fontSize: "12px",
                        }}
                        onClick={() => {
                          settextOpen(!textopen);
                          dispatch(Edit_text("text"));
                          dispatch(Enter_Input("Default text"));
                          setshorttextOpen(!shorttext);
                          dispatch(textPTS("P"));
                          dispatch(textUpdate(""));
                          dispatch(clickCurve(false));
                        }}
                      >
                        Paragraph
                      </button>
                      <button
                        className="bth_img"
                        style={{
                          backgroundColor: "#343434",
                          color: "white",
                          border: "none",
                          height: "33.3%",
                          fontSize: "12px",
                        }}
                        onClick={() => {
                          settextOpen(!textopen);
                          dispatch(Edit_text("text"));

                          // dispatch(Enter_Input("Default text"));
                          setshorttextOpen(!shorttext);
                          dispatch(textPTS("S"));
                          dispatch(textUpdate(""));
                          dispatch(clickCurve(false));
                        }}
                      >
                        Sentence
                      </button> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>{" "}
              {/* <div
                onClick={() => {
                  dispatch(Edit_text("upload"));
                  settextOpen(false);
                  setIsOpen(false);
                }}
              >
                <img className="sideimag" src={uploads} alt="invalid" />
                <div className="sidetext">Upload</div>
                <img src={line} alt="invalid" />
              </div> */}
              {position.data[0].type === "PAGEtesting" && (
                <div
                  onClick={() => {
                    // dispatch(Edit_text(""));
                    // dispatch(
                    //   List_questionID({
                    //     project_id: parseInt(localStorage.getItem("Project_Id")!),
                    //     q_type: "I",
                    //   })
                    // );
                    // dispatch(
                    //   add_PageQuestions(
                    //     parseInt(localStorage.getItem("Page_Id")!)
                    //   )
                    // );

                    setquestionss(!questionss);
                    setshorttextOpen(false);
                    settextOpen(false);
                    setIsOpen(false);
                  }}
                >
                  <div>
                    {questionss ? (
                      <div>
                        <div className="sidearrowww">
                          <img
                            className="sidearrowimgg"
                            src={arrow}
                            alt="invalid"
                          />
                        </div>
                        <div
                          style={{
                            backgroundColor: "#343434",
                            zIndex: 1,
                            height: "7vh",
                            display: "flex",
                            flexDirection: "column",
                            position: "absolute",
                            left: "121%",
                            width: "130px",
                            top: "70%",
                            justifyContent: "space-between",
                          }}
                        >
                          <button
                            className="butn_img"
                            style={{
                              backgroundColor: "#343434",
                              color: "white",
                              border: "none",
                              height: "50%",
                              fontSize: "12px",
                            }}
                            onClick={() => {
                              dispatch(Edit_text("TextQuestions"));
                              dispatch(
                                List_textOption({
                                  project_id: parseInt(
                                    localStorage.getItem("Project_Id")!
                                  ),
                                  q_type: "T",
                                  // text_type: checkEditPage.istextPTS,
                                  // text_type: "T",
                                } as any)
                              );

                              // setOpen(!open);
                              // setOpen(true);
                              // dispatch(Image_library(false));
                              setquestionss(!questionss);
                            }}
                          >
                            Text Questions
                          </button>

                          <button
                            className="bth_img"
                            style={{
                              backgroundColor: "#343434",
                              color: "white",
                              border: "none",
                              height: "50%",
                              fontSize: "12px",
                            }}
                            onClick={() => {
                              dispatch(Edit_text("ImageQuestions"));

                              setquestionss(!questionss);

                              dispatch(
                                List_questionID({
                                  project_id: parseInt(
                                    localStorage.getItem("Project_Id")!
                                  ),
                                  q_type: "I",
                                })
                              );
                              // dispatch(
                              //   list_PageQuestions(
                              //     parseInt(localStorage.getItem("Page_Id")!)
                              //   )
                              // );
                            }}
                          >
                            Image Questions
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <img className="sideimag" src={question} alt="invalid" />
                  <div className="sidetext">Questions</div>
                </div>
              )}
            </div>
          </div>

          {/* {count.map((e: any, index: any) => {
          return (
            <New_layer
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              brand={index}
            ></New_layer>
          );
        })} */}
        </div>
      ) : (
        ""
      )}
      <div style={{ marginTop: "10px" }}>
        {canvas_value.objects ? (
          canvas_value.objects.length > 0 ? (
            <New_layer></New_layer>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AddLayer;
