import { FunctionComponent, useEffect } from "react";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Input,
  Slide,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import reuqird from "./Image/carbon_warning.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import React from "react";
import { login } from "../Redux/Reducers/User-Register&Login/Login_slicer";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { useNavigate } from "react-router";
import Logo from "./Image/Logo.jpg";
import { styled } from "@mui/system";
import Eye from "./Image/Eye.png";
import Eyes from "./Image/Eye2.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialogreset from "../Component/DialogForFormReset";
import {
  add_AddResetPassword,
  clearData,
} from "../Redux/Reducers/ResetPassword/add_ResetPassword_slicer";

interface LoginvalidationProps {}

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#FF7F00 ",
//     },
//   },
// });
const Loginvalidation: FunctionComponent<LoginvalidationProps> = () => {
  const [opens, setOpens] = React.useState(false);
  const dispatcher = useAppDispatch();
  const addData: any = useAppSelector((state) => state?.AddResetPassword?.data);

  const addDataIsLoading = useAppSelector(
    (state) => state?.AddResetPassword?.isLoading
  );

  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleCloses = () => {
    setOpens(false);
  };
  const CustomSpan = styled("span")({
    fontWeight: "100",
    fontSize: "22px",
    fontFamily: "Arial, sans-serif",
  });
  const HelloSpan = styled("span")({
    fontWeight: "100",
    fontSize: "30px",
    fontFamily: "Arial, sans-serif",
  });
  // const [isFocused, setIsFocused] = useState(false);
  // const handleFocus = () => {
  //   setIsFocused(true);
  // };

  // <>    Alert msg   </>
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [loading, setLoading] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const nav = useNavigate();

  const dispatch = useAppDispatch();
  const log_in: any = useAppSelector((state) => state.Login.data);
  const isLoading = useAppSelector((state) => state.Login.isLoading);

  // <>  useEffect  <>
  useEffect(() => {
    setOpen(false);
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
      // log_in?.message?.length !== undefined && setOpen(true);
      log_in?.message?.length !== undefined && !log_in?.status && setOpen(true);
      const token = localStorage.getItem("token");

      if (token !== null && token !== "undefined") {
        nav("/home");
      }
    }
    // if (login?.data?.status) {
    //   // localStorage.setItem("SpToken", login.data.token);
    //   setLoading(false);
    //   // nav("/home");
    //   // if (login?.data?.message === "Login successfully") {
    //   //   nav("/home");
    //   // }
    // } else {
    //   login.length !== 0 && setOpen(true);
    //   setLoading(false);
    // }
  }, [isLoading, nav]);

  return (
    <div
      style={{
        // padding: "2rem",
        display: "flex",
        justifyContent: "center",
        // minHeight: "70vh",
        // margin: "250px 30px 30px 30px ",
        alignItems: "center",
        // boxShadow: "6px 6px 18px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionComponent={(props: any) => {
          return <Slide {...props} direction="down" />;
        }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          {log_in?.message}
        </Alert>
      </Snackbar>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* <>  Google sign in button   </> */}
        {/* <LoadingButton
            style={{
              textTransform: "none",
              backgroundColor: "rgb(224 224 224)",
              width: "22rem",
            }}
            variant="text"
            color="inherit"
            size="large"
            onClick={() => {
              handleClickGoogle();
            }}
            loading={loadingGoogle}
            startIcon={<FcGoogle title="Sign In With Google" />}
          >
            Sign In With Google
          </LoadingButton> */}
      </div>

      {/* <>    horizontal line with text   </> */}
      {/* <Divider style={{ margin: "2rem 0" }}>or</Divider> */}

      {/* <>    Login Form    </> */}

      <div>
        <span
          style={{
            fontWeight: "50",
            // height: "30px",
            fontSize: "30px",
            lineHeight: "23px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            margin: "50px",
          }}
        >
          <img src={Logo} alt="" height={100} />
        </span>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "15px 0px 15px 0px",
          }}
        >
          <HelloSpan>Hello</HelloSpan>
          <span
            style={{
              fontFamily: "sans-serif",

              color: "black",
              fontSize: "30px",
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            Welcome
          </span>
        </span>

        <CustomSpan>Login to your existing account</CustomSpan>

        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={(values: any) => {
            // axios
            //   .post(
            //     `${process.env.REACT_APP_api_url}/v1/api/user/login`,
            //     values
            //   )
            //   .then((response) => {
            //     // setMsg(response);
            //     // console.log(response);
            //     dispatch(add_data(response));
            //     response.data.status && nav("/");
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //   });

            dispatch(login(values));

            // handleClick();
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required("Enter valid email"),
            password: Yup.string()
              .min(8, "Must Contain 8 Characters")
              .required("Please enter a valid password"),
          })}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
          }) => (
            <Form
              autoComplete="off"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "30rem",
              }}
            >
              {/* <ThemeProvider theme={theme}> */}
              <TextField
                name="email"
                id="email"
                style={{ width: "100%", margin: "50px 0px 50px 0px " }}
                label="Email"
                variant="standard"
                margin="dense"
                size="small"
                sx={{
                  "& label": {
                    color: "black",
                  },
                  "&   input": {
                    width: "25rem",
                    color: "#000000",
                  },
                  "& input:focus": {
                    // Styles for input when focused
                    outline: "none", // Remove the default blue outline
                    borderColor: "red", // Set the desired color for the input border when focused
                    boxShadow: "none", // Remove any box shadow when focused
                  },
                }}
                type="text"
                autoComplete="off"
                error={errors.email && touched.email ? true : false}
                helperText={
                  errors.email &&
                  touched.email && (
                    <Typography
                      variant="body2"
                      color="error"
                      sx={{ fontSize: "12px" }}
                    >
                      <img
                        src={reuqird}
                        alt="Error"
                        style={{
                          marginRight: "5px",
                          verticalAlign: "middle",
                          width: "13px",
                          marginTop: "-4px",
                        }}
                      />
                      Please enter the valid email
                    </Typography>
                  )
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <FormControl
                variant="standard"
                margin="dense"
                style={{ width: "100%", margin: "10px 0px 10px 0px " }}
                size="small"
                sx={{
                  "&   input": {
                    width: "22rem",
                    color: "#000000",
                  },
                }}
              >
                <InputLabel
                  htmlFor="standard-adornment-password"
                  style={{
                    color:
                      errors.password && touched.password ? "#d32f2f" : "black",
                  }}
                >
                  Password
                </InputLabel>
                <Input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  inputProps={{
                    autoComplete: "off",
                  }}
                  error={errors.password && touched.password ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{
                        "& input": {
                          color: "#000000",
                        },
                      }}
                    >
                      <IconButton
                        sx={{ marginLeft: "90px" }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <img src={Eyes} alt="show" width={20} height={20} />
                        ) : (
                          <img src={Eye} alt="hide" width={20} height={20} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {errors.password && touched.password && (
                    <span style={{ color: "#d32f2f" }}>
                      <img
                        src={reuqird}
                        alt="Error"
                        style={{
                          marginRight: "5px",
                          verticalAlign: "middle",
                          width: "13px",
                          marginTop: "-4",
                        }}
                      />
                      {errors.password}
                    </span>
                  )}
                </FormHelperText>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  padding: "20px 0px 20px 0px ",
                }}
              >
                <FormControlLabel
                  value="Remember Me"
                  control={<Checkbox />}
                  label="Remember Me"
                  labelPlacement="end"
                  // sx={{ width: "20px" }}
                />

                <Dialogreset
                  successMessage={addData}
                  isLoading={addDataIsLoading}
                  sx={{
                    padding: "15px",
                    backgroundColor: "black",
                    color: "white",
                    width: "150px",
                    height: "37px",
                    borderRadius: "0px",
                    border: "1px solid #BDBDBD",
                    marginLeft: "10px",
                    marginTop: "0px",
                    "&:hover": { backgroundColor: "black" },
                  }}
                  placeholder="Reset Password"
                  getValues={(v: any) => {
                    dispatcher(add_AddResetPassword(v));
                    // console.log(v);
                  }}
                  Buttondialog={["Cancel"]}
                  Dialogtitle={"Reset Password"}
                  Buttonname={"Reset Password"}
                ></Dialogreset>
              </div>
              <LoadingButton
                type="submit"
                variant="contained"
                // onClick={()=>}
                fullWidth
                loadingIndicator={
                  <CircularProgress sx={{ color: "white" }} size={20} />
                }
                loading={loading}
                style={{
                  textTransform: "none",
                  backgroundColor: "black",
                  // width: "100%",
                  // height: "37px",
                  borderRadius: "0px",
                }}
                // fullWidth
              >
                <span>Login</span>
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Loginvalidation;
function setOpen(arg0: boolean) {
  throw new Error("Function not implemented.");
}
