import "./Ordermanagment.css";

import { FunctionComponent, useEffect, useState } from "react";
import Grids from "../../Component/Grides";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowModel,
} from "@mui/x-data-grid";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Link,
  Stack,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import {
  list_OrderManagement,
  ordermanagement_search,
  update_shiping_level,
} from "../../Redux/Reducers/OrderManagement/list_OrderManagement_slicer";
import { IOrderManagement } from "../../interfaces/IOrderManagment";
import {
  clear_SendShopifyRedirectUrl,
  send_url,
} from "../../Redux/Reducers/Shopify/shopify_redirect_url_send_slicer";
import { get_url } from "../../Redux/Reducers/Shopify/shopify_redirect_url_slicer";
import { PDF_Genaration } from "../../Redux/Reducers/PDF/PDF_genaration_slicer";
import { CoverPDF_Genaration } from "../../Redux/Reducers/PDF/Cover_PDF_genaration_slicer";
import { ReactComponent as CoverdownloadIcon } from "../Image/coverDownload.svg";
import { ReactComponent as PdfDownload } from "../Image/pdfDownload.svg";
import { Customer_PDF_Genaration } from "../../Redux/Reducers/PDF/Customer_pdf";
import {
  close_print_popup,
  lulu_Print,
  open_print_popup,
} from "../../Redux/Reducers/LuluPrint/LuluPrint_slicer";
import LoadingButtonC from "../../Component/LoadingButton";
import Dialogs from "../../Component/Dialog";

interface OrderManagmentProps {}

const OrderManagment: FunctionComponent<OrderManagmentProps> = () => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const breadcrumbs = [
    // <Typography key="3" color="text.primary" style={{ display: "flex" }}>
    <Link
      key="1"
      color="black"
      onClick={() => nav("/OrderManagement")}
      underline="none"
      sx={{ cursor: "pointer" }}
    >
      <b className="heading">Order Management</b>
    </Link>,

    // <Typography key="3" color="text.primary">
  ];

  //    userpermission menuList
  const pageName = "Order Management";
  const menuList: any = JSON.parse(
    localStorage?.getItem("permission") as string
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageName;
  });

  const MenuOfUserpermission = menuList[index];

  /*    Redux state    */
  const { data: orderList, search: searchValue } = useAppSelector(
    (state) => state.ListOrderManagement
  );
  const orderListIsLoading = useAppSelector(
    (state) => state.ListOrderManagement.isLoading
  );

  //    PDF
  const { data: PdfData, loadingIDs: PDFGenarationIsLoading } = useAppSelector(
    (state) => state.PDFGenaration
  );
  const { data: coverData, loadingIDs: CoverPDFGenarationIsLoading } =
    useAppSelector((state) => state.CoverPDFGenaration);

  const { data: CustData, loadingIDs: CustomerPDFGenarationIsLoading } =
    useAppSelector((state) => state.Customer_pdf);
  //    Lulu print
  const {
    data: luluData,
    loadingIDs: luluPrintLoading,
    popup: luluPrintPopup,
    luluData: luluPrintData,
    order_number: printOrderNumber,
  } = useAppSelector((state) => state.LuluPrint);

  const column: GridColDef[] = [
    {
      renderHeader: (params: GridColumnHeaderParams) => <b>S.NO</b>,
      field: "field_id",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Order ID</b>,
      field: "name",
      flex: 1,
      headerAlign: "center",
      type: "string",
      align: "center",
      renderCell: (params) => params.value,
    },

    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Date</b>,
      field: "created_at",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "dateTime",
      valueGetter: (params) => {
        return new Date(params.value);
      },
      renderCell: (params) => {
        // Create a Date object from the input string
        const date = new Date(params.value);

        // Extract day, month, and year components
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are 0-based, so we add 1
        const year = date.getFullYear();

        // Format day and month to have leading zeros if necessary
        const formattedDay = String(day).padStart(2, "0");
        const formattedMonth = String(month).padStart(2, "0");

        // Create the formatted date string
        const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
        return (
          <div
            style={
              {
                // backgroundColor: "#0000001f",
                // borderRadius: "6rem",
                // width: "6rem",
              }
            }
          >
            {formattedDate}
          </div>
        );
      },
    },
    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Order Details</b>,
      field: "token",
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<IOrderManagement>) => {
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              margin: "1em 0",
              gap: "1em",
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <img
              src={require("../Image/Noimge.png")}
              srcSet={params.row.line_items[0].imageData!}
              alt={params.row?.line_items[0]?.title}
              style={{
                height: "clamp(4rem,5vw,8rem)",
                width: "clamp(4rem,5vw,8rem)",
              }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              {params.row?.line_items.length !== 0 ? (
                <>
                  <div>{params.row?.line_items[0]?.title ?? "---"}</div>
                  <div>{params.row?.line_items[0]?.variant_title ?? "---"}</div>
                  <div>{params.row?.line_items[0]?.price ?? "---"}</div>
                </>
              ) : (
                "---"
              )}
            </div>
          </div>
        );
      },
    },
    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Order Status</b>,
      field: "fulfillment_status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams<IOrderManagement>) => {
        /*    to convert first letter capital    */
        const upperCaseChange =
          !!params.row.fulfillment_status &&
          params.row.fulfillment_status.charAt(0).toUpperCase() +
            params.row.fulfillment_status?.slice(1);
        return <>{upperCaseChange || "---"}</>;
      },
    },
    MenuOfUserpermission?.edit
      ? {
          renderHeader: (params: GridColumnHeaderParams) => <b>View</b>,
          field: "Action",
          flex: 1,
          type: "actions",
          cellClassName: "actionButtonDiv",
          renderCell: (params: GridRenderCellParams<IOrderManagement>) => {
            // console.log(params.row);

            const customization_id =
              params.row?.line_items[0]?.properties?.find((item) => {
                return item.name === "customId";
              })?.value;

            return (
              <>
                {/*     view     */}
                {/* <Tooltip title="View">
                  <VisibilityIcon
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "black",
                      padding: "5px",
                      fontSize: "1.7rem",
                      cursor: "pointer",
                      borderRadius: "50%",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "black",
                      },
                    }}
                  />
                </Tooltip> */}

                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {CoverPDFGenarationIsLoading?.includes(customization_id!) && (
                    <Tooltip title="downloading...">
                      <CircularProgress
                        size={"2.6rem"}
                        sx={{ position: "absolute" }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Cover">
                    <Button
                      size="small"
                      sx={{
                        backgroundColor: "#F5F5F5",
                        color: CoverPDFGenarationIsLoading?.includes(
                          customization_id!
                        )
                          ? "#c7c7c7"
                          : "black",
                        padding: "5px",
                        borderRadius: "50%",
                        minWidth: "0",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "black",
                        },
                      }}
                      disabled={CoverPDFGenarationIsLoading?.includes(
                        customization_id!
                      )}
                      onClick={() => {
                        if (params.row.line_items[0].cover_pdf) {
                          const link = document.createElement("a");

                          // 👇️ set to download
                          link.href = params.row.line_items[0].cover_pdf;

                          link.click();
                        } else {
                          console.log(params.row);
                          const coverpdf: any = {
                            order_id: params.row.id,
                            product_id: params.row.line_items[0].product_id,
                            customization_id: customization_id,
                            type: "LULU_COVER",
                          };
                          console.log("coverpdf", coverpdf);
                          dispatch(CoverPDF_Genaration(coverpdf));
                        }
                      }}
                    >
                      <SvgIcon component={CoverdownloadIcon} />
                    </Button>
                  </Tooltip>
                </div>

                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {CustomerPDFGenarationIsLoading?.includes(
                    customization_id!
                  ) && (
                    <Tooltip title="downloading...">
                      <CircularProgress
                        size={"2.6rem"}
                        sx={{ position: "absolute" }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Pages">
                    <Button
                      size="small"
                      disabled={CustomerPDFGenarationIsLoading?.includes(
                        customization_id!
                      )}
                      sx={{
                        backgroundColor: "#F5F5F5",
                        color: CustomerPDFGenarationIsLoading?.includes(
                          customization_id!
                        )
                          ? "#c7c7c7"
                          : "black",
                        padding: "5px",
                        borderRadius: "50%",
                        minWidth: "0",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "black",
                        },
                      }}
                      onClick={() => {
                        if (params.row.line_items[0].pdf_url) {
                          const link = document.createElement("a");

                          // 👇️ set to download
                          link.href = params.row.line_items[0].pdf_url;

                          link.click();
                        } else {
                          console.log(params.row);
                          const cuspdf: any = {
                            order_id: params.row.id,
                            product_id: params.row.line_items[0].product_id,
                            customization_id: customization_id,
                            type: "PAGE",
                          };
                          dispatch(Customer_PDF_Genaration(cuspdf));
                        }
                      }}
                    >
                      <SvgIcon component={PdfDownload} />
                    </Button>
                  </Tooltip>
                </div>
              </>
            );
          },
        }
      : {
          field: "",
        },
    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Shipment Level</b>,
      field: "shipping_level",
      type: "singleSelect",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true,
      valueOptions: (params) => {
        return params.row.show_print
          ? [
              { label: "Mail", value: "MAIL" },
              { label: "Priority Mail", value: "PRIORITY_MAIL" },
              { label: "Ground", value: "GROUND" },
              { label: "Expedited", value: "EXPEDITED" },
              { label: "Express", value: "EXPRESS" },
            ]
          : [];
      },
      // renderCell: (params: GridRenderCellParams<IOrderManagement>) =>
      //   params.row.show_print && params.value,
    },
    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Lulu Print</b>,
      field: "Lulu Print",
      flex: 1,
      type: "actions",
      cellClassName: "actionButtonDiv printButton",
      renderCell: (params: GridRenderCellParams<IOrderManagement>) => {
        return (
          params.row.show_print && (
            <>
              <LoadingButtonC
                loading={luluPrintLoading?.includes(params.row.id)}
                disabled={
                  params.row.enable_lulu_print ||
                  !["REJECTED", "CANCELED", null].includes(
                    params.row.printJobStatus
                  )
                }
                size="small"
                variant="contained"
                onClick={() =>
                  dispatch(
                    open_print_popup({
                      order_id: params.row.id,
                      // shopify_product_id: params.row.line_items[0].product_id,
                      shipping_level: params.row.shipping_level,
                      order_number: params.row.order_number,
                    })
                  )
                }
              >
                Print
              </LoadingButtonC>
              {/* <button
                type="button"
                onClick={() => {
                  console.log(params.row.shipping_level);
                }}
              >
                done
              </button> */}
              <div style={{ textTransform: "capitalize" }}>
                {params.row.printJobStatus?.toLowerCase()}
              </div>
            </>
          )
        );
      },
    },
  ];

  /*    initial render     */
  useEffect(() => {
    dispatch(list_OrderManagement(null));

    /*    after reload with new url functions    */
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");

    if (code) {
      dispatch(send_url(window.location.href));
      // Clear all query parameters
      url.search = "";
      // navigate to order managment
      nav("/OrderManagement");
    }

    return () => {
      dispatch(clear_SendShopifyRedirectUrl());
      dispatch(ordermanagement_search(""));
    };
  }, [PdfData?.data, coverData?.data, luluData, CustData?.data]);

  /*    check shopify connection by order management list response     */
  useEffect(() => {
    if (!orderList?.status) {
      /*    redirect to shopify login if new user    */
      if (orderList?.message === "Connect to shopify") {
        dispatch(get_url("/OrderManagement"));
      }
      /*    re-genrate access token api call     */
      if (orderList?.message === "Access token expired.Regenerate the token") {
        /*    temprory api call for re-genrate access token    */
        dispatch(get_url("/OrderManagement"));
      }
    }
  }, [orderList]);

  const expandLineItems = (orderList?.data ?? []).flatMap((order) =>
    order.line_items.map((item, index, arr) => ({
      ...order,
      line_items: [item],
      show_print: index === 0,
      enable_lulu_print: arr
        .flatMap((item) => [item.pdf_url, item.cover_pdf])
        .includes(null),
    }))
  );

  const customizedList = expandLineItems?.map((response, index) => ({
    ...response,
    field_id: index + 1,
    //    if the customization_id is null
    customization_id: response.line_items[0].customization_id ?? `${index}`,
    shipping_level: response.show_print ? response.shipping_level : null,
  }));

  const handleProcessRowUpdate = (newRow: GridRowModel) => {
    if (newRow.shipping_level) {
      dispatch(
        update_shiping_level({
          id: newRow.id,
          shipping_level: newRow.shipping_level,
        })
      );
    }
    return newRow;
  };

  // filter data by search
  const filterdData = customizedList.filter(
    (item) =>
      item.line_items[0]?.title
        .toLowerCase()
        .includes(searchValue!.toLowerCase()) ||
      item.line_items[0]?.variant_title
        .toLowerCase()
        .includes(searchValue!.toLowerCase()) ||
      item.line_items[0]?.price
        .toLowerCase()
        .includes(searchValue!.toLowerCase()) ||
      item.fulfillment_status
        ?.toLowerCase()
        .includes(searchValue!.toLowerCase()) ||
      // item.created_at?.toLowerCase().includes(searchValue!.toLowerCase()) ||
      item.field_id
        ?.toString()
        .toLowerCase()
        .includes(searchValue!.toLowerCase()) ||
      item.name?.toLowerCase().includes(searchValue!.toLowerCase())
  );

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      {/* <IsLoading isLoading={orderListIsLoading} /> */}
      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <Grids
        row={filterdData ?? []}
        column={column.filter((column) => column.field !== "")}
        // Users={"User"}
        getRowHeight={() => "auto"}
        orderListSearch
        pagination
        loading={orderListIsLoading}
        processRowUpdate={handleProcessRowUpdate}
        // dialog={
        //   MenuOfUserpermission?.add && (
        //     <Dialog
        //       successMessage={addData}
        //       isLoading={addDataIsLoading}
        //       sx={{
        //         padding: "15px",
        //         backgroundColor: "black",
        //         color: "white",
        //         width: "150px",
        //         height: "37px",
        //         borderRadius: "0px",
        //         border: "1px solid #BDBDBD",
        //         marginLeft: "10px",
        //         marginTop: "0px",
        //         "&:hover": { backgroundColor: "black" },
        //       }}
        //       getValues={(v: any) => dispatcher(add_user(v))}
        //       Buttondialog={["Cancel"]}
        //       Dialogtitle={"Add  User"}
        //       // Dialogcontenttext={"hi"}
        //       Buttonname={"Add User"}
        //     />
        //   )
        // }
        Userpermissions={undefined}
        // export
      />
      <Dialogs
        maxWidth={"xs"}
        open={luluPrintPopup}
        setOpen={() => dispatch(close_print_popup())}
        sx={{ width: "auto" }}
        Dialog_Content_sx={{
          padding: "1.5em 24px 0!important",
          display: "flex",
          fontSize: "16px",
          justifyContent: "center",
        }}
        Dialog_Content={`Are you sure want to print order ID :${printOrderNumber} ?`}
        Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
        Dialog_Actions={
          <>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(close_print_popup());
              }}
              sx={{
                width: "110px",
                height: "40px",
                borderRadius: "0px",
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                dispatch(lulu_Print(luluPrintData));
              }}
              sx={{
                width: "110px",
                height: "40px",
                backgroundColor: "rgb(0, 0, 0)",
                color: "#FFFFFF",
                display: "flex",
                fontSize: "13px",
                alignItems: "center",

                borderRadius: "0px",
                justifyContent: "space-evenly",
              }}
            >
              Yes
            </Button>
          </>
        }
      />
    </div>
  );
};

export default OrderManagment;
