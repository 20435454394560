import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
import { FAILED, SUCCESE } from "../../../Editor/utills/common";
import { IPDF } from "../../../interfaces/IPDF";

interface IExtends extends IReduxState<string> {
  loadingIDs?: (number | string)[];
}
export const PDFGenaration = createSlice({
  name: "PDFGenaration",
  initialState: {
    isLoading: false,
    data: {},
    loadingIDs: [],
  } as IExtends,
  reducers: {
    PDF_Genaration: (state, action: PayloadAction<IPDF>) => {
      console.log("action", action.payload);

      state.loadingIDs?.push(
        action.payload.customization_id ?? action.payload.project_id!
      );
      state.isLoading = true;
    },
    success_api_call_PDFGenaration: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      SUCCESE(action.payload.message);
    },
    failure_api_call_PDFGenaration: (state, action) => {
      state.data = action.payload;
      FAILED(action.payload.message);
    },
    stop_loader: (state, action: PayloadAction<IPDF>) => {
      state.isLoading = false;
      const id = action.payload.customization_id ?? action.payload.project_id!;

      const copyState = state.loadingIDs;
      const index = copyState?.indexOf(id);
      copyState?.splice(index!, 1);

      copyState?.filter((ids) => ids !== id);
      state.loadingIDs = copyState;
    },
  },
});
export default PDFGenaration.reducer;
export const {
  PDF_Genaration,
  success_api_call_PDFGenaration,
  failure_api_call_PDFGenaration,
  stop_loader,
} = PDFGenaration.actions;
