import { FunctionComponent } from "react";
import Autocompletes from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material";

//  Autocomplete component

type variant = "outlined" | "standard" | "filled" | undefined;
type color = "secondary" | "success" | "error" | "info" | "warning";

type size = "small" | "medium" | undefined;
interface AutocompleteProps {
  Listitem: any;
  width?: number;
  variant: variant;
  disabled?: boolean;
  url?: string;
  color?: color;
  size?: size;
  style?: React.CSSProperties | undefined;
  label?: any;
  multiple?: any;
  defaultValue?: any;
  onchange?: any;
  filter?: boolean;
  required?: boolean;
  name?: string;
  value?: any;
  error?: any;
  onBlur?: any;
  helperText?: any;
  sx?: SxProps<Theme>;
  noOptionsText?: string;
  // onChange?: any;
}

const Autocomplete: FunctionComponent<AutocompleteProps> = (props: any) => {
  // console.log(props.Listitem);

  return (
    <>
      <Autocompletes
        // disablePortal
        // autoHighlight
        // filterSelectedOptions={props.filter ? true : false}
        onChange={(e, value) => {
          props.onchange(value || "");
        }}
        disableClearable
        noOptionsText={props.noOptionsText}
        // defaultValue={props.defaultValue?.map((value: any) => {
        //   return props.Listitem[value];
        // })}
        id="combo-box-demo"
        value={
          props?.Listitem?.length != 0
            ? props?.Listitem?.filter((e: any) => {
                return e?.value === props?.value[props?.name];
              }).length != 0
              ? props?.Listitem?.filter((e: any) => {
                  return e?.value === props?.value[props?.name];
                })[0].label
              : props?.value[props?.name]
            : props?.value[props?.name]
        }
        renderOption={(
          props: React.HTMLAttributes<HTMLLIElement>,
          option: Partial<any>
        ) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          );
        }}
        disabled={props.disabled ? true : false}
        options={props.Listitem}
        // value={[{ id: 1, name: "sdf" }]}
        // getOptionLabel={(option: any) => option}
        sx={props.sx}
        renderInput={(params) => (
          <TextField
            {...params}
            name={props.name}
            label={props.label}
            sx={{
              "& fieldset": {
                borderRadius: "0px",
              },
            }}
            variant={props.variant}
            color={props.color}
            disabled={props.disabled ? true : false}
            size={props.size}
            required={props.required ? true : false}
            style={props.style}
            error={props.error}
            onBlur={props.onBlur}
            helperText={props.helperText}
            fullWidth
          />
        )}
        // multiple={props.multiple ? true : false}
        // disableCloseOnSelect={props.multiple ? true : false}
      />
    </>
  );
};

export default Autocomplete;
