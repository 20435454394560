import { LoadingButton } from "@mui/lab";
import { ButtonPropsVariantOverrides, SxProps, Theme } from "@mui/material";
import { FunctionComponent } from "react";

interface LoadingButtonCProps {
  children?: React.ReactNode;
  loading?: boolean;
  endIcon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
  loadingPosition?: "center" | "start" | "end";
  variant?: "text" | "outlined" | "contained";
  size?: "small" | "medium" | "large";
  disabled?: boolean;
}

const LoadingButtonC: FunctionComponent<LoadingButtonCProps> = (props) => {
  const {
    loading,
    endIcon,
    onClick,
    sx,
    children,
    loadingPosition,
    variant,
    size,
    disabled,
  } = props;
  return (
    <LoadingButton
      size={size}
      loading={loading}
      loadingPosition={loadingPosition}
      endIcon={endIcon}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      sx={sx}
    >
      <span>{children}</span>
    </LoadingButton>
  );
};

export default LoadingButtonC;
