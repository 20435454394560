import { Button, FormHelperText, TextField } from "@mui/material";
import {
  FieldArray,
  FormikProps,
  FormikProvider,
  FormikValues,
  getIn,
} from "formik";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { ICampaignForm } from "../interfaces/ICampaign";
import Select from "../Component/Select";
import uploadImage from "../Pages/Image/UploadCloudImage.png";
import CheckboxLabels from "../Component/Checkbox";
// import { createEditor, Descendant } from "slate";
// import { Slate, Editable, withReact } from "slate-react";
// import { createEditor } from "slate";
// import { Slate, Editable, withReact } from "slate-react";
import Dialogs from "../Component/Dialog";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import EditorComponent from "./wysiwygEditor";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { list_VariantById } from "../Redux/Reducers/Comics_Booklist/list_Variantlist_slicerBy_ID";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";

import "./CampaignForm.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { clear_variant_list } from "../Redux/Reducers/Campaign/VariantList_Slicer";
import IsLoading from "../Component/isLoading";
import {
  closePopup,
  list_UsedVariant,
} from "../Redux/Reducers/Campaign/list_usedvariant_slicer";
import { ICapaignVariantForm } from "./CampaignVariantForm";
import {
  closePopup_UsedProjectIds,
  popupOpen_UsedProjectIds,
} from "../Redux/Reducers/Campaign/list_usedprojectIds_slicer";

interface CampaignFormProps {
  formik: FormikProps<ICampaignForm>;
  variantFormik?: React.RefObject<FormikProps<ICapaignVariantForm>>;
}

const CampaignForm: FunctionComponent<CampaignFormProps> = (props) => {
  const { formik, variantFormik } = props;

  /*    Redux States     */
  const projectId = useAppSelector((state) => state.ProjectID.data);
  /*    Redux dispatch     */
  const dispatch = useAppDispatch();
  /*    States     */
  /*    state for open dialog to show image    */
  const [imageOpen, setImageOpen] = useState<boolean>(false);
  /*    Show image     */
  const [showImage, setShowImage] = useState<
    (string | File | Blob) | (string | File | Blob)[] | null
  >(null);
  /*    filterd project    */
  const filterdProjectId = (projectId?.data ?? []).map((project) => ({
    label: project.name,
    value: project.id,
  }));

  console.log(
    filterdProjectId?.find(
      (project) => project.value === formik?.values.project_id
    ) ?? null
  );

  return (
    <section>
      {/*     preview the image in dialog    */}
      <Dialogs
        sx={{ width: "auto" }}
        maxWidth={"md"}
        open={imageOpen}
        setOpen={setImageOpen}
        Dialog_Content_sx={{ padding: "0" }}
        Dialog_Content={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", top: "0", right: "0" }}>
              <Button
                onClick={() => setImageOpen(false)}
                sx={{
                  color: "red",
                  width: "2em",
                  height: "2em",
                  minWidth: "0",
                  backgroundColor: "#0000006b",
                  borderRadius: "50%",
                  "&:hover": { backgroundColor: "#f5f5f5" },
                }}
              >
                X
              </Button>
            </div>

            <img
              src={uploadImage}
              srcSet={
                showImage
                  ? typeof showImage === "string"
                    ? showImage.replace(/\s/g, "%20")
                    : URL?.createObjectURL(showImage as unknown as File)
                  : uploadImage
              }
              className="productImage"
              alt="upload"
              style={{ height: "clamp(25rem,60vh,60rem)" }}
            />
          </div>
        }
      />

      <div className="campaignDiv">
        <div className="CampaignProductNames">
          <div className="inputField">
            <b>Campaign Name</b>
            <TextField
              name="name"
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly:
                  formik?.values.status === "INACTIVE" ||
                  formik?.values.status === "PUBLISHED",
              }}
              autoComplete="off"
              sx={{
                "& fieldset": {
                  borderRadius: "0px",
                },
                margin: "0",
              }}
              margin="dense"
              value={formik?.values.name}
              onChange={formik?.handleChange}
              helperText={
                formik.errors.name && formik.touched.name && formik.errors.name
              }
              error={!!(formik.errors.name && formik.touched.name)}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="inputField">
            <b>Product Name</b>
            <Select
              Listitem={filterdProjectId}
              name="project_id"
              fullWidth
              readOnly={
                formik?.values.status === "INACTIVE" ||
                formik?.values.status === "PUBLISHED"
              }
              disabled={
                !!formik?.values.status && formik?.values.status !== "INACTIVE"
              }
              disableClearable
              value={
                filterdProjectId?.find(
                  (project) => project.value === formik?.values.project_id
                ) ?? null
              }
              onchange={(_: null, value: { lable: string; value: number }) => {
                formik?.setFieldValue("project_id", value.value);
                dispatch(clear_variant_list());
                dispatch(popupOpen_UsedProjectIds(value.value));

                dispatch(list_VariantById(value.value));
                /*    getting list of used variant id's    */
                dispatch(list_UsedVariant({ project_id: value.value }));
              }}
              helperText={
                formik.errors.project_id &&
                formik.touched.project_id &&
                formik.errors.project_id
              }
              error={!!(formik.errors.project_id && formik.touched.project_id)}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
        <div className="imageDiscriptionNameDiv">
          <div style={{ fontWeight: "bold" }}>Product Image & Description</div>
          <div className="imageDiscriptionDiv">
            <div className="mediaDivOutline">
              <div
                style={{
                  border:
                    formik.errors.images && formik.touched.images
                      ? "1px solid #d32f2f"
                      : undefined,
                }}
                className="mediaDiv"
              >
                <b style={{ fontSize: "small" }}>Media</b>
                <FormikProvider value={formik}>
                  <FieldArray
                    name={"images"}
                    render={(arrayHelpers) => {
                      return (
                        <div className="images">
                          {formik?.values.images &&
                          formik.values.images.length > 0 ? (
                            <>
                              {formik.values.images.map(
                                (image, index: number) => {
                                  /*    image    */
                                  const imageName = `images[${index}].images`;
                                  const touchedimageName = getIn(
                                    formik.touched,
                                    imageName
                                  );
                                  const errorimageName = getIn(
                                    formik.errors,
                                    imageName
                                  );

                                  return (
                                    <div
                                      key={index}
                                      className={
                                        image.images
                                          ? "checkboxImageDiv"
                                          : "checkboxImageDivRemove"
                                      }
                                      style={{
                                        outline:
                                          errorimageName && touchedimageName
                                            ? "1.5px solid #d32f2f"
                                            : undefined,
                                      }}
                                    >
                                      <div className="preview">
                                        <div
                                          className="preeviewPosition"
                                          style={{
                                            zIndex: image.images ? "2" : "0",
                                            color: image.images
                                              ? "white"
                                              : "transparent",
                                          }}
                                          onClick={() => {
                                            !!image.images &&
                                              setImageOpen(true);
                                            setShowImage(image.images);
                                          }}
                                        >
                                          <OpenInFullIcon />
                                        </div>
                                      </div>
                                      {/*     delete     */}
                                      {formik?.values.status !== "INACTIVE" &&
                                        formik?.values.status !==
                                          "PUBLISHED" && (
                                          <div className="checkbox">
                                            <div
                                              className={
                                                image.images
                                                  ? "deleteImage"
                                                  : "deleteImageRemove"
                                              }
                                              onClick={() =>
                                                arrayHelpers.replace(index, {
                                                  images: null,
                                                })
                                              }
                                            >
                                              x
                                            </div>
                                          </div>
                                        )}
                                      <label
                                        htmlFor={imageName}
                                        className={
                                          index === 0
                                            ? "firstImage"
                                            : "imageLabel"
                                        }
                                      >
                                        {!image.images &&
                                          formik?.values.status !==
                                            "INACTIVE" &&
                                          formik?.values.status !==
                                            "PUBLISHED" && (
                                            <input
                                              type="file"
                                              name={imageName}
                                              id={imageName}
                                              hidden
                                              disabled={!!image.images}
                                              accept="image/jpeg,image/jpg,image/png"
                                              onBlur={formik.handleBlur}
                                              onChange={async (
                                                e: React.ChangeEvent<HTMLInputElement>
                                              ) => {
                                                formik.setFieldTouched(
                                                  imageName,
                                                  true
                                                );
                                                formik.setFieldValue(
                                                  imageName,
                                                  e.target.files![0]
                                                );
                                              }}
                                            />
                                          )}

                                        <img
                                          src={uploadImage}
                                          srcSet={
                                            image.images
                                              ? typeof image.images === "string"
                                                ? image.images.replace(
                                                    /\s/g,
                                                    "%20"
                                                  )
                                                : URL?.createObjectURL(
                                                    image.images as unknown as File
                                                  )
                                              : uploadImage
                                          }
                                          className="productImage"
                                          onClick={() => {
                                            !!image.images &&
                                              setImageOpen(true);
                                            setShowImage(image.images);
                                          }}
                                          alt="upload"
                                          height={
                                            image.images && index === 0
                                              ? 180
                                              : 55
                                          }
                                          width={
                                            image.images && index === 0
                                              ? 180
                                              : 55
                                          }
                                        />
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <label
                              className="multipleImgUpload"
                              htmlFor={"imageName"}
                            >
                              <div
                                style={{
                                  // border: "1px solid rgba(0, 0, 0, 0.23)",
                                  display: "flex",
                                }}
                              >
                                <input
                                  type="file"
                                  name={"images"}
                                  id={"imageName"}
                                  hidden
                                  accept="image/jpeg,image/jpg,image/png"
                                  multiple
                                  onBlur={formik.handleBlur}
                                  onChange={async (
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    formik.setFieldTouched("images", true);
                                    if (
                                      Array.from(e.target.files!).length > 4
                                    ) {
                                      e.preventDefault();
                                      alert(
                                        `Cannot upload files more than ${4}`
                                      );
                                      return;
                                    }
                                    for (let index = 0; index < 4; index++) {
                                      formik.setFieldTouched(
                                        `images[${index}].images`,
                                        true
                                      );

                                      arrayHelpers.push({
                                        images: e.target.files![index] ?? null,
                                      });
                                    }
                                  }}
                                />
                                <img
                                  src={uploadImage}
                                  alt="upload"
                                  height={55}
                                  width={55}
                                />
                              </div>
                              <span style={{ fontSize: "small" }}>
                                Upload Image
                              </span>
                              <i style={{ fontSize: "small" }}>
                                (Select any four images( Cont+ Select ))
                              </i>
                            </label>
                          )}
                        </div>
                      );
                    }}
                  />
                </FormikProvider>
              </div>
              {formik.errors?.images && formik.touched?.images && (
                <FormHelperText error>
                  {typeof formik.errors?.images === "object"
                    ? Array.from(
                        new Set(
                          Array.from(formik.errors?.images).map((value: any) =>
                            value?.images ? value?.images : null
                          )
                        )
                      ).map((value) => value)
                    : formik.errors?.images}
                </FormHelperText>
              )}
            </div>
            <div className="editoDivOutline">
              <div
                style={{
                  border:
                    formik.errors.description && formik.touched.description
                      ? "1px solid #d32f2f"
                      : "1px solid rgba(0, 0, 0, 0.23)",
                  flex: "1",
                  // width: "calc(98% - clamp(10rem, 100%, 20rem))",
                }}
              >
                <EditorComponent
                  getValues={(value) =>
                    formik.setFieldValue("description", value)
                  }
                  value={formik.values.description}
                  readOnly={
                    formik?.values.status === "INACTIVE" ||
                    formik?.values.status === "PUBLISHED"
                  }
                />
              </div>
              {formik.errors.description && formik.touched.description && (
                <FormHelperText error>Please enter description</FormHelperText>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CampaignForm;
