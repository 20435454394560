import { createSlice } from "@reduxjs/toolkit";
import { IComicsPage } from "../../../interfaces/ComicsPageEdit";

export const EditableSpineValue = createSlice({
  name: "EditableSpineValue",
  initialState: { data: {} as IComicsPage },
  reducers: {
    edit_Spine_Value: (state, action) => {
      return { ...state, data: action.payload };
    },
  },
});

export default EditableSpineValue.reducer;

export const { edit_Spine_Value } = EditableSpineValue.actions;
