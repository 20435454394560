import { FunctionComponent } from "react";
import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { SxProps, Theme } from "@mui/material";

type size = "small" | "medium" | undefined;
type color =
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | "default"
  | undefined;
type labelPlacement = "end" | "start" | "top" | "bottom" | undefined;

interface CheckboxLabelsProps {
  label?: string | number;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  size?: size;
  color?: color;
  labelPlacement?: labelPlacement;
  onChange?: any;
  sx?: SxProps<Theme> | undefined;
}

const CheckboxLabels: FunctionComponent<CheckboxLabelsProps> = (props) => {
  const {
    label,
    defaultChecked,
    disabled,
    size,
    color,
    labelPlacement,
    onChange,
    checked,
    sx,
  } = props;
  //   const [checked, setChecked] = React.useState(true);

  //   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setChecked(event.target.checked);
  //   };
  return (
    <>
      <FormGroup>
        <FormControlLabel
          disabled={disabled ? true : false}
          sx={sx}
          control={
            <Checkbox
              // defaultChecked={defaultChecked ? true : false}
              size={size}
              color={color}
              checked={checked ? true : false}
              onChange={onChange}
            />
          }
          label={label}
          labelPlacement={labelPlacement}
        />
      </FormGroup>
    </>
  );
};

export default CheckboxLabels;
