import { FunctionComponent } from "react";
import EditIcon from "@mui/icons-material/Edit";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import "./Page.css";
import { add_role } from "../Redux/Reducers/Role/add_Role_slicer";
import { edit_role } from "../Redux/Reducers/Role/edit_Role_slicer";
import { list_role } from "../Redux/Reducers/Role/list_Role_slicer";
import Grids from "../Component/Grides";
import Dialog from "../Component/DialogForFormCategories";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { list_imagecategory } from "../Redux/Reducers/Image Category/list_ImageCategory_slicer";
import { edit_ImageCategory } from "../Redux/Reducers/Image Category/edit_ImageCategory_slicer";
import { add_ListImageCategory } from "../Redux/Reducers/Image Category/add_ImageCategory_slicer";
import IsLoading from "../Component/isLoading";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  delete_ImageCategory,
  image_category_popup_close,
  image_category_popup_open,
} from "../Redux/Reducers/Image Category/delete_ImageCategory_slicer";
import Dialogs from "../Component/Dialog";

interface UserroleProps {}

const Categories: FunctionComponent<UserroleProps> = () => {
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();
  const breadcrumbs = [
    // <Typography key="3" color="text.primary" style={{ display: "flex" }}>
    <Link key="1" color="black" underline="none">
      <b className="heading">Masters</b>
    </Link>,

    // <Typography key="3" color="text.primary">
    <Link
      underline="none"
      key="1"
      color="inherit"
      onClick={() => navigate("/imagecategory")}
      style={{
        fontWeight: "500",
        fontSize: "15px ",
        lineHeight: "16px ",
        color: "#BDBDBD",
        cursor: "pointer",
      }}
    >
      Image Category
    </Link>,
  ];

  //    datas of user role
  const data = useAppSelector((state) => state?.ListImageCategory?.data);
  const filterData = data.map((value: any) => {
    return { ...value, status: value.status === true ? "Active" : "Inactive" };
  });
  const isLoading = useAppSelector(
    (state) => state?.ListImageCategory?.isLoading
  );
  const addData: any = useAppSelector(
    (state) => state?.AddListImageCategory?.data
  );
  const addDataIsLoading = useAppSelector(
    (state) => state?.AddListImageCategory?.isLoading
  );
  const editData: any = useAppSelector(
    (state) => state?.editImageCategory?.data
  );
  const editDataIsLoading = useAppSelector(
    (state) => state?.editImageCategory?.isLoading
  );

  const deleteImageCategory = useAppSelector(
    (state) => state?.DeleteImageCategory?.data
  );
  const deleteImageCategoryIsLoading = useAppSelector(
    (state) => state?.DeleteImageCategory?.isLoading
  );
  const deleteImageCategoryPopupOpen = useAppSelector(
    (state) => state?.DeleteImageCategory?.popup
  );
  const deleteImageCategoryResponsePopupOpen = useAppSelector(
    (state) => state?.DeleteImageCategory?.responsePopup
  );

  /*    useState     */
  //    get row id
  const [rowId, setRowId] = React.useState<number | null>(null);

  //    userpermission menuList
  const pageName = "Masters / Image Category";
  const menuList: any = JSON.parse(
    localStorage?.getItem("permission") as string
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageName;
  });

  const MenuOfUserpermission = menuList[index];

  const closeFunction = (e: boolean) => {
    // console.log("Sfd", e);
  };

  const column = [
    // { headerName: , field: ,  },
    {
      sortable: false,
      field: "field_id",
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          S.No
        </p>
      ),
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
      // renderCell: (params: GridRenderCellParams<any>) =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Categories
        </p>
      ),
      field: "name",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Status
        </p>
      ),
      field: "status",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    MenuOfUserpermission?.edit
      ? // true
        {
          headerName: (
            <p
              className="headingnamebold"
              style={{ color: "black", fontWeight: "bolder" }}
            >
              Action
            </p>
          ),
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <div
                style={{
                  display: "flex",
                  gap: "0.5em",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <Dialog
                  // Dialogcontenttext
                  // getValues={(v: any) => console.log(v)}
                  successMessage={editData}
                  isLoading={editDataIsLoading}
                  Buttondialog={["edit"]}
                  Dialogtitle={"Edit Category "}
                  getValues={(v: any) => {
                    dispatcher(edit_ImageCategory(v));
                    // console.log(values);
                  }}
                  placeholder="Role"
                  sx={{
                    backgroundColor: "transparent",
                    minWidth: "0",
                    padding: "0",
                    borderRadius: "50%",
                    height: "60%",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  edit={
                    // params.row
                    {
                      ...params.row,
                      active: params.row.status === "Active" ? true : false,
                    }
                  }
                  Buttonname={
                    <EditIcon
                      sx={{
                        backgroundColor: "#F5F5F5",
                        color: "black",
                        height: "1.3em",
                        width: "1.3em",
                        padding: "15%",
                        borderRadius: "50%",
                        "&:hover": {
                          color: "#F5F5F5",
                          backgroundColor: "black",
                        },
                      }}
                      onClick={() => console.log(params.row)}
                    />
                  }
                />
                <DeleteIcon
                  onClick={() => {
                    setRowId(params.row.id);
                    dispatcher(image_category_popup_open());
                  }}
                  sx={{
                    backgroundColor: "#F5F5F5",
                    width: "1.2em",
                    height: "1.2em",
                    color: "black",
                    padding: "6px",
                    fontSize: "1.7rem",
                    cursor: "pointer",
                    borderRadius: "50%",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "black",
                    },
                  }}
                />
              </div>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  // console.log(load);

  React.useEffect(() => {
    dispatcher(list_imagecategory(""));
    // axios
    //   .get(`${process.env.REACT_APP_api_url}/v1/api/role/`)
    //   .then((response: any) => {
    //     // console.log(response?.data?.data);
    //     let res = response?.data?.data?.map((x: any) => ({
    //       ...x,
    //       active: x.active ? "Active" : "Inactive",
    //     }));
    //     // console.log(res);

    //     dispatcher(setState(res));
    //   })
    //   .catch((error: any) => {
    //     console.log(error);
    //   });
  }, [addData, editData, deleteImageCategory]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <IsLoading isLoading={isLoading || deleteImageCategoryIsLoading} />
      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={filterData}
        column={column}
        // Users={"Roles"}
        open
        pagination
        dialog={
          MenuOfUserpermission?.add && (
            <Dialog
              // error={errorMessage}
              successMessage={addData}
              isLoading={addDataIsLoading}
              sx={{
                padding: "15px",
                backgroundColor: "black",
                color: "white",
                width: "150px",
                height: "37px",
                borderRadius: "0px",
                border: "1px solid #BDBDBD",
                "&:hover": { backgroundColor: "black" },
              }}
              placeholder="Categories"
              getValues={(v: any) => {
                dispatcher(add_ListImageCategory(v));
                // console.log(v);
              }}
              Buttondialog={["Cancel"]}
              Dialogtitle={"Add Category"}
              Buttonname={"Add Category"}
            />
          )
        }
        Userpermissions={undefined}
      ></Grids>

      {/*     Delete popup     */}
      <Dialogs
        open={deleteImageCategoryPopupOpen}
        setOpen={() => {
          dispatcher(image_category_popup_close());
        }}
        maxWidth="xs"
        sx={{ width: "auto" }}
        Dialog_Content={"Are you sure you want to delete?"}
        Dialog_Content_sx={{
          display: "flex",
          justifyContent: "center",
          padding: "1.5em 1.5em 0 !important",
          fontSize: "16px",
        }}
        Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
        Dialog_Actions={
          <>
            <Button
              variant="outlined"
              onClick={() => {
                dispatcher(image_category_popup_close());
              }}
              sx={{
                width: "110px",
                height: "40px",
                borderRadius: "0px",
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                dispatcher(delete_ImageCategory(rowId));
              }}
              sx={{
                width: "110px",
                height: "40px",
                backgroundColor: "rgb(0, 0, 0)",
                color: "#FFFFFF",
                display: "flex",
                borderRadius: "0px",
              }}
            >
              Yes
            </Button>
          </>
        }
      />

      {/*     Error popup for add and edit    */}
      <Dialogs
        open={deleteImageCategoryResponsePopupOpen}
        setOpen={() => {
          dispatcher(image_category_popup_close());
        }}
        maxWidth="xs"
        sx={{ width: "auto" }}
        Dialog_Content_sx={{
          padding: "1.5em 1.5em 0 !important",
          fontSize: "16px",
        }}
        Dialog_Content={
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <div>{deleteImageCategory?.message}</div>
            <>
              {deleteImageCategory?.status ? (
                <img
                  src={require("../Pages/Image/success.png")}
                  alt="success"
                  width={"20%"}
                />
              ) : (
                <>
                  {deleteImageCategory?.message
                    ?.toLowerCase()
                    .includes("already exists") ? (
                    // console.log("exisite")
                    <img
                      src={require("../Pages/Image/mdi_file-alert-outline.png")}
                      alt="error"
                      width={"20%"}
                    />
                  ) : (
                    <img
                      src={require("../Pages/Image/error.png")}
                      alt="error"
                      width={"20%"}
                    />
                  )}
                </>
              )}
            </>
          </section>
        }
        Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
        Dialog_Actions={
          <>
            <Button
              variant="contained"
              onClick={() => {
                dispatcher(image_category_popup_close());
              }}
              sx={{
                width: "110px",
                height: "40px",
                backgroundColor: "rgb(0, 0, 0)",
                color: "#FFFFFF",
                display: "flex",
                borderRadius: "0px",
              }}
            >
              Ok
            </Button>
          </>
        }
      />
    </div>
  );
};

export default Categories;
