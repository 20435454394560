import { createSlice } from "@reduxjs/toolkit";

export const AddRole = createSlice({
  name: "AddRole",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    add_role: (state) => {
      state.isLoading = true;
    },
    success_api_call_add_role: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_add_role: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      // FAILED(action.payload);
    },
    add_role_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_role_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default AddRole.reducer;
export const {
  add_role,
  success_api_call_add_role,
  failure_api_call_add_role,
  create_role_complete_api,
  add_role_is_success,
} = AddRole.actions;
