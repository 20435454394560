import { FunctionComponent } from "react";
import Grids from "../Component/Grides";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import "./Page.css";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import { list_role } from "../Redux/Reducers/Role/list_Role_slicer";
import { list_userPermission } from "../Redux/Reducers/UserPermission/list_UserPermission_slicer";
import CheckboxLabels from "../Component/checkBoxConent";
import IsLoading from "../Component/isLoading";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

interface UserPermissionProps {}

const UserPermission: FunctionComponent<any> = (props: any) => {
  const dispatcher = useAppDispatch();
  const [closeDio, setCloseDio] = React.useState(true);
  const nav = useNavigate();
  const breadcrumbs = [
    // <Typography key="3" color="text.primary" style={{ display: "flex" }}>
    <Link key="1" color="black" underline="none">
      <b className="heading">Masters</b>
    </Link>,

    // <Typography key="3" color="text.primary">
    <Link
      underline="none"
      key="1"
      color="inherit"
      onClick={() => nav("/UserPermission")}
      style={{
        fontWeight: "500",
        fontSize: "15px ",
        lineHeight: "16px ",
        color: "#BDBDBD",
        cursor: "pointer",
      }}
    >
      User Permission
    </Link>,
  ];

  //    userpermission menuList
  const pageName = "Masters / User Permissions";
  const menuList: any = JSON.parse(
    localStorage?.getItem("permission") as string
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageName;
  });

  const MenuOfUserpermission = menuList[index];
  // console.log(MenuOfUserpermission);
  // console.log(menuList);
  // console.log(index);

  // const action = <Checkbox {...label} />;
  // const data = [
  //   {
  //     id: "1",
  //     RoleName: "asasd ",
  //     ParentMenu: "Service Advisor",
  //     MenuName: "service",
  //   },
  //   {
  //     id: "2",
  //     RoleName: "ssas",
  //     ParentMenu: "Job Card",
  //     MenuName: "user",
  //   },
  //   {
  //     id: "3",
  //     RoleName: "rolcdfd",
  //     ParentMenu: "Service Team",
  //     MenuName: "Roles",
  //     // Action: { action },
  //   },
  //   {
  //     id: "4",
  //     RoleName: "sdsd",
  //     ParentMenu: "Service Completion",
  //     MenuName: "Zone",
  //   },
  //   {
  //     id: "5",
  //     RoleName: "sdsd",
  //     ParentMenu: "Vehicle Location",
  //     MenuName: "Bay",
  //   },
  //   {
  //     id: "6",
  //     RoleName: "sdsd",
  //     ParentMenu: "Masters",
  //     MenuName: "User permission",
  //   },
  // ];

  const column = [
    // Menus column
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Menus
        </p>
      ),
      field: "menuname",
      flex: 1,
      headerAlign: "center",
    },
    // View column
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          View
        </p>
      ),
      field: "view",
      flex: 1,
      type: "actions",
      renderCell: (params: any) => {
        return (
          <>
            <CheckboxLabels
              sx={{ margin: "0" }}
              onChange={(e: any) => {
                const checked = e.target.checked;
                params.api.updateRows([
                  {
                    ...params.row,
                    view: checked,
                    add: checked && params.row.add,
                    edit: checked && params.row.edit,
                    delete: checked && params.row.delete,
                  },
                ]);
              }}
              checked={params.row.view}
            ></CheckboxLabels>
          </>
        );
      },
    },
    // Add column
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Add
        </p>
      ),
      field: "add",
      flex: 1,
      type: "actions",
      renderCell: (params: any) => {
        return (
          <>
            <CheckboxLabels
              sx={{ margin: "0" }}
              onChange={(e: any) => {
                const checked = e.target.checked;
                params.api.updateRows([
                  {
                    ...params.row,
                    add: checked,
                    view: checked || params.row.view,
                  },
                ]);
              }}
              checked={params.row.add}
            ></CheckboxLabels>
          </>
        );
      },
    },
    // Edit column
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Edit
        </p>
      ),
      field: "edit",
      flex: 1,
      type: "actions",
      renderCell: (params: any) => {
        return (
          <>
            <CheckboxLabels
              sx={{ margin: "0" }}
              onChange={(e: any) => {
                const checked = e.target.checked;
                params.api.updateRows([
                  {
                    ...params.row,
                    edit: checked,
                    view: checked || params.row.view,
                  },
                ]);
              }}
              checked={params.row.edit}
            ></CheckboxLabels>
          </>
        );
      },
    },
    // Delete column
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Delete
        </p>
      ),
      field: "delete",
      flex: 1,
      type: "actions",
      renderCell: (params: any) => {
        return (
          <>
            <CheckboxLabels
              sx={{ margin: "0" }}
              onChange={(e: any) => {
                const checked = e.target.checked;
                params.api.updateRows([
                  {
                    ...params.row,
                    delete: checked,
                    view: checked || params.row.view,
                  },
                ]);
              }}
              checked={params.row.delete}
            ></CheckboxLabels>
          </>
        );
      },
    },
  ];
  React.useEffect(() => {
    dispatcher(list_userPermission(""));
    dispatcher(list_role(""));
  }, []);

  const roleData = useAppSelector((state) => state.Role.data);
  const data = useAppSelector((state) => state.UserPermission.data);
  const isLoading = useAppSelector((state) => state.UserPermission.isLoading);

  console.log(data);

  // const data1 = data.map((v:any) =>{
  //   ({
  //         icons: v.path && v.icon,
  //     title: v.path && v.menuname,
  //     url: v.path,
  //     id: v.parentId,
  //   })
  // })

  // console.log(
  //   data.map((v: any) => ({
  //     icons: v.path && v.icon,
  //     title: v.path && v.menuname,
  //     url: v.path,
  //     id: v.parentId,
  //     options: v.icon === null &&
  //       v.parentId === v.parentId && [
  //         {
  //           icons: v.path && v.ico,
  //           title: v.path && v.menuname,
  //           url: v.path,
  //         },
  //       ],
  //   }))
  // );
  // const dataAdd: any = data.map((v: any) => ({
  //   ...v,
  //   add: v.add ? true : false,
  //   delete: v.delete ? true : false,
  //   edit: v.edit ? true : false,
  //   view: v.view ? true : false,
  // }));

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <IsLoading isLoading={isLoading} />
      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={data !== undefined && data}
        roleData={roleData}
        Userpermissions={
          true
          // MenuOfUserpermission?.add || MenuOfUserpermission?.edit
        }
        UserPermission
        column={column}
        Submitbutton
        // Users={"User Permission"}
      ></Grids>
    </div>
  );
};

export default UserPermission;
