import { FunctionComponent, Key, useRef } from "react";
import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Editor/Pages/ImageLibrary.css";

import {
  Alert,
  AlertTitle,
  Backdrop,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Stack,
} from "@mui/material";
import "./Page.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import menu from "./Image/menu.png";

import TextField from "@mui/material/TextField";
import { Formik, Form, Field, useFormikContext } from "formik";
import SearchIcon from "@mui/icons-material/Search";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Uploadimage from "./Image/uploadimage.png";
import mesImage from "./Image/message.png";
import * as Yup from "yup";
import "../App.css";

import Autocomplete from "../Component/Autocomplete";
import Select from "../Component/Select";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { list_image } from "../Redux/Reducers/ImageLibraries/list_Image_slicer";
import { ImageList } from "../interfaces/ImageList";
import { list_imagecategory } from "../Redux/Reducers/Image Category/list_ImageCategory_slicer";
import { add_image } from "../Redux/Reducers/ImageLibraries/add_Image_slicer";
import { AnyAaaaRecord } from "dns";
import IsLoading from "../Component/isLoading";
import Menus from "../Component/menu";
import { ImageCategory } from "../interfaces/ListofImageCategory";
import { useDispatch } from "react-redux";
import CheckboxLabels from "../Component/Checkbox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { delete_PageQuestions } from "../Redux/Reducers/PageQuestions/delete_PageQusetions_slicer";
import SearchBar from "../Component/SearchBar";
import { delete_image } from "../Redux/Reducers/ImageLibraries/delete_Image_slicer";
import TablePagination from "@mui/material/TablePagination";
import Image from "../Component/DialogForFormimageLibraries";
import PopupMessage from "../Component/PopupMessage";
import { useNavigate } from "react-router-dom";

interface FontLibrariesProps {
  touched: any;
  errors: any;
  handleBlur: any;
  values: any;
  edit?: any;
  isLoading?: boolean;

  setFieldValue(arg0: string, value: any): unknown;
}

// this code dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ItemsPerPage = 10; // Number of items to display per page

// its is breadcrumbs

const ImageLibraries: FunctionComponent<FontLibrariesProps> = (props) => {
  const { getValues, closeFunction, editusers, successMessage }: any = props;
  const dispatcher = useAppDispatch();
  React.useEffect(() => {
    dispatcher(list_imagecategory(""));
    // axios
  }, []);
  const Listimagedata = useAppSelector(
    (state) => state?.ListImageCategory?.data
  );

  const navigate = useNavigate();

  const breadcrumbs = [
    <Link key="1" color="black" underline="none">
      <b className="heading">Library</b>
    </Link>,
    <Link
      underline="none"
      key="1"
      color="inherit"
      onClick={() => navigate("/ImageLibraries")}
      style={{
        fontWeight: "500",
        fontSize: "15px ",
        lineHeight: "16px ",
        color: "#BDBDBD",
        cursor: "pointer",
      }}
    >
      Image library
    </Link>,
  ];

  //    userpermission menuList
  const pageName = "Library / Image library";
  const menuList: any = JSON.parse(
    localStorage?.getItem("permission") as string
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageName;
  });

  const MenuOfUserpermission = menuList[index];

  const data = useAppSelector((state) => state?.ListImage?.data);
  const addData: any = useAppSelector((state) => state?.AddImage?.data);
  const addDataIsLoading = useAppSelector(
    (state) => state?.AddImage?.isLoading
  );

  const isLoading: any = useAppSelector((state) => state?.ListImage?.isLoading);
  const deleteData = useAppSelector((state) => state.Deleteimage.data);
  const deleteDataIsLoading = useAppSelector(
    (state) => state?.Deleteimage?.isLoading
  );
  // console.log("deljhg", deleteData);
  const dispatch = useDispatch();

  const [menus, setMenus] = useState<any>([]);
  //    list of image categories
  const ListImageCategory = useAppSelector(
    (state) => state.ListImageCategory.data
  );
  const category = Listimagedata.filter(
    (Listimagedata: ImageCategory) => Listimagedata.status
  ).map((category: ImageCategory) => {
    return { item: category.name, checked: false };
  });

  useEffect(() => {
    dispatch(list_image(""));
    dispatch(list_imagecategory(""));
  }, [deleteData, addData]);
  useEffect(() => {
    setMenus(category);
  }, [ListImageCategory]);

  //    chack box values change
  const handleCheckboxChange = (key: any, v: boolean) => {
    const updatedMenus = [...menus];
    updatedMenus[key].checked = v;
    setMenus(updatedMenus);
  };

  // this code is dialog for comics book is delete
  const [deleteDialog, setDeleteDialog] = React.useState(0);

  const [comicbookopen, comicbooksetOpen] = React.useState(false);

  const [deleteId, setDeleteId] = useState<number | undefined>(0);
  const comicbookdeleteOpen = () => {
    setDeleteDialog(deleteDialog + 0.001);

    // comicbooksetOpen(true);
  };

  const comicsbookdeleteClose = () => {
    comicbooksetOpen(false);
  };
  // Store the resetForm function to use later

  // Reset the form values when OK button in the alert dialog is clicked

  React.useEffect(() => {
    if (isLoading) {
      setDialog(isLoading);
    }
  }, [isLoading]);

  // const [selectedFile, setSelectedFile] = useState<any>(null);
  //    Popup messages
  const [popup, setPopoup] = React.useState(false);
  //    success dialog
  const [dialog, setDialog] = React.useState(false);

  //  font upload
  // const [selectedFile, setSelectedFile] = useState(null);
  const [renderedSize, setRenderedSize] = useState("");
  const [imageName, setimageNames] = useState("");

  const allowedExtensions = ["jpg", "jpeg", "png"];

  // console.log(selectedFile);

  // this code is dialog for add image
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDialog(false);
  };
  // this code search option
  const [myNames, setMyNames] = useState("");

  // const allNames = data.filter((font: any) =>
  //   font.name.toLocaleLowerCase().includes(myNames.toLocaleLowerCase())
  // );
  // console.log("all", allNames);
  const allNames = data
    .filter((card: ImageList) => {
      const selectedMenus = menus.filter(
        (menu: { checked: any }) => menu.checked
      );
      if (selectedMenus.length === 0) {
        return true; // No menu item selected, show all cards
      } else {
        return selectedMenus.some(
          (menu: { item: string }) => menu.item === card.category_name
        );
      }
    })
    .filter((value: ImageList) =>
      value
        .name!.replace(/\s+/g, "")
        .toLowerCase()
        .includes(myNames.replace(/\s+/g, "").toLowerCase())
    );

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [selectedId, setSelectedId] = useState(" ");
  const [selectedlabel, setSelectedlabel] = useState("");

  // pageniation
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page, setPage] = React.useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // Rows per page
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const itemsToShow = allNames.slice(startIndex, endIndex);
  // console.log("menus", menus);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <IsLoading
        isLoading={deleteDataIsLoading || addDataIsLoading || isLoading}
      />
      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div
        className="fontscrollbar"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            top: "0",
            position: "sticky",
            backgroundColor: "white",
            zIndex: "1",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "1em",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <Menus
              Buttonname={<img src={menu} alt="menu" height={16} />}
              size="medium"
              buttonSx={{ borderRadius: "0", minWidth: "0" }}
              Menus={menus.map(
                (
                  menu: {
                    item: string | number | undefined;
                    checked: boolean | undefined;
                  },
                  key: Key | null | undefined
                ) => {
                  return {
                    item: (
                      <div style={{ width: "100%" }} key={key}>
                        <CheckboxLabels
                          label={menu.item}
                          getValue={(v: boolean) => {
                            handleCheckboxChange(key, v);
                            setPage(0);
                          }}
                          defaultChecked={menu.checked}
                        />
                      </div>
                    ),
                  };
                }
              )}
            />
            <div>
              <div style={{ width: "16rem" }}>
                <SearchBar
                  // name="Serach"
                  getValues={(e) => {
                    setMyNames(e.target.value);
                    setPage(0);
                  }}
                  value={myNames}
                ></SearchBar>
              </div>
            </div>
            {MenuOfUserpermission?.add && (
              <Image
                // error={errorMessage}
                successMessage={addData}
                isLoading={addDataIsLoading}
                sx={{
                  padding: "15px",
                  backgroundColor: "black",
                  color: "white",
                  width: "150px",
                  height: "37px",
                  borderRadius: "0px",
                  border: "1px solid #BDBDBD",

                  "&:hover": { backgroundColor: "black" },
                }}
                placeholder="Role"
                getValues={(v: any) => {
                  dispatcher(add_image(v));
                  // console.log(v);
                }}
                Buttondialog={["Cancel"]}
                Dialogtitle={"Add Image"}
                Buttonname={"Add Image"}
              />
            )}
          </div>
        </div>

        <Dialog
          sx={{
            width: "100%",
            height: "100%",
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                borderRadius: "0px",
                width: "30%",
                // backgroundColor: "gray",
              },
            },
          }}
          open={comicbookopen}
          TransitionComponent={Transition}
          keepMounted
          // onClose={comicsbookdeleteClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            sx={{
              backgroundColor: "#000000",
              color: "white",
            }}
          >
            {"Delete"}

            <ClearOutlinedIcon
              className="clear"
              // onClick={comicsbookdeleteClose}
              sx={{ color: "white", float: "right" }}
            ></ClearOutlinedIcon>
          </DialogTitle>
          <DialogContent sx={{}}>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{ paddingTop: "40px", color: "black", textAlign: "center" }}
            >
              Are you sure want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "25px",
            }}
          >
            <Button
              variant="outlined"
              // onClick={comicsbookdeleteClose}
              sx={{
                width: "110px",
                height: "40px",
                marginRight: "20px",
                // fontSize: "13px",
                // alignItems: "center",
                // marginRight: "20px",
                borderRadius: "0px",
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              // onClick={() => {
              //   dispatch(delete_image(deleteId));
              //   setPopoup(true);
              //   comicsbookdeleteClose();
              // }}
              sx={{
                width: "110px",
                height: "40px",
                backgroundColor: "rgb(0, 0, 0)",
                color: "#FFFFFF",
                display: "flex",
                fontSize: "13px",
                alignItems: "center",

                borderRadius: "0px",
                justifyContent: "space-evenly",
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {data.length === 0 ? (
          <div className="NoDataFound">
            <h1>{isLoading ? "Loading" : "No data found"}</h1>
          </div>
        ) : (
          <div className="sectionDivs">
            {itemsToShow.map((card: ImageList, index: number) => {
              return (
                //  Card contains
                <>
                  <div className="cardDivs" key={index}>
                    {/*   Image of card  */}
                    <div
                      style={{
                        height: "200px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        className="zoomable-image"
                        src={card.image_url}
                        alt="image libraries"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                      />
                    </div>
                    <div className="cardFooter">
                      {/*   Name and size  */}
                      <div className="fontnamecenter">
                        <span>{card.name}</span>
                        {MenuOfUserpermission?.delete && (
                          <DeleteOutlineIcon
                            onClick={() => {
                              comicbookdeleteOpen();
                              setDeleteId(card.id);
                            }}
                            sx={{
                              backgroundColor: "#F5F5F5",
                              color: "black",
                              height: "1.3em",
                              width: "1.3em",
                              padding: "5px",
                              borderRadius: "50%",
                              "&:hover": {
                                color: "white",
                                backgroundColor: "black",
                              },
                            }}
                          ></DeleteOutlineIcon>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}
        <div
          style={{
            display: "flex",
            bottom: "0",
            position: "sticky",
            backgroundColor: "white",
            width: "100%",
            right: "0",
            flex: "1",
            alignItems: "flex-end",
          }}
        >
          <TablePagination
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
            component="div"
            count={allNames.length} // Total count of items
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <PopupMessage
        successMessage={deleteData}
        open={deleteDialog}
        Dialogtitle={"Are you sure you want to delete?"}
        submitFunction={() => dispatch(delete_image(deleteId))}
        isLoading={deleteDataIsLoading}
        closeFunction={() => null}
      />
    </div>
  );
};

export default ImageLibraries;
