import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
import { FAILED, SUCCESE } from "../../../Editor/utills/common";
import { ICoverPDF } from "../../../interfaces/IPDF";

interface IExtends extends IReduxState<string> {
  loadingIDs?: (number | string)[];
}
export const CoverPDFGenaration = createSlice({
  name: "CoverPDFGenaration",
  initialState: {
    isLoading: false,
    data: {},
    loadingIDs: [],
  } as IExtends,
  reducers: {
    CoverPDF_Genaration: (state, action: PayloadAction<ICoverPDF>) => {
      state.loadingIDs?.push(action.payload.customization_id!);
      state.isLoading = true;
    },
    success_api_call_CoverPDFGenaration: (
      state,
      action: PayloadAction<any>
    ) => {
      state.data = action.payload;
      SUCCESE(action.payload.message);
    },
    failure_api_call_CoverPDFGenaration: (state, action) => {
      state.data = action.payload;
      FAILED(action.payload.message);
    },
    stop_loader_cover: (state, action: PayloadAction<ICoverPDF>) => {
      state.isLoading = false;
      const id = action.payload.customization_id!;
      console.log(id);

      const copyState = state.loadingIDs;
      const index = copyState?.indexOf(id);
      copyState?.splice(index!, 1);

      copyState?.filter((ids) => ids !== id);
      state.loadingIDs = copyState;
    },
  },
});
export default CoverPDFGenaration.reducer;
export const {
  CoverPDF_Genaration,
  success_api_call_CoverPDFGenaration,
  failure_api_call_CoverPDFGenaration,
  stop_loader_cover,
} = CoverPDFGenaration.actions;
