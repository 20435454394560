import React, { FunctionComponent } from "react";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { IconButton, Slide, Switch, Tooltip } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import PauseIcon from "@mui/icons-material/Pause";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useAppDispatch } from "../Redux/store/hooks";
import { editable_Campaign } from "../Redux/Reducers/Campaign/editable_Campaign_slicer";
import { list_VariantById } from "../Redux/Reducers/Comics_Booklist/list_Variantlist_slicerBy_ID";
import { List_projectID } from "../Redux/Reducers/ProjectID/ProjectID_Slicer";
import {
  launch_popup_true,
  pause_popup_true,
  switch_popup_true,
  updata_launchAndPauseId,
  updata_project_Id,
} from "../Redux/Reducers/Campaign/launch&pass_Campaign_slicer";
import { relaunch_popup } from "../Redux/Reducers/Campaign/relaunch_Campaign_slicer";

interface DeleteoptionProps {
  value?: GridRenderCellParams;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Deleteoption: FunctionComponent<DeleteoptionProps> = (props) => {
  /*    Redux dispatch     */
  const dispatch = useAppDispatch();

  return (
    <section
      style={{
        display: "flex",
        gap: "0.5em",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Tooltip title={"Launch"}>
        <IconButton
          sx={{ p: "4px" }}
          disabled={
            props.value?.row.status === "INACTIVE" ||
            props.value?.row.status === "PUBLISHED"
          }
          onClick={() => {
            if (props.value?.row.shopify_product_id) {
              dispatch(relaunch_popup(props.value?.row.project_id));
            } else {
              dispatch(launch_popup_true());
              dispatch(updata_launchAndPauseId(props.value?.row.campaign_id));
              dispatch(updata_project_Id(props.value?.row.project_id));
            }
          }}
        >
          <RocketLaunchIcon
            sx={{
              backgroundColor: "#F5F5F5",
              color:
                props.value?.row.status === "INACTIVE" ||
                props.value?.row.status === "PUBLISHED"
                  ? "#C7C7C7"
                  : "black",
              padding: "5px",
              fontSize: "1.7rem",

              cursor: "pointer",
              borderRadius: "50%",
              "&:hover": {
                color:
                  props.value?.row.status === "INACTIVE" ||
                  props.value?.row.status === "PUBLISHED"
                    ? undefined
                    : "white",
                backgroundColor:
                  props.value?.row.status === "INACTIVE" ||
                  props.value?.row.status === "PUBLISHED"
                    ? undefined
                    : "black",
              },
            }}
          />
        </IconButton>
      </Tooltip>

      <Tooltip title="Pause">
        <IconButton
          sx={{ p: "4px" }}
          onClick={() => {
            dispatch(pause_popup_true());
            dispatch(updata_launchAndPauseId(props.value?.row.campaign_id));
          }}
          disabled={props.value?.row.status === "INACTIVE"}
        >
          <PauseIcon
            sx={{
              backgroundColor: "#F5F5F5",
              color:
                props.value?.row.status === "INACTIVE" ? "#C7C7C7" : "black",
              padding: "5px",
              cursor: "pointer",
              borderRadius: "50%",
              fontSize: "1.7rem",

              "&:hover": {
                color:
                  props.value?.row.status === "INACTIVE" ? undefined : "white",
                backgroundColor:
                  props.value?.row.status === "INACTIVE" ? undefined : "black",
              },
            }}
          ></PauseIcon>
        </IconButton>
      </Tooltip>

      <Tooltip title={props.value?.row.status === "INACTIVE" ? "View" : "Edit"}>
        <IconButton
          sx={{ p: "4px" }}
          onClick={() => {
            dispatch(List_projectID());
            dispatch(list_VariantById(props.value?.row.project_id));
            dispatch(editable_Campaign(props.value?.row.campaign_id));
          }}
        >
          {props.value?.row.status === "INACTIVE" ||
          props.value?.row.status === "PUBLISHED" ? (
            <VisibilityIcon
              sx={{
                backgroundColor: "#F5F5F5",
                color: "black",
                padding: "5px",
                cursor: "pointer",
                borderRadius: "50%",
                fontSize: "1.7rem",

                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
              }}
            />
          ) : (
            <EditIcon
              sx={{
                backgroundColor: "#F5F5F5",
                color: "black",
                padding: "5px",
                cursor: "pointer",
                borderRadius: "50%",
                fontSize: "1.7rem",

                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
              }}
            />
          )}
        </IconButton>
      </Tooltip>

      <Tooltip
        title={
          (props.value?.row.status === "INACTIVE" && "Inactive") || "Active"
        }
      >
        <Switch
          size="small"
          checked={props.value?.row.status !== "INACTIVE"}
          onChange={(event) => {
            console.log(event.target.checked);

            dispatch(switch_popup_true(event.target.checked));
            dispatch(updata_launchAndPauseId(props.value?.row.campaign_id));
          }}
        />
      </Tooltip>
    </section>
  );
};

export default Deleteoption;
