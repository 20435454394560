import { FunctionComponent } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
// import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../Pages/Page.css";
import Uploadimage from "../Pages/Image/uploadimage.png";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  FormHelperText,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
//
import TextField from "@mui/material/TextField";
import Autocomplete from "./Autocomplete";
import "../App.css";
import { Formik, Form, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";
import "../Pages/Page.css";
import IsLoading from "./isLoading";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { list_imagecategory } from "../Redux/Reducers/Image Category/list_ImageCategory_slicer";
// import IsLoading from "./isLoading";
// import { useAppDispatch } from "../redux/store/hooks";
// import { edit_bay } from "../redux/Bay/edit_Bay_slicer";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogsProps {
  sx?: SxProps<Theme> | undefined;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any | number;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onclick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties | undefined;
  getValues?: any;
  closeDio?: any;
  // error?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;

  // errors?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const Image: FunctionComponent<DialogsProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [val, setVal] = React.useState({});

  //    Popup messages
  const [popup, setPopoup] = React.useState(false);

  //    success dialog
  const [dialog, setDialog] = React.useState(false);

  // console.log(props.closeFunction);
  const { getValues, placeholder, successMessage, isLoading }: any = props;

  //   const dispatch = useAppDispatch();

  const dispatcher = useAppDispatch();
  React.useEffect(() => {
    dispatcher(list_imagecategory(""));
    // axios
  }, []);
  const Listimagedata = useAppSelector(
    (state) => state?.ListImageCategory?.data
  );

  /*    formik form ref    */
  const formik = React.useRef<
    FormikProps<{
      name: string;
      category_id: string;
      image_url: File | string;
    }>
  >(null);
  const initialValues: {
    name: string;
    category_id: string;
    image_url: File | string;
  } = {
    name: props?.edit?.name || "",
    image_url: props?.edit?.image_urls || "",
    category_id: props?.edit?.category_id || "",
  };

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    // closeFunction(true);
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    setDialog(false);
    // dispatch(edit_bay('' as any));
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    setDialog(false);
    setPopoup(false);
  }, [open]);
  React.useEffect(() => {
    if (isLoading) {
      setDialog(isLoading);
    }
  }, [isLoading]);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        color={props.color}
        size={props.size}
        startIcon
        // disabled
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        // onClose={handleClose}
        // scroll={scroll}
        PaperProps={{
          sx: {
            borderRadius: "0",

            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            fontWeight: "bold",
            backgroundColor: "black",
            color: "white",
          }}
        >
          {props.Dialogtitle}
          <ClearOutlinedIcon
            className="clear"
            onClick={handleClose}
            sx={{ color: "white", float: "right" }}
          ></ClearOutlinedIcon>
        </DialogTitle>
        <DialogContent
          sx={{
            "&::-webkit-scrollbar": { display: "none" },
            padding: "1.5em 24px 0!important",
          }}
        >
          <Formik
            initialValues={initialValues}
            innerRef={formik}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Please enter image name"),
              category_id: Yup.string().required("Please select a category"),
              image_url: Yup.mixed()
                .required("Please upload Image.")
                .test(
                  "type",
                  "Only the following formats are accepted: .jpeg, .jpg, and .png",
                  (value: any) => {
                    if (typeof value === "string") return true; // Skip validation if value is not provided
                    return (
                      value &&
                      (value.type === "image/jpeg" ||
                        value.type === "image/png" ||
                        value.type === "image/jpg")
                    );
                  }
                )
                .test(
                  "max-length",
                  "File name must be less than or equal to 35 characters",
                  (value: any) => {
                    if (!value) return true; // Skip validation if value is not provided
                    if (typeof value === "string") return true; // Skip validation if value is not provided
                    const fileName = value.name.length;
                    return fileName <= 35;
                  }
                ),
            })}
            onSubmit={(values) => {
              // getValues(values);
              setDialog(true);
              console.log("image", values);
            }}
          >
            {(props) => {
              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />
                  {/* <PopupMessage
                    open={dialog}
                    submitFunction={props.handleSubmit}
                    successMessage={successMessage}
                    isLoading={isLoading}
                    Dialogtitle={"Are you sure you want to proceed?"}
                  /> */}

                  {/*     for submit when enter button click     */}
                  <button type="submit" style={{ display: "none" }}>
                    submit
                  </button>

                  <div className="lableInputDiv">
                    <b
                      style={{
                        color: "#000000",
                      }}
                    >
                      Image Name
                      <span style={{ color: "red" }}>*</span>
                    </b>
                    <TextField
                      id="outlined-basic"
                      // required
                      variant="outlined"
                      name="name"
                      sx={{
                        "& fieldset": {
                          borderRadius: "0px",
                        },
                        width: "clamp(20rem,30vw,30rem)",
                      }}
                      autoComplete="off"
                      helperText={
                        props.errors.name &&
                        props.touched.name &&
                        props.errors.name
                        // : "Enter your Email."
                      }
                      error={!!(props.errors.name && props.touched.name)}
                      // onChange={props.handleChange}

                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Use regex to check if the input contains only characters (letters)
                        if (
                          /^[0-9a-zA-Z\s_]+$/.test(inputValue) &&
                          inputValue.length < 35
                        ) {
                          props.setFieldValue("name", inputValue);
                        } else {
                          // If validation fails, remove the last character from the input value
                          props.setFieldValue("name", inputValue.slice(0, -1));
                        }
                      }}
                      onBlur={props.handleBlur}
                      value={props.values.name}
                      // onChange={props.handleChange}
                    />
                  </div>
                  <div className="lableInputDiv">
                    <b
                      style={{
                        color: "#000000",
                      }}
                    >
                      Categories
                      <span style={{ color: "red" }}>*</span>
                    </b>
                    <Autocomplete
                      Listitem={Listimagedata.filter((Listimagedata: any) => {
                        return Listimagedata.status === true;
                      }).map((v: any) => {
                        return { label: v.name, value: v.id };
                      })}
                      sx={{ width: "clamp(20rem,30vw,30rem)" }}
                      // width={200}
                      // required
                      variant={"outlined"}
                      name={"category_id"}
                      value={props.values}
                      onBlur={props.handleBlur}
                      onchange={(e: any) => {
                        // console.log(e);

                        props.setFieldValue("category_id", e.value);
                      }}
                      error={
                        props.errors.category_id && props.touched.category_id
                      }
                      helperText={
                        props.errors.category_id &&
                        props.touched.category_id &&
                        props.errors.category_id
                      }
                    ></Autocomplete>
                  </div>
                  <b
                    style={{
                      color: "#000000",
                    }}
                  >
                    Upload Image
                    <span style={{ color: "red" }}>*</span>
                  </b>
                  <label
                    htmlFor={"image_url"}
                    style={{
                      outline:
                        props.touched.image_url && props.errors.image_url
                          ? "1px solid #d32f2f"
                          : "1px solid rgba(0, 0, 0, 0.23)",
                      height: "clamp(20rem,30vw,30rem)",
                      width: "clamp(20rem,30vw,30rem)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <input
                      type="file"
                      name={"image_url"}
                      id={"image_url"}
                      hidden
                      accept="image/jpeg,image/jpg,image/png"
                      onBlur={props.handleBlur}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        props.setFieldTouched("image_url", true);
                        props.setFieldValue("image_url", e.target.files![0]);
                      }}
                    />
                    <img
                      src={Uploadimage}
                      style={{
                        maxWidth: "clamp(20rem,30vw,30rem)",
                        maxHeight: "clamp(20rem,30vw,30rem)",
                      }}
                      srcSet={
                        // typeof props.values?.image_urls === "string"
                        //   ? (props.values?.image_urls as string)
                        //   :
                        props.values?.image_url &&
                        URL?.createObjectURL(props.values?.image_url as File)
                      }
                      alt="upload"
                    />
                  </label>
                  <FormHelperText error sx={{ marginLeft: "13px" }}>
                    {props.touched.image_url &&
                      props.errors.image_url &&
                      props.errors.image_url}
                  </FormHelperText>
                  {!isLoading && (
                    <Dialogs
                      open={!isLoading ? dialog : false}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        sx: {
                          borderRadius: "0",
                          // height: height || "80vh",
                          "&::-webkit-scrollbar": { display: "none" },
                        },
                      }}
                    >
                      <DialogContent
                        sx={{ padding: "1.5em 1.5em 0 !important" }}
                      >
                        {!popup ? (
                          <DialogContentText
                            sx={{ color: "black", fontSize: "16px" }}
                          >
                            Are you sure you want to proceed?
                            {/* <div className="submitPopup">
                              <div
                                style={{
                                  fontSize: "20px",
                                  textAlign: "center",
                                }}
                              >
                              </div>
                            </div> */}
                          </DialogContentText>
                        ) : (
                          // isLoading && (
                          <DialogContentText id="alert-dialog-description">
                            <div className="submitPopup">
                              <div
                                style={{
                                  fontSize: "16px",
                                  textAlign: "center",
                                  color: "black",
                                  margin: "0px 40px 0px 40px ",
                                }}
                              >
                                {successMessage.message}
                              </div>
                              <div
                                style={{
                                  fontSize: "20px",
                                  textAlign: "center",
                                  margin: "0px 40px 0px 40px ",
                                }}
                              >
                                {successMessage?.status ? (
                                  <img
                                    src={require("../Pages/Image/success.png")}
                                    className="imageSize"
                                    alt="success"
                                  />
                                ) : (
                                  <img
                                    src={require("../Pages/Image/error.png")}
                                    className="imageSize"
                                    alt="error"
                                  />
                                )}
                              </div>
                            </div>
                          </DialogContentText>
                          // )
                        )}
                      </DialogContent>

                      {!popup ? (
                        <DialogActions
                          sx={{
                            justifyContent: "center",
                            gap: "0.5em",
                            padding: "1.5em",
                          }}
                        >
                          <Button
                            onClick={() => setDialog(false)}
                            style={{
                              padding: "15px",
                              // backgroundColor: "#1976D2",
                              color: "black",
                              width: "100px",
                              height: "37px",
                              borderRadius: "0px",
                              border: "1px solid black",
                            }}
                            sx={{
                              backgroundColor: "white",
                              "&:hover": { backgroundColor: "white" },
                            }}
                          >
                            No
                          </Button>
                          <Button
                            form="form"
                            type="submit"
                            onClick={() => {
                              // props.handleSubmit();
                              // setTimeout(() => setPopoup(true), 1000);

                              getValues(props.values);
                              setPopoup(true);
                            }}
                            style={{
                              // backgroundColor: "#1976D2",
                              color: "white",
                              width: "100px",
                              height: "37px",
                              borderRadius: "0px",
                              border: "1px solid black",
                            }}
                            autoFocus
                            sx={{
                              backgroundColor: "black",
                              "&:hover": { backgroundColor: "black" },
                            }}
                          >
                            Yes
                          </Button>
                        </DialogActions>
                      ) : (
                        // !props.isLoading && (
                        <DialogActions
                          sx={{
                            justifyContent: "center",
                            gap: "0.5em",
                            padding: "1.5em",
                          }}
                        >
                          <Button
                            onClick={() => {
                              successMessage?.status && setOpen(false);
                              !successMessage?.status && setPopoup(false);
                              setDialog(false);
                            }}
                            style={{
                              // backgroundColor: "#1976D2",
                              color: "white",
                              width: "100px",
                              height: "37px",
                              borderRadius: "0px",
                              border: "1px solid #BDBDBD",
                            }}
                            autoFocus
                            sx={{
                              backgroundColor: "black",
                              "&:hover": { backgroundColor: "black" },
                            }}
                          >
                            OK
                          </Button>
                        </DialogActions>
                        // )
                      )}
                    </Dialogs>
                  )}
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            gap: "0.5em",
            padding: "1.5em",
          }}
        >
          <Button
            onClick={handleClose}
            style={{
              width: "100px",
              height: "40px",
              border: "1px solid black",
              color: "black",
              backgroundColor: "white",
              borderRadius: "0px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => formik.current?.handleSubmit()}
            style={{
              width: "100px",
              height: "40px",
              border: "1px solid black",
              color: "white",
              backgroundColor: "black",
              borderRadius: "0px",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialogs>
    </>
  );
};

export default Image;
