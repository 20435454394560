import { FunctionComponent } from "react";
import backgroundimage from "./Image/image 2.png";

interface LoginImageProps {}

const LoginImage: FunctionComponent<LoginImageProps> = (props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <img src={backgroundimage} width="100%" height="100%" alt="Comics"></img>
    </div>
  );
};

export default LoginImage;
