import { createSlice } from "@reduxjs/toolkit";
import { ImageQuestionOptions } from "../../../interfaces/IImageQuestions";

export const ListPageQuestions = createSlice({
  name: "ListPageQuestions",
  initialState: {
    isLoading: false,
    data: {},
  } as ImageQuestionOptions,
  reducers: {
    list_PageQuestions: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_list_PageQuestions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_list_PageQuestions: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      // FAILED(action.payload);
    },
    // list_PageQuestions_is_success: (state, action) => {
    //   state.isSuccess = false;
    // },
    clearListPageQuestions: (state) => {
      state.data = {};
    },
  },
});
export default ListPageQuestions.reducer;
export const {
  list_PageQuestions,
  success_api_call_list_PageQuestions,
  failure_api_call_list_PageQuestions,
  // list_PageQuestions_is_success,
  clearListPageQuestions,
} = ListPageQuestions.actions;
