import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import {
  failure_api_call_add_ComicsBook,
  success_api_call_add_ComicsBook,
} from "./add_ComicsBook_slicer";

import {
  failure_api_call_ComicsBook,
  success_api_call_ComicsBook,
} from "./list_ComicsBook_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_delete_ComicsBooks,
  success_api_call_delete_ComicsBooks,
} from "./delete_ComicsBook_slicer";
import {
  failure_api_call_edit_ComicsBook,
  success_api_call_edit_ComicsBook,
} from "./edit_ComicsBook_slicer";

// import {
//   failure_api_call_add_user,
//   success_api_call_add_user,
// } from "./add_User_slicer";
// import {
//   failure_api_call_edit_user,
//   success_api_call_edit_user,
// } from "./edit_User_slicer";
// import { failure_api_call_login, success_api_call_login } from "./Login_slicer";

//add user
const addComicsBookCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);
  console.log("api call ", a.payload);

  const add = {
    ...a.payload,
    variant_id: a.payload.variant_id.map((v: any) => v.value),
    updated_by: adminId,
    created_by: adminId,
  };

  // console.log(add);

  return Axios.post(`${process.env.REACT_APP_api_url}/v1/api/project/`, add, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* AddComicsBookApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addComicsBookCall, e);
    // console.log(response.data);

    yield put(success_api_call_add_ComicsBook(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_ComicsBook(e));
    yield put(expireSignature(e));
  }
}

//list comic book
const listComicsBookCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/project/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* listComicsBookApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listComicsBookCall, e);

    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      //   active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    console.log(res);
    yield put(success_api_call_ComicsBook(res));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_ComicsBook(e));
    yield put(expireSignature(e));
  }
}

//    Delete a pageQuestions
export function* deleteComicsBooksApi(e: any) {
  try {
    const response: AxiosResponse = yield call(deleteComicsBooksCall, e);
    yield put(success_api_call_delete_ComicsBooks(response.data.data));
  } catch (error) {
    console.log(error);
    yield put(failure_api_call_delete_ComicsBooks(error));
  }
}
const deleteComicsBooksCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  console.log(a.payload);
  return Axios.delete(`${process.env.REACT_APP_api_url}/v1/api/project/`, {
    data: formData,
    headers: { Authorization: `Bearer ${token}` },
  });
};

const editComicsBookCall = (action: any) => {
  const token = localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);

  const edit = {
    ...action.payload,
    variant_id: action.payload.variant_id.map((v: any) => v.value),
    updated_by: adminId,
    created_by: adminId,
  };
  return Axios.put(`${process.env.REACT_APP_api_url}/v1/api/project/`, edit, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export function* editComicsBookApi(event: any) {
  try {
    const response: AxiosResponse = yield call(editComicsBookCall, event);
    yield put(success_api_call_edit_ComicsBook(response.data));
  } catch (error) {
    yield put(failure_api_call_edit_ComicsBook(error));
  }
}
