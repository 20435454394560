import { createSlice } from "@reduxjs/toolkit";

export const DeleteComicsBooks = createSlice({
  name: "DeleteComicsBooks",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    delete_ComicsBooks: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_delete_ComicsBooks: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_delete_ComicsBooks: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      // FAILED(action.payload);
    },
    delete_ComicsBooks_is_success: (state, action) => {
      state.isSuccess = false;
    },
  },
});
export default DeleteComicsBooks.reducer;
export const {
  delete_ComicsBooks,
  success_api_call_delete_ComicsBooks,
  failure_api_call_delete_ComicsBooks,
  delete_ComicsBooks_is_success,
} = DeleteComicsBooks.actions;
