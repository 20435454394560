import { Button } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import Select from "../../Component/Select";
import ImageUploadPopup from "./ImageUploadedPopup";
import "./ImageUploadedPopup.css";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import {
  List_questionID,
  clearQuestionId,
} from "../../Redux/Reducers/QuestionID/QuestionID_Slicer";
import {
  clearListPageQuestions,
  list_PageQuestions,
} from "../../Redux/Reducers/PageQuestions/list_PageQuestions_slicer";
import { QuestionIDI } from "../../interfaces/QuestionIDI";
import line from "../accets/textlineborder.svg";
import { delete_PageQuestions } from "../../Redux/Reducers/PageQuestions/delete_PageQusetions_slicer";
import {
  ImageQuestionOptionsData,
  ImageUrlsArray,
} from "../../interfaces/IImageQuestions";
import {
  Edit_option,
  Jsonid_Dropdown,
  UpdatedImage,
} from "../../Redux/Reducers/Editor_value";
interface QuestionsProps {}

const Questions: FunctionComponent<QuestionsProps> = () => {
  //    Create a dropdown for the questions
  const [dropdown, setCreateDropdown] = useState<
    { id: number; value: number | null }[]
  >([]);

  //    List of questions
  const [question, setQuestion] = useState<{ label: string; value: number }[]>(
    []
  );

  //    List of questions
  const [arrayOfImages, setArrayOfImages] = useState<
    ImageUrlsArray[] | undefined
  >([]);

  const dispatch = useAppDispatch();
  //  QuestionID for dropdown list
  const QuestionID = useAppSelector((state) => state.QuestionID.data);
  const QuestionIDIsLoading = useAppSelector(
    (state) => state.QuestionID.isLoading
  );

  //  Upload response
  const upLoadData = useAppSelector((state) => state.QuestionImages.data);
  // console.log(upLoadData);

  //  LayerQuestionsID for list of dropdown
  const LayerQuestionsID = useAppSelector(
    (state) => state.ListPageQuestions.data
  );
  // console.log(LayerQuestionsID);
  const LayerQuestionsIDIsLoading = useAppSelector(
    (state) => state.ListPageQuestions.isLoading
  );
  const modifiedLayerQuestionsID = LayerQuestionsID?.data?.question_ids
    ?.map((layerQuestion: number | null, index: number) => {
      return { id: index + 1, value: layerQuestion };
    })
    .filter(
      (item: { id: number; value: number | null }) => item.value !== null
    );
  //    modify for autocomplete
  const modifyQuestionID = QuestionID?.map((question: QuestionIDI) => ({
    label: question.question,
    value: question.id,
  }));

  /*    set array of images for show the options     */
  useEffect(() => {
    setArrayOfImages(LayerQuestionsID?.data?.image_urls);
  }, [LayerQuestionsID?.data?.image_urls]);

  useEffect(() => {
    setArrayOfImages(upLoadData?.data?.image_urls);
  }, [upLoadData?.data?.image_urls]);
  useEffect(() => {
    //    set options
    setQuestion(modifyQuestionID);
    // dispatch(list_PageQuestions(370));
  }, [QuestionID]);

  useEffect(() => {
    if ((LayerQuestionsID?.data?.question_ids ?? []).length !== 0) {
      /*    set dropdown     */
      setCreateDropdown(modifiedLayerQuestionsID!);
    } else {
      /*    clear the dropdown and image option list     */
      setCreateDropdown([]);
      setArrayOfImages([]);
    }
  }, [LayerQuestionsID]);

  useEffect(() => {
    return () => {
      // dispatch(clearQuestionId());
      dispatch(clearListPageQuestions());
      setQuestion([]);
      setCreateDropdown([]);
    };
  }, []);

  return (
    <>
      <div style={{ color: "#ffffff" }}>
        <div>
          <div
            className="texttoolalign"
            style={{ fontSize: "16px", marginTop: "10px" }}
          >
            Image Questions{" "}
          </div>
          <img src={line} alt="invalid" width={"100%"} />
        </div>
        {/*     Mapping the dropdown     */}
        {!QuestionIDIsLoading && (
          <div>
            {dropdown.map((value) => {
              const index = (modifyQuestionID ?? []).findIndex(
                (val) => value.value === val.value
              );
              return (
                <div className="dropdownMappingDiv" key={value.id}>
                  <Select
                    Listitem={
                      (question ?? []).filter((filter) => {
                        return !dropdown
                          ?.map((item) => item.value)
                          .includes(filter.value);
                      }) ?? []
                    }
                    label={"Questions"}
                    disableClearable
                    disabled={LayerQuestionsIDIsLoading}
                    defaultValue={index !== -1 ? modifyQuestionID[index] : null}
                    onchange={(e: any, v: any) => {
                      //    prevent the update of the array
                      setCreateDropdown((preValue) =>
                        preValue.map((val) =>
                          val.id === value.id
                            ? { ...val, value: v ? v?.value : null }
                            : val
                        )
                      );
                    }}
                    sx={{
                      width: "min(20rem,70%)",
                      // height: "max(45px)",
                      backgroundColor: "#868686",
                      padding: "-8px",
                      border: "none",
                    }}
                    labelColor="white"
                    inputColor="white"
                    loading={QuestionIDIsLoading}
                  />

                  {/*     Delete the dropdown    */}
                  <div
                    className="deleteDropdown"
                    onClick={() => {
                      //    Remove the dropdow
                      setCreateDropdown((current) =>
                        current.filter((dropdown) => {
                          return dropdown.id !== value.id;
                        })
                      );
                      /*    cleat image options when clear dropdown    */
                      setArrayOfImages([]);
                      // dispatch(Edit_option("deleted"));

                      // dispatch(
                      //   delete_PageQuestions({
                      //     page_id: parseInt(localStorage.getItem("Page_Id")!),
                      //     question_id: value.value,
                      //   })
                      // );

                      /*    Delete image layer     */
                      dispatch(Edit_option("deleted"));
                    }}
                  >
                    <div>X</div>
                  </div>
                </div>
              );
            })}
            {/*     fileter and show the image uploder popup     */}
            {dropdown.some((dropdown) => dropdown.value === null)
              ? ""
              : !LayerQuestionsIDIsLoading &&
                dropdown.length !== 0 && (
                  <div style={{ display: "inline-block" }}>
                    <ImageUploadPopup
                      QuestionIDs={dropdown.map((num) => num.value)}
                      layer_id={""}
                    />
                  </div>
                )}
          </div>
        )}
        {/*     Add Qustion Button     */}
        {dropdown.length <= 1 ? (
          <Button
            onClick={() => {
              //    Add dropdown
              setCreateDropdown((pre) => [
                ...pre,
                { id: dropdown.length + 1, value: null },
              ]);

              /*    cleat image options when add new question    */
              setArrayOfImages([]);
            }}
            disabled={LayerQuestionsIDIsLoading}
            variant="contained"
            sx={{
              textTransform: "none",
              borderRadius: "0",
              marginTop: "20px",
              "&.Mui-disabled": {
                background: "#333",
                color: "white",
              },
            }}
          >
            {LayerQuestionsIDIsLoading ? "Loading..." : "+ Add Question"}
          </Button>
        ) : null}
        <div
          style={{
            display: "flex",
            gap: "1em",
            justifyContent: "center",
            marginTop: "1em",
            flexWrap: "wrap",
          }}
        >
          {arrayOfImages?.map((image) => {
            return (
              <div
                key={image.id}
                onClick={() => {
                  dispatch(
                    UpdatedImage({
                      imageurl: image.images,
                      questionid: image.id,
                    })
                  );
                  // console.log({ imageurl: image.images, questionid: image.id });
                }}
              >
                <img
                  src={image.images}
                  alt="Options"
                  style={{
                    height: "clamp(3rem, 9vh, 9rem)",
                    width: "clamp(3rem, 9vh, 9rem)",
                  }}
                  loading="lazy"
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Questions;
