import { createSlice } from "@reduxjs/toolkit";
import { IProjectId } from "../../../interfaces/ComicsBookEdit";
import { IReduxState } from "../../../interfaces/IReduxState";

export const ProjectID = createSlice({
  name: "ProjectID",
  initialState: {
    isLoading: false,
    data: {},
  } as IReduxState<IProjectId[]>,
  reducers: {
    List_projectID: (state) => {
      state.isLoading = true;
    },
    Success_list_ProjectID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    Failure_list_ProjectID: (state) => {
      state.isLoading = false;
    },
    clearProjectID: (state) => {
      state.data = {};
    },
  },
});
export default ProjectID.reducer;
export const {
  List_projectID,
  Success_list_ProjectID,
  Failure_list_ProjectID,
  clearProjectID,
} = ProjectID.actions;
