import { createSlice } from "@reduxjs/toolkit";

export const AddListImageCategory = createSlice({
  name: "AddListImageCategory",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    add_ListImageCategory: (state) => {
      state.isLoading = true;
    },
    success_api_call_add_ListImageCategory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_add_ListImageCategory: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      // FAILED(action.payload);
    },
    add_ListImageCategory_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_ListImageCategory_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default AddListImageCategory.reducer;
export const {
  add_ListImageCategory,
  success_api_call_add_ListImageCategory,
  failure_api_call_add_ListImageCategory,
  create_ListImageCategory_complete_api,
  add_ListImageCategory_is_success,
} = AddListImageCategory.actions;
