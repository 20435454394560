import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAPIResponse, IReduxState } from "../../../interfaces/IReduxState";
import { FAILED, SUCCESE } from "../../../Editor/utills/common";
import { ICoverPDF } from "../../../interfaces/IPDF";

interface ExtendState extends IReduxState<null> {
  loadingIDs?: (number | string)[];
  popup?: boolean;
  luluData?: ICoverPDF;
  order_number?: number | null;
}
export const LuluPrint = createSlice({
  name: "LuluPrint",
  initialState: {
    isLoading: false,
    data: {},
    loadingIDs: [],
    popup: false,
    luluData: {},
    order_number: null,
  } as ExtendState,
  reducers: {
    lulu_Print: (state, action) => {
      state.loadingIDs?.push(action.payload.order_id!);
      state.isLoading = true;
      state.popup = false;
    },
    success_api_call_lulu_Print: (
      state,
      action: PayloadAction<IAPIResponse<null>>
    ) => {
      state.data = action.payload;
      if (action.payload.status) {
        SUCCESE(action.payload.message);
      } else {
        FAILED(action.payload.message);
      }
    },
    failure_api_call_lulu_Print: (state, action) => {
      state.data = action.payload;
      FAILED(action.payload.message);
    },
    stop_loader_print: (state, action: PayloadAction<ICoverPDF>) => {
      state.isLoading = false;
      const id = action.payload.order_id!;
      console.log(id);

      const copyState = state.loadingIDs;
      const index = copyState?.indexOf(id);
      copyState?.splice(index!, 1);

      copyState?.filter((ids) => ids !== id);
      state.loadingIDs = copyState;
    },
    open_print_popup: (state, action) => {
      state.luluData = action.payload;
      state.popup = true;
      state.order_number = action.payload.order_number;
    },
    close_print_popup: (state) => {
      state.popup = false;
    },
  },
});
export default LuluPrint.reducer;
export const {
  lulu_Print,
  success_api_call_lulu_Print,
  failure_api_call_lulu_Print,
  stop_loader_print,
  open_print_popup,
  close_print_popup,
} = LuluPrint.actions;
