import { createSlice } from "@reduxjs/toolkit";

export const Login = createSlice({
  name: "Login",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    login: (state) => {
      state.isLoading = true;
    },
    success_api_call_login: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // console.log(action.payload);
      // SUCCESE(action.payload.message);
    },
    failure_api_call_login: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      // FAILED(action.payload);
    },
    login_is_success: (state) => {
      state.isSuccess = false;
    },
    create_user_complete_api: (state) => {
      state.api_completed = false;
    },
    clear_data: (state) => {
      state.data = [];
    },
  },
});
export default Login.reducer;
export const {
  login,
  success_api_call_login,
  failure_api_call_login,
  create_user_complete_api,
  login_is_success,
  clear_data,
} = Login.actions;
