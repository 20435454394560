import { FunctionComponent, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Input,
  Slide,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import React from "react";
import reuqird from "./Image/carbon_warning.svg";
import { login } from "../Redux/Reducers/User-Register&Login/Login_slicer";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { useNavigate } from "react-router";
import Logo from "./Image/Logo.jpg";
import { styled } from "@mui/system";
import Eye from "./Image/Eye.png";
import Eyes from "./Image/Eye2.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { add_CreatePassword } from "../Redux/Reducers/CreatePassword/add_CreatePassword_slicer";

interface CreatepasswordFormProps {}

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#FF7F00 ",
//     },
//   },
// });
const CreatepasswordForm: FunctionComponent<CreatepasswordFormProps> = () => {
  const CustomSpan = styled("span")({
    fontWeight: "100",
    fontSize: "22px",
    fontFamily: "Arial, sans-serif",
  });
  const HelloSpan = styled("span")({
    fontWeight: "100",
    fontSize: "30px",
    fontFamily: "Arial, sans-serif",
  });
  // const [isFocused, setIsFocused] = useState(false);
  // const handleFocus = () => {
  //   setIsFocused(true);
  // };

  // Custom validation function to check if password and setpassword match
  const validatePasswords = (values: any) => {
    const errors: any = {};
    if (values.password !== values.setpassword) {
      errors.setpassword = "Passwords do not match";
    }
    return errors;
  };

  // <>    Alert msg   </>
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswords, setShowPasswords] = React.useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] =
    React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswords = () => {
    setShowPasswords((show) => !show);
    setConfirmPasswordVisible((visible) => !visible);
  };
  const handleClickHidePasswords = () => {
    setShowPasswords((show) => !show);
    setConfirmPasswordVisible((visible) => !visible);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseDownPasswords = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const nav = useNavigate();

  const dispatch = useAppDispatch();
  const log_in: any = useAppSelector((state) => state.AddCreatePassword.data);
  const passwordsubmit: any = useAppSelector(
    (state) => state.AddCreatePassword.data
  );

  const isLoading = useAppSelector(
    (state) => state.AddCreatePassword.isLoading
  );
  console.log("loading", isLoading);

  // <>  useEffect  <>
  useEffect(() => {
    console.log("hi");
    setOpen(false);
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
      // log_in?.message?.length !== undefined && setOpen(true);
      log_in?.message?.length !== undefined && !log_in?.status && setOpen(true);
      const token = localStorage.getItem("token");
      console.log(token);

      if (token !== null && token !== "undefined") {
        // nav("/login");
      }

      if (log_in?.status) {
        localStorage.clear();
        nav("/login");
      }
    }
    // if (login?.data?.status) {
    //   // localStorage.setItem("SpToken", login.data.token);
    //   setLoading(false);
    //   // nav("/home");
    //   // if (login?.data?.message === "Login successfully") {
    //   //   nav("/home");
    //   // }
    // } else {
    //   login.length !== 0 && setOpen(true);
    //   setLoading(false);
    // }
  }, [isLoading, nav]);

  return (
    <div
      style={{
        // padding: "2rem",
        display: "flex",
        justifyContent: "center",
        // minHeight: "70vh",
        // margin: "250px 30px 30px 30px ",
        alignItems: "center",
        // boxShadow: "6px 6px 18px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionComponent={(props: any) => {
          return <Slide {...props} direction="down" />;
        }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          {log_in?.message}
        </Alert>
      </Snackbar>
      <div style={{ display: "flex", justifyContent: "center" }}></div>

      <div>
        <span
          style={{
            fontWeight: "50",
            // height: "30px",
            fontSize: "30px",
            lineHeight: "23px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            margin: "50px",
          }}
        >
          <img src={Logo} alt="" height={100} />
        </span>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "15px 0px 15px 0px",
          }}
        >
          {/* <HelloSpan>Hello</HelloSpan> */}
          <span
            style={{
              fontFamily: "sans-serif",

              color: "black",
              fontSize: "30px",
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            Create New Password
          </span>
        </span>

        <CustomSpan>Enter the valid password</CustomSpan>

        <Formik
          initialValues={{
            setpassword: "",
            password: "",
          }}
          onSubmit={(values: any) => {
            dispatch(add_CreatePassword(values));
            // handleClick();
            // const urlParams = new URLSearchParams(window.location.search);
            // const email = urlParams.get("email");
            // const token = urlParams.get("verify_token");

            // console.log("create password", values);
            // console.log("url", email);
            // console.log("url", token);
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{7,20}\S$/,
                "One UpperCase, One LowerCase, One Special Character and No Spaces."
              )
              .required("Password is requird "),
            setpassword: Yup.string()
              .oneOf([Yup.ref("password")], "Passwords do not match")
              .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{7,20}\S$/,
                "One UpperCase, One LowerCase, One Special Character and No Spaces."
              ),

            // Comparison validation
            // .required("Confirm password is required"),

            // .required("Confirm password is requird"),
          })}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
          }) => (
            <Form
              autoComplete="off"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "30rem",
              }}
            >
              {/* <ThemeProvider theme={theme}> */}

              <FormControl
                variant="standard"
                margin="dense"
                style={{ width: "100%", margin: "80px 0px 30px 0px " }}
                size="small"
                sx={{
                  "&   input": {
                    width: "22rem",
                    color: "#000000",
                  },
                }}
              >
                <InputLabel
                  htmlFor="standard-adornment-password"
                  style={{
                    color:
                      errors.password && touched.password ? "#d32f2f" : "black",
                  }}
                >
                  Password
                </InputLabel>
                <Input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  inputProps={{
                    autoComplete: "off",
                  }}
                  error={errors.password && touched.password ? true : false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{
                        "& input": {
                          color: "#000000",
                        },
                      }}
                    >
                      <IconButton
                        sx={{ marginLeft: "90px" }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPasswords}
                      >
                        {showPassword ? (
                          <img src={Eye} alt="" width="15px" height="10px" />
                        ) : (
                          <img src={Eyes} alt="" width="17px" height="20px" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {errors.password && touched.password && (
                    <span style={{ color: "#d32f2f" }}>
                      <img
                        src={reuqird}
                        alt="Error"
                        style={{
                          marginRight: "5px",
                          verticalAlign: "middle",
                          width: "13px",
                          marginTop: "-4",
                        }}
                      />
                      Password is requird
                    </span>
                  )}
                </FormHelperText>
              </FormControl>
              <FormControl
                variant="standard"
                margin="dense"
                style={{ width: "100%", margin: "10px 0px 80px 0px " }}
                size="small"
                sx={{
                  "&   input": {
                    width: "22rem",
                    color: "#000000",
                  },
                }}
              >
                <InputLabel
                  htmlFor="standard-adornment-password"
                  style={{
                    color:
                      errors.setpassword && touched.setpassword
                        ? "#d32f2f"
                        : "black",
                  }}
                >
                  Confirm Password
                </InputLabel>
                <Input
                  id="setpassword"
                  name="setpassword"
                  type={showPasswords ? "text" : "password"}
                  inputProps={{
                    autoComplete: "off",
                  }}
                  error={
                    errors.setpassword && touched.setpassword ? true : false
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{
                        "& input": {
                          color: "#000000",
                        },
                      }}
                    >
                      <IconButton
                        sx={{ marginLeft: "90px" }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswords}
                        onMouseDown={handleMouseDownPasswords}
                      >
                        {showPasswords ? (
                          <img src={Eye} alt="" width="15px" height="10px" />
                        ) : (
                          <img src={Eyes} alt="" width="17px" height="20px" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText>
                  {errors.setpassword && touched.setpassword && (
                    <span style={{ color: "#d32f2f" }}>
                      <img
                        src={reuqird}
                        alt="Error"
                        style={{
                          marginRight: "5px",
                          verticalAlign: "middle",
                          width: "13px",
                          marginTop: "-4",
                        }}
                      />
                      Confirm password is requird
                    </span>
                  )}
                </FormHelperText>
              </FormControl>
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                loadingIndicator={
                  <CircularProgress sx={{ color: "white" }} size={20} />
                }
                loading={loading}
                style={{
                  textTransform: "none",
                  backgroundColor: "black",
                  borderRadius: "0px",
                }}
              >
                <span>Submit</span>
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreatepasswordForm;
