import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  failure_api_call_Variant,
  success_api_call_Variant,
} from "./list_Variant_slicer";
import {
  failure_api_call_add_Variant,
  success_api_call_add_Variant,
} from "./add_Variant_slicer";
import {
  failure_api_call_edit_Variant,
  success_api_call_edit_Variant,
} from "./edit_Variant_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";

//add Variant
const addVariantCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  // console.log(a.payload);
  const adminId = JSON.parse(localStorage.getItem("id")!);
  const add = {
    name: a.payload.name,
    description: a.payload.description,
    updated_by: adminId,
    status: a.payload.status,
    created_by: adminId,
  };

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/variant/`,
    add,

    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddVariantApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addVariantCall, e);

    yield put(success_api_call_add_Variant(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_Variant(e));
    yield put(expireSignature(e));
  }
}

//Edit  Variant

const editVariantCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  const adminId = JSON.parse(localStorage.getItem("id")!);

  // const id = a.payload.id;
  const edit = {
    id: a.payload.id,
    name: a.payload.name,
    description: a.payload.description,
    status: a.payload.status,
    updated_by: adminId,
  };
  // console.log(edit);

  return Axios.put(`${process.env.REACT_APP_api_url}/v1/api/variant/`, edit, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* editVariantApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editVariantCall, e);
    // console.log(response.data);

    yield put(success_api_call_edit_Variant(response.data));
  } catch (e) {
    yield put(failure_api_call_edit_Variant(e));
    yield put(expireSignature(e));
  }
}

//list PageSize
const listVariantCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/variant/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* listVariantApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listVariantCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    yield put(success_api_call_Variant(res));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_Variant(e));
    yield put(expireSignature(e));
  }
}
