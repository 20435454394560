import { createSlice } from "@reduxjs/toolkit";
import { IComicsBookEdit } from "../../../interfaces/ComicsBookEdit";

export const EditableValueComicsBook = createSlice({
  name: "EditableValueComicsBook",
  initialState: { data: {} as IComicsBookEdit },
  reducers: {
    editable_value: (state, action) => {
      return { ...state, data: action.payload };
    },
  },
});

export default EditableValueComicsBook.reducer;

export const { editable_value } = EditableValueComicsBook.actions;
