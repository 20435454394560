// Add new layer based on object index value
import logo from "../accets/logodot (1).svg";
import show from "../accets/show(2).svg";
import edit from "../accets/edit.svg";
import copy from "../accets/copy(4).svg";
import cancel from "../accets/cancel(5).svg";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";

import React, { useState, useEffect, useRef, createContext } from "react";
import "../editor.css";
import {
  Drag_Drop,
  Edit_option,
  Edit_text,
  Get_Index,
  Get_Index2,
  LayerName,
  ObjectSelecterValue,
  Unselect,
  textBold,
  textitalic,
  textunderline,
} from "../../Redux/Reducers/Editor_value";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import { Padding } from "@mui/icons-material";

const New_layer = (props: any) => {
  const dispatch = useDispatch();

  const [activeLayer, setActiveLayer] = useState("");
  const [dragging, setDragging] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [canval, setCanval] = useState<any>();

  const [drgdrp, setDrgdrp] = useState<any>([]);
  const menuRef: any = useRef(null);
  const checkEditPage = useSelector((state: any) => state.DetailEdit);

  // console.log("selection true", checkEditPage.isObjectsele_boolean);

  useEffect(() => {
    const canvas_value = checkEditPage.isCanvas
      ? JSON.parse(checkEditPage.isCanvas)
      : "";
    setCanval(canvas_value);
    let mappedData: any = [];
    if (canvas_value?.objects.length > 0) {
      mappedData = canvas_value?.objects
        .filter((e: any) => e.type !== "line")
        .map((e: any, index: any) => {
          return {
            name: e.name,
            // name: e.type,
            value: e.unique_id,
            value1: index.toString().padStart(2, "0"),

            // value1: (index + 1).toString().padStart(2, "0"),
          };
        });
    }
    setItems(mappedData);
  }, [checkEditPage.isCanvas]);
  useEffect(() => {
    if (!checkEditPage.isObjectsele_boolean) {
      dispatch(ObjectSelecterValue(""));
      dispatch(textunderline(false));
      dispatch(textitalic(false));
      dispatch(textBold(false));
      // setActiveLayer("");
    }
  }, [checkEditPage.isObjectsele_boolean]);
  // useEffect(() => {
  //   console.log("mappedData", mappedData);

  //   setItems(mappedData);
  // }, [checkEditPage.isCanvas]);
  // const index = parseInt(props.brand.value) + 1;
  // const formattedIndex = index.toString().padStart(2, "0");

  // const editt = () => {
  //   props.setIsOpen({
  //     active: props.brand === props.isOpen.index ? !props.isOpen.active : true,
  //     index: props.brand,
  //   });
  // };

  useEffect(() => {
    setActiveLayer(checkEditPage.isIndex);
    // dispatch(Get_Index(""));
  }, [checkEditPage.isIndex]);
  // console.log("avtive", activeLayer);
  // console.log("item", items);

  // let drag: any = [];
  // const handleDragStart = (e: any, index: any, value: any) => {
  //   // if (checkEditPage.isObjectsele_boolean) {
  //   setDrgdrp(index);
  //   e.dataTransfer.setData("text/plain", JSON.stringify({ index, value }));
  //   setDragging(true);
  //   // }
  // };

  // const handleDragOver = (e: any) => {
  //   e.preventDefault();
  // };

  // const handleDrop = (e: any, index: any) => {
  //   e.preventDefault();
  //   const droppedItem = JSON.parse(e.dataTransfer.getData("text/plain"));
  //   // console.log("drop", e);
  //   const draggedItem = items.find(
  //     (item: any) => item.value === droppedItem.value
  //   );
  //   const newItems = items.filter(
  //     (item: any) => item.value !== droppedItem.value
  //   );

  //   const dropIndex = index > newItems.length ? newItems.length : index;
  //   // console.log("Drop Index:", dropIndex);
  //   const movingvalue = [{ drag: drgdrp, drop: dropIndex }];
  //   dispatch(Drag_Drop(movingvalue));

  //   setDrgdrp(dropIndex);
  //   newItems.splice(index, 0, draggedItem);
  //   // console.log(newItems);

  //   setItems(newItems);
  //   setDragging(false);
  // };
  // // console.log(drgdrp);
  // const dragoption =
  //   checkEditPage.isObjectsele_boolean && checkEditPage.isIndex != ""
  //     ? !dragging
  //     : dragging;
  return (
    <div className="newlayerscroll">
      {items.map((item: any, index: any) => (
        <div
          style={{
            background: item.value === activeLayer ? "black" : "",
          }}
          className="add01layer"
          key={item.value}
          // onClick={() => {
          //   // setActiveLayer(item.value);
          //   dispatch(Get_Index2(item.value));
          // }}
          onDoubleClick={() => {
            dispatch(Edit_option("deactive"));
          }}
          // draggable={dragoption}
          // onDragStart={(e) => {
          //   // console.log("e", e);
          //   handleDragStart(e, index, item.value);
          // }}
          // onDragOver={handleDragOver}
          // onDrop={(e) => handleDrop(e, index)}
        >
          <div
            className="imggap1"
            onClick={() => {
              // setActiveLayer(item.value);
              dispatch(Get_Index2(item.value));
            }}
          >
            <img
              className="layerimg1"
              src={logo}
              alt="invalid"
              height={"60%"}
            />
            <img className="layerimg" src={show} alt="invalid" />

            <span className="layertext">{item.name}</span>
          </div>
          <div className="imggap2">
            <div>
              <DoNotDisturbAltIcon
                onClick={() => {
                  dispatch(Edit_option("deactive"));
                }}
                style={{ fontSize: "1.2rem", verticalAlign: "bottom" }}
              />
            </div>
            {/* <img className="layerimg" src={edit} alt="invalid" />

            <img className="layerimg" src={copy} alt="invalid" /> */}
            <img
              className="layerimg"
              onClick={() => dispatch(Edit_option("deleted"))}
              src={cancel}
              alt="invalid"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export { New_layer };
