import { Navigation } from "swiper/modules";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { EditorPreview } from "./editor";
import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/swiper-bundle.css";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import IsLoading from "../Component/isLoading";
import { add_preview_page } from "../Redux/Reducers/Preview_page/add_previewpage_s;icer";
export const PreviewSlider = () => {
  const { selectedObjects, editor, onReady } = useFabricJSEditor();
  const [count, setCount] = useState<any>(0);
  const dispatch = useDispatch();
  const previewdata: any = useSelector((state: any) => state.AddPreviewPage);
  useEffect(() => {
    const queryString = window.location.search;
    const id = +queryString.slice(1);
    const value = { preview_id: id };
    dispatch(add_preview_page(value));
  }, []);
  const slideNames = previewdata?.data?.data?.results.map(
    (slide: any) => slide.name
  );
  const slidjson = previewdata?.data?.data?.results.map(
    (slide: any) => slide.template[0]
  );
  const handleSlideChange = (swiper: any) => {
    setCount(swiper.activeIndex);
    // This function will be triggered when the slide changes
    console.log("Current slide index:", swiper.activeIndex);
    // You can perform actions based on the current slide index or other properties
  };
  return (
    <>
      {!previewdata.isLoading ? (
        <>
          <Swiper
            navigation={true}
            modules={[Navigation]}
            onSlideChange={(swiper: any) => handleSlideChange(swiper)}
            className="mySwiper"
          >
            {slideNames?.map((slideContent: any, index: any) => {
              <SwiperSlide key={index}>Page:{slideContent}</SwiperSlide>;
            })}
            {slidjson?.map((slideContent: any, index: any) => (
              <SwiperSlide key={index}>
                <div
                  style={{
                    width: "100%",
                    height: "85vh",
                    textAlign: "start",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    {/* <div style={{ width: "50%", height: "50% !important" }}> */}
                    {/* <EditorPreview
                      prv={JSON.stringify(slideContent)}
                      dimension={previewdata?.data?.data?.page_dimensions}
 
                            
                            ></EditorPreview> */}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* <strong style={{ marginTop: "20px" }}>
            {previewdata?.data?.data?.results[count]?.name}
          </strong> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack spacing={2}>
              {/* <Typography>
                Page: {previewdata?.data?.data?.results[count]?.name}
              </Typography> */}
              <Pagination
                count={previewdata?.data?.data?.results?.length}
                page={count + 1}
                size="large"
                hidePrevButton
                hideNextButton
              />
            </Stack>
          </div>
        </>
      ) : (
        <IsLoading isLoading={true}></IsLoading>
      )}
      {/* <div
        style={{
          width: "100%",
          height: "80vh",
          textAlign: "start",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "50%", height: "50% !important" }}>
          {!previewdata.isLoading ? (
            <EditorPreview
              prv={JSON.stringify(
                previewdata?.data?.data?.results[count]?.template[0]
              )}
              dimension={previewdata?.data?.data?.page_dimensions}
            ></EditorPreview>
          ) : (
            <IsLoading isLoading={true}></IsLoading>
          )}
        </div>
      </div>
      {!previewdata.isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stack spacing={2}>
            <Typography>
              Page: {previewdata?.data?.data?.results[count]?.name}
            </Typography>
            <Pagination
              count={previewdata?.data?.data?.results?.length}
              page={count + 1}
              size="large"
              onChange={(event, value) => setCount(value - 1)}
            />
          </Stack>
        </div>
      ) : (
        ""
      )} */}
    </>
  );
};
