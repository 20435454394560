import { createSlice } from "@reduxjs/toolkit";

export const AddFont = createSlice({
  name: "AddFont",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    add_font: (state) => {
      state.isLoading = true;
    },
    success_api_call_add_font: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_add_font: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      // FAILED(action.payload);
    },
    add_font_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_font_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default AddFont.reducer;
export const {
  add_font,
  success_api_call_add_font,
  failure_api_call_add_font,
  create_font_complete_api,
  add_font_is_success,
} = AddFont.actions;
