import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import {
  failure_api_call_imagecategory,
  success_api_call_imagecategory,
} from "./list_ImageCategory_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_add_ListImageCategory,
  success_api_call_add_ListImageCategory,
} from "./add_ImageCategory_slicer";
import {
  failure_api_call_edit_ImageCategory,
  success_api_call_edit_ImageCategory,
} from "./edit_ImageCategory_slicer";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  failure_api_call_delete_ImageCategory,
  success_api_call_delete_ImageCategory,
} from "./delete_ImageCategory_slicer";

//add role
const addImageCategoryCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  // console.log(a.payload);
  const adminId = JSON.parse(localStorage.getItem("id")!);
  const add = {
    name: a.payload.name,
    status: a.payload.status,
    updated_by: adminId,
    created_by: adminId,
  };

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/image_category/`,
    add,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddImageCategoryApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addImageCategoryCall, e);

    yield put(success_api_call_add_ListImageCategory(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_ListImageCategory(e));
    yield put(expireSignature(e));
  }
}

//Edit role

const editImageCategoryCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  const adminId = JSON.parse(localStorage.getItem("id")!);

  // const id = a.payload.id;
  const edit = {
    id: a.payload.id,
    name: a.payload.name,
    status: a.payload.status,
    updated_by: adminId,
  };
  // console.log(edit);

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/image_category/`,
    edit,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* editImageCategoryApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editImageCategoryCall, e);
    // console.log(response.data);

    yield put(success_api_call_edit_ImageCategory(response.data));
  } catch (e) {
    yield put(failure_api_call_edit_ImageCategory(e));
    yield put(expireSignature(e));
  }
}

//list image
const listImageCategoryCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/image_category/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* listImageCategoryApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listImageCategoryCall, e);

    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    console.log(res);
    yield put(success_api_call_imagecategory(res));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_imagecategory(e));
    yield put(expireSignature(e));
  }
}

/*    delete ImageCategory */
const deleteImageCall = (action: PayloadAction<number>) => {
  let token = localStorage.getItem("token");
  console.log(action.payload);

  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/image_category/${action.payload}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export function* deleteImageCategoryApi(action: PayloadAction<number>) {
  try {
    console.log(action.payload);

    const response: AxiosResponse = yield call(deleteImageCall, action);
    yield put(success_api_call_delete_ImageCategory(response.data));
  } catch (error) {
    console.log(error);
    yield put(failure_api_call_delete_ImageCategory(error));
  }
}
