import { createSlice } from "@reduxjs/toolkit";

export const SetPageQuestionsId = createSlice({
  name: "SetPageQuestionsId",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    set_PageQuestionsID: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_set_PageQuestionsID: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_set_PageQuestionsID: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      // FAILED(action.payload);
    },
    set_PageQuestionsID_is_success: (state, action) => {
      state.isSuccess = false;
    },
  },
});
export default SetPageQuestionsId.reducer;
export const {
  set_PageQuestionsID,
  success_api_call_set_PageQuestionsID,
  failure_api_call_set_PageQuestionsID,
  set_PageQuestionsID_is_success,
} = SetPageQuestionsId.actions;
