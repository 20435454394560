import { Navigation } from "swiper/modules";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { EditorPreview } from "./editor";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import IsLoading from "../Component/isLoading";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Gif_KO from "../Editor/accets/GIF_KO.gif";
import { add_preview_page } from "../Redux/Reducers/Preview_page/add_previewpage_s;icer";
import { Button } from "@mui/material";
import { Margin } from "@mui/icons-material";
import DoubleArrowTwoToneIcon from "@mui/icons-material/DoubleArrowTwoTone";
export const Preview = () => {
  const { selectedObjects, editor, onReady } = useFabricJSEditor();
  const [count, setCount] = useState<any>(0);
  const [portinc, setPortinc] = useState(0);
  const [portdec, setPortdec] = useState(0);
  const [jsonval, setJsonval] = useState<any>();
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const dispatch = useDispatch();
  const previewdata: any = useSelector((state: any) => state.AddPreviewPage);
  useEffect(() => {
    const queryString = window.location.search;
    const id = +queryString.slice(1);
    const value = { preview_id: id };
    dispatch(add_preview_page(value));
  }, []);
  useEffect(() => {
    setJsonval(previewdata);
  }, [previewdata]);
  // 768px
  const isMobile = window.matchMedia(
    "only screen and (max-width: 420px)"
  ).matches;
  const prv = jsonval?.data?.data?.results[count]?.template[0];
  const dimension = previewdata?.data?.data?.page_dimensions;
  let width: any;
  let height: any;
  if (dimension) {
    width =
      (dimension.width + dimension.bleed_margin + dimension.bleed_margin) *
      dimension.dpi;
    height =
      (dimension.height + dimension.bleed_margin + dimension.bleed_margin) *
      dimension.dpi;
  }
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  // const hght_res = isMobile ? 0.76 : 0.955;
  // const wght_res = isMobile ? 2 : 1.36;
  // const hght_res = isMobile ? 0.88 : 0.955;
  // const wght_res = isMobile ? 1.56 : 1.46;
  // const hght_res = isMobile ? 0.73 : 0.955;
  // const wght_res = isMobile ? 1.95 : 1.46;
  // const hght_res = isMobile ? 0.81 : 0.955;
  // const wght_res = isMobile ? 1.95 : 1.46;

  const hght_res = isMobile ? 0.84 : 0.845;
  const wght_res = isMobile ? 1.65 : 1.66;
  const scaledHeight = hght_res * windowHeight;
  const widthvaluee = windowHeight / windowWidth;
  const widthvalue = widthvaluee / wght_res;
  const scaledWidth = widthvalue * windowWidth;
  const [portwidthh, setPortwidth] = useState(scaledWidth);
  const [porthighh, setPorthigh] = useState(scaledHeight);
  const [scale1, setScale1] = useState(0);
  const [scale2, setScale2] = useState(0);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(false);
  useEffect(() => {
    if (prv && prv.objects && prv.objects.length > 0) {
      const fontObjects = prv.objects.filter(
        (obj: any) => obj.type === "textbox" && obj.fontUrl && obj.fontFamily
      );
      const fontPromises = fontObjects.map((obj: any) => {
        return new Promise<void>((resolve, reject) => {
          const fontUrl = obj.fontUrl.replace(/^https:/, "http:");
          const font = new FontFace(obj.fontFamily, `url(${obj.fontUrl})`);
          font
            .load()
            .then((data) => {
              document.fonts.add(font);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      });

      Promise.all(fontPromises)
        .then(() => {
          // All fonts loaded successfully
          setFontsLoaded(true); // Set font loading complete
        })
        .catch((error) => {
          console.error("Error loading fonts:", error);
        });
    }
  }, [prv]);

  useEffect(() => {
    if (!zoomLevel && prv && jsonval && fontsLoaded) {
      // let isMounted = true;
      if (loadingComplete) setLoadingComplete(false);

      if (editor?.canvas) {
        editor.canvas.loadFromJSON(prv, () => {
          // if (isMounted) {
          editor.canvas.discardActiveObject();
          editor.canvas.forEachObject((obj) => {
            obj.selectable = false;
            obj.evented = false;
          });
          editor.canvas.renderAll();
          setLoadingComplete(true);
          setFontsLoaded(false);
          // Set canvas loading complete after rendering
          // }
        });
      }

      // return () => {
      //   isMounted = false; // Prevent state update if unmounted
      // };
    }
  }, [prv, editor, fontsLoaded, zoomLevel, jsonval]);

  useEffect(() => {
    if (editor && editor.canvas) {
      const scaleX = portwidthh / width;
      const scaleY = porthighh / height;
      setScale1(scaleX);
      setScale2(scaleY);
    }
  });
  const increment = 45;
  const increment2 = 30;
  const portwidth = () => {
    setPortwidth((prevPortwidth) =>
      Math.max(prevPortwidth - increment2, scaledWidth)
    );
    setPorthigh((prevPorthigh) =>
      Math.max(prevPorthigh - increment, scaledHeight)
    );
    setZoomLevel(true);
  };

  const porthight = () => {
    setPortwidth((prevPortwidth) => prevPortwidth + increment2);
    setPorthigh((prevPorthigh) => prevPorthigh + increment);
    setZoomLevel(true);
  };
  // useEffect(() => {
  //   if (portinc) {
  //     porthight();
  //   }
  // }, [portinc]);
  // useEffect(() => {
  //   if (portdec) {
  //     portwidth();
  //   }
  // }, [portdec]);
  // key move
  useEffect(() => {
    if (!isMobile) {
      let panning = false;
      let delta: fabric.Point | null = null;

      const handleKeyUp = () => {
        delta = null;
        panning = false;
      };

      const handleKeyDown = (e: KeyboardEvent) => {
        switch (e.key) {
          case "ArrowLeft":
            delta = new fabric.Point(-50, 0);
            break;
          case "ArrowRight":
            delta = new fabric.Point(50, 0);
            break;
          case "ArrowUp":
            delta = new fabric.Point(0, -50);
            break;
          case "ArrowDown":
            delta = new fabric.Point(0, 50);
            break;
          default:
            delta = null;
            break;
        }
        if (editor && editor.canvas && delta) {
          editor.canvas.relativePan(delta);
          editor.canvas.renderAll();
          panning = true;
        }
      };

      window.addEventListener("keyup", handleKeyUp);
      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keyup", handleKeyUp);
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [editor]);
  // mouse move
  useEffect(() => {
    if (editor && editor.canvas && !isMobile) {
      let panning = false;
      editor.canvas.discardActiveObject();

      const handleMouseUp = () => {
        editor.canvas.selection = false;

        panning = false;
      };

      const handleMouseDown = () => {
        editor.canvas.selection = false;

        panning = true;
      };

      const handleMouseMove = (e: any) => {
        if (panning && e && e.e) {
          const units = 10;

          const delta = new fabric.Point(e.e.movementX, e.e.movementY);
          editor.canvas.relativePan(delta);
          editor.canvas.renderAll();
        }

        // }
      };

      editor?.canvas.on("mouse:up", handleMouseUp);
      editor?.canvas.on("mouse:down", handleMouseDown);
      editor?.canvas.on("mouse:move", handleMouseMove);

      return () => {
        editor?.canvas.off("mouse:up", handleMouseUp);
        editor?.canvas.off("mouse:down", handleMouseDown);
        editor?.canvas.off("mouse:move", handleMouseMove);
      };
    }
  });
  // mobile move
  useEffect(() => {
    if (editor && editor.canvas && isMobile) {
      editor.canvas.discardActiveObject();

      let isPanning = false;
      let lastPosX = 0;
      let lastPosY = 0;

      const handleStart = (event: any) => {
        editor.canvas.discardActiveObject();
        if (event.e.type === "touchstart") {
          event.e.preventDefault(); // Prevent default touch behavior
        }

        editor.canvas.selection = false;
        isPanning = true;

        const { clientX, clientY } = event.e.touches
          ? event.e.touches[0]
          : event.e;
        lastPosX = clientX;
        lastPosY = clientY;
      };

      const handleMove = (event: any) => {
        if (isPanning) {
          editor.canvas.discardActiveObject();

          let touch;
          if (event.e.type === "touchmove" && event.e.touches.length > 0) {
            touch = event.e.touches[0];
          } else if (event.e.type === "mousemove") {
            touch = event.e;
          }

          if (touch) {
            const { clientX, clientY } = touch;
            const deltaX = clientX - lastPosX;
            const deltaY = clientY - lastPosY;

            lastPosX = clientX;
            lastPosY = clientY;

            const delta = new fabric.Point(deltaX, deltaY);
            editor.canvas.relativePan(delta);
            editor.canvas.renderAll();
          }
        }
      };

      const handleEnd = () => {
        isPanning = false;
      };

      editor.canvas.on("mouse:down", handleStart);
      editor.canvas.on("touchstart", handleStart);
      editor.canvas.on("mouse:move", handleMove);
      editor.canvas.on("touchmove", handleMove);
      editor.canvas.on("mouse:up", handleEnd);
      editor.canvas.on("touchend", handleEnd);

      return () => {
        editor.canvas.off("mouse:down", handleStart);
        editor.canvas.off("touchstart", handleStart);
        editor.canvas.off("mouse:move", handleMove);
        editor.canvas.off("touchmove", handleMove);
        editor.canvas.off("mouse:up", handleEnd);
        editor.canvas.off("touchend", handleEnd);
      };
    }
  }, [editor]);

  function resetCanvasPosition() {
    const initialZoom = 1; // Set this to your desired initial zoom level
    const initialPan = { x: 0, y: 0 }; // Set this to your desired initial pan position
    setPortwidth(scaledWidth);
    setPorthigh(scaledHeight);
    editor?.canvas.setZoom(initialZoom);
    editor?.canvas.absolutePan(initialPan);
  }

  useEffect(() => {
    resetCanvasPosition();
  }, [count]);
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: isMobile ? "87vh" : "86vh",
          textAlign: "start",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          {!previewdata.isLoading ? (
            <div
              id="testid"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <div>
                <style>
                  {`.sample-canvas {
          width: ${width}px !important;
          height: ${height}px !important;          
          transform: scale(${scale1},${scale2});
          border:3px solid black
              }         
             `}
                </style>
                <FabricJSCanvas className="sample-canvas" onReady={onReady} />
              </div>
            </div>
          ) : (
            // <EditorPreview
            //   prv={previewdata?.data?.data?.results[count]?.template[0]}
            //   dimension={previewdata?.data?.data?.page_dimensions}
            //   portinc={portinc}
            //   portdec={portdec}
            // ></EditorPreview>
            //loader
            <img src={Gif_KO} alt="loading..." width={360} height={270} />
            // <IsLoading isLoading={true}></IsLoading>
          )}
        </div>
      </div>
      {!previewdata.isLoading ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              // height: "4vh",
              justifyContent: "center",
              zIndex: 1,
              backgroundColor: "white",
              marginTop: !isMobile ? "10px" : "5px",
            }}
          >
            {/* {!isMobile ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  zIndex: 1,
                  fontSize: "small",
                }}
              >
                {previewdata?.data?.data?.results[count]?.name}
              </div>
            ) : (
              ""
            )} */}
            <Stack spacing={isMobile ? 0 : 3} alignItems="center">
              <Stack
                direction="row"
                spacing={isMobile ? 1 : 2}
                justifyContent="center"
                marginBottom={isMobile ? "0px" : "13px"}
              >
                <Button
                  sx={{
                    borderRadius: "3px",
                    minWidth: "20px",
                    padding: "5px 11px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    if (count > 0) {
                      setCount(count - 1);
                      setZoomLevel(false);
                    }
                  }}
                  disabled={count === 0 || !loadingComplete}
                >
                  <DoubleArrowTwoToneIcon
                    sx={{ transform: "rotate(180deg)" }}
                  />
                </Button>
                <Pagination
                  sx={{ marginTop: 0 }}
                  count={previewdata?.data?.data?.results?.length}
                  page={count + 1}
                  size={isMobile ? "small" : "small"}
                  onChange={(event, value) => {
                    setCount(value - 1);
                    setZoomLevel(false);
                  }}
                  disabled={!loadingComplete}
                  hidePrevButton
                  hideNextButton
                  siblingCount={0}
                />
                <Button
                  sx={{
                    borderRadius: "3px",
                    minWidth: "20px",
                    padding: "5px 11px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    if (count < previewdata?.data?.data?.results?.length - 1) {
                      setCount(count + 1);
                      setZoomLevel(false);
                    }
                  }}
                  disabled={
                    count === previewdata?.data?.data?.results?.length - 1 ||
                    !loadingComplete
                  }
                >
                  <DoubleArrowTwoToneIcon sx={{ rotate: "left" }} />
                </Button>
              </Stack>
            </Stack>
          </div>
          {/* {isMobile ? (
            ""
          ) : ( */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              columnGap: "10px",
              position: "absolute",
              top: 0,
              right: 0,
              margin: "10px",
            }}
          >
            <button
              style={{ zIndex: 1, marginBottom: "10px" }}
              onClick={() => {
                porthight();
              }}
            >
              <ZoomInIcon></ZoomInIcon>
            </button>
            <button
              style={{ zIndex: 1 }}
              onClick={() => {
                portwidth();
              }}
            >
              <ZoomOutIcon></ZoomOutIcon>
            </button>
          </div>
          {/* )} */}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
