import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
import { IEditableCapmaign } from "../../../interfaces/ICampaign";

export interface IStateExtend extends IReduxState<IEditableCapmaign> {
  editable?: boolean;
}
export const EditableCampaign = createSlice({
  name: "EditableCampaign",
  initialState: {
    isLoading: false,
    data: {},
    editable: false,
  } as IStateExtend,
  reducers: {
    editable_Campaign: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_editable_Campaign: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.editable = true;
    },
    failure_api_call_editable_Campaign: (state) => {
      state.isLoading = false;
    },
    false_editable: (state) => {
      state.editable = false;
      state.data = {};
    },
  },
});
export default EditableCampaign.reducer;
export const {
  editable_Campaign,
  success_api_call_editable_Campaign,
  failure_api_call_editable_Campaign,
  false_editable,
} = EditableCampaign.actions;
