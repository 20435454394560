import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
import { IListOfTextTypeQuestions } from "../../../interfaces/ITextTypeOptions";

interface IStateExtend extends IReduxState<IListOfTextTypeQuestions[]> {
  questionIds: number[];
}
export const TextOption = createSlice({
  name: "TextOption",
  initialState: {
    isLoading: false,
    data: {},
    questionIds: [],
  } as IStateExtend,
  reducers: {
    List_textOption: (state, _) => {
      state.isLoading = true;
    },
    Success_listOption: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    Failure_listOption: (state) => {
      state.isLoading = false;
    },
    clearTextOption: (state) => {
      state.data = {};
    },
    update_questionIds: (state, action: PayloadAction<number[]>) => {
      state.questionIds = action.payload;
    },
  },
});
export default TextOption.reducer;
export const {
  List_textOption,
  Success_listOption,
  Failure_listOption,
  clearTextOption,
  update_questionIds,
} = TextOption.actions;
