import { createSlice } from "@reduxjs/toolkit";

export const AddResetPassword = createSlice({
  name: "AddResetPassword",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    add_AddResetPassword: (state) => {
      state.isLoading = true;
    },
    success_api_call_add_AddResetPassword: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_add_AddResetPassword: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      // FAILED(action.payload);
    },
    add_AddResetPassword_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_AddResetPassword_complete_api: (state, action) => {
      state.api_completed = false;
    },
    clearData: (state, action) => {
      state.data = action.payload;
    },
  },
});
export default AddResetPassword.reducer;
export const {
  add_AddResetPassword,
  success_api_call_add_AddResetPassword,
  failure_api_call_add_AddResetPassword,
  create_AddResetPassword_complete_api,
  add_AddResetPassword_is_success,
  clearData,
} = AddResetPassword.actions;
