import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import {
  failure_api_call_add_image,
  success_api_call_add_image,
} from "./add_Image_slicer";
import {
  failure_api_call_image,
  success_api_call_image,
} from "./list_Image_slicer";
import { ImageList } from "../../../interfaces/ImageList";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_delete_image,
  success_api_call_delete_image,
} from "./delete_Image_slicer";

// import {
//   failure_api_call_add_user,
//   success_api_call_add_user,
// } from "./add_User_slicer";
// import {
//   failure_api_call_edit_user,
//   success_api_call_edit_user,
// } from "./edit_User_slicer";
// import { failure_api_call_login, success_api_call_login } from "./Login_slicer";

//add user
const addImageCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);
  console.log("payload value", a.payload);
  // const add = {
  //   image_url: ,
  //   category_id: a.payload.category_id,
  //   name: a.payload.name,
  //   created_by: adminId,
  // };
  const formData = new FormData();
  formData.append("image_url", a.payload.image_url);
  formData.append("category_id", a.payload.category_id);
  formData.append("name", a.payload.name);

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/image_library/`,
    formData,

    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddImageApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addImageCall, e);
    // console.log(response.data);

    yield put(success_api_call_add_image(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_image(e));
    yield put(expireSignature(e));
  }
}

//list image
const listImageCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get<ImageList>(
    `${process.env.REACT_APP_api_url}/v1/api/image_library/`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* listImageApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listImageCall, e);

    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      //   active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    console.log(res);
    yield put(success_api_call_image(res));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_image(e));
    yield put(expireSignature(e));
  }
}

//    Delete a pageQuestions

const deleteimageCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/image_library/`,
    { data: formData, headers: { Authorization: `Bearer ${token}` } }
  );
};
export function* deleteimageApi(e: any) {
  try {
    const response: AxiosResponse = yield call(deleteimageCall, e);

    yield put(success_api_call_delete_image(response.data));
  } catch (error) {
    console.log(error);
    yield put(failure_api_call_delete_image(error));
  }
}
