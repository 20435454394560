import { FunctionComponent, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import React from "react";
import IsLoading from "./isLoading";
import { SxProps, Theme } from "@mui/material";
interface PopupMessageProps {
  Dialogtitle?: any;
  submitFunction: any;
  closeFunction: any;
  open?: number;
  successMessage?: any;
  isLoading?: any;
  sx?: SxProps<Theme>;
  // submitButtonName: any;
}

const PopupMessage: FunctionComponent<PopupMessageProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [popup, setPopoup] = React.useState(false);

  // console.log(props.successMessage);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setPopoup(false), 100);
  };
  useEffect(() => {
    if (props.open) {
      handleClickOpen();
    }
  }, [props.open]);
  return (
    <>
      <IsLoading isLoading={props.isLoading} />
      {!props.isLoading && (
        <Dialog
          sx={{
            " & .MuiDialog-container": {
              "& .MuiPaper-root": {
                border: "none",
              },
            },
          }}
          PaperProps={{
            sx: {
              ...props.sx,
              borderRadius: "0",
              // height: height || "80vh",
              "&::-webkit-scrollbar": { display: "none" },
            },
          }}
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* {!popup ? (
            <DialogTitle
              id="alert-dialog-title"
              style={{ fontSize: "20px", textAlign: "center" }}
            >
              {props.Dialogtitle}
            </DialogTitle>
          ) : undefined} */}
          <DialogContent sx={{ padding: "1.5em 1.5em 0 !important" }}>
            {!popup ? (
              <DialogContentText
                // sx={{ padding: "1.5em 1.5em 0 !important" }}
                id="alert-dialog-description"
              >
                <div className="submitPopup">
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    {props.Dialogtitle}
                  </div>
                </div>
              </DialogContentText>
            ) : (
              // !props.isLoading && (
              <DialogContentText id="alert-dialog-description">
                <div className="submitPopup">
                  <div
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      color: "black",
                      margin: "0px 40px 0px 40px ",
                    }}
                  >
                    {props?.successMessage?.message}
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      textAlign: "center",
                      margin: "0px 40px 0px 40px ",
                    }}
                  >
                    {props?.successMessage?.status ? (
                      <img
                        src={require("../Pages/Image/success.png")}
                        className="imageSize"
                        alt="success"
                      />
                    ) : (
                      <>
                        {props?.successMessage?.message
                          ?.toLowerCase()
                          .includes("already exists") ? (
                          // console.log("exisite")
                          <img
                            src={require("../Pages/Image/mdi_file-alert-outline.png")}
                            className="imageSize"
                            alt="error"
                          />
                        ) : (
                          <img
                            src={require("../Pages/Image/error.png")}
                            className="imageSize"
                            alt="error"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </DialogContentText> // )
            )}
          </DialogContent>

          {!popup ? (
            <DialogActions
              sx={{ justifyContent: "center", padding: "1.5em", gap: "0.5em" }}
            >
              <Button
                onClick={handleClose}
                autoFocus
                style={{
                  padding: "15px",
                  // backgroundColor: "#1976D2",
                  color: "black",
                  width: "100px",
                  height: "37px",
                  borderRadius: "0px",
                  border: "1px solid black",
                }}
                sx={{
                  backgroundColor: "white",
                  "&:hover": { backgroundColor: "white" },
                }}
              >
                No
              </Button>
              <Button
                form="form"
                type="submit"
                onClick={() => {
                  setPopoup(true);
                  props?.submitFunction();
                }}
                style={{
                  // backgroundColor: "#1976D2",
                  color: "white",
                  width: "100px",
                  height: "37px",
                  borderRadius: "0px",
                  border: "1px solid black",
                }}
                autoFocus
                sx={{
                  backgroundColor: "black",
                  "&:hover": { backgroundColor: "black" },
                }}
              >
                Yes
              </Button>
            </DialogActions>
          ) : (
            // !props.isLoading && (
            <DialogActions
              sx={{ justifyContent: "center", padding: "1.5em", gap: "0.5em" }}
            >
              <Button
                onClick={() => {
                  handleClose();
                  props?.closeFunction();
                }}
                style={{
                  padding: "15px",
                  // backgroundColor: "#1976D2",
                  color: "white",
                  width: "100px",
                  height: "37px",
                  borderRadius: "0px",
                  border: "1px solid black",
                }}
                sx={{
                  backgroundColor: "black",
                  "&:hover": { backgroundColor: "black" },
                }}
                autoFocus
              >
                OK
              </Button>
            </DialogActions>
            // )
          )}
        </Dialog>
      )}
    </>
  );
};

export default PopupMessage;
