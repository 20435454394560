import { FunctionComponent } from "react";
import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FormHelperText, SxProps, Theme } from "@mui/material";

type size = "small" | "medium" | undefined;
type color =
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | "default"
  | undefined;
type labelPlacement = "end" | "start" | "top" | "bottom" | undefined;

interface CheckboxLabelsProps {
  getValue?: any;
  label?: string | number;
  defaultChecked?: boolean;
  disabled?: boolean;
  size?: size;
  color?: color;
  labelPlacement?: labelPlacement;
  required?: boolean;
  style?: React.CSSProperties | undefined;
  sx?: SxProps<Theme>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const CheckboxLabels: FunctionComponent<CheckboxLabelsProps> = (props) => {
  const {
    label,
    defaultChecked,
    disabled,
    size,
    color,
    labelPlacement,
    required,
    style,
    getValue,
    sx,
    onClick,
  } = props;
  const [checked, setChecked] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);

    getValue && getValue(event?.target?.checked);
  };
  return (
    <>
      <FormGroup onClick={onClick}>
        <FormControlLabel
          disabled={disabled ? true : false}
          id={label as string}
          sx={sx}
          control={
            <Checkbox
              id={label as string}
              defaultChecked={defaultChecked ? true : false}
              size={size}
              color={color}
              style={style}
              required={required ? true : false}
              onChange={handleChange}
              onFocus={() => setErrorMsg(true)}
            />
          }
          label={<span>{label}</span>}
          labelPlacement={labelPlacement}
        />
        <FormHelperText error>
          {errorMsg && required && !checked && "This feild is required"}
        </FormHelperText>
      </FormGroup>
    </>
  );
};

export default CheckboxLabels;
