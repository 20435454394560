import uploadImage from "../../Pages/Image/UploadCloudImage.png";
import Dialogs from "../../Component/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ImageUploader from "./ImageUplodeerDiv";
import { FunctionComponent, useState, useEffect } from "react";
import { Button, FormHelperText } from "@mui/material";
import "./ImageUploadedPopup.css";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import {
  Clear_data,
  Get_Question_By_Id,
} from "../../Redux/Reducers/QuestionOption/QuestionOption_Slicer";
import {
  Add_questionImages,
  clearData,
  closePopup,
} from "../../Redux/Reducers/QuestionImages/QuestionImages_Slicer";
import { QueryOptionsI } from "../../interfaces/QuestionOption";
import { set_PageQuestionsID } from "../../Redux/Reducers/PageQuestions/set_PageQuestionsID_slicer";
import PopupMessage from "../../Component/PopupMessage";
import IsLoading from "../../Component/isLoading";
import { ImageQuestionData } from "../../interfaces/IImageQuestions";
import * as Yup from "yup";
import {
  FieldArray,
  FormikProvider,
  FormikValues,
  getIn,
  useFormik,
} from "formik";

interface QuestionsID {
  QuestionIDs: (number | null)[];
  layer_id: string;
}
const ImageUploadPopup: FunctionComponent<QuestionsID> = (props: any) => {
  // const { QuestionIDs } = props;
  //   For open the dialog
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const checkEditPage = useAppSelector((state) => state.DetailEdit);

  const matrix = {
    question_id: props.QuestionIDs,
    layerid: checkEditPage.layer_id,
  };
  return (
    <>
      {/*     Upload image div     */}
      <div
        onClick={() => {
          dispatch(Get_Question_By_Id(matrix));
          setOpenDialog(true);
        }}
        className="uploadImageDiv"
      >
        <img src={uploadImage} alt="upload" height={40} width={40} />
        Upload Images
      </div>

      {/*     Dialog components    */}
      <Dialogs
        open={openDialog}
        setOpen={setOpenDialog}
        DialogBody={
          <DialogBody
            setOpenDialog={setOpenDialog}
            openDialog={openDialog}
            QuestionIDs={props.QuestionIDs}
          />
        }
        height="auto"
        maxWidth={"sm"}
      />
    </>
  );
};

//    Dialog boy for the above dialog component
const DialogBody = (props: {
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openDialog: boolean;
  QuestionIDs: (number | null)[];
}) => {
  const { setOpenDialog, QuestionIDs } = props;

  interface IwidthAndHeight {
    width?: number;
    height?: number;
  }

  /*    image dimentions     */
  const [widthAndHeight, setWidthAndHeight] = useState<IwidthAndHeight>({});

  const dispatch = useAppDispatch();

  /*    matrix images    */
  const question = useAppSelector((state) => state.QuestionOptions.data);
  const questionLoading = useAppSelector(
    (state) => state.QuestionOptions.isLoading
  );
  //  Upload response
  const upLoadData = useAppSelector((state) => state.QuestionImages.data);
  const upLoadDataPopup = useAppSelector(
    (state) => state.QuestionImages.openPopup
  );
  const upLoadDataisLoading = useAppSelector(
    (state) => state.QuestionImages.isLoading
  );
  // //    Make api calls
  // const makeApiCall = async (listOfOptions: ImageQuestionData[]) => {
  //   for (const value of listOfOptions) {
  //     await dispatch(Add_questionImages(value as any));
  //   }
  // };

  //    Make API calls for pageQuestionInteraction
  // const pageQuestionInteractionAPICall = (
  //   pageQuestionInteraction: {
  //     page_id: number;
  //     question_id: number | null;
  //     sort_order: number;
  //   }[]
  // ) => {
  //   dispatch(set_PageQuestionsID(pageQuestionInteraction));
  // };

  useEffect(() => {
    return () => {
      dispatch(Clear_data());
      setWidthAndHeight({});
    };
  }, []);

  /*    for update widthAndHeight state if the images already have     */
  useEffect(() => {
    if (question?.data?.length !== 0) {
      const preImage = question?.data
        ?.filter((question) => question.image_url !== null)
        .map((question) => question.image_url)[0] as string;
      const images = new Image();
      images.src = preImage;
      images.onload = () => {
        setWidthAndHeight({
          width: images.width,
          height: images.height,
        });
      };
    }
  }, [question]);

  const formik = useFormik<FormikValues>({
    initialValues: {
      matrixImages: question?.data ?? [],
    },
    onSubmit(values, formikHelpers) {
      /*    page question interaction api call     */
      // const pageQuestionInteraction: {
      //   page_id: number;
      //   question_id: number | null;
      //   sort_order: number;
      // }[] = QuestionIDs.map((questionId: number | null, index: number) => {
      //   return {
      //     page_id: parseInt(localStorage.getItem("Page_Id")!),
      //     question_id: questionId,
      //     sort_order: index + 1,
      //   };
      // });

      // pageQuestionInteractionAPICall(pageQuestionInteraction);

      /*    add question api call    */
      dispatch(Add_questionImages(values.matrixImages));
    },
    validationSchema: Yup.object().shape({
      matrixImages: Yup.array()
        .of(
          Yup.object().shape({
            image_url: Yup.mixed()
              .test(
                "max-length",
                "File name must be less than or equal to 35 characters",
                (value: any) => {
                  if (!value) return true; // Skip validation if value is not provided
                  if (typeof value === "string") return true; // Skip validation if value is not provided
                  const fileName = value.name.length;
                  return fileName <= 35;
                }
              )
              .test(
                "type",
                "Only the following formats are accepted: .jpeg, .jpg, and .png",
                (value: any) => {
                  if (typeof value === "string") return true; // Skip validation if value is not provided

                  return (
                    value &&
                    (value.type === "image/jpeg" ||
                      value.type === "image/png" ||
                      value.type === "image/jpg")
                  );
                }
              )
              .test(
                "fileSize",
                `Please choose a ${widthAndHeight.width}x${widthAndHeight.height} pixel image.`,
                (value) => {
                  if (!value) return true; // Skip validation if no image selected
                  if (!(widthAndHeight.width && widthAndHeight.height)) {
                    return true;
                  }
                  return new Promise((resolve) => {
                    const image = new Image();

                    image.src =
                      typeof value === "string"
                        ? value.replace(/\s/g, "%20")
                        : URL?.createObjectURL(value as Blob | MediaSource);
                    image.onload = () => {
                      const isValid =
                        image.width === widthAndHeight.width &&
                        image.height === widthAndHeight.height;
                      if (isValid) {
                        resolve(true);
                      } else {
                        resolve(false);
                      }
                      resolve(isValid);
                    };
                  });
                }
              ),
          })
        )
        .min(1),
    }),
    enableReinitialize: true,
    // validateOnMount: true,
  });

  useEffect(() => {
    const getImageDimensions = (src: string | Blob | MediaSource) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.src =
          typeof src === "string"
            ? src.replace(/\s/g, "%20")
            : !!src && URL?.createObjectURL(src);
      });
    };

    const loadDimensions = async () => {
      /*    filter(non-nullable) and map the matrix images width and height     */
      const dimensions: IwidthAndHeight[] = await Promise.all(
        formik.values.matrixImages
          .filter(
            (value: { image_url: string | Blob | MediaSource }) =>
              value.image_url !== null
          )
          .map((item: { image_url: string | Blob | MediaSource }) =>
            getImageDimensions(item.image_url)
          )
      );

      // Check if all dimensions are equal
      const firstDimension = dimensions[0];
      const dimensionsEqual = dimensions.every(
        (dimension) =>
          dimension.width === firstDimension.width &&
          dimension.height === firstDimension.height
      );

      /*    check and set width and height if all images have same width and height    */
      dimensions.length !== 0 &&
        dimensionsEqual &&
        setWidthAndHeight(dimensions[0]);
    };

    loadDimensions();
    formik.validateForm();
  }, [formik.values.matrixImages]);

  return (
    <>
      <IsLoading isLoading={upLoadDataisLoading} />
      <DialogTitle sx={{ backgroundColor: "#000000", color: "#FFFFFF" }}>
        <div className="dialogTitle">
          <div>Question Option</div>
          <div>
            <Button
              sx={{
                color: "#FFFFFF",
                borderRadius: "50%",
                minWidth: "37px",
                "&:hover": { backgroundColor: "#ffffff3d" },
              }}
              onClick={() => setOpenDialog(false)}
            >
              X
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogContent sx={{ "&::-webkit-scrollbar": { display: "none" } }}>
        <div className="Options">
          {questionLoading ? (
            <h1>Loading...</h1>
          ) : (
            <FormikProvider value={formik}>
              <FieldArray
                name="matrixImages"
                render={(arrayHelpers) => {
                  return (
                    <>
                      {formik?.values?.matrixImages &&
                      formik?.values?.matrixImages.length > 0 ? (
                        <>
                          {formik?.values?.matrixImages?.map(
                            (option: ImageQuestionData, index: number) => {
                              /*    images     */
                              const image_url = `matrixImages[${index}].image_url`;
                              const touchedimage_url = getIn(
                                formik.touched,
                                image_url
                              );
                              const errorimage_url = getIn(
                                formik.errors,
                                image_url
                              );

                              return (
                                <>
                                  <div className="Option" key={index}>
                                    <div className="OptionName">
                                      <b>
                                        {`${option?.option1}`}{" "}
                                        {option?.option2 &&
                                          `& ${option?.option2}`}
                                      </b>
                                      <b>:</b>
                                    </div>
                                    <label
                                      htmlFor={image_url}
                                      style={{
                                        display: "flex",
                                        border:
                                          !formik.isValid &&
                                          touchedimage_url &&
                                          errorimage_url
                                            ? "1px solid #d32f2f"
                                            : "1px solid rgba(0, 0, 0, 0.23)",
                                      }}
                                    >
                                      <input
                                        type="file"
                                        name={image_url}
                                        id={image_url}
                                        hidden
                                        accept="image/jpeg,image/jpg,image/png"
                                        onBlur={formik.handleBlur}
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          formik.setFieldTouched(
                                            image_url,
                                            true
                                          );
                                          formik.setFieldValue(
                                            image_url,
                                            e.target.files![0]
                                          );
                                          if (
                                            !(
                                              widthAndHeight.width &&
                                              widthAndHeight.height
                                            )
                                          ) {
                                            const images = new Image();
                                            images.src = URL?.createObjectURL(
                                              e.target.files![0] as
                                                | Blob
                                                | MediaSource
                                            );
                                            images.onload = () => {
                                              setWidthAndHeight({
                                                width: images.width,
                                                height: images.height,
                                              });
                                            };
                                          }
                                        }}
                                      />
                                      <img
                                        src={uploadImage}
                                        srcSet={
                                          typeof option?.image_url === "string"
                                            ? option?.image_url.replace(
                                                /\s/g,
                                                "%20"
                                              )
                                            : option?.image_url &&
                                              URL?.createObjectURL(
                                                option?.image_url
                                              )
                                        }
                                        alt="upload"
                                        height={60}
                                        width={60}
                                      />
                                    </label>
                                  </div>
                                  <FormHelperText error>
                                    {!formik.isValid &&
                                      errorimage_url &&
                                      touchedimage_url &&
                                      errorimage_url}
                                  </FormHelperText>
                                </>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <h1>No Data Found</h1>
                      )}
                    </>
                  );
                }}
              />
            </FormikProvider>
          )}
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          sx={{ border: "1px solid black", borderRadius: "0" }}
          onClick={() => setOpenDialog(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ borderRadius: "0" }}
          onClick={() => {
            formik.handleSubmit();
            // setTestPass(false);
          }}
        >
          Save
        </Button>
      </DialogActions>

      {/*     Dialog components    */}
      <Dialogs
        open={upLoadDataPopup}
        setOpen={() => dispatch(closePopup())}
        Dialog_Content={
          <div>
            <>
              <div
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  margin: "0px 40px 0px 40px ",
                }}
              >
                {upLoadData?.message}
              </div>
              <div
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  margin: "0px 40px 0px 40px ",
                }}
              >
                {upLoadData?.status ? (
                  <img
                    src={require("../../Pages/Image/success.png")}
                    className="imageSize"
                    alt="success"
                  />
                ) : (
                  <>
                    {upLoadData?.message
                      ?.toLowerCase()
                      ?.includes("already exists") ? (
                      <img
                        src={require("../../Pages/Image/mdi_file-alert-outline.png")}
                        className="imageSize"
                        alt="error"
                      />
                    ) : (
                      <img
                        src={require("../../Pages/Image/error.png")}
                        className="imageSize"
                        alt="error"
                      />
                    )}
                  </>
                )}
              </div>
            </>
            {/* )} */}
          </div>
        }
        Dialog_Actions={
          <Button
            onClick={() => {
              dispatch(closePopup());
              setOpenDialog(false);
            }}
            style={{
              padding: "15px",
              color: "white",
              width: "100px",
              height: "37px",
              borderRadius: "0px",
              border: "1px solid black",
            }}
            sx={{
              backgroundColor: "black",
              "&:hover": { backgroundColor: "black" },
            }}
            autoFocus
          >
            OK
          </Button>
        }
        height="auto"
        maxWidth={"xs"}
      />
    </>
  );
};
export default ImageUploadPopup;
