import { FunctionComponent } from "react";
import "./Page.css";
import Loginvalidation from "./Loginvalidation";
import LoginImage from "./LoginImage";
import CreatepasswordForm from "./CreatepasswordForm";

interface CreatepasswordProps {}

const Createpassword: FunctionComponent<CreatepasswordProps> = () => {
  return (
    <div className="Login">
      <div className="LoginImage">
        <LoginImage></LoginImage>
      </div>
      <div className="LoginImages">
        <CreatepasswordForm></CreatepasswordForm>
      </div>
    </div>
  );
};

export default Createpassword;
