import { FunctionComponent } from "react";
import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { SxProps, Theme } from "@mui/material";

type size = "small" | "medium" | undefined;
type color =
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | "default"
  | undefined;
type labelPlacement = "end" | "start" | "top" | "bottom" | undefined;

interface CheckboxLabelsProps {
  label?: string | number;
  name?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  size?: size;
  color?: color;
  labelPlacement?: labelPlacement;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  sx?: SxProps<Theme>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const CheckboxLabels: FunctionComponent<CheckboxLabelsProps> = (props) => {
  const {
    label,
    defaultChecked,
    disabled,
    size,
    color,
    labelPlacement,
    onChange,
    checked,
    sx,
    onClick,
    name,
  } = props;

  return (
    <FormGroup onClick={onClick}>
      <FormControlLabel
        name={name}
        disabled={disabled}
        sx={sx}
        control={
          <Checkbox
            defaultChecked={defaultChecked}
            size={size}
            color={color}
            checked={checked}
            onChange={onChange}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
    </FormGroup>
  );
};

export default CheckboxLabels;
