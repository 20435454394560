import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  failure_api_call_role,
  success_api_call_role,
} from "./list_Role_slicer";
import {
  failure_api_call_add_role,
  success_api_call_add_role,
} from "./add_Role_slicer";
import {
  failure_api_call_edit_role,
  success_api_call_edit_role,
} from "./edit_Role_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";

//add role
const addRoleCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  // console.log(a.payload);
  const adminId = JSON.parse(localStorage.getItem("id")!);
  const add = {
    name: a.payload.name,
    active: a.payload.active,
    updated_by: adminId,
    created_by: adminId,
  };

  return Axios.post(`${process.env.REACT_APP_api_url}/v1/api/role/`, add, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* AddRoleApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addRoleCall, e);

    yield put(success_api_call_add_role(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_role(e));
    yield put(expireSignature(e));
  }
}

//Edit role

const editRoleCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  const adminId = JSON.parse(localStorage.getItem("id")!);

  // const id = a.payload.id;
  const edit = {
    id: a.payload.id,
    name: a.payload.name,
    active: a.payload.active,
    updated_by: adminId,
  };
  // console.log(edit);

  return Axios.put(`${process.env.REACT_APP_api_url}/v1/api/role/`, edit, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* editRoleApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editRoleCall, e);
    // console.log(response.data);

    yield put(success_api_call_edit_role(response.data));
  } catch (e) {
    yield put(failure_api_call_edit_role(e));
    yield put(expireSignature(e));
  }
}

//list role
const listRoleCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/role/?offset=0&limit=100`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* listRoleApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listRoleCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    yield put(success_api_call_role(res));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_role(e));
    yield put(expireSignature(e));
  }
}
