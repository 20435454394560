import { createSlice } from "@reduxjs/toolkit";

export const MenuItem = createSlice({
  name: "MenuItem",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_menuItem: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_menuItem: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_menuItem: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    menuItem_is_first: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default MenuItem.reducer;
export const {
  list_menuItem,
  success_api_call_menuItem,
  failure_api_call_menuItem,
  menuItem_is_first,
} = MenuItem.actions;
