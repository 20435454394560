//    For dropdown list items
import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import { Success_list, Failure_list } from "./QuestionID_Slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";

// import {
//   failure_api_call_questionID,
//   success_api_call_questionID,
// } from "./list_QuestionID_slicer";
// import {
//   failure_api_call_add_questionID,
//   success_api_call_add_questionID,
// } from "./add_QuestionID_slicer";
// import {
//   failure_api_call_edit_questionID,
//   success_api_call_edit_questionID,
// } from "./edit_QuestionID_slicer";
// import { failure_api_call_login, success_api_call_login } from "./Login_slicer";

//list questionID
const listQuestionIDCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  const text_type = a.payload.text_type
    ? `&text_type=${a.payload.text_type}`
    : ``;

  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/project_questions/filter?project_id=${a.payload.project_id}&q_type=${a.payload.q_type}${text_type}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* listQuestionIDApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listQuestionIDCall, e);

    // let res = response?.data?.data?.map((x: any, i: any) => ({
    //   ...x,
    //   //   active: x.active ? "Active" : "Inactive",
    //   field_id: i + 1,
    // }));
    yield put(Success_list(response.data.data));
  } catch (e) {
    console.log(e);
    yield put(Failure_list(e));
    yield put(expireSignature(e));
  }
}
