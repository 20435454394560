import { createSlice } from "@reduxjs/toolkit";

export const AddUserPermission = createSlice({
  name: "AddUserPermission",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    add_UserPermission: (state) => {
      state.isLoading = true;
      state.data = [];
    },
    success_api_call_add_UserPermission: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_add_UserPermission: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      // FAILED(action.payload);
    },
    add_UserPermission_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_UserPermission_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default AddUserPermission.reducer;
export const {
  add_UserPermission,
  success_api_call_add_UserPermission,
  failure_api_call_add_UserPermission,
  create_UserPermission_complete_api,
  add_UserPermission_is_success,
} = AddUserPermission.actions;
