import Axios, { AxiosResponse } from "axios";
import {
  success_api_call_add_preview_page,
  failure_api_call_add_preview_page,
} from "./add_previewpage_s;icer";
import { call, put } from "redux-saga/effects";

const addpreviewPageCall = async (a: any) => {
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/preview_list/replaceJsons?preview_id=${a.payload.preview_id}
`
  );
};
export function* AddPreviewPageApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addpreviewPageCall, e);
    // console.log(response.data);

    yield put(success_api_call_add_preview_page(response.data));
  } catch (e) {
    yield put(failure_api_call_add_preview_page(e));
  }
}
