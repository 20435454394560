import { FunctionComponent } from "react";
import EditIcon from "@mui/icons-material/Edit";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import "./Page.css";
import { add_role } from "../Redux/Reducers/Role/add_Role_slicer";
import { edit_role } from "../Redux/Reducers/Role/edit_Role_slicer";
import { list_role } from "../Redux/Reducers/Role/list_Role_slicer";
import Grids from "../Component/Grides";
import Dialog from "../Component/DialogForForm";
import { Backdrop, CircularProgress } from "@mui/material";
import IsLoading from "../Component/isLoading";

interface UserroleProps {}

const Userrole: FunctionComponent<UserroleProps> = () => {
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();
  const breadcrumbs = [
    // <Typography key="3" color="text.primary" style={{ display: "flex" }}>
    <Link key="1" color="black" underline="none">
      <b className="heading">Masters</b>
    </Link>,

    // <Typography key="3" color="text.primary">
    <Link
      underline="none"
      key="1"
      color="inherit"
      onClick={() => navigate("/userrole")}
      style={{
        fontWeight: "500",
        fontSize: "15px ",
        lineHeight: "16px ",
        color: "#BDBDBD",
        cursor: "pointer",
      }}
    >
      User Role
    </Link>,
  ];

  //    datas of user role
  const data = useAppSelector((state) => state?.Role?.data);
  const isLoading = useAppSelector((state) => state?.Role?.isLoading);
  const addData: any = useAppSelector((state) => state?.AddRole?.data);
  const addDataIsLoading = useAppSelector((state) => state?.AddRole?.isLoading);
  const editData: any = useAppSelector((state) => state?.editRole?.data);
  const editDataIsLoading = useAppSelector(
    (state) => state?.editRole?.isLoading
  );

  //    userpermission menuList
  const pageName = "Masters / Roles";
  const menuList: any = JSON.parse(
    localStorage?.getItem("permission") as string
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageName;
  });

  const MenuOfUserpermission = menuList[index];

  const closeFunction = (e: boolean) => {
    // console.log("Sfd", e);
  };

  const column = [
    // { headerName: , field: ,  },
    {
      sortable: false,
      field: "field_id",
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          S.No
        </p>
      ),
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
      // renderCell: (params: GridRenderCellParams<any>) =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Roles
        </p>
      ),
      field: "name",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Status
        </p>
      ),
      field: "active",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    MenuOfUserpermission?.edit
      ? // true
        {
          headerName: (
            <p
              className="headingnamebold"
              style={{ color: "black", fontWeight: "bolder" }}
            >
              Action
            </p>
          ),
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <>
                <Dialog
                  // Dialogcontenttext
                  // getValues={(v: any) => console.log(v)}
                  successMessage={editData}
                  isLoading={editDataIsLoading}
                  Buttondialog={["edit"]}
                  Dialogtitle={"Edit Role "}
                  getValues={(v: any) => {
                    dispatcher(edit_role(v));
                    // console.log(values);
                  }}
                  placeholder="Role"
                  sx={{
                    backgroundColor: "transparent",
                    minWidth: "0",
                    padding: "0",
                    borderRadius: "50%",
                    height: "60%",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  edit={
                    // params.row
                    {
                      ...params.row,
                      active: params.row.active === "Active" ? true : false,
                    }
                  }
                  Buttonname={
                    <EditIcon
                      sx={{
                        backgroundColor: "#F5F5F5",
                        color: "black",
                        height: "1.3em",
                        width: "1.3em",
                        padding: "15%",
                        borderRadius: "50%",
                        "&:hover": {
                          color: "#F5F5F5",
                          backgroundColor: "black",
                        },
                      }}
                      onClick={() => console.log(params.row)}
                    />
                  }
                />
              </>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  // console.log(load);

  React.useEffect(() => {
    dispatcher(list_role(""));
    // axios
    //   .get(`${process.env.REACT_APP_api_url}/v1/api/role/`)
    //   .then((response: any) => {
    //     // console.log(response?.data?.data);
    //     let res = response?.data?.data?.map((x: any) => ({
    //       ...x,
    //       active: x.active ? "Active" : "Inactive",
    //     }));
    //     // console.log(res);

    //     dispatcher(setState(res));
    //   })
    //   .catch((error: any) => {
    //     console.log(error);
    //   });
  }, [addData, editData]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <IsLoading isLoading={isLoading} />
      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={data}
        column={column}
        // Users={"Roles"}
        open
        pagination
        dialog={
          MenuOfUserpermission?.add && (
            <Dialog
              // error={errorMessage}
              successMessage={addData}
              isLoading={addDataIsLoading}
              sx={{
                padding: "15px",
                backgroundColor: "black",
                color: "white",
                width: "150px",
                height: "37px",
                borderRadius: "0px",
                border: "1px solid #BDBDBD",
                "&:hover": { backgroundColor: "black" },
              }}
              placeholder="Role"
              getValues={(v: any) => {
                dispatcher(add_role(v));
                // console.log(v);
              }}
              Buttondialog={["Cancel"]}
              Dialogtitle={"Add Role"}
              Buttonname={"Add Role"}
            />
          )
        }
        Userpermissions={undefined}
      ></Grids>
    </div>
  );
};

export default Userrole;
