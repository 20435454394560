import { createSlice } from "@reduxjs/toolkit";

export const ListFont = createSlice({
  name: "ListFont",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_font: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_font: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_font: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    font_is_first: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default ListFont.reducer;
export const {
  list_font,
  success_api_call_font,
  failure_api_call_font,
  font_is_first,
} = ListFont.actions;
