//    For dropdown list items
import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  Failure_list_ProjectID,
  Success_list_ProjectID,
} from "./ProjectID_Slicer";

//list projectID
const listProjectIDCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/project/list`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* listProjectIDApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listProjectIDCall, e);

    yield put(Success_list_ProjectID(response.data));
  } catch (e) {
    console.log(e);
    yield put(Failure_list_ProjectID());
    yield put(expireSignature(e));
  }
}
