import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_add_AddResetPassword,
  success_api_call_add_AddResetPassword,
} from "./add_ResetPassword_slicer";

const addResetPasswordCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  const adminId = JSON.parse(localStorage.getItem("id")!);
  const add = {
    email: a.payload.email,
    updated_by: adminId,
    created_by: adminId,
  };

  return Axios.put(`${process.env.REACT_APP_api_url}/v1/api/user/reset`, add, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* AddResetPasswordApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addResetPasswordCall, e);
    // console.log(response.data);

    yield put(success_api_call_add_AddResetPassword(response.data));
  } catch (e) {
    yield put(failure_api_call_add_AddResetPassword(e));
    yield put(expireSignature(e));
  }
}
