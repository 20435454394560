import { createSlice } from "@reduxjs/toolkit";
import { TextOptionState } from "../../../interfaces/ITextTypeOptions";

export const TextOptionsList = createSlice({
  name: "TextOptionsList",
  initialState: {
    isLoading: false,
    data: {},
  } as TextOptionState,
  reducers: {
    List_textOptionsList: (state, _) => {
      state.isLoading = true;
    },
    Success_textOptionsList: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    Failure_textOptionsList: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    cleartextOptionsList: (state) => {
      state.data = {};
    },
  },
});
export default TextOptionsList.reducer;
export const {
  List_textOptionsList,
  Success_textOptionsList,
  Failure_textOptionsList,
  cleartextOptionsList,
} = TextOptionsList.actions;
