import "./New_Campaign.css";

import { FunctionComponent } from "react";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import "./Page.css";
import { Button, FormHelperText } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { FormikProps, useFormik } from "formik";
import CampaignForm from "./CampaignForm";
import { ICampaignForm } from "../interfaces/ICampaign";
import IsLoading from "../Component/isLoading";
import { EditorState, convertToRaw } from "draft-js";
import {
  clear_variant_list,
  close_error_message,
  delete_popup_close,
  delete_variant,
  open_error_message,
  popup_close,
  popup_open,
} from "../Redux/Reducers/Campaign/VariantList_Slicer";
import {
  DataGrid,
  GridToolbarContainer,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import DialogComponent from "../Component/Dialog";
import VariantForm, { ICapaignVariantForm } from "./CampaignVariantForm";
import VariantAction from "./CampaignVariantAction";
import { List_projectID } from "../Redux/Reducers/ProjectID/ProjectID_Slicer";
import { IProjectId } from "../interfaces/ComicsBookEdit";
import {
  add_Campaign,
  false_error,
} from "../Redux/Reducers/Campaign/add_Campaign_slicer";
import draftToHtml from "draftjs-to-html";
import { false_editable } from "../Redux/Reducers/Campaign/editable_Campaign_slicer";
import { clear_VariantById } from "../Redux/Reducers/Comics_Booklist/list_Variantlist_slicerBy_ID";
import {
  eidt_Campaign,
  false_error_edit,
} from "../Redux/Reducers/Campaign/edit_Campaign_slicer";
import * as Yup from "yup";
import { list_UsedProjectIds } from "../Redux/Reducers/Campaign/list_usedprojectIds_slicer";
import {
  closePopup,
  list_UsedVariant,
} from "../Redux/Reducers/Campaign/list_usedvariant_slicer";
import {
  closePopup_UsedProjectIds,
  popupOpen_UsedProjectIds,
} from "../Redux/Reducers/Campaign/list_usedprojectIds_slicer";

interface NewCampaignProps {}

const NewCampaign: FunctionComponent<NewCampaignProps> = () => {
  const dispatcher = useAppDispatch();
  const nav = useNavigate();
  const breadcrumbs = [
    // <Typography key="3" color="text.primary" style={{ display: "flex" }}>
    <Link
      key="1"
      color="black"
      // href="/Campaign"
      sx={{ cursor: "pointer" }}
      onClick={() => {
        dispatcher(false_editable());
        nav("/Campaign");
      }}
      underline="none"
    >
      <b className="heading">Campaign</b>
    </Link>,
    <Link
      underline="none"
      key="1"
      color="inherit"
      sx={{ cursor: "pointer" }}
      onClick={() => nav("/NewCampaign")}
      // href="/NewCampaign"
      style={{
        fontWeight: "500",
        fontSize: "15px ",
        lineHeight: "16px ",
        color: "#BDBDBD",
      }}
    >
      New Campaign
    </Link>,

    // <Typography key="3" color="text.primary">
  ];

  /*    Redux States     */
  /*    variant form dialog     */
  const variantDialog = useAppSelector((state) => state.VariantList.popup);
  /*    variant delete dialog     */
  const variantDeleteDialog = useAppSelector(
    (state) => state.VariantList.deletePopup
  );
  /*    variant list     */
  const VariantList = useAppSelector((state) => state?.VariantList?.variants);

  /*    list of variant name and id    */
  const listOfVariant: IProjectId[] = useAppSelector(
    (state) => state.VariantById.data
  );

  /*    editable value     */
  const editableValue = useAppSelector((state) => state.EditableCampaign.data);
  const isEditableValue = useAppSelector(
    (state) => state.EditableCampaign.editable
  );
  /*    add campaign     */
  const addCampaign = useAppSelector((state) => state.AddCampaign.data);
  const successfullyAdded = useAppSelector(
    (state) => state.AddCampaign.isSuccess
  );
  const faildAddingCampaign = useAppSelector(
    (state) => state.AddCampaign.isError
  );
  const addCampignIsLoading = useAppSelector(
    (state) => state.AddCampaign.isLoading
  );

  /*    edit campaign    */
  const editCampaign = useAppSelector((state) => state.EidtCampaign.data);
  const successfullyEdited = useAppSelector(
    (state) => state.EidtCampaign.isSuccess
  );
  const faildEditingCampaign = useAppSelector(
    (state) => state.EidtCampaign.isError
  );
  const editCampignIsLoading = useAppSelector(
    (state) => state.EidtCampaign.isLoading
  );

  /*    show variant error message     */
  const openErrorMessage = useAppSelector(
    (state) => state.VariantList.errorMessage
  );

  const { popupOpen: errorOpen } = useAppSelector((state) => state.UsedVariant);
  const { popupOpen: errorOpenProduct } = useAppSelector(
    (state) => state.UsedProjectIds
  );

  /*  */

  /*    for initial render     */
  React.useLayoutEffect(() => {
    dispatcher(List_projectID());
    dispatcher(list_UsedProjectIds());
    if (editableValue?.data?.campaign.project_id) {
      dispatcher(
        list_UsedVariant({
          project_id: editableValue?.data?.campaign.project_id,
        })
      );
    }
  }, []);
  React.useEffect(() => {
    return () => {
      dispatcher(false_editable());
      dispatcher(clear_variant_list());
      dispatcher(clear_VariantById());
      dispatcher(close_error_message());
      formik.resetForm();
    };
  }, []);
  /*    for routing if successfully added the data     */
  React.useEffect(() => {
    if (successfullyAdded || successfullyEdited) {
      nav("/Campaign");
    }
  }, [successfullyAdded, successfullyEdited]);

  /*    variant formik form ref    */
  const variantForm = React.useRef<FormikProps<ICapaignVariantForm>>(null);

  /*    filter variant for show filtername     */
  const filterVariant = VariantList.map((variant) => ({
    ...variant,
    variant_id: listOfVariant?.filter(
      (vari) => vari.id === variant.variant_id
    )[0]?.name,
    available: variant.available ? "Available" : "Unavailable",
  }));

  /*      formik       */
  const formik = useFormik({
    initialValues: {
      campaign_id: editableValue?.data?.campaign.campaign_id,
      project_id: editableValue?.data?.campaign.project_id ?? null,
      name: editableValue?.data?.campaign.campaign_name ?? "",
      description:
        editableValue?.data?.campaign.description ?? EditorState.createEmpty(),
      images: editableValue?.data?.product_images ?? [],
      status: editableValue?.data?.campaign.status,
    } as ICampaignForm,
    validationSchema: Yup.object().shape({
      project_id: Yup.number().required("Please select product"),
      name: Yup.string().required("Please enter campaign name"),
      images: Yup.array()
        .of(
          Yup.object().shape({
            images: Yup.mixed()
              .test(
                "type",
                "Only the following formats are accepted: .jpeg, .jpg, and .png",
                (value: any) => {
                  console.log(value);
                  if (!value) return true;
                  if (typeof value === "string") return true; // Skip validation if value is not provided

                  return (
                    value &&
                    (value.type === "image/jpeg" ||
                      value.type === "image/png" ||
                      value.type === "image/jpg")
                  );
                }
              )
              .test(
                "max-length",
                "File name must be less than or equal to 35 characters",
                (value: any) => {
                  console.log(value);
                  if (!value) return true; // Skip validation if value is not provided
                  if (typeof value === "string") return true; // Skip validation if value is not provided
                  const fileName = value.name.length;
                  return fileName <= 35;
                }
              )
              .nullable(),
          })
        )
        .min(1, "Please select images")
        .test("noneNullable", "Please select images", (value) => {
          return value?.some((image) => image.images !== null);
        }),
      description: Yup.mixed().test(
        "editorRequird",
        "Please enter description",
        (value: any) => {
          // Get the Content State from the editor's value
          const rawContentState = convertToRaw(value.getCurrentContent());

          // Extract the plain text content from the rawContentState
          const plainText = rawContentState.blocks
            .map((block) => block.text)
            .join("\n");
          return plainText !== "";
        }
      ),
    }),
    onSubmit(values) {
      console.log(values);
      const variantImg = VariantList.map((image) => image.image_url);
      const images = (values.images ?? [])
        .map((image) => image.images)
        .filter((image) => !!image);
      /*    convert description as html formate    */
      const rawContentState = convertToRaw(
        values.description.getCurrentContent()
      );
      const html = draftToHtml(rawContentState);

      /*    if add or edit     */
      if (VariantList.length !== 0) {
        values.campaign_id
          ? dispatcher(
              eidt_Campaign({
                ...values,
                variant: JSON.stringify(VariantList),
                image_url: variantImg,
                images: images,
                description: html,
              })
            )
          : dispatcher(
              add_Campaign({
                ...values,
                variant: JSON.stringify(VariantList),
                image_url: variantImg,
                images: images,
                description: html,
              })
            );
      } else {
        formik.setSubmitting(false);
      }
    },
  });

  const column: GridColDef[] = [
    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Variant Name</b>,
      field: "variant_id",
      flex: 1,
      headerAlign: "center",
    },

    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Price (INR)</b>,
      field: "price",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => <>Rs.{params.value}</>,
    },

    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Compared Price</b>,
      field: "compared_price",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        <>Rs.{params.value ?? "---"}</>
      ),
    },
    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Base Cost</b>,
      field: "base_cost",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        <>Rs.{params.value ?? "---"}</>
      ),
    },
    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Available</b>,
      field: "available",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    formik?.values.status !== "INACTIVE" &&
    formik?.values.status !== "PUBLISHED"
      ? {
          renderHeader: (params: GridColumnHeaderParams) => <b>Action</b>,
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params) => {
            const variant = {
              ...params.row,
              variant_id: listOfVariant?.filter(
                (vari) => vari.name === params.row.variant_id
              )[0]?.id,
              available: params.row.available === "Available",
            };
            return (
              <>
                <VariantAction value={variant} />
              </>
            );
          },
        }
      : {
          field: "remove",
        },
  ];

  // console.log(load);

  /*    custom toolbar for table     */
  const customToolBar = () => {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1em",
          "@media print": {
            display: "none",
          },
        }}
      >
        <div style={{ fontWeight: "600", fontSize: "20px" }}>Variant</div>
        {formik?.values.status !== "INACTIVE" &&
          formik?.values.status !== "PUBLISHED" && (
            <div
              style={{
                display: "flex",
                // width: "clamp(25rem, 70%, 50rem)",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "#000000",
                  color: "white",
                  borderRadius: "0",
                  "&: hover": {
                    backgroundColor: "#000000",
                    color: "white",
                  },
                }}
                onClick={() => dispatcher(popup_open())}
              >
                Add Variant Price
              </Button>
              {openErrorMessage && VariantList.length === 0 && (
                <FormHelperText error>
                  Please add a variant price.
                </FormHelperText>
              )}
            </div>
          )}
      </GridToolbarContainer>
    );
  };

  return (
    <div className="newCapaign">
      <IsLoading isLoading={editCampignIsLoading! || addCampignIsLoading!} />
      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <section className="campainForm">
        {/*     campaign form    */}
        <CampaignForm formik={formik} variantFormik={variantForm} />

        {/*     variant datagrid     */}
        <div>
          <DataGrid
            sx={{
              backgroundColor: "white",
              height: "auto",
              "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                display: "none",
              },
              borderRadius: "0",
            }}
            rows={filterVariant}
            hideFooter
            getRowId={(row) => row.field_id}
            columns={column.filter((column) => column.field !== "remove")}
            slots={{
              toolbar: customToolBar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>

        {/*     save and cancel buttons    */}
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: "1em",
            position: "sticky",
            bottom: "0",
            backgroundColor: "#FAFAFA",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              dispatcher(false_editable());
              nav(-1);
              formik.resetForm();
            }}
            sx={{
              width: "110px",
              height: "40px",
              borderRadius: "0px",
            }}
          >
            {formik?.values.status === "INACTIVE" ||
            formik?.values.status === "PUBLISHED"
              ? "Back"
              : "Cancel"}
          </Button>
          {formik?.values.status !== "INACTIVE" &&
            formik?.values.status !== "PUBLISHED" && (
              <Button
                variant="contained"
                disabled={
                  formik?.values.status === "INACTIVE" ||
                  formik?.values.status === "PUBLISHED"
                }
                onClick={() => {
                  if (VariantList.length !== 0) {
                    dispatcher(false_editable());
                  }
                  formik.handleSubmit();
                  dispatcher(open_error_message());
                }}
                sx={{
                  width: "110px",
                  height: "40px",
                  backgroundColor: "rgb(0, 0, 0)",
                  color: "#FFFFFF",
                  display: "flex",
                  fontSize: "13px",
                  alignItems: "center",

                  borderRadius: "0px",
                  justifyContent: "space-evenly",
                }}
              >
                Save
              </Button>
            )}
        </div>
      </section>

      <div>
        {/*     variant form     */}
        <DialogComponent
          maxWidth={"md"}
          sx={{ width: "auto" }}
          open={variantDialog}
          setOpen={() => dispatcher(popup_close())}
          Dialog_Title_sx={{ backgroundColor: "black", color: "white" }}
          Dialog_Title={
            <div>
              <span>Variant Price</span>
              <ClearOutlinedIcon
                className="clear"
                onClick={() => {
                  dispatcher(popup_close());
                }}
                sx={{ color: "white", float: "right" }}
              />
            </div>
          }
          Dialog_Content_sx={{
            padding: "1.5em 24px 0!important",
            fontSize: "16px",
          }}
          Dialog_Content={<VariantForm formik={variantForm} />}
          Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
          Dialog_Actions={
            <>
              <Button
                variant="outlined"
                onClick={() => {
                  dispatcher(popup_close());
                }}
                sx={{
                  width: "110px",
                  height: "40px",
                  borderRadius: "0px",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  // setVariantDialog(false);
                  variantForm.current?.handleSubmit();
                }}
                sx={{
                  width: "110px",
                  height: "40px",
                  backgroundColor: "rgb(0, 0, 0)",
                  color: "#FFFFFF",
                  display: "flex",
                  fontSize: "13px",
                  alignItems: "center",

                  borderRadius: "0px",
                  justifyContent: "space-evenly",
                }}
              >
                Save
              </Button>
            </>
          }
        />
        {/*     Delete popup     */}
        <DialogComponent
          maxWidth={"xs"}
          open={variantDeleteDialog}
          setOpen={() => dispatcher(delete_popup_close())}
          sx={{ width: "auto" }}
          Dialog_Content_sx={{
            padding: "1.5em 24px 0!important",
            display: "flex",
            fontSize: "16px",
            justifyContent: "center",
          }}
          Dialog_Content={"Are you sure want to delete?"}
          Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
          Dialog_Actions={
            <>
              <Button
                variant="outlined"
                onClick={() => {
                  dispatcher(delete_popup_close());
                }}
                sx={{
                  width: "110px",
                  height: "40px",
                  borderRadius: "0px",
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  dispatcher(delete_variant());
                }}
                sx={{
                  width: "110px",
                  height: "40px",
                  backgroundColor: "rgb(0, 0, 0)",
                  color: "#FFFFFF",
                  display: "flex",
                  fontSize: "13px",
                  alignItems: "center",

                  borderRadius: "0px",
                  justifyContent: "space-evenly",
                }}
              >
                Yes
              </Button>
            </>
          }
        />
        {/*     Error popup for add and edit    */}
        <DialogComponent
          open={faildEditingCampaign || faildAddingCampaign}
          setOpen={() => {
            dispatcher(false_error());
            dispatcher(false_error_edit());
          }}
          maxWidth="xs"
          Dialog_Content_sx={{
            padding: "1.5em 24px 0!important",
            fontSize: "16px",
          }}
          Dialog_Content={
            <section
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <div>{editCampaign?.message || addCampaign?.message}</div>
              <>
                {editCampaign?.status || addCampaign?.status ? (
                  <img
                    src={require("../Pages/Image/success.png")}
                    alt="success"
                    width={"20%"}
                  />
                ) : (
                  <>
                    {editCampaign?.message ||
                    addCampaign?.message
                      ?.toLowerCase()
                      .includes("already exists") ? (
                      // console.log("exisite")
                      <img
                        src={require("../Pages/Image/mdi_file-alert-outline.png")}
                        alt="error"
                        width={"20%"}
                      />
                    ) : (
                      <img
                        src={require("../Pages/Image/error.png")}
                        alt="error"
                        width={"20%"}
                      />
                    )}
                  </>
                )}
              </>
            </section>
          }
          Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
          Dialog_Actions={
            <>
              <Button
                variant="contained"
                onClick={() => {
                  dispatcher(false_error());
                  dispatcher(false_error_edit());
                }}
                sx={{
                  width: "110px",
                  height: "40px",
                  backgroundColor: "rgb(0, 0, 0)",
                  color: "#FFFFFF",
                  display: "flex",
                  borderRadius: "0px",
                }}
              >
                Ok
              </Button>
            </>
          }
        />

        {/*     Error message    */}
        <DialogComponent
          open={errorOpen || errorOpenProduct}
          setOpen={() => {
            dispatcher(closePopup());
            dispatcher(closePopup_UsedProjectIds());
          }}
          maxWidth="xs"
          sx={{ width: "auto" }}
          Dialog_Content_sx={{
            padding: "1.5em 1.5em 0 !important",
            fontSize: "16px",
          }}
          Dialog_Content={
            <section
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <div>
                {errorOpen &&
                  "Selected variant was different from the lulu variant"}
                {errorOpenProduct && "This product was already in campaign."}
              </div>

              <img
                src={require("../Pages/Image/error.png")}
                alt="error"
                width={"20%"}
              />
            </section>
          }
          Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
          Dialog_Actions={
            <Button
              variant="contained"
              onClick={() => {
                errorOpen &&
                  variantForm?.current?.setFieldValue("variant_id", null);
                errorOpenProduct && formik.setFieldValue("project_id", null);
                dispatcher(closePopup());
                dispatcher(closePopup_UsedProjectIds());
              }}
              sx={{
                width: "110px",
                height: "40px",
                backgroundColor: "rgb(0, 0, 0)",
                color: "#FFFFFF",
                display: "flex",
                borderRadius: "0px",
              }}
            >
              Ok
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default NewCampaign;
