import { Backdrop, CircularProgress } from "@mui/material";
import { FunctionComponent } from "react";
import logo from "./loading-fast.gif";
interface isLoadingProps {
  isLoading: any;
}

const IsLoading: FunctionComponent<isLoadingProps> = (props) => {
  const { isLoading } = props;
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.snackbar + 1 }}
        open={isLoading}
      >
        <CircularProgress style={{ color: "black" }} />
        {/* <img src={logo} alt="loading..." /> */}
      </Backdrop>
    </>
  );
};

export default IsLoading;
