import { FunctionComponent, useEffect } from "react";
import { useState } from "react";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Backdrop,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContentText,
  FormControlLabel,
  Stack,
  TablePagination,
  checkboxClasses,
} from "@mui/material";
import "./Page.css";
// import TextField from "@mui/material/TextField";
// import { Formik, Form } from "formik";
import BackupIcon from "@mui/icons-material/Backup";
import SearchIcon from "@mui/icons-material/Search";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { list_font } from "../Redux/Reducers/FontLibraries/list_Font_slicer";
// import React from "react";
import { add_font } from "../Redux/Reducers/FontLibraries/add_Font_slicer";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import "../App.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FieldProps,
} from "formik";
import * as Yup from "yup";
import { DialogTitle, FormHelperText } from "@mui/material";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import IsLoading from "../Component/isLoading";
import Autocomplete from "../Component/Autocomplete";
import SearchBar from "../Component/SearchBar";
import { delete_font } from "../Redux/Reducers/FontLibraries/delete_Font_slicer";
import { useDispatch } from "react-redux";
import { edit_Font } from "../Redux/Reducers/FontLibraries/edit_Font_slicer";
import Dialogfont from "../Component/DialogForFormFont";
import PopupMessage from "../Component/PopupMessage";
import { useNavigate } from "react-router-dom";

interface FontLibrariesProps {
  values?: any;
  getValues?: any;
  editusers?: any;
  edit?: any;
  isLoading?: boolean;
  successMessage?: any;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

// its is breadcrumbs

// this code dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FontLibraries: FunctionComponent<FontLibrariesProps> = (props) => {
  //    Popup messages
  const [popup, setPopoup] = React.useState(false);

  //    success dialog
  const [dialog, setDialog] = React.useState(false);

  // console.log(props.closeFunction);
  const { getValues, placeholder, successMessage, editusers }: any = props;

  const data = useAppSelector((state) => state?.ListFont?.data);
  const isLoading: any = useAppSelector((state) => state?.ListFont?.isLoading);
  const addData: any = useAppSelector((state) => state?.AddFont?.data);
  const deleteData: any = useAppSelector((state) => state?.DeleteFont?.data);
  const editFont: any = useAppSelector((state) => state?.editFont?.data);
  const editFontIsLoading: any = useAppSelector(
    (state) => state?.editFont?.isLoading
  );

  const addDataIsLoading: any = useAppSelector(
    (state) => state?.AddFont?.isLoading
  );
  const dispatcher = useAppDispatch();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatcher(list_font(""));
    // axios
  }, [deleteData, addData, editFont]);
  React.useEffect(() => {
    if (isLoading) {
      setDialog(isLoading);
    }
  }, [isLoading]);

  const navigate = useNavigate();

  const breadcrumbs = [
    // <Typography key="3" color="text.primary" style={{ display: "flex" }}>
    <Link key="1" color="black" underline="none">
      <b className="heading">Library</b>
    </Link>,

    // <Typography key="3" color="text.primary">
    <Link
      underline="none"
      key="1"
      color="inherit"
      onClick={() => navigate("/fontlibraries")}
      style={{
        fontWeight: "500",
        fontSize: "15px ",
        lineHeight: "16px ",
        color: "#BDBDBD",
        cursor: "pointer",
      }}
    >
      Font library{" "}
    </Link>,
  ];

  // edit data pass
  const initialFont = {
    id: 0, // You should replace this with the appropriate initial values
    status: false, // You should replace this with the appropriate initial values
  };
  const [Data, setRequestData] = useState(initialFont);

  const [editDialog, seteditDialog] = React.useState(0);
  console.log("Data", Data);

  const editOpen = () => {
    seteditDialog(editDialog + 0.001);
  };

  //    userpermission menuList

  const pageName = "Library / Font library";
  const menuList: any = JSON.parse(
    localStorage?.getItem("permission") as string
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageName;
  });

  const MenuOfUserpermission = menuList[index];

  // this code is dialog for comics book is delete
  const [comicbookopen, comicbooksetOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(0);
  const comicbookdeleteOpen = () => {
    comicbooksetOpen(true);
  };

  const comicsbookdeleteClose = () => {
    comicbooksetOpen(false);
  };

  const loadFont = async (font: any, index: any) => {
    console.log("fontchange", font);

    const fontFace = new FontFace(
      "uploadedFont" + index,
      `url(${font.file_url})`
    );

    document.fonts.add(fontFace);
    await fontFace.load();
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDialog(false);
  };

  const [myNames, setMyNames] = useState("");

  console.log(myNames);
  console.log("dta value", data);

  const allNames = data.filter((font: any) =>
    font.name.toLocaleLowerCase().includes(myNames.toLocaleLowerCase())
  );
  console.log("all", allNames);
  const [selectedFile, setSelectedFile] = useState(null);

  // pageniation
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page, setPage] = React.useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = React.useState(10); // Rows per page
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const itemsToShow = allNames.slice(startIndex, endIndex);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <IsLoading
        isLoading={editFontIsLoading || isLoading || addDataIsLoading}
      />

      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div
        className="fontscrollbar"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            // margin: "10px 30px",
            top: "0",
            position: "sticky",
            backgroundColor: "white",
            zIndex: "1",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "1em",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1em",
            }}
          >
            <div>
              <div style={{ width: "16rem" }}>
                <SearchBar
                  // name="Serach"
                  getValues={(e) => {
                    setMyNames(e.target.value);
                    setPage(0);
                  }}
                  value={myNames}
                ></SearchBar>
              </div>
            </div>
            {MenuOfUserpermission?.add && (
              <Dialogfont
                // error={errorMessage}
                successMessage={addData}
                isLoading={addDataIsLoading}
                sx={{
                  padding: "15px",
                  backgroundColor: "black",
                  color: "white",
                  width: "150px",
                  height: "37px",
                  borderRadius: "0px",
                  border: "1px solid #BDBDBD",
                  "&:hover": { backgroundColor: "black" },
                }}
                placeholder="Role"
                getValues={(v: any) => {
                  dispatcher(add_font(v));
                  // console.log(v);
                }}
                Buttondialog={["Cancel"]}
                Dialogtitle={"Add Font"}
                Buttonname={"Add Font"}
              />
            )}
          </div>
        </div>

        <Dialog
          sx={{
            width: "100%",
            height: "100%",
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                borderRadius: "0px",
                width: "30%",
                // backgroundColor: "gray",
              },
            },
          }}
          open={comicbookopen}
          TransitionComponent={Transition}
          keepMounted
          // onClose={comicsbookdeleteClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            sx={{
              backgroundColor: "#000000",
              color: "white",
            }}
          >
            {"Delete"}

            <ClearOutlinedIcon
              className="clear"
              onClick={comicsbookdeleteClose}
              sx={{ color: "white", float: "right" }}
            ></ClearOutlinedIcon>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{ paddingTop: "40px", color: "black", textAlign: "center" }}
            >
              Are you sure want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "25px",
            }}
          >
            <Button
              variant="outlined"
              onClick={comicsbookdeleteClose}
              sx={{
                width: "110px",
                height: "40px",
                marginRight: "20px",
                // fontSize: "13px",
                // alignItems: "center",
                // marginRight: "20px",
                borderRadius: "0px",
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                dispatch(delete_font(deleteId));
                setPopoup(true);
                comicsbookdeleteClose();
              }}
              sx={{
                width: "110px",
                height: "40px",
                backgroundColor: "rgb(0, 0, 0)",
                color: "#FFFFFF",
                display: "flex",
                fontSize: "13px",
                alignItems: "center",

                borderRadius: "0px",
                justifyContent: "space-evenly",
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {data.length === 0 ? (
          <div className="NoDataFound">
            <h1>{isLoading ? "Loading" : "No data found"}</h1>
          </div>
        ) : (
          <div className="welcomeconent">
            {itemsToShow && (
              <>
                {itemsToShow
                  .slice() // Create a copy of the allNames array to avoid modifying the original array
                  .sort((a: any, b: any) => a.name.localeCompare(b.name)) // Sort the array alphabetically based on the name property
                  .map((font: any, index: any) => {
                    loadFont(font, index);
                    // console.log("all", allNames);

                    return (
                      <div className="conent" key={index}>
                        <div className="conent1">
                          <div
                            className={`conent2 ${
                              font.status ? "secondaryColor" : "primeColor"
                            }`}
                          >
                            <div className="fontname">
                              {font.name.split(/[_-]/)[0]}
                            </div>
                            <div
                              className="Name"
                              key={index}
                              style={{
                                fontFamily: "uploadedFont" + index,
                                // fontSize: "21px",
                                // margin: "10px",
                              }}
                            >
                              The quick brown fox jumps over the lazy dog
                            </div>
                            {/* <p style={fontStyle}>Choose your font style</p> */}
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                // alignItems: "center",
                              }}
                            >
                              {MenuOfUserpermission?.edit && (
                                <FormControlLabel
                                  sx={{ marginRight: "1px" }}
                                  control={
                                    <Checkbox
                                      checked={font.status}
                                      sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                          color: font.status
                                            ? "#ffffff"
                                            : "black",
                                        },
                                      }}
                                      onChange={(e) => {
                                        const requestData = {
                                          id: font.id,
                                          status: e.target.checked,
                                        };

                                        setRequestData(requestData);
                                        editOpen();
                                        // dispatcher(edit_Font(requestData));
                                      }}
                                    />
                                  }
                                  label={null}
                                  // labelPlacement="end"
                                />
                              )}
                              {/* {MenuOfUserpermission?.delete && (
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  comicbookdeleteOpen();
                                  setDeleteId(font.id);
                                }}
                              >
                                <DeleteOutlineIcon
                                  sx={{
                                    // backgroundColor: "#F5F5F5",
                                    color: "black",
                                    // height: "1.3em",
                                    // width: "1.3em",
                                    margin: "9px 5px 0px 0px ",
                                    // borderRadius: "50%",
                                    "&:hover": {
                                      color: "white",
                                      backgroundColor: "black",
                                      borderRadius: "50%",
                                    },
                                  }}
                                ></DeleteOutlineIcon>
                              </span>
                            )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        )}
        <div
          style={{
            display: "flex",
            bottom: "0",
            position: "sticky",
            backgroundColor: "white",
            width: "100%",
            right: "0",
            flex: "1",
            alignItems: "flex-end",
          }}
        >
          <TablePagination
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
            component="div"
            count={allNames.length} // Total count of items
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <PopupMessage
        successMessage={editFont}
        open={editDialog}
        Dialogtitle={
          Data.status == true
            ? "Do you want to activate the font"
            : "Do you want to de-activate the font"
        }
        submitFunction={() => dispatcher(edit_Font(Data))}
        isLoading={editFontIsLoading}
        closeFunction={() => null}
      />
    </div>
  );
};

export default FontLibraries;
function dispatcher(arg0: { payload: any; type: "ListFont/list_font" }) {
  throw new Error("Function not implemented.");
}
