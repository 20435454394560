import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import Dialogs from "../Component/Dialog";
import {
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  FormikValues,
  getIn,
  useFormik,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { list_ComicsBookPage } from "../Redux/Reducers/Comics_Book_Page/list_ComicsBookPage_slicer";
import PopupMessage from "../Component/PopupMessage";
import { list_Reorder } from "../Redux/Reducers/Comics_Book_Page/Reorder_slicer";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

interface ReorderProps {
  disabled?: boolean;
}

const Reorder: FunctionComponent<ReorderProps> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  //   useEffect(() => {
  //     dispatch(
  //       list_ComicsBookPage(parseInt(localStorage.getItem("Project_Id")!))
  //     );
  //   }, []);
  return (
    <div>
      <Button
        variant="contained"
        fullWidth
        onClick={handleClickOpen}
        sx={{
          height: "40px",
          backgroundColor: "rgb(0, 0, 0)",
          color: "#FFFFFF",
          textTransform: "none",
          borderRadius: "0px",
        }}
        disabled={props.disabled}
      >
        Reorder
      </Button>
      <Dialogs
        open={open}
        setOpen={setOpen}
        DialogBody={<DialogBody open={open} setOpen={setOpen} />}
        maxWidth={"xs"}
      />
    </div>
  );
};

const DialogBody = (props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const data: any = useAppSelector((state) => state?.ListComicsBookPage?.data);
  const reorderData: any = useAppSelector((state) => state?.Reorder?.data);
  const reorderIsLoading: any = useAppSelector(
    (state) => state?.Reorder?.isLoading
  );
  const dispatch = useAppDispatch();
  const filterData = data?.page?.map(
    (val: { page_id: number; page_name: string; index: number }) => {
      return {
        page_id: val.page_id,
        index: val.index,
        page_name: val.page_name,
      };
    }
  );
  const initialValues: {
    arrOfValues: { page_id: number; page_name: string; index: number }[];
  } = {
    arrOfValues: filterData ?? [],
  };
  // const formik = useFormik({
  //   initialValues: initialValues,
  //   validationSchema: Yup.object().shape({
  //     arrOfValues: Yup.array().of(
  //       Yup.object().shape({
  //         page_id: Yup.number(),
  //         page_name: Yup.string(),
  //         index: Yup.number()
  //           .positive("Index should be a positive number")
  //           .min(0, "Index must be greater than or equal to 0")
  //           .integer("Index must be an integer")
  //           .required("Enter the Index of the Page"),
  //       })
  //     ),
  //   }),
  //   onSubmit: (values) => {
  //     console.log(values);
  //   },
  // });
  const [dialog, setDialog] = useState(0);
  const formRef = useRef<FormikProps<FormikValues>>(null);
  return (
    <>
      <DialogTitle sx={{ backgroundColor: "#000000", color: "white" }}>
        Reorder Index
        <ClearOutlinedIcon
          className="clear"
          onClick={() => props.setOpen(false)}
          sx={{ color: "white", float: "right" }}
        ></ClearOutlinedIcon>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "1.5em 24px 0!important",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              arrOfValues: Yup.array()
                .of(
                  Yup.object().shape({
                    page_id: Yup.number(),
                    page_name: Yup.string(),
                    index: Yup.number()
                      .positive("Index should be a positive number")
                      .min(0, "Index must be greater than or equal to 0")
                      .integer("Index must be an integer")
                      .required("Enter the Index of the Page"),
                  })
                )
                .min(1),
            })}
            onSubmit={(values) => {
              // getValues(values);
              setDialog(dialog + 0.001);
              console.log(values);
            }}
            innerRef={formRef}
          >
            {(formik) => (
              <Form
                autoComplete="off"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5em",
                }}
              >
                <FieldArray
                  name={"arrOfValues"}
                  render={(arrayHelpers) => {
                    return (
                      <>
                        {formik.values.arrOfValues &&
                        formik.values.arrOfValues.length > 0 ? (
                          <>
                            {formik.values.arrOfValues.map(
                              (
                                values: {
                                  page_id: number;
                                  page_name: string;
                                  index: number;
                                },
                                index: number
                              ) => {
                                const indexs = `arrOfValues[${index}].index`;
                                const id = `arrOfValues[${index}].page_id`;
                                const name = `arrOfValues[${index}].page_name`;
                                const touchedIndex = getIn(
                                  formik.touched,
                                  indexs
                                );
                                const errorIndex = getIn(formik.errors, indexs);
                                const touchedId = getIn(formik.touched, id);
                                const errorId = getIn(formik.errors, id);
                                const touchedName = getIn(formik.touched, name);
                                const errorName = getIn(formik.errors, name);
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "stretch",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {/* <div>
                                    <TextField
                                      variant="outlined"
                                      name={id}
                                      sx={{
                                        "& fieldset": {
                                          borderRadius: "0px",
                                        },
                                      }}
                                      type="number"
                                      value={values.page_id}
                                      onChange={formik.handleChange}
                                      style={{
                                        width: "25rem",
                                        height: "50px",
                                        margin: " 0px 0px 30px ",
                                      }}
                                      InputProps={{
                                        inputProps: {
                                          min: 0,
                                          inputMode: "numeric",
                                          pattern: "[0-9]*",
                                        },
                                      }}
                                      helperText={
                                        errorId && touchedId && errorIndex
                                      }
                                      error={
                                        errorId && touchedId ? true : false
                                      }
                                      onBlur={formik.handleBlur}
                                    />
                                  </div> */}
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color:
                                          errorIndex && touchedIndex
                                            ? "red"
                                            : "black",
                                      }}
                                    >
                                      {errorIndex && touchedIndex && "*"}
                                      {values.page_name}
                                      {/* <TextField
                                      // id="outlined-basic"
                                      // label="Outlined"
                                      variant="outlined"
                                      name={name}
                                      sx={{
                                        "& fieldset": {
                                          borderRadius: "0px",
                                        },
                                      }}
                                      type="text"
                                      value={values.page_name}
                                      onChange={formik.handleChange}
                                      style={{
                                        width: "25rem",
                                        height: "50px",
                                        margin: " 0px 0px 30px ",
                                      }}
                                      //   InputProps={{
                                      //     inputProps: {
                                      //       min: 0,
                                      //       inputMode: "numeric",
                                      //       pattern: "[0-9]*",
                                      //       style: { textAlign: "right" },
                                      //     },
                                      //   }}
                                      helperText={
                                        errorName && touchedName && errorName
                                      }
                                      error={
                                        errorName && touchedName ? true : false
                                      }
                                      onBlur={formik.handleBlur}
                                    /> */}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      :&nbsp;
                                      <TextField
                                        // id="outlined-basic"
                                        // label="Outlined"
                                        variant="outlined"
                                        name={indexs}
                                        sx={{
                                          "& fieldset": {
                                            borderRadius: "0px",
                                          },
                                          // width: "5rem",
                                        }}
                                        type="number"
                                        value={values.index}
                                        onChange={formik.handleChange}
                                        InputProps={{
                                          inputProps: {
                                            min: 0,
                                            inputMode: "numeric",
                                            pattern: "[0-9]*",
                                            // style: { textAlign: "right" },
                                          },
                                        }}
                                        // helperText={
                                        //   errorIndex && touchedIndex && errorIndex
                                        // }
                                        error={
                                          errorIndex && touchedIndex
                                            ? true
                                            : false
                                        }
                                        onBlur={formik.handleBlur}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <h2>No Data Available</h2>
                        )}
                      </>
                    );
                  }}
                />
                <PopupMessage
                  successMessage={reorderData}
                  open={dialog}
                  Dialogtitle={"Are you sure you want to proceed?"}
                  submitFunction={() => dispatch(list_Reorder(formik.values))}
                  isLoading={reorderIsLoading}
                  closeFunction={() => props.setOpen(false)}
                />
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          gap: "0.5em",
          padding: "1.5em",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => props.setOpen(false)}
          sx={{
            width: "110px",
            height: "40px",

            // fontSize: "13px",
            // alignItems: "center",
            // marginRight: "20px",
            borderRadius: "0px",
          }}
        >
          CANCEL
        </Button>
        <Button
          onClick={() => formRef.current!.handleSubmit()}
          // form="Reorder"
          // type="submit"
          disabled={(formRef.current && !formRef.current!.isValid) ?? false}
          style={{
            // border: "1px solid black",
            width: "100px",
            height: "40px",
            border: "1px solid black",
            color: "white",
            // backgroundColor: "#C00A30",
            backgroundColor: "black",
            borderRadius: "0px",
          }}
        >
          SAVE
        </Button>
      </DialogActions>
    </>
  );
};

export default Reorder;
