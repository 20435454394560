import { FunctionComponent } from "react";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import "./Page.css";
import Grids from "../Component/Grides";
import { Button } from "@mui/material";
import Deleteoption from "./Deleteoption";
import { list_Campaign } from "../Redux/Reducers/Campaign/list_Campaign_slicer";
import {
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridColDef,
} from "@mui/x-data-grid";
import IsLoading from "../Component/isLoading";
import { IListCampaign } from "../interfaces/ICampaign";
import { false_success } from "../Redux/Reducers/Campaign/add_Campaign_slicer";
import { false_success_edit } from "../Redux/Reducers/Campaign/edit_Campaign_slicer";
import Dialogs from "../Component/Dialog";
import {
  false_success_launchAndPass_Campaign,
  launchAndPass_Campaign,
  launch_popup_false,
  pause_popup_false,
  switch_popup_false,
} from "../Redux/Reducers/Campaign/launch&pass_Campaign_slicer";
import {
  clear_SendShopifyRedirectUrl,
  send_url,
} from "../Redux/Reducers/Shopify/shopify_redirect_url_send_slicer";
import { get_url } from "../Redux/Reducers/Shopify/shopify_redirect_url_slicer";
import { SUCCESE } from "../Editor/utills/common";
import {
  false_success_relaunch,
  relaunch_Campaign,
  relaunch_popup,
} from "../Redux/Reducers/Campaign/relaunch_Campaign_slicer";

interface CampaignProps {}

const Campaign: FunctionComponent<CampaignProps> = () => {
  const navigate = useNavigate();

  const dispatcher = useAppDispatch();
  const breadcrumbs = [
    // <Typography key="3" color="text.primary" style={{ display: "flex" }}>
    <Link
      key="1"
      color="black"
      onClick={() => navigate("/Campaign")}
      underline="none"
      sx={{ cursor: "pointer" }}
    >
      <b className="heading">Campaign</b>
    </Link>,

    // <Typography key="3" color="text.primary">
  ];

  /*    Redux state    */
  /*    success popup    */
  const success = useAppSelector(
    (state) => state.LaunchAndPauseCampaign.isSuccess
  );
  /*    Launch popup     */
  const launch = useAppSelector((state) => state.LaunchAndPauseCampaign.launch);
  /*    pause popup     */
  const pause = useAppSelector((state) => state.LaunchAndPauseCampaign.pause);
  /*    switch popup and data    */
  const switchButton: {
    popup: boolean;
    switch: boolean;
  } = useAppSelector((state) => state.LaunchAndPauseCampaign.switch);
  /*    relaunch     */
  const {
    data: relaunchData,
    popup: relaunch,
    isSuccess: successRelaunch,
    isLoading: relaunchIsLoading,
  } = useAppSelector((state) => state.RelaunchCampaign);

  //    Campign
  const Campign = useAppSelector((state) => state?.ListCampaign?.data);
  const CampignIsLoading = useAppSelector(
    (state) => state?.ListCampaign?.isLoading
  );
  const AddCampaign = useAppSelector((state) => state?.AddCampaign?.data);
  const updateCampaignStatus = useAppSelector(
    (state) => state.LaunchAndPauseCampaign.data
  );
  const updateCampaignStatusisLoading = useAppSelector(
    (state) => state.LaunchAndPauseCampaign.isLoading
  );
  const campaignIdbyLaunchAndPause = useAppSelector(
    (state) => state.LaunchAndPauseCampaign.launchAndPauseId
  );

  /*    Shopify access token genarated response    */
  const accessTokenResponse = useAppSelector(
    (state) => state.SendShopifyRedirectUrl.data
  );

  /*    project id by launch     */
  const projectIdbyLaunch = useAppSelector(
    (state) => state.LaunchAndPauseCampaign.projectId
  );

  const projectIdbyRelaunch = useAppSelector(
    (state) => state.RelaunchCampaign.projectId
  );

  /*    coustomized for datagrid    */
  const coustomizedCampaign = Campign?.data?.map((campaign: IListCampaign) => {
    return {
      ...campaign,
      field_id: campaign.campaign_id,
    };
  });

  /*    editable value     */
  const isEditableValue = useAppSelector(
    (state) => state.EditableCampaign.editable
  );
  const isEditableValueIsLoading = useAppSelector(
    (state) => state.EditableCampaign.isLoading
  );

  //    userpermission menuList
  const pageName = "Campaign";
  const menuList: any = JSON.parse(
    localStorage?.getItem("permission") as string
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageName;
  });

  // Delete

  const MenuOfUserpermission = menuList[index];

  const column: GridColDef[] = [
    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Campaign Mockup</b>,
      field: "images",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div style={{ margin: "1em 0", display: "flex" }}>
            <img
              src={params.value}
              style={{
                height: "clamp(4rem,5vw,8rem)",
                width: "clamp(4rem,5vw,8rem)",
              }}
            />
          </div>
        );
      },
    },

    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Campaign Name</b>,
      field: "campaign_name",
      flex: 1,
      headerAlign: "center",
    },

    {
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>Last Updated Date</b>
      ),
      field: "updated_on",
      flex: 1,
      headerAlign: "center",
      align: "center",
      type: "dateTime",
      valueGetter: (params) => {
        return new Date(params.value);
      },
      renderCell: (params) => {
        // Create a Date object from the input string
        const date = new Date(params?.value);

        // Extract day, month, and year components
        const day = date?.getDate();
        const month = date?.getMonth() + 1; // Months are 0-based, so we add 1
        const year = date?.getFullYear();

        // Format day and month to have leading zeros if necessary
        const formattedDay = String(day)?.padStart(2, "0");
        const formattedMonth = String(month)?.padStart(2, "0");

        // Create the formatted date string
        const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

        return (
          <div
            style={{
              backgroundColor: "#F5F5F5",
              borderRadius: "6rem",
              width: "6rem",
            }}
          >
            {formattedDate}
          </div>
        );
      },
    },
    {
      renderHeader: (params: GridColumnHeaderParams) => <b>Status</b>,
      field: "status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        function camelize(params: GridRenderCellParams) {
          return params.value
            .replace(
              /(?:^\w|[A-Z]|\b\w)/g,
              function (word: string, index: number) {
                return index === 0 ? word.toUpperCase() : word.toLowerCase();
              }
            )
            .replace(/\s+/g, " ");
        }
        return <>{camelize(params)}</>;
      },
    },
    MenuOfUserpermission?.edit
      ? {
          renderHeader: (params: GridColumnHeaderParams) => <b>Action</b>,
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params) => {
            return <Deleteoption value={params} />;
          },
        }
      : {
          field: "",
        },
  ];

  /*    initial render     */
  React.useEffect(() => {
    dispatcher(false_success());
    dispatcher(false_success_edit());
    /*    after reload with new url functions    */
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");

    if (code) {
      dispatcher(send_url(window.location.href));
      // navigate to order managment
      navigate("/Campaign");
    }

    return () => {
      dispatcher(clear_SendShopifyRedirectUrl());
    };
  }, []);

  React.useEffect(() => {
    dispatcher(list_Campaign());
    if (!updateCampaignStatus?.status) {
      if (updateCampaignStatus?.message === "Connect to shopify") {
        /*    redirect to shopify login if new user    */
        dispatcher(get_url("/Campaign"));
      }
      if (
        updateCampaignStatus?.message ===
        "Access token expired.Regenerate the token"
      ) {
        /*    re-genrate access token api call     */
        dispatcher(get_url("/Campaign"));
      }
    }
  }, [AddCampaign, updateCampaignStatus, relaunchData]);

  /*    Redirect when click edit     */
  React.useEffect(() => {
    if (isEditableValue) {
      navigate("/NewCampaign");
    }
  }, [isEditableValue]);

  React.useEffect(() => {
    if (accessTokenResponse?.status) {
      SUCCESE(accessTokenResponse.message);
    }
  }, [accessTokenResponse]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={coustomizedCampaign ?? []}
        column={column.filter((column) => column.field !== "")}
        // Users={"Campaign"}
        getRowHeight={() => "auto"}
        open
        pagination
        dialog={
          MenuOfUserpermission?.add && (
            <Button
              onClick={() => {
                navigate("/NewCampaign");
              }}
              sx={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "0px",
                whiteSpace: "nowrap",
                "&:hover": { backgroundColor: "black" },
              }}
            >
              Add New Campaign
            </Button>
          )
        }
        Userpermissions={undefined}
      ></Grids>

      {/*     Dialogs    */}
      {/*     Launch,Pause and switch popup    */}
      <Dialogs
        open={launch || pause || switchButton.popup || relaunch}
        setOpen={() => {
          dispatcher(launch_popup_false());
          dispatcher(pause_popup_false());
          dispatcher(switch_popup_false());
          dispatcher(relaunch_popup(false));
        }}
        maxWidth="xs"
        sx={{ width: "auto" }}
        Dialog_Content={
          <>
            {launch && "Are you sure you want to launch?"}
            {relaunch && "Are you sure you want to launch?"}
            {pause && "Are you sure you want to pause?"}
            {switchButton.popup &&
              `Are you sure you want to ${
                switchButton.switch ? "Active" : "Inactive"
              }?`}
          </>
        }
        Dialog_Content_sx={{
          display: "flex",
          fontSize: "16px",
          justifyContent: "center",
          padding: "1.5em 1.5em 0 !important",
        }}
        Dialog_Actions={
          <>
            <Button
              variant="outlined"
              onClick={() => {
                dispatcher(launch_popup_false());
                dispatcher(pause_popup_false());
                dispatcher(switch_popup_false());
                dispatcher(relaunch_popup(false));
              }}
              sx={{
                width: "110px",
                height: "40px",
                borderRadius: "0px",
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                launch &&
                  dispatcher(
                    launchAndPass_Campaign({
                      id: campaignIdbyLaunchAndPause,
                      project_id: projectIdbyLaunch,
                      status: "PUBLISHED",
                    })
                  );
                pause &&
                  dispatcher(
                    launchAndPass_Campaign({
                      id: campaignIdbyLaunchAndPause,
                      status: "PAUSE",
                    })
                  );
                switchButton.popup &&
                  dispatcher(
                    launchAndPass_Campaign({
                      id: campaignIdbyLaunchAndPause,
                      status: switchButton.switch ? "ACTIVE" : "INACTIVE",
                    })
                  );
                relaunch && dispatcher(relaunch_Campaign(projectIdbyRelaunch));
              }}
              sx={{
                width: "110px",
                height: "40px",
                backgroundColor: "rgb(0, 0, 0)",
                color: "#FFFFFF",
                display: "flex",
                borderRadius: "0px",
              }}
            >
              Yes
            </Button>
          </>
        }
        Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
      />
      {/*     Success popup for launch and pause    */}
      <Dialogs
        open={success}
        setOpen={() => dispatcher(false_success_launchAndPass_Campaign())}
        maxWidth="xs"
        sx={{ width: "auto" }}
        Dialog_Content_sx={{
          padding: "1.5em 1.5em 0 !important",
          fontSize: "16px",
        }}
        Dialog_Content={
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <div>{updateCampaignStatus?.message}</div>

            {updateCampaignStatus?.status ? (
              <img
                src={require("../Pages/Image/success.png")}
                alt="success"
                width={"20%"}
              />
            ) : (
              <>
                {updateCampaignStatus?.message
                  ?.toLowerCase()
                  .includes("already exists") ? (
                  // console.log("exisite")
                  <img
                    src={require("../Pages/Image/mdi_file-alert-outline.png")}
                    alt="error"
                    width={"20%"}
                  />
                ) : (
                  <img
                    src={require("../Pages/Image/error.png")}
                    alt="error"
                    width={"20%"}
                  />
                )}
              </>
            )}
          </section>
        }
        Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
        Dialog_Actions={
          <Button
            variant="contained"
            onClick={() => {
              dispatcher(false_success_launchAndPass_Campaign());
            }}
            sx={{
              width: "110px",
              height: "40px",
              backgroundColor: "rgb(0, 0, 0)",
              color: "#FFFFFF",
              display: "flex",
              borderRadius: "0px",
            }}
          >
            Ok
          </Button>
        }
      />
      {/* success relaunch */}
      <Dialogs
        open={successRelaunch}
        setOpen={() => {
          dispatcher(false_success_relaunch());
        }}
        maxWidth="xs"
        sx={{ width: "auto" }}
        Dialog_Content_sx={{
          padding: "1.5em 1.5em 0 !important",
          fontSize: "16px",
        }}
        Dialog_Content={
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <div>{relaunchData?.message}</div>

            {relaunchData?.status ? (
              <img
                src={require("../Pages/Image/success.png")}
                alt="success"
                width={"20%"}
              />
            ) : (
              <>
                {relaunchData?.message
                  ?.toLowerCase()
                  .includes("already exists") ? (
                  // console.log("exisite")
                  <img
                    src={require("../Pages/Image/mdi_file-alert-outline.png")}
                    alt="error"
                    width={"20%"}
                  />
                ) : (
                  <img
                    src={require("../Pages/Image/error.png")}
                    alt="error"
                    width={"20%"}
                  />
                )}
              </>
            )}
          </section>
        }
        Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
        Dialog_Actions={
          <Button
            variant="contained"
            onClick={() => {
              dispatcher(false_success_relaunch());
            }}
            sx={{
              width: "110px",
              height: "40px",
              backgroundColor: "rgb(0, 0, 0)",
              color: "#FFFFFF",
              display: "flex",
              borderRadius: "0px",
            }}
          >
            Ok
          </Button>
        }
      />
      {/*     loader     */}
      <IsLoading
        isLoading={
          CampignIsLoading! ||
          isEditableValueIsLoading! ||
          updateCampaignStatusisLoading! ||
          relaunchIsLoading
        }
      />
    </div>
  );
};

export default Campaign;
