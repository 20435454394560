import { FunctionComponent, useEffect, useState } from "react";
import Select from "../../Component/Select";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { list_font } from "../../Redux/Reducers/FontLibraries/list_Font_slicer";
import "./Fonts/fonts.css";
import TextField from "@mui/material/TextField";
import {
  CustomFont,
  Editable,
  fontFamily,
  textAlignment,
} from "../../Redux/Reducers/Editor_value";
import { useSelector } from "react-redux";
import { Padding } from "@mui/icons-material";

interface FontLibraryProps {}

const FontLibrary: FunctionComponent<FontLibraryProps> = () => {
  //      Local Font Library
  const checkEditPage = useSelector((state: any) => state.DetailEdit);

  const [font, setfonts] = useState<any>("");
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  // const ff = font?.url;
  // console.log("font", ff);
  // const loadFont = async () => {
  //   try {
  //     await ff.load();
  //     document.fonts.add(ff);
  //     console.log("success load font");
  //     // editor?.canvas.renderAll();

  //     // setIsFontLoaded(true);
  //   } catch (error) {
  //     console.error("Error loading font:", error);
  //     // setIsFontLoaded(false);
  //   }
  // };
  // loadFont();

  // const localFonts = [
  //   {
  //     value: "'Times New Roman', serif",
  //     label: "Times New Roman",
  //     url: "http://fonts.googleapis.com/css?family=Times+New+Roman",
  //   },
  //   {
  //     value: "'Courier New', monospace",
  //     label: "Courier New",
  //     url: "http://fonts.googleapis.com/css?family=Courier+New",
  //   },

  //   {
  //     value: "'Lato', sans-serif",
  //     label: "Lato",
  //     url: "http://fonts.googleapis.com/css?family=Lato",
  //   },
  //   // Add more fonts here
  //   {
  //     value: "'Montserrat', sans-serif",
  //     label: "Montserrat",
  //     url: "http://fonts.googleapis.com/css?family=Montserrat",
  //   },

  //   {
  //     value: "'Dancing Script', cursive",
  //     label: "Dancing Script",
  //     url: "http://fonts.googleapis.com/css?family=Dancing+Script",
  //   },
  //   {
  //     value: "Smooch Sans",
  //     label: "Smooch Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@900&display=swap",
  //   },
  //   {
  //     value: "Ephesis",
  //     label: "Ephesis",
  //     url: "https://fonts.googleapis.com/css2?family=Ephesis&family=Josefin+Sans:wght@200&family=Smooch+Sans:wght@200&display=swap",
  //   },

  //   // Add more fonts here
  // ];
  // useEffect(() => {
  //   // Add the CSS links dynamically to the head of the document
  //   localFonts.forEach((font) => {
  //     const link = document.createElement("link");
  //     link.href = font.url;
  //     link.rel = "stylesheet";
  //     document.head.appendChild(link);
  //   });
  // });
  //      Backend recived Font Library
  const [customFonts, setCustomFonts] = useState<{
    label: string;
    value: string;
    url: any;
  } | null>();

  const data: {
    font_reference_name: any;
    field_id: number;
    file_url: string;
    id: number;
    name: string;
    status: boolean;
  }[] = useAppSelector((state) => state?.ListFont?.data);

  const FilterDataa = data.filter((data) => data.status === true);

  const FilterData = FilterDataa.map((data) => {
    return {
      label: data.name,
      value: data.font_reference_name,
      url: data.file_url,
    };
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    //      call custom font api
    dispatch(list_font(""));
  }, []);

  const totalfont = [...FilterData];

  const handleEnterButtonClick = () => {
    // dispatch(fontFamily(selectedFont));
    dispatch(textAlignment("null"));
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      dispatch(textAlignment("nulll"));
    }
  };

  return (
    <div>
      <div
        style={{
          // width: "clamp(20rem,calc(100% - 50rem),100rem)",
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          marginTop: "10px",
        }}
      ></div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div onKeyPress={handleKeyPress} style={{ width: "80%" }}>
          <Select
            Listitem={totalfont}
            label={"Font Family"}
            // multiple
            filter
            onchange={(
              _: null,
              value: { label: string; value: string; url: any; fontlist: any }
            ) => {
              // dispatch(CustomFont(""));
              dispatch(fontFamily(""));
              dispatch(textAlignment("empty"));
              setfonts(value);
              console.log(value);

              dispatch(CustomFont(value));
              setTimeout(() => {
                dispatch(textAlignment("null"));
              }, 1300);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FontLibrary;
