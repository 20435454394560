import { call, delay, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  PDF_Genaration,
  failure_api_call_PDFGenaration,
  stop_loader,
  success_api_call_PDFGenaration,
} from "./PDF_genaration_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import { PayloadAction } from "@reduxjs/toolkit";
import { IAPIResponse } from "../../../interfaces/IReduxState";
import { ICoverPDF, IPDF } from "../../../interfaces/IPDF";
import {
  failure_api_call_CoverPDFGenaration,
  stop_loader_cover,
  success_api_call_CoverPDFGenaration,
} from "./Cover_PDF_genaration_slicer";
import {
  failure_api_call_cus_PDFGenaration,
  stop_loaderr,
  success_api_call_cus_PDFGenaration,
} from "./Customer_pdf";
interface ExtendPdf extends IPDF {
  count: number;
}
interface ExtendICoverPDF extends ICoverPDF {
  count: number;
}

const PDFCall = (action: any) => {
  let token = localStorage.getItem("token");
  const requestData1 = {
    project_id: action.project_id,
    type: action.type,
  };

  return Axios.post(
    `${process.env.REACT_APP_nodeurl}/pdfgeneration/admin_pdf`,
    requestData1,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* PDF_genaration_Api(action: PayloadAction<IPDF>) {
  while (true) {
    try {
      const response: AxiosResponse<IAPIResponse<string>> = yield call(
        PDFCall,
        {
          ...action.payload,
        }
      );
      if (response.data.data) {
        yield put(success_api_call_PDFGenaration(response.data));
        const link = document.createElement("a");
        // 👇️ set to download
        link.href = response.data.data;
        link.click();
        yield put(stop_loader(action.payload));
        break;
      }
      if (response.data.status == true) {
        yield put(success_api_call_PDFGenaration(response.data));
        // Wait for 45 seconds before making the next API call
        yield delay(45000);
      } else {
        yield put(failure_api_call_PDFGenaration(response.data));
        yield put(stop_loader(action.payload));
        break;
      }
    } catch (e) {
      console.log(e);
      yield put(failure_api_call_PDFGenaration(e));
      yield put(expireSignature(e));
      yield put(stop_loader(action.payload));
      break;
    }
  }
}

// Customer_pdf
const CustomerPDFCall = (action: any) => {
  let token = localStorage.getItem("token");
  const requestData = {
    order_id: action.order_id,
    product_id: action.product_id,
    customization_id: action.customization_id,
    type: action.type,
  };

  return Axios.post(
    `${process.env.REACT_APP_nodeurl}/customer-pdf/pdf`,
    requestData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* Customer_PDF_genaration_Api(action: PayloadAction<IPDF>) {
  while (true) {
    try {
      const response: AxiosResponse<IAPIResponse<string>> = yield call(
        CustomerPDFCall,
        {
          ...action.payload,
        }
      );
      if (response.data.data) {
        yield put(success_api_call_cus_PDFGenaration(response.data));
        const link = document.createElement("a");
        // 👇️ set to download
        link.href = response.data.data;
        link.click();
        yield put(stop_loaderr(action.payload));
        break;
      }
      if (response.data.status == true) {
        yield put(success_api_call_cus_PDFGenaration(response.data));
        // Wait for 45 seconds before making the next API call
        yield delay(45000);
      } else {
        yield put(failure_api_call_cus_PDFGenaration(response.data));
        yield put(stop_loaderr(action.payload));
        break;
      }
    } catch (e) {
      yield put(failure_api_call_cus_PDFGenaration(e));
      yield put(expireSignature(e));
      yield put(stop_loaderr(action.payload));
      break;
    }
  }
}

//    cover pdf
const CoverPDFCall = (action: any) => {
  let token = localStorage.getItem("token");
  const requestcoverData = {
    order_id: action.order_id,
    product_id: action.product_id,
    customization_id: action.customization_id,
    type: action.type,
    token: token,
  };
  return Axios.post(
    `${process.env.REACT_APP_nodeurl}/comics/lulupdf`,
    // `http://192.168.1.109:3030/comics/lulupdf`,
    requestcoverData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* CoverPDF_genaration_Api(action: PayloadAction<ICoverPDF>) {
  // let count = 0;
  // while (true) {
  try {
    // count++;
    const response: AxiosResponse<IAPIResponse<string>> = yield call(
      CoverPDFCall,
      { ...action.payload }
    );
    if (response.data.data) {
      yield put(success_api_call_CoverPDFGenaration(response.data));
      const link = document.createElement("a");

      // 👇️ set to download
      link.href = response.data.data;

      link.click();
      yield put(stop_loader_cover(action.payload));
      // break;
    }
    if (response.data.status == false) {
      yield put(failure_api_call_CoverPDFGenaration(response.data));
      yield put(stop_loader_cover(action.payload));
    }
    // console.log(response.data);

    // yield put(success_api_call_CoverPDFGenaration(response.data));
    // break;
    // Wait for 20 seconds before making the next API call
    // yield delay(20000);
  } catch (e) {
    yield put(failure_api_call_CoverPDFGenaration(e));
    yield put(expireSignature(e));
    yield put(stop_loader_cover(action.payload));
    // break;
  }
}
// }
