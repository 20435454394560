import { FunctionComponent, useState } from "react";
import Dialogs from "../../Component/Dialog";
import { Button, DialogContent, DialogTitle } from "@mui/material";
// import "./ImageUploadedPopup.css";
// import "./ImageUplodeForEdiorPage.css";
import "./ComicsLibrary.css";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { add_PageQuestions } from "../../Redux/Reducers/PageQuestions/add_PageQuestions_slicer";
import { ListOfImages } from "../../interfaces/ListOfImagesForEditor";
import { useDispatch } from "react-redux";
import { Image_Upload } from "../../Redux/Reducers/Editor_value";

// interface ImageLibraryForEditorProps {}

// const ImageLibraryForEditor: FunctionComponent<
//   ImageLibraryForEditorProps
// > = () => {
//   //    useState for open DialogComponent
//   const [open, setOpen] = useState<boolean>(false);
//   const dispatch = useAppDispatch();
//   return (
//     <>
//       <button
//         onClick={() => {
//           setOpen(true);
//           dispatch(add_PageQuestions(3));
//         }}
//       >
//         Comics Library
//       </button>

//       {/*     Dialog component     */}
//       <Dialogs
//         open={open}
//         setOpen={setOpen}
//         DialogBody={<DialogBody setOpenDialog={setOpen} openDialog={open} />}
//         maxWidth={"md"}
//       />
//     </>
//   );
// };

//    Body of the dialog
const DialogBody = (props: {
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openDialog: boolean;
}) => {
  const dispatch = useDispatch();
  const { setOpenDialog } = props;
  const listOfCards: ListOfImages[] = useAppSelector(
    (state) => state.AddPageQuestions.data
  );

  const listOfCardsLoading: boolean = useAppSelector(
    (state) => state.AddPageQuestions.isLoading
  );

  // console.log(listOfCards);

  return (
    <>
      {/*     Title of the dialog    */}
      <DialogTitle sx={{ backgroundColor: "#000000", color: "#FFFFFF" }}>
        <div className="dialogTitleForComicsLibrary">
          <div>Question Option</div>
          <div>
            {/*     Close button for the Dialog     */}
            <Button
              sx={{
                color: "#FFFFFF",
                borderRadius: "50%",
                minWidth: "37px",
                "&:hover": { backgroundColor: "#ffffff3d" },
              }}
              onClick={() => setOpenDialog(false)}
            >
              X
            </Button>
          </div>
        </div>
      </DialogTitle>
      {/*     Content of the dialog    */}
      <DialogContent sx={{ "&::-webkit-scrollbar": { display: "none" } }}>
        {/*     API loading condition    */}
        {listOfCardsLoading ? (
          <div className="NoDataFoundForComicsLibrary">
            <h1>Loading...</h1>
          </div>
        ) : (
          <div className="cards">
            {/*     Whether the cards exist or not     */}
            {(listOfCards ?? []).length === 0 ? (
              <div className="NoDataFoundForComicsLibrary">
                <h1>No Data Found</h1>
              </div>
            ) : (
              <>
                {/*     Mapping the options */}
                {listOfCards.map((card, index: number) => {
                  return (
                    //  Card contains
                    <div className="cardDivsForComicsLibrary" key={index}>
                      {/*   Image of card  */}
                      <div className="imageDivForComicsLibrary">
                        <img
                          loading="lazy"
                          className="imageForComicsLibrary"
                          src={card?.image_url}
                          alt="message"
                          width={"150px"}
                        />
                      </div>
                      <div className="cardFooterForComicsLibrary">
                        {/*   Name of the card  */}
                        <div className="cardNameAndSizeForComicsLibrary">
                          <b>{card?.id}</b>
                          {/* <span>{card.size}</span> */}
                        </div>
                        <div className="cardButtonForComicsLibrary">
                          {/*   Select Button  */}
                          <Button
                            onClick={() => {
                              setOpenDialog(false);
                              dispatch(Image_Upload(card?.image_url));
                            }}
                            variant="contained"
                            sx={{
                              textTransform: "none",
                              borderRadius: "0",
                            }}
                          >
                            Select
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}
      </DialogContent>
    </>
  );
};

export default DialogBody;
