import { createSlice } from "@reduxjs/toolkit";
import { IComicsPage } from "../../../interfaces/ComicsPageEdit";

export const EditablePageValue = createSlice({
  name: "EditablePageValue",
  initialState: { data: {} as IComicsPage },
  reducers: {
    edit_Page_Value: (state, action) => {
      return { ...state, data: action.payload };
    },
  },
});

export default EditablePageValue.reducer;

export const { edit_Page_Value } = EditablePageValue.actions;
