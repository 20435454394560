import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
// import { IRelaunchCampaign } from "../../../interfaces/ICampaign";

interface ExtendedInterface extends IReduxState<null> {
  popup?: boolean;
  isSuccess?: boolean;
  projectId?: number | null;
}
export const RelaunchCampaign = createSlice({
  name: "RelaunchCampaign",
  initialState: {
    isLoading: false,
    popup: false,
    isSuccess: false,
    projectId: null,
    data: {},
  } as ExtendedInterface,
  reducers: {
    relaunch_Campaign: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_relaunch_campaign: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.popup = false;
      state.isSuccess = true;
    },
    failure_api_call_relaunch_campaign: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.popup = false;
      state.isSuccess = true;
    },
    relaunch_popup: (state, action) => {
      state.popup = Boolean(action.payload);
      if (typeof action.payload === "number") {
        state.projectId = action.payload;
      }
    },
    false_success_relaunch: (state) => {
      state.isSuccess = false;
    },
  },
});
export default RelaunchCampaign.reducer;
export const {
  relaunch_Campaign,
  success_api_call_relaunch_campaign,
  failure_api_call_relaunch_campaign,
  relaunch_popup,
  false_success_relaunch,
} = RelaunchCampaign.actions;
