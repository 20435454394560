import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { FunctionComponent, useEffect } from "react";
import { ICoverForm } from "../interfaces/ComicsPageEdit";
import { Button, TextField } from "@mui/material";
import * as Yup from "yup";
import Select from "../Component/Select";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { list_VariantById } from "../Redux/Reducers/Comics_Booklist/list_Variantlist_slicerBy_ID";
import {
  add_Cover,
  clear_add_Cover,
  close_confirmation_popup,
  close_messagePopup_popup,
  false_addcover_popups,
  open_confirmation_popup,
} from "../Redux/Reducers/Comics_Book_Page/add_Cover_slicer";
import Dialogs from "../Component/Dialog";
import { edit_Cover_Value } from "../Redux/Reducers/Comics_Book_Page/editableCoverValue";
import {
  clear_edit_Cover,
  edit_Cover,
} from "../Redux/Reducers/Comics_Book_Page/edit_Cover_slicer";
import IsLoading from "../Component/isLoading";

interface CoverFormProps {
  formik?: React.RefObject<FormikProps<ICoverForm>>;
}

const CoverForm: FunctionComponent<CoverFormProps> = (props) => {
  const { formik } = props;
  const dispatch = useAppDispatch();

  /*    Redux state   */
  //      variant list
  const { data: listOfVariant, isLoading: listOfVariantIsLoading } =
    useAppSelector((state) => state?.VariantById);

  //      variant cover
  const {
    data: AddCoverData,
    ConfirmationPopup: AddCoverConfirmationPopup,

    MessagePopup: AddCoverMessagePopup,
  } = useAppSelector((state) => state?.AddCover);
  const { data: CoverEditableData } = useAppSelector(
    (state) => state.EditableCoverValue
  );
  const { data: editData } = useAppSelector((state) => state.EditCover);

  /*        initial render       */
  useEffect(() => {
    let projectId = localStorage.getItem("Project_Id");
    dispatch(list_VariantById(projectId));
    return () => {
      dispatch(edit_Cover_Value({}));
      // dispatch(clear_edit_Cover());
      // dispatch(clear_add_Cover());
    };
  }, []);

  const editedValue = Object.keys(CoverEditableData).length !== 0;

  const initialValues: ICoverForm = {
    id: CoverEditableData?.page_id,
    name: CoverEditableData?.page_name ?? null,
    variant_id: CoverEditableData?.variant_id ?? null,
    cover_width: CoverEditableData?.cover_width ?? null,
    cover_height: CoverEditableData?.cover_height ?? null,
  };

  const coverValidation = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    variant_id: Yup.number().required("Please select variant from the list"),
    cover_width: Yup.number()
      .positive("Width should be a positive number")
      .min(0.1, "Width must be greater than or equal to 0")
      .required("Please enter cover width"),
    cover_height: Yup.number()
      .positive("Height should be a positive number")
      .min(0.1, "Height must be greater than or equal to 0")
      .required("Please enter cover_height"),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          dispatch(open_confirmation_popup());
          console.log(values);
        }}
        validationSchema={coverValidation}
        innerRef={formik}
      >
        {(formikProps) => {
          const {
            values,
            handleChange,
            handleBlur,
            touched,
            errors,
            setFieldValue,
            handleSubmit,
          } = formikProps;
          return (
            <Form
              autoComplete="off"
              style={{ width: "clamp(20rem,30vw,30rem)" }}
              onSubmit={handleSubmit}
            >
              <div className="lableInputDiv">
                <b>Name</b>
                <Field
                  as={TextField}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors.name)}
                  helperText={<ErrorMessage name="name" />}
                  fullWidth
                />
              </div>

              <div className="lableInputDiv">
                <b>Variant</b>
                <Select
                  Listitem={
                    listOfVariant.map((val: { name: string; id: number }) => {
                      return { label: val.name, value: val.id };
                    }) ?? []
                  }
                  name="variant_id"
                  value={
                    listOfVariant
                      .map((val: { name: string; id: number }) => {
                        return { label: val.name, value: val.id };
                      })
                      .filter(
                        (val: { label: string; value: number }) =>
                          val.value === values.variant_id
                      )[0] ?? null
                  }
                  onchange={(
                    _: null,
                    value: { label: string; value: number }
                  ) => {
                    setFieldValue("variant_id", value?.value);
                  }}
                  onBlur={handleBlur}
                  error={Boolean(touched.variant_id && errors.variant_id)}
                  helperText={<ErrorMessage name="variant_id" />}
                  loading={listOfVariantIsLoading}
                />
              </div>

              <div className="lableInputDiv">
                <b>Width</b>
                <Field
                  as={TextField}
                  name="cover_width"
                  type="number"
                  value={values.cover_width}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.cover_width && errors.cover_width)}
                  helperText={<ErrorMessage name="cover_width" />}
                  fullWidth
                />
              </div>

              <div className="lableInputDiv">
                <b>Height</b>
                <Field
                  as={TextField}
                  name="cover_height"
                  type="number"
                  value={values.cover_height}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.cover_height && errors.cover_height)}
                  helperText={<ErrorMessage name="cover_height" />}
                  fullWidth
                />
              </div>

              {/*   for enter key submit     */}
              <button type="submit" style={{ display: "none" }} />
            </Form>
          );
        }}
      </Formik>

      {/*       Confirmation popup       */}
      <Dialogs
        open={AddCoverConfirmationPopup}
        setOpen={() => {
          dispatch(close_confirmation_popup());
        }}
        maxWidth="xs"
        sx={{ width: "auto" }}
        Dialog_Content="Are you sure you want to proceed?"
        Dialog_Content_sx={{
          display: "flex",
          justifyContent: "center",
          padding: "1.5em 1.5em 0 !important",
          fontSize: "16px",
        }}
        Dialog_Actions={
          <>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(close_confirmation_popup());
              }}
              sx={{
                width: "110px",
                height: "40px",
                borderRadius: "0px",
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (editedValue) {
                  return dispatch(edit_Cover(formik?.current?.values));
                }

                dispatch(add_Cover(formik?.current?.values));
              }}
              sx={{
                width: "110px",
                height: "40px",
                backgroundColor: "rgb(0, 0, 0)",
                color: "#FFFFFF",
                display: "flex",
                borderRadius: "0px",
              }}
            >
              Yes
            </Button>
          </>
        }
        Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
      />

      {/*     Message popup     */}
      <Dialogs
        open={AddCoverMessagePopup}
        setOpen={() => dispatch(close_messagePopup_popup())}
        maxWidth="xs"
        sx={{ width: "auto" }}
        Dialog_Content_sx={{
          padding: "1.5em 1.5em 0 !important",
          fontSize: "16px",
        }}
        Dialog_Content={
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <div>{editedValue ? editData?.message : AddCoverData?.message}</div>

            {(editedValue ? editData?.status : AddCoverData?.status) ? (
              <img
                src={require("../Pages/Image/success.png")}
                alt="success"
                width={"20%"}
              />
            ) : (
              <>
                {(editedValue ? editData?.message : AddCoverData?.message)
                  ?.toLowerCase()
                  .includes("already exists") ? (
                  // console.log("exisite")
                  <img
                    src={require("../Pages/Image/mdi_file-alert-outline.png")}
                    alt="error"
                    width={"20%"}
                  />
                ) : (
                  <img
                    src={require("../Pages/Image/error.png")}
                    alt="error"
                    width={"20%"}
                  />
                )}
              </>
            )}
          </section>
        }
        Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
        Dialog_Actions={
          <Button
            variant="contained"
            onClick={() => {
              dispatch(false_addcover_popups());
            }}
            sx={{
              width: "110px",
              height: "40px",
              backgroundColor: "rgb(0, 0, 0)",
              color: "#FFFFFF",
              display: "flex",
              borderRadius: "0px",
            }}
          >
            Ok
          </Button>
        }
      />
    </>
  );
};

export default CoverForm;
