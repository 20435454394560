import { useEffect, useState } from "react";
import "./App.css";
import Createpassword from "./Pages/Createpassword";
// import QuestionForm from "./Pages/QuesionForm";
// import QuesionPageForDialog from "./Pages/QuesionPageForDialog";
import Questions from "./Editor/Pages/QuestionsForEditor";
import LoginRoute from "./Routers/LoginRouter";
import { ThemeProvider, createTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "./Redux/store/hooks";
import { list_questionOption } from "./Redux/Reducers/QuestionOption/list_QuestionOption_slicer";
import { List_questionOptions } from "./Redux/Reducers/QuestionOption/QuestionOption_Slicer";
import { List_questionID } from "./Redux/Reducers/QuestionID/QuestionID_Slicer";
import TextQuestion from "./Editor/Pages/TextTypeQuestion";
import Question from "./Pages/Quesion";
import { Add_dropdown } from "./Redux/Reducers/TextOptions/TextOptions_Slicer";
import ImageLibraryForEditor from "./Editor/Pages/ComicsLibrary";
import ErrorDialog from "./Component/ErrorDialog";
import FontLibrary from "./Editor/Pages/FontLibrary";
import ImageUpload from "./Editor/Pages/ImageLibrary";
import { ToastContainer } from "react-toastify";
import Resetpassword from "./Pages/Resetpassword";

// import { useState } from "react";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
    shape: {
      borderRadius: 0,
    },
  });

  const data = useAppSelector((state) => state.TextOptions.extraData);
  const dispatch = useAppDispatch();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ToastContainer />

        {/* <QuesionPageForDialog></QuesionPageForDialog> */}
        <LoginRoute></LoginRoute>
        {/* <Resetpassword></Resetpassword> */}
        {/* <ImageLibraryForEditor /> */}
        {/* <ErrorDialog></ErrorDialog> */}
        {/* <FontLibrary /> */}
        {/* <Createpassword></Createpassword> */}
        {/* <Questions /> */}
        {/* <ImageUpload /> */}
        {/* <TextQuestion /> */}
        {/* <TextDropdownInputC getValue={(v) => console.log(v)} /> */}
        {/* <Popup
          buttonname={"Click Me"}
          buttontitle={"success"}
          // Successmessage
          Errormessage
          Contentpopup={
            "            Thank you for your request. We are working hard to find the best service and deals for you. Shortly you will find a confirmation in your email.  "
          }
          Errorbutton
          // Successbutton
          // controls
          showAlertComponet={() => handleAlertOpen()}
        ></Popup>
        <button
          onClick={() => {
            handleButtonClick();
          }}
        >
          click me
        </button>
        <AlertComponent
          isShowAlert={showAlert}
          onClose={handleAlertClose}
          type={"error"}
          alertmessage={"This is an error alert — check it out!"}
        ></AlertComponent> */}
      </ThemeProvider>
    </div>
  );
}

export default App;
