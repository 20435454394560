import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  failure_api_call_userPermission,
  success_api_call_userPermission,
} from "./list_UserPermission_slicer";
import {
  failure_api_call_add_UserPermission,
  success_api_call_add_UserPermission,
} from "./add_UserPermission_slicer";
import {
  failure_api_call_edit_UserPermission,
  success_api_call_edit_UserPermission,
} from "./edit_UserPermission_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_AnthorUserPermission,
  success_api_call_AnthorUserPermission,
} from "./list_anthoruserpermission";

//add & Edit userPermission
const addUserPermissionCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  // console.log(a.payload);
  const adminId = JSON.parse(localStorage.getItem("id")!);

  const add = a.payload.map((value: any) => {
    return { ...value, updated_by: adminId, created_by: adminId };
  });
  // console.log(add);

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/user_permission/`,
    add,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddUserPermissionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addUserPermissionCall, e);

    yield put(success_api_call_add_UserPermission(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_UserPermission(e));
    yield put(expireSignature(e));
  }
}

//Edit userPermission

const editUserPermissionCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  const adminId = JSON.parse(localStorage.getItem("id")!);
  // console.log("edit");

  const id = a.payload.id;
  const edit = {
    firstname: a.payload.firstname,
    lastname: a.payload.lastname,
    username: a.payload.username,
    phone: a.payload.phone,
    email: a.payload.email,
    role: a.payload.role,
    active: a.payload.active,
    updated_by: adminId,
  };
  // console.log(edit);

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/user_permission/${id}`,
    edit,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* editUserPermissionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editUserPermissionCall, e);
    // console.log(response.data);

    yield put(success_api_call_edit_UserPermission(response.data));
  } catch (e) {
    yield put(failure_api_call_edit_UserPermission(e));
    yield put(expireSignature(e));
  }
}

//list userPermission
const listUserPermissionCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const roleId = `?roleId=${a.payload}`;

  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/user_permission/${
      a.payload ? a.payload !== undefined && roleId : ""
    }`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* listUserPermissionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listUserPermissionCall, e);
    // console.log(response?.data?.data);
    const res = response.data.data.map((value: any, index: number) => {
      return { ...value, id: index };
    });
    // console.log(res);

    yield put(success_api_call_userPermission(res));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_userPermission(e));
    yield put(expireSignature(e));
  }
}

//list AnthoruserPermission
const listAnthorUserPermissionCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const roleId = `?roleId=${a.payload}`;

  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/user_permission/${
      a.payload ? a.payload !== undefined && roleId : ""
    }`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* listAnthorUserPermissionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listAnthorUserPermissionCall, e);
    // console.log(response?.data?.data);
    const res = response.data.data.map((value: any, index: number) => {
      return { ...value, id: index };
    });
    // console.log(res);
    localStorage.setItem("permission", JSON.stringify(response?.data?.data));

    yield put(success_api_call_AnthorUserPermission(res));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_AnthorUserPermission(e));
    yield put(expireSignature(e));
  }
}
