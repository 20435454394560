import { createSlice } from "@reduxjs/toolkit";

export const AddPageQuestions = createSlice({
  name: "AddPageQuestions",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    add_PageQuestions: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_add_PageQuestions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_add_PageQuestions: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      // FAILED(action.payload);
    },
    add_PageQuestions_is_success: (state, action) => {
      state.isSuccess = false;
    },
  },
});
export default AddPageQuestions.reducer;
export const {
  add_PageQuestions,
  success_api_call_add_PageQuestions,
  failure_api_call_add_PageQuestions,
  add_PageQuestions_is_success,
} = AddPageQuestions.actions;
