import { FunctionComponent } from "react";
import Autocompletes from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import React from "react";

//  Autocomplete component

type variant = "outlined" | "standard" | "filled" | undefined;
type color = "primary" | "secondary" | "success" | "error" | "info" | "warning";

type size = "small" | "medium" | undefined;
interface ListitemsOptions {
  label: string | number;
  value: any;
}
interface AutocompleteProps {
  Listitem: ListitemsOptions[];
  width?: any;
  height?: any;
  variant?: variant;
  disabled?: boolean;
  color?: color;
  size?: size;
  style?: React.CSSProperties;
  sx?: React.CSSProperties;
  label?: any;
  multiple?: boolean; // filter?: boolean;
  onchange?: any;
  defaultValue?: ListitemsOptions | ListitemsOptions[] | null;
  name?: string;
  helperText?: any;
  error?: boolean;
  onChange?: any;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  value?: any;
  fullWidth?: boolean;
  labelColor?: string;
  inputColor?: string;
  filter?: boolean;
  checkBox?: boolean;
  disableClearable?: boolean;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  loading?: boolean;
  readOnly?: boolean;
  placeholder?: string;
}

const Select: FunctionComponent<AutocompleteProps> = (props) => {
  const {
    Listitem,
    width,
    height,
    variant,
    disabled,
    color,
    size,
    style,
    label,
    multiple, // filter,
    helperText,
    name,
    onchange,
    defaultValue,
    error,
    onChange,
    value,
    onBlur,
    fullWidth,
    sx,
    labelColor,
    inputColor,
    filter,
    disableClearable,
    checkBox,
    onFocus,
    loading,
    readOnly,
    placeholder,
  } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  // const index = Listitem.findIndex((value) => value.value === defaultValue);

  return (
    <Autocompletes
      clearOnBlur={false}
      disablePortal
      disableClearable={disableClearable ? true : false}
      autoHighlight
      readOnly={readOnly}
      filterSelectedOptions={filter ? true : false}
      onChange={onchange} //getting value on 2nd parameter (e,value)=>{value}
      defaultValue={defaultValue}
      // defaultValue={Listitem[index] || value}
      options={Listitem}
      disabled={disabled ? true : false}
      getOptionLabel={(option: any) => option.label}
      onFocus={onFocus}
      sx={sx}
      onBlur={onBlur}
      value={value}
      loading={loading}
      fullWidth
      // renderOption={
      //   checkBox
      //     ? (props, option, { selected }) => (
      //         <li {...props}>
      //           <Checkbox
      //             icon={icon}
      //             checkedIcon={checkedIcon}
      //             style={{ marginRight: 8 }}
      //             checked={selected}
      //           />
      //           {option.label}
      //         </li>
      //       )
      //
      // }
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          sx={{
            width: "100%",
            height: "100%",
            "& fieldset": {
              borderRadius: "0px",
              paddingBottom: "10px",
            },
          }}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            style: { color: inputColor ? inputColor : "black" },
          }}
          InputLabelProps={{
            style: { color: labelColor ? labelColor : "black" },
          }}
          autoComplete="off"
          label={label}
          variant={variant}
          color={color}
          disabled={disabled ? true : false}
          size={size}
          style={style}
          helperText={helperText}
          error={error}
          onChange={onChange}
          // onBlur={onBlur}
          // value={value}
          fullWidth={fullWidth ? true : false}
        />
      )}
      multiple={multiple ? true : false}
      disableCloseOnSelect={multiple ? true : false}
    />
  );
};

export default React.memo(Select);
