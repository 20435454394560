import { PayloadAction } from "@reduxjs/toolkit";
import Axios, { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import {
  failure_api_call_lulu_Print,
  stop_loader_print,
  success_api_call_lulu_Print,
} from "./LuluPrint_slicer";
import { ICoverPDF, ILuluReprint } from "../../../interfaces/IPDF";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import { IAPIResponse } from "../../../interfaces/IReduxState";
import { FAILED } from "../../../Editor/utills/common";
import {
  failure_api_call_lulu_reprint,
  stop_loader_reprint,
  success_api_call_lulu_reprint,
} from "./Lulu_reprint_slicer";

//      Lulu print
const LuluPrintCall = (action: PayloadAction<ICoverPDF>) => {
  let token = localStorage.getItem("token");
  const urlencoded = new URLSearchParams();
  for (const key in action.payload) {
    urlencoded.append(key, `${action.payload[key]}`);
  }

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/lulu/print-job/create`,
    urlencoded,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
const authFllow = () => {
  let token = localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/lulu/auth`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export function* LuluPrintAPICall(action: PayloadAction<ICoverPDF>) {
  let retryCount = 3;
  while (retryCount > 0) {
    try {
      const response: AxiosResponse<IAPIResponse<null>> = yield call(
        LuluPrintCall,
        action
      );
      if (
        response.data.status_code === 400 ||
        response.data.message?.includes("Signature has expired")
      ) {
        yield call(authFllow);
        retryCount--;
      } else {
        yield put(success_api_call_lulu_Print(response.data));
        yield put(stop_loader_print(action.payload));
        break;
      }
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.detail?.includes("Signature has expired")) {
        yield call(authFllow);
        retryCount--;
      } else {
        yield put(failure_api_call_lulu_Print(error));
        yield put(expireSignature(error));
        yield put(stop_loader_print(action.payload));
        break;
      }
    }
  }
  if (retryCount === 0) {
    yield put(stop_loader_print(action.payload));
    FAILED("Failed with maximum attempts");
  }
}

//      Lulu reprint
const LuluRePrintCall = (action: PayloadAction<ILuluReprint>) => {
  let token = localStorage.getItem("token");
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/lulu/reprint-job/create`,
    action.payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/x-www-form-urlencoded",
      },
    }
  );
};

export function* LuluRePrintAPICall(action: PayloadAction<ILuluReprint>) {
  let retryCount = 3;
  while (retryCount > 0) {
    try {
      const response: AxiosResponse<IAPIResponse<null>> = yield call(
        LuluRePrintCall,
        action
      );
      if (
        response.data.status_code === 400 ||
        response.data.message?.includes("Signature has expired")
      ) {
        yield call(authFllow);
        retryCount--;
      } else {
        yield put(success_api_call_lulu_reprint(response.data));
        yield put(stop_loader_reprint(action.payload));
        break;
      }
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.detail?.includes("Signature has expired")) {
        yield call(authFllow);
        retryCount--;
      } else {
        yield put(failure_api_call_lulu_reprint(error));
        yield put(expireSignature(error));
        yield put(stop_loader_reprint(action.payload));
        break;
      }
    }
  }
  if (retryCount === 0) {
    yield put(stop_loader_reprint(action.payload));
    FAILED("Failed with maximum attempts");
  }
}
