import { createSlice } from "@reduxjs/toolkit";

export const DeletePageQuestions = createSlice({
  name: "DeletePageQuestions",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    delete_PageQuestions: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_delete_PageQuestions: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_delete_PageQuestions: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      // FAILED(action.payload);
    },
    delete_PageQuestions_is_success: (state, action) => {
      state.isSuccess = false;
    },
  },
});
export default DeletePageQuestions.reducer;
export const {
  delete_PageQuestions,
  success_api_call_delete_PageQuestions,
  failure_api_call_delete_PageQuestions,
  delete_PageQuestions_is_success,
} = DeletePageQuestions.actions;
