import { createSlice } from "@reduxjs/toolkit";

export const editRole = createSlice({
  name: "editRole",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    edit_role: (state) => {
      state.isLoading = true;
    },
    success_api_call_edit_role: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_edit_role: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      // FAILED(action.payload);
    },
    edit_role_is_success: (state) => {
      state.isSuccess = false;
    },
    edit_role_complete_api: (state) => {
      state.api_completed = false;
    },
  },
});
export default editRole.reducer;
export const {
  edit_role,
  success_api_call_edit_role,
  edit_role_complete_api,
  failure_api_call_edit_role,
  edit_role_is_success,
} = editRole.actions;
