import { FunctionComponent } from "react";
import EditIcon from "@mui/icons-material/Edit";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import "./Page.css";
import { Backdrop, CircularProgress } from "@mui/material";
import Grids from "../Component/Grides";
import DialogQuestion from "../Component/DialogForFormQuestion ";
import { list_Projectcategory } from "../Redux/Reducers/Project Category/list_ProjectCategory_slicer";
import { edit_PageSize } from "../Redux/Reducers/Page Size/edit_PageSize_slicer";
import { list_PageSize } from "../Redux/Reducers/Page Size/list_PageSize_slicer";
import { add_PageSize } from "../Redux/Reducers/Page Size/add_PageSize_slicer";
import DialogQuestionPageSize from "../Component/DialogForFormPageSize";
import { edit_Variant } from "../Redux/Reducers/Variant/edit_Variant_slicer";
import { list_Variant } from "../Redux/Reducers/Variant/list_Variant_slicer";
import { add_Variant } from "../Redux/Reducers/Variant/add_Variant_slicer";
import DialogQuestionVariant from "../Component/DialogForFormVariant";
import IsLoading from "../Component/isLoading";

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

interface VariantProps {}

const Variantform: FunctionComponent<any> = (props: any) => {
  const dispatcher = useAppDispatch();
  const [closeDio, setCloseDio] = React.useState(true);
  const nav = useNavigate();
  const breadcrumbs = [
    // <Typography key="3" color="text.primary" style={{ display: "flex" }}>
    <Link key="1" color="black" underline="none">
      <b className="heading">Masters</b>
    </Link>,

    // <Typography key="3" color="text.primary">
    <Link
      underline="none"
      key="1"
      color="inherit"
      onClick={() => nav("/variantname")}
      style={{
        cursor: "pointer",
        fontWeight: "500",
        fontSize: "15px ",
        lineHeight: "16px ",
        color: "#BDBDBD",
      }}
    >
      Variant
    </Link>,

    // <Typography key="3" color="text.primary">
  ];

  //    datas of user role
  const data = useAppSelector((state) => state?.Variant?.data);

  const isLoading = useAppSelector((state) => state?.Variant?.isLoading);
  const addData: any = useAppSelector((state) => state?.AddVariant?.data);
  const addDataIsLoading = useAppSelector(
    (state) => state?.AddVariant?.isLoading
  );

  const editData = useAppSelector((state) => state?.editVariant?.data);

  const editDataIsLoading = useAppSelector(
    (state) => state?.editVariant?.isLoading
  );
  const listdata = data.map((datalist: any) => {
    return {
      ...datalist,
      status: datalist.status === true ? "Active" : "Inactive",
    };
  });
  //    userpermission menuList
  const pageName = "Masters / Variant";
  const menuList: any = JSON.parse(
    localStorage?.getItem("permission") as string
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageName;
  });

  const MenuOfUserpermission = menuList[index];

  const closeFunction = (e: boolean) => {
    // console.log("Sfd", e);

    setCloseDio(false);
  };

  const column = [
    // { headerName: , field: ,  },
    {
      sortable: false,
      field: "field_id",
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          S.No
        </p>
      ),
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
      // renderCell: (params: GridRenderCellParams<any>) =>
      //   params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Name
        </p>
      ),
      field: "name",
      flex: 1,
      align: "left",
      headerAlign: "center",
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Description
        </p>
      ),
      field: "description",
      align: "left",
      flex: 1,
      headerAlign: "center",
    },
    {
      headerName: (
        <p
          className="headingnamebold"
          style={{ color: "black", fontWeight: "bolder" }}
        >
          Status
        </p>
      ),
      field: "status",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    // MenuOfUserpermission?.edit
    true
      ? {
          headerName: (
            <p
              className="headingnamebold"
              style={{ color: "black", fontWeight: "bolder" }}
            >
              Action
            </p>
          ),
          field: "Action",
          flex: 1,
          type: "actions",
          renderCell: (params: any) => {
            return (
              <>
                <DialogQuestionVariant
                  // Dialogcontenttext
                  // getValues={(v: any) => console.log(v)}
                  successMessage={editData}
                  isLoading={editDataIsLoading}
                  Buttondialog={["edit"]}
                  Dialogtitle={"Edit Variant"}
                  getValues={(v: any) => {
                    dispatcher(edit_Variant(v));
                    // console.log(values);
                  }}
                  placeholder="Role"
                  sx={{
                    backgroundColor: "transparent",
                    minWidth: "0",
                    padding: "0",
                    borderRadius: "50%",
                    height: "60%",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  edit={
                    // params.row
                    {
                      ...params.row,
                      status: params.row.status === "Active" ? true : false,
                    }
                  }
                  Buttonname={
                    <EditIcon
                      sx={{
                        backgroundColor: "#F5F5F5",
                        color: "black",
                        height: "1.3em",
                        width: "1.3em",
                        padding: "15%",
                        borderRadius: "50%",
                        "&:hover": {
                          color: "#F5F5F5",
                          backgroundColor: "black",
                        },
                      }}
                      onClick={() => console.log(params.row)}
                    />
                  }
                />
              </>
            );
          },
        }
      : {
          flex: 1,
          type: "actions",
          hide: true,
          display: false,
          renderCell: (params: any) => {
            return null;
          },
        },
  ];

  // console.log(load);

  React.useEffect(() => {
    dispatcher(list_Variant(""));
    // console.log(editData);
  }, [addData, editData]);

  React.useEffect(() => {
    dispatcher(list_Projectcategory(""));
    // axios
  }, []);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <IsLoading isLoading={isLoading} />

      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <Grids
        row={listdata}
        column={column}
        // Users={"Variant"}
        open
        pagination
        dialog={
          MenuOfUserpermission?.add && (
            <DialogQuestionVariant
              // error={errorMessage}
              successMessage={addData}
              isLoading={addDataIsLoading}
              sx={{
                padding: "15px",
                backgroundColor: "black",
                color: "white",
                width: "160px",
                height: "37px",
                borderRadius: "0px",
                border: "1px solid #BDBDBD",
                "&:hover": { backgroundColor: "black" },
              }}
              placeholder="Role"
              getValues={(v: any) => {
                dispatcher(add_Variant(v));
                // console.log(v);
              }}
              Buttondialog={["Cancel"]}
              Dialogtitle={"Add Variant"}
              Buttonname={"Add Variant"}
            />
          )
        }
        Userpermissions={undefined}
      ></Grids>
    </div>
  );
};

export default Variantform;
