import { FunctionComponent } from "react";
import "./Page.css";
import Loginvalidation from "./Loginvalidation";
import LoginImage from "./LoginImage";
import ResetpasswordForm from "./ResetpasswordForm";

interface ResetpasswordProps {}

const Resetpassword: FunctionComponent<ResetpasswordProps> = () => {
  return (
    <div className="Login">
      <div className="LoginImage">
        <LoginImage></LoginImage>
      </div>
      <div className="LoginImages">
        <ResetpasswordForm></ResetpasswordForm>
      </div>
    </div>
  );
};

export default Resetpassword;
