import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";

export const Textreplace = createSlice({
  name: "Textreplace",
  initialState: {
    isLoading: false,
    data: {},
  } as IReduxState<string>,
  reducers: {
    addTextreplace: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_textreplace: (state, action: PayloadAction<any>) => {
      console.log(action.payload);
      state.data = action.payload;
      state.isLoading = false;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_textreplace: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      // FAILED(action.payload);
    },
  },
});
export default Textreplace.reducer;
export const {
  addTextreplace,
  success_api_call_textreplace,
  failure_api_call_textreplace,
} = Textreplace.actions;
