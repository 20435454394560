import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_list,
  success_api_call_list,
} from "./list_Variantlist_slicer";
import {
  failure_api_call_list_VariantById,
  success_api_call_list_VariantById,
} from "./list_Variantlist_slicerBy_ID";

//list //list PageSize
const listVariantCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/variant/list`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export function* listsVariantApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listVariantCall, e);

    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      // active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    yield put(success_api_call_list(res));
  } catch (e) {
    yield put(failure_api_call_list(e));
    yield put(expireSignature(e));
  }
}
//list VariantById
const listVariantByIdCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  console.log(a.payload);

  const id = new FormData();
  id.append("project_id", a.payload);

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/variant/project_id`,
    id,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export function* listVariantByIdApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listVariantByIdCall, e);

    yield put(success_api_call_list_VariantById(response?.data?.data));
  } catch (e) {
    yield put(failure_api_call_list_VariantById(e));
    yield put(expireSignature(e));
  }
}
