import { FunctionComponent, useEffect, useRef, useState } from "react";
import "../Pages/ImageUploadedPopup.css";
import { useAppDispatch, useAppSelector } from "../../Redux/store/hooks";
import { clearExtraData } from "../../Redux/Reducers/TextOptions/TextOptions_Slicer";
import {
  AutoScaleBool,
  Jsonid_Dropdown,
  textUpdate,
} from "../../Redux/Reducers/Editor_value";
import { useSelector } from "react-redux";
import "../editor.css";
import line from "../accets/textlineborder.svg";
import { cleartextOptionsList } from "../../Redux/Reducers/TextOptions/textOptionsList_Slicer";
import {
  IListOfTextTypeQuestions,
  TextOptionAPIResponse,
} from "../../interfaces/ITextTypeOptions";
import { update_questionIds } from "../../Redux/Reducers/TextOptions/listOfTextOption_Slicer";
import CheckboxLabels from "../../Component/CheckboxComponent";
import { Text_Question_By_Id } from "../../Redux/Reducers/QuestionOption/QuestionOption_Slicer";

interface TextQuestionProps {}

const TextQuestion: FunctionComponent<TextQuestionProps> = () => {
  const dispatch = useAppDispatch();
  const keyword: any = useAppSelector((state) => state.TextOptions.data);

  const [text, setText] = useState(keyword[0] ? keyword[0].keyword : "");

  //    List of questions
  const [question, setQuestion] = useState<{ label: string; value: number }[]>(
    []
  );

  const [data, setData] = useState<any>(false);
  const [value, setValue] = useState<any>();
  // const handleKeyDown = (event: any) => {
  //   // console.log("event", event.key);

  //   if (event.key === "Enter") {
  //     console.log("eventtext", text);
  //     if (text) {
  //       dispatch(Enter_Input(text));
  //       dispatch(textUpdate("updated"));
  //       dispatch(Edit_text(""));
  //       dispatch(clickCurve(false));
  //       dispatch(
  //         Jsonid_Dropdown([{ dropdownid: value.id, questionid: value.value }])
  //       );
  //       dispatch(Screen_render("true"));
  //     }
  //   }
  // };
  // const oncl = () => {
  //   dispatch(Enter_Input(text));
  // };
  // useEffect(() => {
  //   dispatch(Enter_Input(text));

  //   // setText("");
  // }, [text]);
  // dispatch(update_questionIds([]));
  /*    list of text questions id for initially checking the checkbox    */
  const listOfQuestionIds = useAppSelector(
    (state) => state.TextOption.questionIds
  );
  const checkEditPage = useSelector((state: any) => state.DetailEdit);
  //  text question update table
  // useEffect(() => {
  //   if (checkEditPage.layer_id && listOfQuestionIds.length > 0) {
  //     const ff = {
  //       question_id: JSON.stringify(listOfQuestionIds),
  //       layerid: checkEditPage.layer_id,
  //     };
  //     dispatch(Text_Question_By_Id(ff));
  //   }
  // }, [listOfQuestionIds]);
  // useEffect(() => {
  //   setAuto_scale(checkEditPage.autoscalebool_return);
  //   console.log(
  //     "checkEditPage.autoscalebool_return",
  //     checkEditPage.autoscalebool_return
  //   );
  // }, [checkEditPage.autoscalebool_return]);
  // const [textquesId, setTextquesId] = useState<any>(listOfQuestionIds);
  // useEffect(() => {
  //   setTextquesId(listOfQuestionIds);
  // }, [listOfQuestionIds]);

  // console.log("listOfQuestionIds", listOfQuestionIds);
  // console.log("textquesId", textquesId);

  /*    text type questions in dropdown    */
  const TextOption = useAppSelector((state) => state.TextOption.data);
  const TextOptionIsLoading = useAppSelector(
    (state) => state.TextOption.isLoading
  );

  const TextOptionsIsLoading = useAppSelector(
    (state) => state.TextOptions.isLoading
  );

  //    modify for autocomplete
  const modifyTextOption = (TextOption?.data ?? []).map((question) => ({
    label: question.question,
    value: question.id,
  }));
  const dropdown = useAppSelector((state) => state.TextOptions.extraData);
  const dropdownIsLoading = useAppSelector(
    (state) => state.TextOptions.extraDataIsloading
  );

  /*    list of option by layer id     */
  const listOfOption: TextOptionAPIResponse | undefined = useAppSelector(
    (state) => state.TextOptionsList.data
  );

  // console.log("keyword ", keyword);
  const obj = () => {
    if (checkEditPage.isCurve) {
      dispatch(textUpdate(""));
    } else {
      dispatch(textUpdate("updated"));
    }
  };
  useEffect(() => {
    //    set options
    setQuestion(modifyTextOption);
    // dispatch(listDropdownById(132));
    return () => {
      dispatch(clearExtraData());
      dispatch(cleartextOptionsList());
    };
  }, [TextOption]);
  const [auto_scale, setAuto_scale] = useState(false);
  const handleAutoScaleToggle = () => {
    console.log("checkbox text");

    setAuto_scale(auto_scale ? false : true);

    dispatch(AutoScaleBool(auto_scale ? false : true));
  };

  useEffect(() => {
    setAuto_scale(checkEditPage.autoscalebool_return);
    console.log("autoscalebool_return", checkEditPage.autoscalebool_return);
  }, [checkEditPage.autoscalebool_return, checkEditPage.layer_id]);
  return (
    <>
      {/* <div style={{ color: "#ffffff" }}> */}
      <div style={{ color: "#ffffff" }}>
        <div
          className="texttoolalign"
          style={{ fontSize: "16px", marginBlock: "15px" }}
        >
          Text Questions{" "}
        </div>
        <div style={{ width: "40%", marginLeft: "20px" }}>
          <CheckboxLabels
            sx={{
              whiteSpace: "pre-wrap",
              background: "white",
              color: "black",
            }}
            checked={auto_scale}
            size="medium"
            label={"Autoscale"}
            onClick={handleAutoScaleToggle}
          />
        </div>
        <img src={line} alt="invalid" width={"100%"} />
      </div>

      {Object.keys(TextOption!).length !== 0 && !dropdownIsLoading && (
        <table>
          <thead>
            <tr>
              <th
                style={{
                  maxWidth: "100px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                Question
              </th>
              <th>Keyword</th>
              <th>Length</th>
            </tr>
          </thead>
          <tbody>
            {TextOption?.data?.length !== 0 ? (
              <>
                {(TextOption!.data ?? []).map(
                  (option: IListOfTextTypeQuestions) => {
                    return (
                      <tr key={option.id}>
                        <td
                          style={{
                            maxWidth: "100px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <CheckboxLabels
                            sx={{ whiteSpace: "pre-wrap" }}
                            checked={listOfQuestionIds.includes(option.id)}
                            size="small"
                            label={option?.question ?? "---"}
                            onClick={() => dispatch(Jsonid_Dropdown(option.id))}
                          />
                        </td>
                        <td>{option?.keyword ?? "---"}</td>
                        <td>{option?.length ?? "---"}</td>
                      </tr>
                    );
                  }
                )}
              </>
            ) : (
              <tr>
                <td colSpan={3}>No questions available</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TextQuestion;
