import { FunctionComponent, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { useNavigate } from "react-router-dom";
import { expireSignature } from "../Redux/Reducers/ExpireSignature/ExpireSignature_slicer";
import { useAppDispatch } from "../Redux/store/hooks";
interface ErrorDialogProps {}

const ErrorDialog: FunctionComponent<ErrorDialogProps> = (props) => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(expireSignature(null));
  }, []);

  //  ExpireSignature data
  // const ExpireSignature: any = useAppSelector(
  //   (state) => state.ExpireSignature.data
  // );

  //    Logout functionality
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("firstname");
    localStorage.removeItem("role_id");
    localStorage.removeItem("permission");
    localStorage.clear();

    if (!localStorage.getItem("token")) {
      nav("/login");
    }
  };
  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            fontSize: "2rem",
          }}
          id="alert-dialog-title"
        >
          {/* {ExpireSignature?.response?.data?.detail ?? "Invalid Credentials"} */}
          Session Expired
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={logout} autoFocus sx={{ color: "#C00A30" }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ErrorDialog;
