import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";

interface ExtendState extends IReduxState<null> {
  popup: boolean;
}
export const DeleteCampaign = createSlice({
  name: "DeleteCampaign",
  initialState: {
    isLoading: false,
    data: {},
    popup: false,
  } as ExtendState,
  reducers: {
    delete_Campaign: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_delete_Campaign: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.popup = true;
    },
    failure_api_call_delete_Campaign: (state, action) => {
      state.isLoading = false;
      state.popup = true;
      state.data = action.payload;
    },
  },
});
export default DeleteCampaign.reducer;
export const {
  delete_Campaign,
  success_api_call_delete_Campaign,
  failure_api_call_delete_Campaign,
} = DeleteCampaign.actions;
