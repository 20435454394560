import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  failure_api_call_Question,
  success_api_call_Question,
} from "./list_Question_slicer";
import {
  failure_api_call_add_Question,
  success_api_call_add_Question,
} from "./add_Question_slicer";
import {
  failure_api_call_edit_Question,
  success_api_call_edit_Question,
} from "./edit_Question_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import { IDeleteQuestion, Options } from "../../../interfaces/IQuestion";
import { PayloadAction } from "@reduxjs/toolkit";
import { failureDeletion, successDeletion } from "./delete_Question_slice";

//add Question
const addQuestionCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  // console.log(a.payload);
  const adminId = JSON.parse(localStorage.getItem("id")!);
  const add = {
    ...a.payload,
    project_id: parseInt(localStorage.getItem("Project_Id")!),
    updated_by: adminId,
    created_by: adminId,
  };
  Object.keys(add).forEach((key) => {
    if (add[key] === 0 || add[key] === "") {
      delete add[key];
    }
  });

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/project_questions/`,
    add,

    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
//add Image Options
const addImages = async (a: any) => {
  let token = await localStorage.getItem("token");
  console.log(a);

  return Axios.post(`${process.env.REACT_APP_api_url}/v1/api/options/`, a, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* AddQuestionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addQuestionCall, e);
    console.log(response);

    if (response.data.data) {
      const formData = new FormData();
      const options = e.payload.Options;
      console.log(options);

      const arrayOfOption = options?.map((option: Options) => ({
        ...option,
        option_id: option?.question_id ?? null,
      }));

      console.log(arrayOfOption);

      formData.append("question_id", response.data.data.id);
      formData.append("options", JSON.stringify(arrayOfOption));
      formData.append("q_type", e.payload.q_type);
      if (e.payload.q_type === "I") {
        options.forEach((option: { options: string; image_urls: File }) => {
          formData.append("image_urls", option?.image_urls);
        });
      }
      // const ImageResponse: AxiosResponse = yield call(addImages, formData);
      yield call(addImages, formData);
    }
    yield put(success_api_call_add_Question(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_Question(e));
    yield put(expireSignature(e));
  }
}

//Edit  Question

const editQuestionCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  const adminId = JSON.parse(localStorage.getItem("id")!);

  // const id = a.payload.id;
  const edit = {
    ...a.payload,
    project_id: parseInt(localStorage.getItem("Project_Id")!),
    updated_by: adminId,
    created_by: adminId,
  };
  Object.keys(edit).forEach((key) => {
    if (edit[key] === 0 || edit[key] === "") {
      delete edit[key];
    }
  });
  // console.log(edit);

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/project_questions/`,
    edit,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
//edit Image Options
const editImages = async (a: any) => {
  let token = await localStorage.getItem("token");
  console.log(a);

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/options/`,
    a,

    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export function* editQuestionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editQuestionCall, e);
    // console.log(response.data);
    if (response.data.data) {
      const formData = new FormData();
      const options = e.payload.Options;
      console.log(options);

      const arrayOfOption = options?.map((option: Options) => ({
        ...option,
        option_id: option?.option_id ?? null,
      }));

      formData.append("question_id", response.data.data.id);
      formData.append("options", JSON.stringify(arrayOfOption));
      formData.append("q_type", e.payload.q_type);
      if (e.payload.q_type === "I") {
        options.forEach((option: { options: string; image_urls: File }) => {
          formData.append("image_urls", option?.image_urls);
        });
      }
      // const ImageResponse: AxiosResponse = yield call(addImages, formData);
      yield call(editImages, formData);
    }
    yield put(success_api_call_edit_Question(response.data));
  } catch (e) {
    yield put(failure_api_call_edit_Question(e));
    yield put(expireSignature(e));
  }
}

//list role
const listQuestionCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);
  // const list = {
  //   project_id: a.payload,
  // };
  // console.log("payload", a.payload);
  const list = new FormData();
  list.append("project_id", a.payload);

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/project_questions/project_id`,
    list,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* listQuestionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listQuestionCall, e);
    console.log("res", response);

    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      // active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    yield put(success_api_call_Question(res));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_Question(e));
    yield put(expireSignature(e));
  }
}

/*    Delete Questions     */
const deleteQuestionCall = (action: PayloadAction<IDeleteQuestion>) => {
  const token = localStorage.getItem("token");
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/project_questions/`,
    { params: action.payload, headers: { Authorization: `Bearer ${token}` } }
  );
};

export function* deleteQuestionApi(action: PayloadAction<IDeleteQuestion>) {
  try {
    const response: AxiosResponse = yield call(deleteQuestionCall, action);
    yield put(successDeletion(response.data));
  } catch (error) {
    yield put(failureDeletion(error));
    yield put(expireSignature(error));
  }
}
