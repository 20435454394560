import { createSlice } from "@reduxjs/toolkit";

export const Variant = createSlice({
  name: "Variant",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_Variant: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_Variant: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_Variant: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    Variant_is_first: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default Variant.reducer;
export const {
  list_Variant,
  success_api_call_Variant,
  failure_api_call_Variant,
  Variant_is_first,
} = Variant.actions;
