import { createSlice } from "@reduxjs/toolkit";

export const listVariant = createSlice({
  name: "listVariant",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_listVariant: (state, action?) => {
      state.isLoading = true;
    },
    success_api_call_list: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_list: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    list_is_first: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default listVariant.reducer;
export const {
  list_listVariant,
  success_api_call_list,
  failure_api_call_list,
  list_is_first,
} = listVariant.actions;
