//    For Table rows
import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  success_api_call_questionOption,
  failure_api_call_questionOption,
} from "./list_QuestionOption_slicer";
import {
  success_api_call_add_QuestionOption,
  failure_api_call_add_QuestionOption,
} from "./add_QuestionOption_slicer";
// import {
//   failure_api_call_edit_questionOption,
//   success_api_call_edit_questionOption,
// } from "./edit_QuestionOption_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  Failure_Text_Question_By_Id,
  Failure_get_Question_By_Id,
  Success_Text_Question_By_Id,
  Success_get_Question_By_Id,
} from "./QuestionOption_Slicer";

//add questionOption
const addQuestionOptionCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  // console.log(a.payload);
  const adminId = JSON.parse(localStorage.getItem("id")!);
  const add = {
    name: a.payload.name,
    active: a.payload.active,
    updated_by: adminId,
    created_by: adminId,
  };

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/question_options/`,
    add,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddQuestionOptionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addQuestionOptionCall, e);

    yield put(success_api_call_add_QuestionOption(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_QuestionOption(e));
    yield put(expireSignature(e));
  }
}

//     getQuestionById
const getQuestionByIdCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);
  // console.log(a.payload);
  const getData = {
    project_id: parseInt(localStorage.getItem("Project_Id")!),
    question_ids: a.payload.question_id,
    layer_id: a.payload.layerid,
    page_id: parseInt(localStorage.getItem("Page_Id")!),
  };

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/question_image_matrix/matrix`,
    getData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* getQuestionByIdApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getQuestionByIdCall, e);
    // console.log(response.data);

    yield put(Success_get_Question_By_Id(response.data));
  } catch (e) {
    console.log(e);
    yield put(Failure_get_Question_By_Id(e));
    yield put(expireSignature(e));
  }
}

// textlayer id
const TextQuestionByIdCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);
  // console.log(a.payload);
  const getData = {
    project_id: parseInt(localStorage.getItem("Project_Id")!),
    question_ids: a.payload.question_id,
    layer_id: a.payload.layerid,
  };

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/txt_ques_list/create`,
    getData, // This is the data you want to send in the request body
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* TextQuestionByIdApi(e: any) {
  try {
    const response: AxiosResponse = yield call(TextQuestionByIdCall, e);
    // console.log(response.data);

    yield put(Success_Text_Question_By_Id(response.data));
  } catch (e) {
    console.log(e);
    yield put(Failure_Text_Question_By_Id(e));
    yield put(expireSignature(e));
  }
}
//Edit questionOption

// const editQuestionOptionCall = async (a: any) => {
//   let token = await localStorage.getItem("token");

//   const adminId = JSON.parse(localStorage.getItem("id")!);

//   // const id = a.payload.id;
//   const edit = {
//     id: a.payload.id,
//     name: a.payload.name,
//     active: a.payload.active,
//     updated_by: adminId,
//   };
//   // console.log(edit);

//   return Axios.put(`${process.env.REACT_APP_api_url}/v1/api/questionOption`, edit, {
//     headers: { Authorization: `Bearer ${token}` },
//   });
// };
// export function* editQuestionOptionApi(e: any) {
//   try {
//     const response: AxiosResponse = yield call(editQuestionOptionCall, e);
//     // console.log(response.data);

//     yield put(success_api_call_edit_questionOption(response.data));
//   } catch (e) {
//     yield put(failure_api_call_edit_questionOption(e));
//     yield put(expireSignature(e));
//   }
// }

//list questionOption
const listQuestionOptionCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/question_options/`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* listQuestionOptionApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listQuestionOptionCall, e);
    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    yield put(success_api_call_questionOption(res));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_questionOption(e));
    yield put(expireSignature(e));
  }
}
