import { createSlice } from "@reduxjs/toolkit";

export const Pagesizelist = createSlice({
  name: "Pagesizelist",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_Pagesizelist: (state, action) => {
      console.log("list", action.payload);

      state.isLoading = true;
      // console.log("list page", list_PageSizelist);
    },
    success_api_call_Pagesizelist: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_Pagesizelist: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    Pagesizelist_is_first: (state, action) => {
      state.isFirst = false;
    },
  },
});
export default Pagesizelist.reducer;
export const {
  list_Pagesizelist,
  success_api_call_Pagesizelist,
  failure_api_call_Pagesizelist,
  Pagesizelist_is_first,
} = Pagesizelist.actions;
