// Text based sub tools
import line from "../accets/textlineborder.svg";
import curveText from "../accets/curvetext.png";
import align1 from "../accets/align1.svg";
import align2 from "../accets/align2.svg";
import align3 from "../accets/align3.svg";
import align4 from "../accets/align4.svg";
import align5 from "../accets/align5.svg";
import align6 from "../accets/align6.svg";
import curve from "../accets/Vector 300.svg";
import angle from "../accets/Vector 299.svg";
// import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import NumberInput from "react-number-input";

import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Capital,
  Enter_Input,
  Small,
  clickCurve,
  curveinput,
  curving,
  flipCurve,
  fontSize,
  textAlignment,
  textBold,
  textUpdate,
  textcolor,
  textitalic,
  textunderline,
  Xaxis,
  Yaxis,
  Angle,
  Width,
  Height,
  clickCurveextra,
  Layer_name,
  Line,
  Space,
} from "../../Redux/Reducers/Editor_value";
import { useSelector } from "react-redux";
import Select from "../../Component/Select";
import TextQuestion from "../Pages/TextTypeQuestion";
import FontLibrary from "../Pages/FontLibrary";
import TextField from "@mui/material/TextField";

const ToolPage = () => {
  const dispatch = useDispatch();
  const checkEditPage = useSelector((state: any) => state.DetailEdit);
  const [text, setText] = useState("Default Text");
  const [zoomtext, setzoomText] = useState(false);
  const [zoomitalic, setzoomitalic] = useState(false);
  const defaultColor = checkEditPage.isselecclr;
  const [xActive, setXActive] = useState(false);
  const [yActive, setYActive] = useState(false);
  const [angleActive, setAngleActive] = useState(false);
  const [autohght, setAutohght] = useState(false);

  const [textColor, setTextColor] = useState(checkEditPage.isselecclr);

  // const [textColor, setTextColor] = useState(""); // Initial text color is set to red
  const [curve, setCurve] = useState(false);
  const [isCapitalized, setIsCapitalized] = useState(
    checkEditPage.capitalreply
  );

  const [flip, setFlip] = useState(checkEditPage.isupdateFlip);
  const [curvings, setCurvings] = useState<any>(checkEditPage.isUpdateCurving);
  const [count, setCount] = useState<any>();
  const [x_axis, setX_axis] = useState<any>();
  const [y_axis, setY_axis] = useState<any>();
  const [angles, setAngle] = useState<any>();
  const [width, setWidth] = useState<number>();
  const [linee, setLine] = useState<any>();
  const [height, setHeight] = useState<number>();
  const [space, setSpace] = useState<any>();
  const [name, setName] = useState<any>("");

  const zoom = () => {
    setTimeout(() => {
      setzoomText(false);
      setzoomitalic(false);
    }, 100);
  };
  useEffect(() => {
    setCurve(checkEditPage.isobjectvalue != "group" ? false : true);
  }, [checkEditPage.isobjectvalue]);
  const Xaxiss = (event: any) => {
    const newValue = parseFloat(event.target.value); // Parse to float and add 0.10
    setX_axis((prevXAxis: any) => Math.ceil(newValue));
  };
  const Yaxiss = (event: any) => {
    const newValue = parseFloat(event.target.value); // Parse to float and add 0.10

    const roundedValue = Number.isInteger(newValue)
      ? newValue
      : Math.ceil(newValue);
    setY_axis((prevXAxis: any) => roundedValue);
  };
  const Fontsize = (event: any) => {
    const newValue = parseInt(event.target.value);
    setCount(newValue); //new add
  };
  const Widthh = (event: any) => {
    const newValue = parseInt(event.target.value, 10);
    setWidth(newValue);
  };
  const Linee = (event: any) => {
    const newValue = parseFloat(event.target.value); // Parse to float and add 0.10

    setLine(newValue.toFixed(2));
  };
  const Heightt = (event: any) => {
    const newValue = +event.target.value.replace(/\D/g, "");

    setHeight(newValue);
  };
  const spacee = (event: any) => {
    const newValue = parseInt(event.target.value, 10);
    dispatch(Space(newValue));

    setSpace(newValue);
  };
  const Angles = (event: any) => {
    let newValue = parseFloat(event.target.value);
    if (isNaN(newValue)) {
      newValue = 0;
    } else if (newValue < 0) {
      newValue = (newValue + 360) % 361;
    } else if (newValue > 0) {
      newValue = newValue % 361;
    }
    setAngle(Math.ceil(newValue));
  };
  // console.log("tools", checkEditPage.x_return, checkEditPage.angle_return);

  useEffect(() => {
    setCurvings(checkEditPage.isUpdateCurving);
  }, [checkEditPage.isUpdateCurving]);
  useEffect(() => {
    if (!xActive) {
      setX_axis(checkEditPage.x_return);
      // console.log(checkEditPage.x_return);
    }
  }, [checkEditPage.x_return, xActive]);
  useEffect(() => {
    if (!yActive) {
      setY_axis(checkEditPage.y_return);
    }
  }, [checkEditPage.y_return, yActive]);

  useEffect(() => {
    setCount(checkEditPage.isfontselectedvalue);
  }, [checkEditPage.isfontselectedvalue]);
  useEffect(() => {
    if (!angleActive) {
      setAngle(checkEditPage.angle_return);
    }
  }, [checkEditPage.angle_return, angleActive]);
  useEffect(() => {
    setWidth(checkEditPage.width_return);
  }, [checkEditPage.width_return]);
  useEffect(() => {
    setLine(checkEditPage.line_return);
  }, [checkEditPage.line_return]);
  useEffect(() => {
    if (!autohght) {
      setHeight(checkEditPage.height_return);
    }
  }, [checkEditPage.height_return, autohght]);
  useEffect(() => {
    setSpace(checkEditPage.spacereturn);
  }, [checkEditPage.spacereturn]);
  useEffect(() => {
    dispatch(textcolor(textColor));
    // setTextColor("");
  }, [textColor]);

  useEffect(() => {
    if (count) {
      dispatch(fontSize(count));
    }
    if (angles) {
      dispatch(Angle(angles));
    }
    if (width) {
      dispatch(Width(width));
    }
    if (height) {
      dispatch(Height(height));
    }
    if (linee) {
      dispatch(Line(linee));
    }
  }, [count, angles, width, height, linee]);
  useEffect(() => {
    if (x_axis == 0) {
      dispatch(Xaxis(0));
    } else if (x_axis) {
      dispatch(Xaxis(x_axis));
    }
  }, [x_axis]);
  useEffect(() => {
    if (y_axis == 0) {
      dispatch(Yaxis(0));
    } else if (y_axis) {
      dispatch(Yaxis(y_axis));
    }
  }, [y_axis]);
  // useEffect(() => {
  //   dispatch(Space(space));
  // }, [space]);
  // useEffect(() => {
  //   dispatch(textcolor(checkEditPage.isUpdateColor));
  //   // setTextColor("");
  // }, [checkEditPage.isUpdateColor]);
  const handleIncrement = () => {
    setX_axis((prevYAxis: any) => prevYAxis + 1);
  };

  const handleDecrement = () => {
    setX_axis((prevYAxis: any) => prevYAxis - 1);
  };
  useEffect(() => {
    dispatch(clickCurve(curve));
    // if (!curve) {
    //   setCurvings(1);
    //   setFlip(false);
    // }
    // if (checkEditPage.isobjectvalue === "group") {
    //   dispatch(clickCurve(true));
    // }
  }, [curve]);
  useEffect(() => {
    dispatch(flipCurve(flip));
  }, [flip]);
  useEffect(() => {
    dispatch(curving(curvings));
  }, [curvings]);

  const handleSwitchChange = () => {
    dispatch(Capital(true));
  };
  const handleSwitchsmall = () => {
    dispatch(Small(true));
  };
  // const Xaxiss = (event: any) => {
  //   const newValue = parseInt(event.target.value, 10);
  //   dispatch(Xaxis(newValue));
  // };
  // const Yaxiss = (event: any) => {
  //   const newValue = parseInt(event.target.value, 10);
  //   dispatch(Yaxis(newValue));
  // };

  const layernamesave = () => {
    dispatch(Layer_name(name));
    setName("");
  };

  return (
    <>
      <div
        style={{
          color: "#ffffff",
          overflowY: "scroll",
          height: "97vh",
        }}
        className="scroll"
      >
        <div>
          <div
            className="texttoolalign"
            style={{ fontSize: "16px", marginTop: "10px" }}
          >
            Text Properties
          </div>
          <img src={line} alt="invalid" width={"100%"} />
        </div>
        {/* text alignment */}
        <div>
          <div className="texttoolalign" style={{ marginBottom: "35px" }}>
            Layer Name{" "}
          </div>
          <div>
            <input
              style={{ width: "60%", height: "32px" }}
              type="text"
              value={name}
              onChange={(e: any) => {
                setName(e.target.value);
              }}
            />
            <button
              onClick={() => {
                layernamesave();
              }}
              style={{ height: "32px" }}
            >
              Submit
            </button>
          </div>
          <img src={line} alt="invalid" width={"100%"} />

          <div className="Aligntext">
            <img
              src={align1}
              onClick={() => {
                dispatch(textAlignment("left"));
              }}
              alt="invalid"
            />
            <img
              src={align2}
              onClick={() => {
                dispatch(textAlignment("center"));
              }}
              alt="invalid"
            />
            <img
              src={align3}
              onClick={() => {
                dispatch(textAlignment("right"));
              }}
              alt="invalid"
            />
            {/* <img src={align4} alt="invalid" />
            <img src={align5} alt="invalid" />
            <img src={align6} alt="invalid" /> */}
          </div>
        </div>
        {/* text size and position */}
        {/* <div style={{ width: "100%" }}>
          <div className="texttoolalign"> Frame</div>
          <div>
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: "small" }}>Font size</div>
            </div>
          </div> */}
        {/* <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              rowGap: "30px",
            }}
          >
            <div
              style={{
                width: "90%",
              }}
            >
              <div className="textposition">X</div>
              <div className="textposition">Y</div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ columnGap: "20px", display: "flex" }}>
                  <img src={angle} alt="invalid" height={"20px"} />
                  <div
                    style={{
                      backgroundColor: "#7e7e7e",
                      paddingBlock: "3px",
                      width: "100px",
                    }}
                  >
                    0
                  </div>
                </div>
                <div style={{ columnGap: "20px", display: "flex" }}>
                  <div>Curve</div>
                  <div
                    style={{
                      backgroundColor: "#7e7e7e",
                      paddingBlock: "3px",
                      width: "100px",
                    }}
                  >
                    <img src={curve} />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        {/* <img src={line} width={"100%"} alt="invalid" />
        </div> */}
        <div>
          {/* <div className="texttoolalign">Text </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                fontWeight: zoomtext ? "" : "bolder",
                cursor: "pointer",

                // padding: zoomtext ? "1px" : "",
              }}
              onClick={() => {
                dispatch(textBold("bold"));

                setzoomText(!zoomtext);
                zoom();
              }}
              onDoubleClick={() => {
                dispatch(textBold("normal"));

                setzoomText(!zoomtext);
                zoom();
              }}
            >
              B
            </div>
            <div
              style={{
                fontWeight: zoomitalic ? "" : "bolder",
                cursor: "pointer",

                // padding: zoomtext ? "1px" : "",
              }}
              onClick={() => {
                dispatch(textitalic("italic"));
                setzoomText(!zoomitalic);
                zoom();
              }}
              onDoubleClick={() => {
                dispatch(textitalic("null"));
                setzoomText(!zoomitalic);
                zoom();
              }}
            >
              I
            </div>
            <div
              style={{
                fontWeight: zoomitalic ? "" : "bolder",
                cursor: "pointer",
                textDecoration: "underline",

                // padding: zoomtext ? "1px" : "",
              }}
              onClick={() => {
                dispatch(textunderline("true"));
                setzoomText(!zoomitalic);
                zoom();
              }}
              onDoubleClick={() => {
                dispatch(textunderline("null"));
                setzoomText(!zoomitalic);
                zoom();
              }}
            >
              U
            </div>
            {/* <div>
              Capital{" "}
              <Switch checked={isCapitalized} onChange={handleSwitchChange} />
            </div> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <button onClick={handleSwitchChange}> Capital</button>
            <button onClick={handleSwitchsmall}>Small</button>
            <input
              type="color"
              defaultValue={defaultColor}
              value={checkEditPage.isUpdateColor}
              onChange={(e) => setTextColor(e.target.value)}
            />
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-around",
              fontSize: "10px",
            }}
          >
            <div>Size</div>
            <div>X-Axis</div>
            <div>Y-Axis</div>
          </div>
          <div
            style={{
              // marginTop: "20px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <input
              style={{ width: "20%" }}
              type="number"
              value={count}
              onChange={Fontsize}
            />
            {/* <div style={{ width: "30%", display: "flex" }}> */}
            <input
              style={{ width: "20%" }}
              type="number"
              value={x_axis}
              onChange={Xaxiss}
              step="any"
              onFocus={() => setXActive(true)}
              onBlur={() => setXActive(false)}
            />
            {/* <div style={{ display: "flex", flexDirection: "column" }}>
                <button onClick={handleIncrement}>+</button>
                <button onClick={handleDecrement}>-</button>
              </div> */}
            {/* </div> */}

            <input
              style={{ width: "20%" }}
              type="number"
              value={y_axis}
              onChange={Yaxiss}
              step="any"
              onFocus={() => setYActive(true)}
              onBlur={() => setYActive(false)}
            />
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "20%" }}>
              <div style={{ fontSize: "10px" }}>Angle</div>
              <input
                style={{ width: "100%" }}
                type="number"
                value={angles}
                onChange={Angles}
                step={"any"}
                onFocus={() => setAngleActive(true)}
                onBlur={() => setAngleActive(false)}
              />
            </div>
            <div style={{ width: "20%" }}>
              <div style={{ fontSize: "10px" }}>Width</div>
              <input
                style={{ width: "100%", appearance: "textfield" }}
                type="number"
                value={width}
                onChange={Widthh}
              />
            </div>
            <div style={{ width: "20%" }}>
              <div style={{ fontSize: "10px" }}>Line Spacing</div>
              <input
                style={{ width: "100%" }}
                type="number"
                step="0.01"
                value={linee}
                onChange={Linee}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "20%" }}>
              <div style={{ fontSize: "10px" }}>Height</div>
              {/* <input
                style={{ width: "100%" }}
                type="number"
                step="0.10"
                value={height}
                onChange={Heightt}
              /> */}

              <input
                style={{
                  width: "100%",
                }}
                // type="text"
                type="number"
                value={height}
                onChange={Heightt}
                step={"any"}
                onFocus={() => setAutohght(true)}
                onBlur={() => setAutohght(false)}
              />
            </div>
            <div style={{ width: "20%" }}>
              <div style={{ fontSize: "10px" }}>Spacing</div>
              <input
                style={{
                  width: "100%",
                }}
                // type="text"
                type="number"
                value={space}
                onChange={spacee}
                // step={"any"}
                // onFocus={() => setAutohght(true)}
                // onBlur={() => setAutohght(false)}
              />
            </div>
            <div style={{ width: "20%" }}></div>
          </div>
          <div style={{ marginTop: "30px" }}>
            <img
              src={curveText}
              alt="curve"
              onClick={() => {
                setCurve(!curve);
                dispatch(textUpdate(curve ? "updatedd" : "curved"));
                // dispatch(clickCurveextra(curve ? "true" : ""));
                // curve
                //   ? dispatch(textUpdate("updated"))
                //   : dispatch(textUpdate(""));
              }}
            />
            {checkEditPage.isCurve ? (
              <>
                {/* <input
                  type="checkbox"
                  checked={
                    checkEditPage.isupdateFlip
                    // checkEditPage.isobjectvalue === "group" ? flip : false
                  }
                  onChange={(e) => setFlip(e.target.checked)}
                /> */}
                <input
                  type="range"
                  value={
                    curvings
                    // checkEditPage.isUpdateCurving
                    // checkEditPage.isobjectvalue === "group" ? curvings : 1
                  }
                  min={-360}
                  max={360}
                  onChange={(e) => setCurvings(parseInt(e.target.value))}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div style={{ marginTop: "30px" }}>
            <FontLibrary></FontLibrary>
          </div>
        </div>
        <img src={line} width={"100%"} alt="invalid" />
        <div>
          <TextQuestion />
        </div>
        <img
          src={line}
          width={"100%"}
          alt="invalid"
          style={{ paddingBottom: "30px" }}
        />

        {/* <div>
          <div style={{ marginBottom: "23px" }} className="texttoolalign">
            Add Text
          </div>
          <input
            style={{ outline: "none" }}
            name={`text`}
            type={`text`}
            value={text}
            onChange={(event) => {
              setText(event.target.value);
            }}
          /> */}
        {/* <button onClick={onAddText}>SUBMIT</button> */}

        {/* <img src={line} width={"100%"} alt="invalid" />
        </div> */}
      </div>
    </>
  );
};
export default ToolPage;
