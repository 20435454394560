import { FunctionComponent } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../Pages/Page.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  FormHelperText,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
//
import TextField from "@mui/material/TextField";
import Autocomplete from "./Autocomplete";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useAppSelector } from "../Redux/store/hooks";
import IsLoading from "./isLoading";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogsProps {
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any | number;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onclick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties | undefined;
  getValues?: any;
  closeDio?: any;
  error?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;
  errors?: any;
  placeholder?: string;
  textfield?: boolean;
  editusers?: any;
  isLoading?: boolean;
  successMessage?: any;
  sx?: SxProps<Theme> | undefined;
}

const Dialog: FunctionComponent<DialogsProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [val, setVal] = React.useState({});

  //    Popup messages
  const [popup, setPopoup] = React.useState(false);

  //    success dialog
  const [dialog, setDialog] = React.useState(false);

  const Roledata: any = useAppSelector((state) => state?.Role?.data);
  const index = Roledata.findIndex(
    (i: any) => i?.name === props?.editusers?.role
  );
  const roleId = Roledata[index]?.id;

  // console.log(props.editusers);
  const { getValues, editusers, isLoading, successMessage }: any = props;

  const initialValues: {
    id: number;
    // file_id: phone;
    firstname: string;
    lastname: string;
    username: string;
    role: string;
    email: string;
    phone: number;
    active: boolean;
    password: string;
  } = {
    id: props?.editusers?.id,
    // file_id: 0,
    firstname: props?.editusers?.firstname || "",
    lastname: props?.editusers?.lastname || "",
    username: props?.editusers?.username || "",
    // active: props?.editusers?.active || "",
    email: props?.editusers?.email || "",
    phone: props?.editusers?.phone || null,
    active:
      props?.editusers?.active ||
      (props?.editusers?.active === undefined && true),
    role: roleId || "",
    password: "",
  };

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    // closeFunction(true);
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    setDialog(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    setDialog(false);
    setPopoup(false);
  }, [open]);
  React.useEffect(() => {
    if (isLoading) {
      setDialog(isLoading);
    }
  }, [isLoading]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  //    data for role
  const data = useAppSelector((state) => state?.Role?.data);

  return (
    <div>
      <Button
        onClick={handleClickOpen("paper")}
        color={props.color}
        size={props.size}
        startIcon
        // disabled
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        maxWidth={"lg"}
        // onClose={handleClose}
        // scroll={scroll}
        PaperProps={{
          sx: {
            maxWidth: "none",
            borderRadius: "0",

            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        // PaperProps={
        //   {
        // style: {
        //   width: "50vw", // 50% of the viewport width
        //   height: "70vh", // 50% of the viewport height
        //   maxWidth: "90vw", // Maximum width is 90% of the viewport width
        //   maxHeight: "90vh", // Maximum height is 90% of the viewport height
        // },
        //   }
        // }
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            fontWeight: "bold",
            backgroundColor: "black",
            color: "white",
          }}
        >
          {props.Dialogtitle}
          <ClearOutlinedIcon
            className="clear"
            onClick={handleClose}
            sx={{ color: "white", float: "right" }}
          ></ClearOutlinedIcon>
        </DialogTitle>
        <DialogContent sx={{ padding: "1.5em 24px 0 !important" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              firstname: Yup.string().required("Please enter firstname"),
              // .min(10)
              // .max(10)
              // lastname: Yup.string().required("Enter Valid Lastname"),
              // username: Yup.string().required("Please enter username"),
              active: Yup.string().required(" Please select a status"),
              // email: Yup.string().email().required("Enter Vaild Email"),
              email: Yup.string()
                .email("Please enter email")
                .required("Email is required")
                .matches(/^.+@.+\..+$/, "Enter a valid email"),

              // phone: Yup.string().matches(
              //   /^(?:\+91)?[6789]\d{9}$/,
              //   "Please Enter a Valid Mobile Number"
              // ),
              // .required("Enter the Moblie Phone"),

              role: Yup.string().required("Please select a role "),
            })}
            onSubmit={(values) => {
              // actions.setSubmitting(false);
              console.log(values);
              setDialog(true);
              // getValues(values);
            }}
          >
            {(props) => (
              <Form
                style={{ display: "flex", gap: "1em" }}
                id="form"
                autoComplete="off"
              >
                <IsLoading isLoading={isLoading} />
                {/* <PopupMessage
                  open={dialog}
                  submitFunction={props.handleSubmit}
                  successMessage={successMessage}
                  isLoading={isLoading}
                  Dialogtitle={"Are you sure you want to proceed?"}
                /> */}
                {/*     for submit when enter button click     */}
                <button type="submit" style={{ display: "none" }}>
                  submit
                </button>

                <>
                  <div>
                    <div className="lableInputDiv">
                      <b>
                        First Name
                        <span style={{ color: "red" }}>*</span>
                      </b>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        name="firstname"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                          },
                          width: "clamp(20rem,30vw,30rem)",
                        }}
                        autoComplete="off"
                        value={props.values.firstname}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Use regex to check if the input contains only characters (letters)
                          if (
                            /^[A-Za-z ]+$/.test(inputValue) &&
                            inputValue.length < 31
                          ) {
                            props.setFieldValue("firstname", inputValue);
                          } else {
                            // If validation fails, remove the last character from the input value
                            props.setFieldValue(
                              "firstname",
                              inputValue.slice(0, -1)
                            );
                          }
                        }}
                        helperText={
                          props.errors.firstname &&
                          props.touched.firstname &&
                          props.errors.firstname
                        }
                        error={
                          props.errors.firstname && props.touched.firstname
                            ? true
                            : false
                        }
                        onBlur={props.handleBlur}
                      />
                    </div>
                    <div className="lableInputDiv">
                      <b>Last Name</b>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        name="lastname"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                          },
                          width: "clamp(20rem,30vw,30rem)",
                        }}
                        autoComplete="off"
                        value={props.values.lastname}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Use regex to check if the input contains only characters (letters)
                          if (
                            /^[A-Za-z ]+$/.test(inputValue) &&
                            inputValue.length < 31
                          ) {
                            props.setFieldValue("lastname", inputValue);
                          } else {
                            // If validation fails, remove the last character from the input value
                            props.setFieldValue(
                              "lastname",
                              inputValue.slice(0, -1)
                            );
                          }
                        }}
                        // onChange={props.handleChange}

                        helperText={
                          props.errors.lastname &&
                          props.touched.lastname &&
                          props.errors.lastname
                          // : "Enter your Email."
                        }
                        error={
                          props.errors.lastname && props.touched.lastname
                            ? true
                            : false
                        }
                        onBlur={props.handleBlur}
                      />
                    </div>
                    {/* <b                    >
                      User Name
                      <span style={{ color: "red" }}>*</span>
                    </b>
                    <TextField
                      id="userName"
                      variant="outlined"
                      name="username"
                      sx={{
                        "& fieldset": {
                          borderRadius: "0px",
                        },
                      }}
                      autoComplete="off"
                      // required
                      value={props.values.username}
                      // onChange={props.handleChange}

                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Use regex to check if the input contains only characters (letters)
                        if (
                          /^[A-Za-z]+$/.test(inputValue) &&
                          inputValue.length < 31
                        ) {
                          props.setFieldValue("username", inputValue);
                        } else {
                          // If validation fails, remove the last character from the input value
                          props.setFieldValue(
                            "username",
                            inputValue.slice(0, -1)
                          );
                        }
                      }}
                      helperText={
                        props.errors.username &&
                        props.touched.username &&
                        props.errors.username
                        // : "Enter your Email."
                      }
                      error={
                        props.errors.username && props.touched.username
                          ? true
                          : false
                      }
                      onBlur={props.handleBlur}
                    /> */}
                    <div className="lableInputDiv">
                      <b>
                        Status <span style={{ color: "red" }}>*</span>
                      </b>
                      <Autocomplete
                        Listitem={[
                          { label: "Active", value: true },
                          { label: "Inactive", value: false },
                        ]}
                        sx={{ width: "clamp(20rem,30vw,30rem)" }}
                        // required
                        variant={"outlined"}
                        name={"active"}
                        value={props.values}
                        onBlur={props.handleBlur}
                        onchange={(e: any) => {
                          // console.log(e);

                          props.setFieldValue("active", e.value);
                        }}
                        error={props.errors.active && props.touched.active}
                        helperText={
                          props.errors.active &&
                          props.touched.active &&
                          props.errors.active
                        }
                      ></Autocomplete>
                    </div>
                  </div>

                  <div>
                    <div className="lableInputDiv">
                      <b>
                        Email Id
                        <span style={{ color: "red" }}>*</span>
                      </b>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        name="email"
                        autoComplete="off"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                          },
                          width: "clamp(20rem,30vw,30rem)",
                        }}
                        value={props.values.email}
                        onChange={(e) => {
                          if (e.target.value.length < 50) {
                            props.setFieldValue("email", e.target.value);
                          }
                        }}
                        helperText={
                          props.errors.email && props.touched.email
                            ? props.errors.email
                            : " "
                        }
                        error={
                          props.errors.email && props.touched.email
                            ? true
                            : false
                        }
                        onBlur={props.handleBlur}
                      />
                    </div>

                    <div className="lableInputDiv">
                      <b>Mobile Number</b>
                      <TextField
                        inputProps={{ maxLength: 10 }}
                        id="outlined-basic"
                        // label="Mobile Number"
                        variant="outlined"
                        name="phone"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                          },
                          width: "clamp(20rem,30vw,30rem)",
                        }}
                        autoComplete="off"
                        value={props.values.phone}
                        onChange={(e) => {
                          const onlyNumbers = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          ); // Remove non-numeric characters
                          props.handleChange({
                            target: { name: "phone", value: onlyNumbers },
                          });
                        }}
                        helperText={
                          props.errors.phone &&
                          props.touched.phone &&
                          props.errors.phone
                          // : "Enter your Email."
                        }
                        error={
                          props.errors.phone && props.touched.phone
                            ? true
                            : false
                        }
                        onBlur={props.handleBlur}
                      />
                    </div>
                    <div className="lableInputDiv">
                      <b>
                        Roles<span style={{ color: "red" }}>*</span>
                      </b>
                      <Autocomplete
                        Listitem={data
                          .filter((data: any) => {
                            return data.active === "Active";
                          })
                          .map((v: any) => {
                            return { label: v.name, value: v.id };
                          })}
                        sx={{ width: "clamp(20rem,30vw,30rem)" }}
                        // required
                        variant={"outlined"}
                        name={"role"}
                        value={
                          data
                            .filter((data: any) => {
                              return data.id === props.values.role;
                            })
                            .filter((data: any) => {
                              console.log("testing", data);
                              return data.active === "Inactive";
                            }).length === 0
                            ? props.values
                            : ""
                        }
                        onBlur={props.handleBlur}
                        onchange={(e: any) => {
                          console.log(e);

                          props.setFieldValue("role", e.value);
                        }}
                        helperText={
                          props.errors.role &&
                          props.touched.role &&
                          props.errors.role
                        }
                        error={props.errors.role && props.touched.role}
                      ></Autocomplete>
                    </div>
                  </div>
                </>
                {!isLoading && (
                  <Dialogs
                    open={!isLoading ? dialog : false}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      sx: {
                        borderRadius: "0",
                        // height: height || "80vh",
                        "&::-webkit-scrollbar": { display: "none" },
                      },
                    }}
                  >
                    <DialogContent sx={{ padding: "1.5em 1.5em 0 !important" }}>
                      {!popup ? (
                        <DialogContentText id="alert-dialog-description">
                          <div className="submitPopup">
                            <div
                              style={{
                                fontSize: "16px",
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              Are you sure you want to proceed?
                              {/* Submitted Successfully */}
                            </div>
                          </div>
                        </DialogContentText>
                      ) : (
                        // !props.isLoading && (
                        <DialogContentText id="alert-dialog-description">
                          <div className="submitPopup">
                            <div
                              style={{
                                fontSize: "16px",
                                color: "black",
                                textAlign: "center",
                                margin: "0px 40px 0px 40px ",
                              }}
                            >
                              {successMessage.message}
                            </div>
                            <div
                              style={{
                                fontSize: "16px",
                                color: "black",
                                textAlign: "center",
                                margin: "0px 40px 0px 40px ",
                              }}
                            >
                              {successMessage?.status ? (
                                <img
                                  src={require("../Pages/Image/success.png")}
                                  className="imageSize"
                                  alt="success"
                                />
                              ) : (
                                <>
                                  {successMessage?.message
                                    .toLowerCase()
                                    .includes("already exists") ? (
                                    // console.log("exisite")
                                    <img
                                      src={require("../Pages/Image/mdi_file-alert-outline.png")}
                                      className="imageSize"
                                      alt="error"
                                    />
                                  ) : (
                                    <img
                                      src={require("../Pages/Image/error.png")}
                                      className="imageSize"
                                      alt="error"
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </DialogContentText>
                        // )
                      )}
                    </DialogContent>

                    {!popup ? (
                      <DialogActions
                        sx={{
                          justifyContent: "center",
                          gap: "0.5em",
                          padding: "1.5em",
                        }}
                      >
                        <Button
                          onClick={() => setDialog(false)}
                          style={{
                            padding: "15px",
                            // backgroundColor: "#1976D2",
                            color: "black",
                            width: "100px",
                            height: "37px",
                            borderRadius: "0px",
                            border: "1px solid black",
                          }}
                          sx={{
                            backgroundColor: "white",
                            "&:hover": { backgroundColor: "white" },
                          }}
                        >
                          No
                        </Button>
                        <Button
                          // form="form"
                          // type="submit"
                          onClick={() => {
                            // props.handleSubmit();
                            getValues(props.values);
                            setDialog(false);
                            setTimeout(() => setPopoup(true), 100);
                          }}
                          style={{
                            // backgroundColor: "#1976D2",
                            color: "white",
                            width: "100px",
                            height: "37px",
                            borderRadius: "0px",
                            border: "1px solid black",
                          }}
                          autoFocus
                          sx={{
                            backgroundColor: "black",
                            "&:hover": { backgroundColor: "black" },
                          }}
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    ) : (
                      // !props.isLoading && (
                      <DialogActions
                        sx={{
                          justifyContent: "center",
                          gap: "0.5em",
                          padding: "1.5em",
                        }}
                      >
                        <Button
                          onClick={() => {
                            successMessage?.status && setOpen(false);
                            !successMessage?.status && setPopoup(false);
                            setDialog(false);
                          }}
                          style={{
                            // backgroundColor: "#1976D2",
                            color: "white",
                            width: "100px",
                            height: "37px",
                            borderRadius: "4px",
                            border: "1px solid #BDBDBD",
                          }}
                          autoFocus
                          sx={{
                            backgroundColor: "black",
                            "&:hover": { backgroundColor: "black" },
                          }}
                        >
                          OK
                        </Button>
                      </DialogActions>
                      // )
                    )}
                  </Dialogs>
                )}
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            gap: "0.5em",
            padding: "1.5em",
          }}
        >
          <Button
            onClick={handleClose}
            // disabled={!props.isValid}
            style={{
              width: "100px",
              height: "40px",
              border: "1px solid black",
              color: "black",
              backgroundColor: "white",
              borderRadius: "0px",
            }}
          >
            Cancel
          </Button>
          <Button
            // onClick={() => {
            //   setDialog(true);
            // }}
            form="form"
            type="submit"
            // disabled={!props.isValid}
            style={{
              width: "100px",
              height: "40px",
              border: "1px solid black",
              color: "white",
              // backgroundColor: "#C00A30",
              backgroundColor: "black",
              borderRadius: "0px",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialogs>
    </div>
  );
};

export default Dialog;
