import "./TextInputField.css";

import { FunctionComponent } from "react";
import {
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { TextInputFieldProps } from "./ITextInputFieldProps";

const TextInputField: FunctionComponent<TextInputFieldProps> = (props) => {
  const {
    id,
    name,
    placeholder,
    variant,
    label,
    color,
    required,
    className,
    sx,
    value,
    defaultValue,
    error,
    disabled,
    readOnly,
    fullWidth,
    type,
    inputClassName,
    helperText,
    InputProps,
    shrink,
    inputLableStyle,
    size,
    onKeyDown,
    labelSize,
    onChange,
    onBlur,
    onFocus,
  } = props;
  return (
    <TextField
      id={id}
      name={name}
      placeholder={placeholder}
      variant={variant}
      label={label}
      color={color}
      required={required}
      fullWidth={fullWidth}
      className={`GlobalInputStyle ${className}`}
      sx={sx}
      value={value}
      defaultValue={defaultValue}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled}
      type={type ?? "text"}
      size={size}
      onKeyDown={onKeyDown}
      InputProps={{
        ...InputProps,
        readOnly: readOnly,
        className: `GlobalInputPropsStyle ${inputClassName}`,
      }}
      InputLabelProps={{
        shrink: shrink,
        size: labelSize,
        className: `GlobalInputLableStyle ${inputLableStyle}`,
      }}
    />
  );
};

export default TextInputField;
