import { FunctionComponent } from "react";
import "./Page.css";
import Loginvalidation from "./Loginvalidation";
import LoginImage from "./LoginImage";

interface LoginProps {}

const Login: FunctionComponent<LoginProps> = () => {
  return (
    <div className="Login">
      <div className="LoginImage">
        <LoginImage></LoginImage>
      </div>
      <div className="LoginImages">
        <Loginvalidation></Loginvalidation>
      </div>
    </div>
  );
};

export default Login;
