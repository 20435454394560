import { createSlice } from "@reduxjs/toolkit";

export const editUser = createSlice({
  name: "editUser",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    edit_user: (state) => {
      state.isLoading = true;
    },
    success_api_call_edit_user: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_edit_user: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      // FAILED(action.payload);
    },
    edit_user_is_success: (state) => {
      state.isSuccess = false;
    },
    edit_user_complete_api: (state) => {
      state.api_completed = false;
    },
  },
});
export default editUser.reducer;
export const {
  edit_user,
  success_api_call_edit_user,
  edit_user_complete_api,
  failure_api_call_edit_user,
  edit_user_is_success,
} = editUser.actions;
