import { createSlice } from "@reduxjs/toolkit";

export const AddUser = createSlice({
  name: "AddUser",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    add_user: (state) => {
      state.isLoading = true;
    },
    success_api_call_add_user: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_add_user: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      // FAILED(action.payload);
    },
    add_user_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_user_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default AddUser.reducer;
export const {
  add_user,
  success_api_call_add_user,
  failure_api_call_add_user,
  create_user_complete_api,
  add_user_is_success,
} = AddUser.actions;
