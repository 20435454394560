import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
import { IListCampaign } from "../../../interfaces/ICampaign";

export const ListCampaign = createSlice({
  name: "ListCampaign",
  initialState: {
    isLoading: false,
    data: {},
  } as IReduxState<IListCampaign[]>,
  reducers: {
    list_Campaign: (state) => {
      state.isLoading = true;
    },
    success_api_call_Campaign: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_call_Campaign: (state) => {
      state.isLoading = false;
    },
  },
});
export default ListCampaign.reducer;
export const {
  list_Campaign,
  success_api_call_Campaign,
  failure_api_call_Campaign,
} = ListCampaign.actions;
