import { FunctionComponent } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "../Redux/store/hooks";
import {
  delete_popup_open,
  deleteable_variant,
  editable_variant,
  popup_open,
} from "../Redux/Reducers/Campaign/VariantList_Slicer";
import { ICapaignVariantForm } from "./CampaignVariantForm";

interface VariantActionProps {
  value?: ICapaignVariantForm;
}

const VariantAction: FunctionComponent<VariantActionProps> = (props) => {
  /*      dispatch props       */
  const { value } = props;
  /*      Redux dispatch      */
  const dispatch = useAppDispatch();
  return (
    <div style={{ display: "flex", gap: "0.5em", flexWrap: "wrap" }}>
      <EditIcon
        onClick={() => {
          dispatch(popup_open());
          dispatch(editable_variant(value));
        }}
        sx={{
          backgroundColor: "#F5F5F5",
          color: "black",
          padding: "5px",
          fontSize: "1.7rem",

          cursor: "pointer",
          borderRadius: "50%",
          "&:hover": {
            color: "white",
            backgroundColor: "black",
          },
        }}
      />
      <DeleteIcon
        onClick={() => {
          dispatch(delete_popup_open());
          dispatch(deleteable_variant(value?.field_id));
        }}
        sx={{
          backgroundColor: "#F5F5F5",
          color: "black",
          padding: "5px",
          fontSize: "1.7rem",

          cursor: "pointer",
          borderRadius: "50%",
          "&:hover": {
            color: "white",
            backgroundColor: "black",
          },
        }}
      />
    </div>
  );
};

export default VariantAction;
