import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const Projectid = createSlice({
  name: "Projectid",
  initialState: {
    projectid: 0,
    projectname: "",
    pagesvalue: "",
  },
  reducers: {
    // screnn render
    Projectid_render: (state, action: PayloadAction<any>) => {
      state.projectid = action.payload;
      console.log("projectid", action.payload);
    },
    Projectname_render: (state, action: PayloadAction<any>) => {
      state.projectname = action.payload;
      console.log("Projectname_render", action.payload);
    },
    PageValue: (state, action: PayloadAction<any>) => {
      console.log("pagesvalue", action.payload);
      state.pagesvalue = action.payload;
    },
  },
});
export default Projectid.reducer;
export const {
  Projectid_render,
  Projectname_render,
  PageValue,
  // ObjectSelectertotalValue,
} = Projectid.actions;
