import { FunctionComponent } from "react";
import { Route, Routes } from "react-router";
import Login from "../Pages/Login";
import Editor from "../Editor/index";
import Sidenavbar from "../Pages/Sidenavbar";
import PrivateRoute from "./PrivateRoute";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import Question from "../Pages/Image/Qustion.png";
import HelpIcon from "@mui/icons-material/Help";
import orderimage from "../Pages/Image/orderimage.png";
import { useAppSelector } from "../Redux/store/hooks";
import * as icons from "@mui/icons-material";
import Createpassword from "../Pages/Createpassword";
import Resetpassword from "../Pages/Resetpassword";
import { Preview } from "../preview/index";
import { PreviewSlider } from "../preview/index2";
const LoginRoute: FunctionComponent<any> = () => {
  const menuItems = useAppSelector((state) => state.MenuItem.data);

  const Listitem = [
    {
      icon: <LibraryBooksIcon></LibraryBooksIcon>,
      menuname: "Library",
      options: [
        {
          icon: (
            <FiberManualRecordRoundedIcon
              style={{ fontSize: "10px ", margin: "8px 0px 0px 5px  " }}
            ></FiberManualRecordRoundedIcon>
          ),
          menuname: "Font library",
          path: "/fontlibraries",
          img: "//",
        },
        {
          icon: (
            <FiberManualRecordRoundedIcon
              style={{ fontSize: "10px ", margin: "8px 0px 0px 5px  " }}
            ></FiberManualRecordRoundedIcon>
          ),
          menuname: "Image library",
          path: "/ImageLibraries",
        },
      ],
      // path: "/Character",
    },
    {
      icon: <LocalOfferIcon></LocalOfferIcon>,
      menuname: "Campaign",
      path: "/Campaign",
    },
    {
      icon: <AutoStoriesIcon></AutoStoriesIcon>,
      menuname: "Comics Book",
      path: "/ComicsBook",
    },
    {
      icon: <AutoAwesomeMotionIcon></AutoAwesomeMotionIcon>,
      menuname: "Order Management",
      path: "/OrderManagement",
    },

    {
      icon: <ManageAccountsIcon></ManageAccountsIcon>,

      menuname: "Masters",
      options: [
        {
          icon: (
            <FiberManualRecordRoundedIcon
              style={{ fontSize: "10px ", margin: "8px 0px 0px 5px  " }}
            ></FiberManualRecordRoundedIcon>
          ),
          menuname: "Users",
          path: "/user",
        },
        {
          icon: (
            <FiberManualRecordRoundedIcon
              style={{ fontSize: "10px ", margin: "8px 0px 0px 5px  " }}
            ></FiberManualRecordRoundedIcon>
          ),
          menuname: "Roles",
          path: "/userrole",
        },
        {
          icon: (
            <FiberManualRecordRoundedIcon
              style={{ fontSize: "10px ", margin: "8px 0px 0px 5px  " }}
            ></FiberManualRecordRoundedIcon>
          ),
          menuname: "User Permissions",
          path: "/UserPermission",
        },
        {
          icon: (
            <FiberManualRecordRoundedIcon
              style={{ fontSize: "10px ", margin: "8px 0px 0px 5px  " }}
            ></FiberManualRecordRoundedIcon>
          ),
          menuname: "Page Dimension",
          path: "/pagedimension",
        },
        {
          icon: (
            <FiberManualRecordRoundedIcon
              style={{ fontSize: "10px ", margin: "8px 0px 0px 5px  " }}
            ></FiberManualRecordRoundedIcon>
          ),
          menuname: "Variant",
          path: "/variantname",
        },
        {
          icon: (
            <FiberManualRecordRoundedIcon
              style={{ fontSize: "10px ", margin: "8px 0px 0px 5px  " }}
            ></FiberManualRecordRoundedIcon>
          ),
          menuname: "Image Category",
          path: "/imagecategory",
        },
      ],
    },
  ];

  const menus = menuItems?.map((menu: any) => {
    const menuIcons = menu?.icon as keyof typeof icons;
    const IconComponent = icons[menuIcons];
    const subMenu = menu?.options?.map((option: any) => {
      const subMenuIcons = option?.icon as keyof typeof icons;
      const IconComponent = icons[subMenuIcons];
      return {
        ...option,
        icon: <IconComponent sx={{ fontSize: "0.7rem" }} />,
      };
    });
    return { ...menu, icon: <IconComponent />, options: subMenu };
  });
  return (
    <>
      <Routes>
        <Route path="/Preview" element={<Preview></Preview>}></Route>
        <Route path="/PreviewSlide" element={<PreviewSlider />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/Comictools" element={<Editor></Editor>}></Route>
        <Route path="/resetpassword" element={<Resetpassword />} />
        <Route
          path="/createpassword"
          element={<Createpassword></Createpassword>}
        ></Route>

        <Route
          path="/*"
          element={
            <>
              <PrivateRoute>
                {/* <Route
                  path="/sidenavbar"
                  // element={<Sidenavbar list={Listitem} />}
                /> */}
                <Sidenavbar list={menus}></Sidenavbar>

                {/* <Sidenavbar list={Listitem}></Sidenavbar> */}
              </PrivateRoute>
            </>
          }
        />
      </Routes>
    </>
  );
};

export default LoginRoute;
