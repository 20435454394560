import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import Character from "../Pages/Character";
import Templates from "../Pages/Templates";
import FontLibraries from "../Pages/FontLibraries";
import Editor from "../Editor/index";
import ImageLibraries from "../Pages/ImageLibraries";
import ComicsBook from "../Pages/ComicsBook";
import ComicbookPages from "../Pages/NextPage";
import Userrole from "../Pages/Userrole";
import User from "../Pages/User";
import UserPermission from "../Pages/UserPermission";
import Question from "../Pages/Quesion";
import Createpassword from "../Pages/Createpassword";
import ErrorDialog from "../Component/ErrorDialog";
import Campaign from "../Pages/Campaign";
import PageSize from "../Pages/PageSize";
import Variantform from "../Pages/Variant";
import NewCampaign from "../Pages/New_Campaign";
import Categories from "../Pages/Categories";
import Resetpassword from "../Pages/Resetpassword";
import OrderManagment from "../Pages/OrderManagement/Ordermanagment";
import { Preview } from "../preview/index";
interface RoutingPagesProps {}

const RoutingPages: FunctionComponent<RoutingPagesProps> = () => {
  return (
    <div style={{ height: "95%" }}>
      <Routes>
        <Route
          path="/fontlibraries"
          element={<FontLibraries></FontLibraries>}
        />

        {/* <Route path="/Campaign" element={<Campaign></Campaign>} /> */}
        <Route
          path="/UserPermission"
          element={<UserPermission></UserPermission>}
        />
        <Route
          path="/ImageLibraries"
          element={
            <ImageLibraries
              touched={undefined}
              errors={undefined}
              handleBlur={undefined}
              values={undefined}
              setFieldValue={function (arg0: string, value: any): unknown {
                throw new Error("Function not implemented.");
              }}
            ></ImageLibraries>
          }
        />
        <Route
          path="/ComicsBook"
          element={
            <ComicsBook
              touched={undefined}
              errors={undefined}
              handleBlur={undefined}
              values={undefined}
              setFieldValue={function (arg0: string, value: any): unknown {
                throw new Error("Function not implemented.");
              }}
            ></ComicsBook>
          }
        />
        <Route
          path="/Comicbookpages"
          element={
            <ComicbookPages
              touched={undefined}
              errors={undefined}
              handleBlur={undefined}
              values={undefined}
              setFieldValue={function (arg0: string, value: any): unknown {
                throw new Error("Function not implemented.");
              }}
            ></ComicbookPages>
          }
        />

        <Route path="/Character" element={<Character></Character>} />
        <Route path="/Campaign" element={<Campaign></Campaign>} />
        <Route path="/Question" element={<Question></Question>}></Route>
        <Route path="/userrole" element={<Userrole />} />
        <Route path="/imagecategory" element={<Categories />} />

        <Route path="/user" element={<User />} />
        <Route path="/pagedimension" element={<PageSize />} />
        <Route path="/variantname" element={<Variantform />} />
        <Route path="/NewCampaign" element={<NewCampaign />} />
        <Route path="/OrderManagement" element={<OrderManagment />} />

        {/* <Route
          path="/ImageList"
          element={<ImageListComponent></ImageListComponent>}
        /> */}

        <Route path="/Comictools" element={<Editor></Editor>}></Route>
        <Route
          path="/ErrorDialog"
          element={<ErrorDialog></ErrorDialog>}
        ></Route>

        <Route
          path="/createpassword"
          element={<Createpassword></Createpassword>}
        ></Route>
      </Routes>
    </div>
  );
};

export default RoutingPages;
