import { FunctionComponent, useEffect } from "react";
import {
  DataGrid,
  GridPagination,
  GridToolbar,
  useGridApiContext,
} from "@mui/x-data-grid";
import { GridSlotsComponentsProps } from "@mui/x-data-grid";
import { useState } from "react";
import {
  SearchOutlined,
  RefreshOutlined,
  RemoveCircleOutline,
} from "@mui/icons-material";
import "../App.css";
import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import Select from "./Select";
import {
  Box,
  IconButton,
  InputAdornment,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { TablePaginationProps } from "@mui/material";
import { useGridSelector } from "@mui/x-data-grid";
import { gridPageCountSelector } from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
import "../Pages/Page.css";

// import { list_userPermission } from "../redux/UserPermission/list_UserPermission_slicer";
// import { add_UserPermission } from "../redux/UserPermission/add_UserPermission_slicer";
// import { add_gatePass } from "../redux/GatePass/add_GatePass_slicer";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { list_userPermission } from "../Redux/Reducers/UserPermission/list_UserPermission_slicer";
import { add_UserPermission } from "../Redux/Reducers/UserPermission/add_UserPermission_slicer";
import PopupMessage from "./PopupMessage";
import { GridRowHeightParams } from "@mui/x-data-grid";
import { GridRowHeightReturnValue } from "@mui/x-data-grid";
import { GridToolbarExport } from "@mui/x-data-grid";
import { GridRowModesModel } from "@mui/x-data-grid";
import { GridCallbackDetails } from "@mui/x-data-grid";
import { GridEventListener } from "@mui/x-data-grid";
import TextInputField from "./TextInput/TextInputField";
import {
  list_OrderManagement,
  ordermanagement_search,
} from "../Redux/Reducers/OrderManagement/list_OrderManagement_slicer";
// import PopupMessage from "./PopupMessage";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker";

//  footer button
type FooterStatus = "connected" | "disconnected";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    status: FooterStatus;
    gatpass?: any;
    pagination?: any;
    Userpermissions?: any;
  }
}

interface GridsProps {
  Userpermissions?: any;
  pagination?: any;
  gatpass?: boolean | undefined;
  scrollbarSize?: number | undefined;
  column?: any;
  Users?: any;
  dialog?: any;
  row?: any;
  serach?: any;
  open?: boolean;
  opens?: boolean;

  Camera?: boolean;
  UserPermission?: boolean;
  ServiceStatus?: boolean;
  data?: any;
  roleData?: any;
  Submitbutton?: any;
  Gatepassbutton?: any;
  Servicestatusbutton?: any;
  componet?: any;
  height?: any;
  setDate?: any;
  getRowId?: string;
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
  export?: boolean;
  loading?: boolean;
  processRowUpdate?: (newRow: any, oldRow: any) => any;
  orderListSearch?: boolean;
}

//  Style the component

const useStyles = makeStyles({
  dataGrid: {
    "& .MuiDataGrid-virtualScroller": {
      "&::-webkit-scrollbar": {
        width: "0px",
        height: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "grey",
        borderRadius: "5px",
      },
    },
  },
});

//    Custome Toolbar
const CustomToolbar = (props: any) => {
  // const Editdata = useAppSelector((state) => state.editGatePass.data);
  const dispatche = useAppDispatch();
  // console.log(
  //   props?.data?.filter(
  //     (item: any) => item.name.toLowerCase().replace(/\s/g, "") !== "superadmin"
  //   )
  // );

  // order list search and remove
  const { remove: removeIcon, search: searchValue } = useAppSelector(
    (state) => state.ListOrderManagement
  );
  const userRole = localStorage
    .getItem("role")
    ?.toLowerCase()
    .replace(/\s/g, "");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginLeft: "1px",
        alignItems: "center",
      }}
    >
      <div>
        <span
          style={{
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "23px",

            color: "#000000",
          }}
        >
          {props.Users}
        </span>
      </div>
      {props.ServiceStatus ? (
        <div style={{ display: "flex", margin: "10px 20px 0px 0px  " }}>
          <div style={{ width: "100%" }}></div>

          <div>
            <TextField
              className="textfieldinput"
              id="outlined-basic"
              type="date"
              placeholder="DD/MM/YYYY"
              onChange={(event: any) => {
                props.setDate(event?.target?.value);
                // dispatche(add_gatePass(event?.target?.value));
              }}
              // value={props.values.Date}
              inputProps={{
                placeholder: "Enter Your Date",
                style: {
                  textTransform: "uppercase",
                  color: "gray",
                },
              }}
              // label={"Vehicle N"}
              variant="outlined"
              name="Date"
              autoComplete="off"
              style={{
                width: "20rem",
                height: "56px",
              }}
            />
          </div>
        </div>
      ) : //   {/* </Form>
      // </Formik> */}
      undefined}
      {props.Permission ? (
        <div>
          <Select
            name="role"
            placeholder={"Select Role"}
            Listitem={props?.data
              .filter((data: any) => {
                return data.active === "Active";
              })
              ?.filter((item: any) => {
                const role = item?.name?.toLowerCase().replace(/\s/g, "");
                if (JSON.parse(userRole ?? "") === "superadmin") {
                  return true;
                }
                return role !== "superadmin";
              })
              .map((v: any) => ({
                label: v.name,
                value: v.id,
              }))}
            size="small"
            sx={{ width: "clamp(10rem,20vw,25rem)" }}
            onchange={(e: any, val: any) => {
              props.setRoleId(val?.value);
              dispatche(list_userPermission(val?.value));
            }}
          ></Select>
        </div>
      ) : undefined}
      {props.show ? (
        <div
          style={{
            display: "flex",
            gap: "1em",
            flexWrap: "wrap",
            justifyContent: "flex-end",
          }}
        >
          <div>
            <GridToolbar
              {...props}
              sx={{
                "& fieldset": {
                  textDecoration: "none",
                  // borderBottom: "1px solid red",
                },
                "& .MuiInput-root": {
                  borderBottom: "none !important",
                  "&:hover": {
                    borderBottom: "none !important",
                  },
                  "&:before": {
                    borderBottom: "none !important",
                  },
                  "&:after": {
                    borderBottom: "none !important",
                  },
                },
                height: "37px",
                // marginTop: "0px",
                // padding: "0 10px",
                border: "1px solid #BDBDBD",
                borderRadius: "0px",
                backgroundColor: "#F5F5F5",
                display: "flex",
                // gap: "0",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                textDecoration: "none", // Add textDecoration: none
              }}
            />
          </div>
          {props.export && (
            <div>
              <GridToolbarExport
                csvOptions={{ disableToolbarButton: true }}
                printOptions={{ hideFooter: true, hideToolbar: true }}
                allColumns
                sx={{
                  padding: "15px",
                  color: "white",
                  maxWidth: "200px",
                  height: "37px",
                  borderRadius: "0",
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "black",
                  },
                  "@media print": {
                    display: "none",
                  },
                }}
              />
            </div>
          )}

          {props?.dialog && <div>{props?.dialog}</div>}
        </div>
      ) : undefined}
      {props.orderListSearch && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <TextInputField
            size="small"
            value={searchValue}
            placeholder="Search..."
            onChange={(event) => {
              dispatche(ordermanagement_search(event.target.value));
            }}
            sx={{
              border: "1px solid #d5d5d5",
              "&:hover": {
                borderColor: "#d5d5d5",
              },
              "&:focus": {
                borderColor: "#d5d5d5",
                borderWidth: 1,
              },
            }}
            InputProps={{
              sx: {
                borderRadius: "0px",
                backgroundColor: "#F5F5F5",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Remove the input border
                },
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatche(list_OrderManagement(searchValue));
              }
            }}
          />
          <Tooltip title={"Refresh"}>
            <IconButton
              onClick={() => {
                dispatche(ordermanagement_search(""));
                dispatche(list_OrderManagement(""));
              }}
            >
              <RefreshOutlined />
            </IconButton>
          </Tooltip>
          {/* <IconButton
            onClick={() => {
              dispatche(
                list_OrderManagement(
                  searchValue && !removeIcon ? searchValue : null
                )
              );
            }}
          >
            {searchValue ? (
              removeIcon ? (
                <RemoveCircleOutline
                  onClick={() => dispatche(ordermanagement_search(""))}
                />
              ) : (
                <SearchOutlined />
              )
            ) : (
              <RefreshOutlined />
            )}
          </IconButton> */}
        </div>
      )}

      {props.shows ? (
        <div style={{ display: "flex", margin: " 0 20px " }}>
          <div style={{ width: "280px", margin: "0 0 20px" }}>
            {/* <GridToolbar
              {...props}
              sx={{
                "& fieldset": { textDecoration: "none" },
              }}
              style={{
                width: "17rem",
                height: "37px",
                marginTop: "0px",
                padding: "0 10px",
                border: "1px solid #BDBDBD",
                borderRadius: "0px",
                backgroundColor: "#F5F5F5",
                display: "flex",
                gap: "unset",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                textDecoration: "none", // Add textDecoration: none
              }}
            /> */}
          </div>

          <div>{props?.dialog}</div>
        </div>
      ) : undefined}

      {props.CameraButton ? (
        <div style={{ display: "flex", margin: " 0 20px " }}>
          <div style={{ width: "280px", margin: "0 0 20px" }}>
            <GridToolbar
              {...props}
              style={{
                width: "16rem",
                height: "37px",
                marginTop: "20px",
                padding: "0 10px ",
                border: "1px solid #BDBDBD",
                borderRadius: "4px",
                display: "flex",
                gap: "unset",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            />
          </div>
        </div>
      ) : undefined}
    </div>
  );
};

const Grids: FunctionComponent<GridsProps> = (props) => {
  //  Style the data grid scroll bar

  const classes = useStyles();

  /*    print event listener     */
  const printEvent = useMediaQuery("@media print");

  // message show submit

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatche = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState<any[]>([]);
  // const [columns] = useState<any[]>(props.column);

  //    useState for userpermissions to getting role id
  const [roleId, setRoleId] = useState(0);

  //    for getting values from data gride
  function useApiRef() {
    const apiRef = React.useRef<any>(null);
    const _columns = React.useMemo(
      () =>
        props.column.concat({
          field: "",
          flex: 0,
          minWidth: 0,
          minHeight: 0,
          width: 0,
          height: 0,
          type: "actions",
          renderCell: (params: any) => {
            apiRef.current = params.api;
            return null;
          },
        }),
      [props.column]
    );

    return { apiRef, columns: _columns };
  }

  const { apiRef, columns } = useApiRef();
  const handleClickButton = () => {
    const array = Array.from(apiRef.current.getRowModels());
    const result: any = array.map((v: any) => {
      return { ...v[1], roleId: roleId };
    });
    console.log(result);

    dispatche(add_UserPermission(result));
  };
  // const datas = useAppSelector((state) => state.User.value);
  // console.log(datas);

  const requestSearch = (searchValue: string) => {
    const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
    const filteredRows = props?.row.filter((o: any) => {
      return Object.keys(o).some((k: any) => {
        return searchRegex.test(o[k].toString());
      });
    });
    setTableData(filteredRows);
  };

  useEffect(() => {
    setTableData(props?.row);
  });

  const cancelSearch = () => {
    setSearchText("");
    requestSearch(searchText);
  };
  // console.log(tableData);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  function Pagination({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event: any, newPage: any) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  }

  // function CustomPagination(props: any) {
  // }
  function CustomFooterStatusComponent(
    props: NonNullable<GridSlotsComponentsProps["footer"]> | any
  ) {
    // CustomPagination(props: any);
    const [dialog, setDialog] = useState(0);
    const addUserPermissionResponse: any = useAppSelector(
      (state) => state.AddUserPermission.data
    );
    const addUserPermissionLoading: any = useAppSelector(
      (state) => state.AddUserPermission.isLoading
    );

    return (
      <div className={classes.dataGrid}>
        {/* pageination component */}
        {props?.pagination && (
          <GridPagination
            sx={{
              "@media print": {
                display: "none",
              },
            }}
            ActionsComponent={Pagination}
            {...props}
          />
        )}
        {/* Gate pass button */}
        {props?.gatpass && (
          <button
            className="rightAllign"
            style={{
              padding: "10px",
              backgroundColor: "#1976D2",
              color: "white",
              width: "140px",
              height: "40px",
              cursor: "pointer",
              borderRadius: "4px",
              fontSize: "14px",
              fontWeight: "bold",
              border: "1px solid #BDBDBD",
              margin: "10px 10px 10px 10px ",
            }}
            onClick={() =>
              setStatus((current) =>
                current === "connected" ? "disconnected" : "connected"
              )
            }
          >
            Gate Pass
          </button>
        )}
        {/* userpermissions submit button  */}
        {props?.Userpermissions && (
          <div className="rightAllign">
            <Button
              variant="outlined"
              onClick={() => {
                setDialog(dialog + 0.001);
                // handleClickButton();
              }}
              style={{
                padding: "10px",
                backgroundColor: "black",
                color: "white",
                width: "140px",
                height: "40px",
                borderRadius: "0px",
                fontSize: "14px",
                fontWeight: "bold",
                float: "right",
                cursor: "pointer",
                border: "1px solid #BDBDBD",
                margin: "10px 10px 10px 10px ",
              }}
            >
              Submit
            </Button>
            <PopupMessage
              successMessage={addUserPermissionResponse}
              open={dialog}
              Dialogtitle={"Are you sure you want to proceed?"}
              submitFunction={handleClickButton}
              isLoading={addUserPermissionLoading}
              closeFunction={() => null}
            />
          </div>
          // <div className="rightAllign">
          //   <Button
          //     variant="outlined"
          //     onClick={() => {
          //       handleClickOpen();
          //       handleClickButton();
          //     }}
          //     style={{
          //       padding: "10px",
          //       backgroundColor: "#1976D2",
          //       color: "white",
          //       width: "140px",
          //       height: "40px",
          //       borderRadius: "4px",
          //       fontSize: "14px",
          //       fontWeight: "bold",
          //       cursor: "pointer",
          //       border: "1px solid #BDBDBD",
          //       margin: "10px 10px 10px 10px ",
          //     }}
          //   >
          //     Submit
          //   </Button>
          //   <Dialog
          //     open={open}
          //     onClose={handleClose}
          //     aria-labelledby="alert-dialog-title"
          //     aria-describedby="alert-dialog-description"
          //   >
          //     <DialogTitle
          //       id="alert-dialog-title"
          //       style={{ fontSize: "20px", textAlign: "center" }}
          //     >
          //       {"Are You Sure Went to Submit "}
          //     </DialogTitle>
          //     <DialogContent>
          //       <DialogContentText id="alert-dialog-description">
          //         <div>
          //           <TaskAltIcon
          //             style={{
          //               color: "green",
          //               width: "80px",
          //               height: "80px",
          //               margin: "10px 0px 0px 140px ",
          //             }}
          //           ></TaskAltIcon>
          //           <div style={{ fontSize: "20px", textAlign: "center" }}>
          //             {/* Submitted Successfully */}
          //           </div>
          //         </div>
          //       </DialogContentText>
          //     </DialogContent>
          //     <DialogActions>
          //       <Button
          //         onClick={handleClose}
          //         style={{
          //           padding: "15px",
          //           backgroundColor: "#1976D2",
          //           color: "white",
          //           width: "100px",
          //           height: "37px",
          //           borderRadius: "4px",
          //           margin: "0px 20px 0px 90px ",
          //           border: "1px solid #BDBDBD",
          //         }}
          //       >
          //         Yes
          //       </Button>
          //       <Button
          //         onClick={handleClose}
          //         autoFocus
          //         style={{
          //           padding: "15px",
          //           backgroundColor: "#1976D2",
          //           color: "white",
          //           width: "100px",
          //           height: "37px",
          //           borderRadius: "4px",
          //           margin: "0px 100px 0px 0px ",
          //           border: "1px solid #BDBDBD",
          //         }}
          //       >
          //         No
          //       </Button>
          //     </DialogActions>
          //   </Dialog>
          // </div>

          // <button
          //   style={{
          //     padding: "10px",

          //     backgroundColor: "#1976D2",

          //     color: "white",
          //     width: "140px",
          //     height: "40px",
          //     borderRadius: "4px",
          //     fontSize: "14px",
          //     fontWeight: "bold",
          //     cursor: "pointer",
          //     border: "1px solid #BDBDBD",
          //     marginLeft: "1300px",
          //     marginTop: "10px",
          //     marginBottom: "20px",
          //     left: "0",
          //     right: "0",
          //     bottom: "0",
          //   }}
          //   onClick={handleClickButton}
          // >
          //   Submit
          // </button>
        )}
      </div>
    );
  }

  // console.log(tableData);

  // footer button
  const [status, setStatus] = React.useState<FooterStatus>("connected");
  return (
    <>
      <div
        style={{
          height: props.height || "90%",
          width: "100%",
          backgroundColor: "white",
          // border: "none",
          transition: "all 1s linear",
          borderRadius: "0px",
          position: "sticky",
          zIndex: "1",
          top: "0",
          overflow: "auto", // Add this line to hide the scrollbar

          boxShadow: "6px 6px 20px rgba(0, 0, 0, 0.15)",
        }}
        className={`${classes.dataGrid} Questionpageconent`}
      >
        <DataGrid
          sx={{
            borderRadius: "0",
            // Remove the border inside the header rows
            "& .MuiDataGrid-columnHeader, & .MuiDataGrid-columnHeaderTitle": {
              borderBottom: "none",
            },
            "& .css-axafay-MuiDataGrid-virtualScroller": {
              overflowX: "hidden",
            },
            "&::-webkit-scrollbar": { display: "none" },
            border: "none",
            justifyContent: "center",
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          getRowHeight={props.getRowHeight}
          slots={{
            toolbar: CustomToolbar,
            // columnHeaders: () => null,
            // footer: CustomFooterStatusComponent,
            footer: CustomFooterStatusComponent,
          }}
          getRowClassName={(params) => {
            // Add the "no-border" class to rows without border
            return params.row.shouldHaveBorder
              ? "custom-row"
              : "custom-row no-border";
          }}
          slotProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              Users: props.Users,
              dialog: props.dialog,
              show: props.open,
              shows: props.opens,
              CameraButton: props.Camera,
              Permission: props.UserPermission,
              data: props?.roleData,
              setRoleId: setRoleId,
              ServiceStatus: props.ServiceStatus,
              setDate: props.setDate,
              export: props.export,
              orderListSearch: props.orderListSearch,
            },
            footer: {
              gatpass: props.gatpass,
              pagination: props.pagination,
              Userpermissions: props.Userpermissions,
            },
          }}
          disableColumnMenu
          // components={{ Toolbar: CustomToolbar }}
          rows={tableData}
          columns={props.Userpermissions ? columns : props.column}
          getRowId={(row) => (row?.field_id ? row?.field_id : row.id)}
          // density="compact"
          // autoHeight={true}
          // onRowDoubleClick={(e) => {
          //   // console.log(e);
          // }}
          // rowSelection={false}
          // {...tableData}
          // initialState={{
          //   ...tableData.initialState,
          //   pagination: { paginationModel: { pageSize: 5 } },
          // }}
          // // rowCount={15}
          // pageSizeOptions={[5, 10, 20, 50, 100]}
          // componet={component}
          // autoHeight={true}
          // hideFooterPagination
          // scrollbarSize
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          {...tableData}
          disableRowSelectionOnClick
          loading={props.loading}
          //   pageSize={5}
          // rowsPerPageOptions={[5]}
          processRowUpdate={props.processRowUpdate}
        />
      </div>
      {/* {props.Submitbutton ? (
        <button
          style={{
            padding: "10px",
            backgroundColor: "blue",
            color: "white",
            width: "140px",
            height: "40px",
            borderRadius: "4px",
            fontSize: "14px",
            fontWeight: "bold",
            border: "1px solid gray",
            marginLeft: "1330px",
            marginTop: "20px",
          }}
        >
          Submit
        </button>
      ) : undefined} */}
      {/* {props.Gatepassbutton ? (
        <button
          style={{
            padding: "10px",
            backgroundColor: "blue",
            color: "white",
            width: "140px",
            height: "40px",
            borderRadius: "4px",
            fontSize: "14px",
            fontWeight: "bold",
            border: "1px solid gray",
            marginLeft: "1330px",
            current === "connected" ? "disconnected" : "connected"
            marginTop: "20px",
          }}
          onClick={() =>
            setStatus((current) =>
            )
          }
        >
          Gate Pass
        </button>
      ) : undefined} */}
      {props.Servicestatusbutton ? (
        <div style={{ display: "flex" }}>
          <div>
            <button
              style={{
                padding: "10px",
                backgroundColor: "#1976D2",
                color: "white",
                width: "140px",
                height: "40px",
                borderRadius: "4px",
                fontSize: "14px",
                cursor: "pointer",
                fontWeight: "bold",
                border: "1px solid #BDBDBD",
                marginLeft: "1170px",
                marginTop: "20px",
              }}
              onClick={() =>
                setStatus((current) =>
                  current === "connected" ? "disconnected" : "connected"
                )
              }
            >
              Completed
            </button>
          </div>
          <div>
            <button
              style={{
                padding: "10px",
                backgroundColor: "#1976D2",
                color: "white",
                width: "140px",
                height: "40px",
                borderRadius: "4px",
                fontSize: "14px",
                fontWeight: "bold",
                border: "1px solid #BDBDBD",
                marginLeft: "20px",
                cursor: "pointer",
                marginTop: "20px",
              }}
              onClick={() =>
                setStatus((current) =>
                  current === "connected" ? "disconnected" : "connected"
                )
              }
            >
              Broadcast
            </button>
          </div>
        </div>
      ) : undefined}
    </>
  );
};

export default Grids;
