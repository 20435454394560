import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";

export const EditCover = createSlice({
  name: "EditCover",
  initialState: {
    isLoading: false,
    data: {},
  } as IReduxState<null>,
  reducers: {
    edit_Cover: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_edit_Cover: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_call_edit_Cover: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    clear_edit_Cover: (state) => {
      state.data = {};
    },
  },
});
export default EditCover.reducer;
export const {
  edit_Cover,
  success_api_call_edit_Cover,
  failure_api_call_edit_Cover,
  clear_edit_Cover,
} = EditCover.actions;
