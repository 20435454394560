import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
import { IDeleteQuestion } from "../../../interfaces/IQuestion";

interface IExtend extends IReduxState<null> {
  questionId?: IDeleteQuestion | null;
  openPopup?: boolean;
  openSuccessPopup?: boolean;
}
export const DeleteQuestion = createSlice({
  name: "DeleteQuestion",
  initialState: {
    data: {},
    isLoading: false,
    questionId: null,
    openPopup: false,
    openSuccessPopup: false,
  } as IExtend,
  reducers: {
    deleteQuestion: (state, _) => {
      state.isLoading = true;
    },
    successDeletion: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.openSuccessPopup = true;
    },
    failureDeletion: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.openSuccessPopup = true;
    },
    updateQuestionId: (state, action: PayloadAction<IDeleteQuestion>) => {
      state.questionId = action.payload;
      state.openPopup = true;
    },
    falsePopup: (state) => {
      state.openPopup = false;
      state.questionId = null;
      state.openSuccessPopup = false;
    },
  },
});

export default DeleteQuestion.reducer;
export const {
  deleteQuestion,
  updateQuestionId,
  falsePopup,
  failureDeletion,
  successDeletion,
} = DeleteQuestion.actions;
