import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
import { IOrderManagement } from "../../../interfaces/IOrderManagment";

interface IExtend extends IReduxState<IOrderManagement[]> {
  remove?: boolean;
  search?: string;
}

export const ListOrderManagement = createSlice({
  name: "ListOrderManagement",
  initialState: {
    isLoading: false,
    data: {},
    remove: false,
    search: "",
  } as IExtend,
  reducers: {
    list_OrderManagement: (state, action) => {
      state.isLoading = true;
      state.remove = !!action.payload;
    },
    success_api_call_OrderManagement: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_call_OrderManagement: (state, _) => {
      state.isLoading = false;
    },
    update_shiping_level: (
      state,
      action: PayloadAction<{ id: number; shipping_level: string }>
    ) => {
      const copyState = state.data?.data;
      const index = copyState?.findIndex(
        (item: IOrderManagement) => item.id === action.payload.id
      );
      copyState![index!].shipping_level = action.payload.shipping_level;
      state.data!.data = copyState;
    },
    ordermanagement_search: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      state.remove = false;
    },
  },
});
export default ListOrderManagement.reducer;
export const {
  list_OrderManagement,
  success_api_call_OrderManagement,
  failure_api_call_OrderManagement,
  update_shiping_level,
  ordermanagement_search,
} = ListOrderManagement.actions;
