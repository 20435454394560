import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";

interface ExtendState extends IReduxState<null> {
  isSuccess: boolean;
  isError: boolean;
}
export const EidtCampaign = createSlice({
  name: "EidtCampaign",
  initialState: {
    isLoading: false,
    data: {},
    isSuccess: false,
    isError: false,
  } as ExtendState,
  reducers: {
    eidt_Campaign: (state, _) => {
      state.data = {};
      state.isLoading = true;
    },
    success_api_call_eidt_Campaign: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    failure_api_call_eidt_Campaign: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isError = true;
      // FAILED(action.payload);
    },
    false_success_edit: (state) => {
      state.isSuccess = false;
    },
    false_error_edit: (state) => {
      state.isError = false;
    },
  },
});
export default EidtCampaign.reducer;
export const {
  eidt_Campaign,
  success_api_call_eidt_Campaign,
  failure_api_call_eidt_Campaign,
  false_success_edit,
  false_error_edit,
} = EidtCampaign.actions;
