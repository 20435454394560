import { createSlice } from "@reduxjs/toolkit";

export const editPageSize = createSlice({
  name: "editPageSize",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    edit_PageSize: (state) => {
      state.isLoading = true;
    },
    success_api_call_edit_PageSize: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_edit_PageSize: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      // FAILED(action.payload);
    },
    edit_PageSize_is_success: (state) => {
      state.isSuccess = false;
    },
    edit_PageSize_complete_api: (state) => {
      state.api_completed = false;
    },
  },
});
export default editPageSize.reducer;
export const {
  edit_PageSize,
  success_api_call_edit_PageSize,
  edit_PageSize_complete_api,
  failure_api_call_edit_PageSize,
  edit_PageSize_is_success,
} = editPageSize.actions;
