import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import saga from "../Saga/saga";
import DetailEdit from "../Reducers/Editor_value";
import ProjectidReducer from "../Reducers/Project_id";
// import UserReducer from "../Reducers/User-Register&Login/User_Slicer";
//    User
import UserReducer from "../Reducers/User-Register&Login/list_User_slicer";
import AddUserReducer from "../Reducers/User-Register&Login/add_User_slicer";
import editUserReducer from "../Reducers/User-Register&Login/edit_User_slicer";
//    Role
import RoleReducer from "../Reducers/Role/list_Role_slicer";
import AddRoleReducer from "../Reducers/Role/add_Role_slicer";
import editRoleReducer from "../Reducers/Role/edit_Role_slicer";

//    variant page
import listVariantReducer from "../Reducers/Comics_Booklist/list_Variantlist_slicer";
import VariantReducer from "../Reducers/Variant/list_Variant_slicer";
import AddVariantReducer from "../Reducers/Variant/add_Variant_slicer";
import editVariantReducer from "../Reducers/Variant/edit_Variant_slicer";
import VariantByIdReducer from "../Reducers/Comics_Booklist/list_Variantlist_slicerBy_ID";

//    PageSize page
import PagesizelistReducer from "../Reducers/Pagesizelist/Pagesizelist_slicer";
import PageSizeReducer from "../Reducers/Page Size/list_PageSize_slicer";
import AddPageSizeReducer from "../Reducers/Page Size/add_PageSize_slicer";
import editPageSizeReducer from "../Reducers/Page Size/edit_PageSize_slicer";

// comics book
import ListComicsBookReducer from "../Reducers/Comics Book/list_ComicsBook_slicer";
import AddComicsBookReducer from "../Reducers/Comics Book/add_ComicsBook_slicer";
import DeleteComicsBooksReducer from "../Reducers/Comics Book/delete_ComicsBook_slicer";
import EditComicsBookReducer from "../Reducers/Comics Book/edit_ComicsBook_slicer";
import EditableValueComicsBookReducer from "../Reducers/Comics Book/editableValue";
//  comics book page
import Text_rep from "../Reducers/Comics_Book_Page/text_replace";
import GeneratePDF from "../Reducers/Comics_Book_Page/pdfgeneration";
import ListComicsBookPageReducer from "../Reducers/Comics_Book_Page/list_ComicsBookPage_slicer";
import AddComicsBookPageReducer from "../Reducers/Comics_Book_Page/add_ComicsBookPage_slicer";
import DeleteComicsBookPageReducer from "../Reducers/Comics_Book_Page/delete_ComicsBookPage_slicer";
import AddComicsBookPageJsonValueReducer from "../Reducers/Comics_Book_Page/comics_page_jsonvalue";
import AddComicsBookPageSizeReducer from "../Reducers/Comics_Book_Page/comics_page_size";
import ReorderReducer from "../Reducers/Comics_Book_Page/Reorder_slicer";
import EditablePageValueReducer from "../Reducers/Comics_Book_Page/editablePageValue";
import EditableSpineValueReducer from "../Reducers/Comics_Book_Page/editableSpineValue";
import EidtComicsBookPageReducer from "../Reducers/Comics_Book_Page/edit_ComicsBookPage_slicer";
import AddCoverReducer from "../Reducers/Comics_Book_Page/add_Cover_slicer";
import EditCoverReducer from "../Reducers/Comics_Book_Page/edit_Cover_slicer";
import EditableCoverValueReducer from "../Reducers/Comics_Book_Page/editableCoverValue";
//    question page
import QuestionReducer from "../Reducers/QuestionPage/list_Question_slicer";
import AddQuestionReducer from "../Reducers/QuestionPage/add_Question_slicer";
import editQuestionReducer from "../Reducers/QuestionPage/edit_Question_slicer";
import DeleteQuestionReducer from "../Reducers/QuestionPage/delete_Question_slice";
//    QuestionOption
import QuestionOptionReducer from "../Reducers/QuestionOption/list_QuestionOption_slicer";
import QuestionOptionsReducer from "../Reducers/QuestionOption/QuestionOption_Slicer";
import AddQuestionOptionReducer from "../Reducers/QuestionOption/add_QuestionOption_slicer";
import editQuestionOptionReducer from "../Reducers/QuestionOption/edit_QuestionOption_slicer";

//    MenuItem
import MenuItemReducer from "../Reducers/menuItem/list_menuItem_slicer";
//    UserPermission
import AnthorUserPermissionReducer from "../Reducers/UserPermission/list_anthoruserpermission";
import UserPermissionReducer from "../Reducers/UserPermission/list_UserPermission_slicer";
//    Permission
// import PermissionReducer from "../RoleBased_permissions/list_Permission_slicer";
import AddUserPermissionReducer from "../Reducers/UserPermission/add_UserPermission_slicer";
import AddResetPasswordReducer from "../Reducers/ResetPassword/add_ResetPassword_slicer";
import AddCreatePasswordReducer from "../Reducers/CreatePassword/add_CreatePassword_slicer";
//font
import DeleteFontReducer from "../Reducers/FontLibraries/delete_Font_slicer";

import ListFontReducer from "../Reducers/FontLibraries/list_Font_slicer";
import AddFontReducer from "../Reducers/FontLibraries/add_Font_slicer";
import ListProjectCategoryReducer from "../Reducers/Project Category/list_ProjectCategory_slicer";
// image category
import ListImageCategoryReducer from "../Reducers/Image Category/list_ImageCategory_slicer";
import AddListImageCategoryReducer from "../Reducers/Image Category/add_ImageCategory_slicer";
import editImageCategoryReducer from "../Reducers/Image Category/edit_ImageCategory_slicer";
import DeleteImageCategoryReducer from "../Reducers/Image Category/delete_ImageCategory_slicer";
// image
import ListImageReducer from "../Reducers/ImageLibraries/list_Image_slicer";
import AddImageReducer from "../Reducers/ImageLibraries/add_Image_slicer";
import DeleteimageReducer from "../Reducers/ImageLibraries/delete_Image_slicer";
import editFontReducer from "../Reducers/FontLibraries/edit_Font_slicer";

//    Login
import LoginReducer from "../Reducers/User-Register&Login/Login_slicer";
//    QuestionID
import QuestionIDReducer from "../Reducers/QuestionID/QuestionID_Slicer";
//    TextOptions
import TextOptionsReducer from "../Reducers/TextOptions/TextOptions_Slicer";
import TextOptionReducer from "../Reducers/TextOptions/listOfTextOption_Slicer";
import TextOptionsListReducer from "../Reducers/TextOptions/textOptionsList_Slicer";
//    QuestionImages
import QuestionImagesReducer from "../Reducers/QuestionImages/QuestionImages_Slicer";
import matrixQuestionLayerReducer from "../Reducers/QuestionImages/delete_matrixQuestionLayer_slicer";
//    ProjectID
import ProjectIDReducer from "../Reducers/ProjectID/ProjectID_Slicer";
/*    Campain    */
import AddCampaignReducer from "../Reducers/Campaign/add_Campaign_slicer";
import ListCampaignReducer from "../Reducers/Campaign/list_Campaign_slicer";
import DeleteCampaignReducer from "../Reducers/Campaign/delete_Campaign_slicer";
import EditableCampaignReducer from "../Reducers/Campaign/editable_Campaign_slicer";
import EidtCampaignReducer from "../Reducers/Campaign/edit_Campaign_slicer";
import LaunchAndPassCampaignReducer from "../Reducers/Campaign/launch&pass_Campaign_slicer";
import RelaunchCampaignReducer from "../Reducers/Campaign/relaunch_Campaign_slicer";
import UsedVariantReducer from "../Reducers/Campaign/list_usedvariant_slicer";
import UsedProjectIdsReducer from "../Reducers/Campaign/list_usedprojectIds_slicer";
//    AddPageQuestions
import AddPageQuestionsReducer from "../Reducers/PageQuestions/add_PageQuestions_slicer";
import SetPageQuestionsIdReducer from "../Reducers/PageQuestions/set_PageQuestionsID_slicer";
import DeletePageQuestionsReducer from "../Reducers/PageQuestions/delete_PageQusetions_slicer";
import ListPageQuestionsReducer from "../Reducers/PageQuestions/list_PageQuestions_slicer";
//    Expire Signature
import expireSignatureReducer from "../Reducers/ExpireSignature/ExpireSignature_slicer";
import VariantListReducer from "../Reducers/Campaign/VariantList_Slicer";
/*    Order management     */
import ListOrderManagementReducer from "../Reducers/OrderManagement/list_OrderManagement_slicer";
import GetShopifyRedirectUrlReducer from "../Reducers/Shopify/shopify_redirect_url_slicer";
import SendShopifyRedirectUrlReducer from "../Reducers/Shopify/shopify_redirect_url_send_slicer";
/*    PDF */
import CustomerPDFGenarationReducer from "../Reducers/PDF/Customer_pdf";
import PDFGenarationReducer from "../Reducers/PDF/PDF_genaration_slicer";
import CoverPDFGenarationReducer from "../Reducers/PDF/Cover_PDF_genaration_slicer";
/*    LuluPrint    */
import LuluPrintReducer from "../Reducers/LuluPrint/LuluPrint_slicer";
import LuluReprintReducer from "../Reducers/LuluPrint/Lulu_reprint_slicer";
// preview
import AddPreviewPageReducer from "../Reducers/Preview_page/add_previewpage_s;icer";
let sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    //    User
    User: UserReducer,
    AddUser: AddUserReducer,
    Login: LoginReducer,
    editUser: editUserReducer,

    //   font
    AnthorUserPermission: AnthorUserPermissionReducer,

    UserPermission: UserPermissionReducer,
    AddUserPermission: AddUserPermissionReducer,

    //   Image
    ListImage: ListImageReducer,
    AddImage: AddImageReducer,
    DeleteFont: DeleteFontReducer,
    editFont: editFontReducer,
    Deleteimage: DeleteimageReducer,

    //    UserPermission
    ListFont: ListFontReducer,
    AddFont: AddFontReducer,
    // project
    ListProjectCategory: ListProjectCategoryReducer,
    // ImageCategory
    ListImageCategory: ListImageCategoryReducer,
    editImageCategory: editImageCategoryReducer,
    AddListImageCategory: AddListImageCategoryReducer,
    DeleteImageCategory: DeleteImageCategoryReducer,

    AddCreatePassword: AddCreatePasswordReducer,
    AddResetPassword: AddResetPasswordReducer,

    //    Role
    Role: RoleReducer,
    AddRole: AddRoleReducer,
    editRole: editRoleReducer,
    //    Variant page
    Variant: VariantReducer,
    AddVariant: AddVariantReducer,
    editVariant: editVariantReducer,
    listVariant: listVariantReducer,
    VariantById: VariantByIdReducer,

    //    PageSize page
    Pagesizelist: PagesizelistReducer,
    PageSize: PageSizeReducer,
    AddPageSize: AddPageSizeReducer,
    editPageSize: editPageSizeReducer,
    //    Question page
    Questions: QuestionReducer,
    AddQuestion: AddQuestionReducer,
    editQuestion: editQuestionReducer,
    DeleteQuestion:DeleteQuestionReducer,
    //    Comics book
    ListComicsBook: ListComicsBookReducer,
    AddComicsBook: AddComicsBookReducer,
    DeleteComicsBooks: DeleteComicsBooksReducer,
    EditComicsBook: EditComicsBookReducer,
    EditableValueComicsBook: EditableValueComicsBookReducer,
    //    Comics book  page
    Textreplace: Text_rep,
    PDFgeneration: GeneratePDF,
    ListComicsBookPage: ListComicsBookPageReducer,
    AddComicsBookPage: AddComicsBookPageReducer,
    DeleteComicsBookPage: DeleteComicsBookPageReducer,
    AddComicsBookPageJsonValue: AddComicsBookPageJsonValueReducer,
    AddComicsBookPageSize: AddComicsBookPageSizeReducer,
    Reorder: ReorderReducer,
    EditablePageValue: EditablePageValueReducer,
    EditableSpineValue: EditableSpineValueReducer,
    EidtComicsBookPage: EidtComicsBookPageReducer,
    AddCover: AddCoverReducer,
    EditCover: EditCoverReducer,
    EditableCoverValue: EditableCoverValueReducer,
    //    QuestionOption
    QuestionOption: QuestionOptionReducer,
    QuestionOptions: QuestionOptionsReducer,
    AddQuestionOption: AddQuestionOptionReducer,
    editQuestionOption: editQuestionOptionReducer,
    //    PDF
    Customer_pdf: CustomerPDFGenarationReducer,
    PDFGenaration: PDFGenarationReducer,
    CoverPDFGenaration: CoverPDFGenarationReducer,

    //    QuestionID
    QuestionID: QuestionIDReducer,

    //    TextOptions(Questions)
    TextOptions: TextOptionsReducer,
    TextOption: TextOptionReducer,
    //  TextOptions
    TextOptionsList: TextOptionsListReducer,
    //    expireSignature
    ExpireSignature: expireSignatureReducer,

    //    MenuItem
    MenuItem: MenuItemReducer,
    // //    menu for find userpermisson id
    // Menu: MenuReducer,
    // //    menuIndex for userpermisson
    // MenuIndex: menuIndexReducer,

    //    QuestionImages
    QuestionImages: QuestionImagesReducer,
    matrixQuestionLayer: matrixQuestionLayerReducer,
    //    ProjectID
    ProjectID: ProjectIDReducer,

    //    Campaign
    AddCampaign: AddCampaignReducer,
    ListCampaign: ListCampaignReducer,
    DeleteCampaign: DeleteCampaignReducer,
    EditableCampaign: EditableCampaignReducer,
    EidtCampaign: EidtCampaignReducer,
    LaunchAndPauseCampaign: LaunchAndPassCampaignReducer,
    RelaunchCampaign: RelaunchCampaignReducer,
    UsedVariant: UsedVariantReducer,
    UsedProjectIds: UsedProjectIdsReducer,

    //    PageQuestions
    AddPageQuestions: AddPageQuestionsReducer,
    SetPageQuestionsId: SetPageQuestionsIdReducer,
    DeletePageQuestions: DeletePageQuestionsReducer,
    ListPageQuestions: ListPageQuestionsReducer,

    /*    Order management     */
    ListOrderManagement: ListOrderManagementReducer,
    GetShopifyRedirectUrl: GetShopifyRedirectUrlReducer,
    SendShopifyRedirectUrl: SendShopifyRedirectUrlReducer,

    DetailEdit: DetailEdit,
    Projectid: ProjectidReducer,

    /*    LuluPrint    */
    LuluPrint: LuluPrintReducer,
    LuluReprint: LuluReprintReducer,
    // preview
    AddPreviewPage: AddPreviewPageReducer,
    /*    stores only    */
    /* VariantList */
    VariantList: VariantListReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});
sagaMiddleware.run(saga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
