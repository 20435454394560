import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";

export const SendShopifyRedirectUrl = createSlice({
  name: "SendShopifyRedirectUrl",
  initialState: {
    isLoading: false,
    data: "",
  } as IReduxState<string>,
  reducers: {
    send_url: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_send_url: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_call_send_url: (state, _) => {
      state.isLoading = false;
    },
    clear_SendShopifyRedirectUrl: (state) => {
      state.data = {};
    },
  },
});
export default SendShopifyRedirectUrl.reducer;
export const {
  send_url,
  success_api_call_send_url,
  failure_api_call_send_url,
  clear_SendShopifyRedirectUrl,
} = SendShopifyRedirectUrl.actions;
