import "../App.css";
import "../Pages/Page.css";

import { InputAdornment, TextField } from "@mui/material";
import { FunctionComponent, useEffect } from "react";
// import Dialog from "@mui/material/Dialog";
import Rupee from "../Pages/Image/bx_rupee.svg";
import { FormHelperText } from "@mui/material";
//
import { Formik, Form, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import Autocomplete from "../Component/Autocomplete";
import uploadImage from "../Pages/Image/UploadCloudImage.png";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import {
  add_variant,
  edit_variant,
  popup_close,
} from "../Redux/Reducers/Campaign/VariantList_Slicer";
import TextInputField from "../Component/TextInput/TextInputField";
import { popupOpen } from "../Redux/Reducers/Campaign/list_usedvariant_slicer";

interface VariantFormProps {
  formik?: React.RefObject<FormikProps<ICapaignVariantForm>>;
}
export interface ICapaignVariantForm {
  id?: number | null;
  field_id?: number | null;
  compared_price?: number | null;
  variant_id?: number | null;
  available?: boolean | null;
  price?: number | null;
  base_cost?: number | null;
  image_url?: File | null | string;
  campaign_id?: number;
  variantName?: string;
  pod_package_id?: string;
  shipping_level?: string;
}
const VariantForm: FunctionComponent<VariantFormProps> = (props) => {
  /*    dispatch the props     */
  const { formik } = props;
  /*    dispatch for redux     */
  const dispatch = useAppDispatch();
  /*    Redux State    */
  const editVariant = useAppSelector((state) => state.VariantList.editValue);
  const listOfVariant = useAppSelector((state) => state.VariantById.data);
  /*    formik initial values    */
  const initialValues: ICapaignVariantForm = {
    id: editVariant.id,
    field_id: editVariant.field_id,
    compared_price: editVariant.compared_price ?? null,
    variant_id: editVariant.variant_id ?? null,
    base_cost: editVariant.base_cost ?? null,
    available: editVariant.available ?? null,
    price: editVariant.price ?? null,
    image_url: editVariant.image_url ?? null,
    pod_package_id: editVariant.pod_package_id ?? "",
    shipping_level: editVariant.shipping_level ?? "",
  };

  useEffect(() => {
    return () => {
      dispatch(popup_close());
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formik}
      validationSchema={Yup.object().shape({
        variant_id: Yup.number().required("Please select a variant name."),
        // compared_price: Yup.number()
        //   // .moreThan(
        //   //   Yup.ref("price"),
        //   //   "Compared price must be greater than price"
        //   // )
        //   .required("Please enter Compared Price (in INR)."),
        available: Yup.boolean().required("Please select availability."),
        price: Yup.number()
          // .moreThan(
          //   Yup.ref("base_cost"),
          //   "Price must be greater than base cost"
          // )
          .required("Please enter price (in INR)."),
        // base_cost: Yup.number()
        //   // .lessThan(Yup.ref("price"), "Base cost must be less than price")
        //   .required("Please enter Base cost (in INR)."),
        image_url: Yup.mixed()
          .test(
            "type",
            "Only the following formats are accepted: .jpeg, .jpg, and .png",
            (value: any) => {
              if (typeof value === "string") return true; // Skip validation if value is not provided
              return (
                value &&
                (value.type === "image/jpeg" ||
                  value.type === "image/png" ||
                  value.type === "image/jpg")
              );
            }
          )
          .test(
            "max-length",
            "File name must be less than or equal to 35 characters",
            (value: any) => {
              if (!value) return true; // Skip validation if value is not provided
              if (typeof value === "string") return true; // Skip validation if value is not provided
              const fileName = value.name.length;
              return fileName <= 35;
            }
          )
          .required("Please upload the variant image"),
        pod_package_id: Yup.string().required("Please enter pod package id"),
        // shipping_level: Yup.string().required("Please enter shipping level"),
      })}
      onSubmit={(values) => {
        if (Object.keys(editVariant).length === 0) {
          dispatch(add_variant(values));
        } else {
          dispatch(edit_variant(values));
        }
      }}
    >
      {(props) => {
        return (
          <Form id="form" autoComplete="off">
            {/*     for submit when enter button click     */}
            <button type="submit" style={{ display: "none" }}>
              submit
            </button>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "1em",
              }}
            >
              <div
                style={{
                  width: "clamp(20rem,30vw,30rem)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5em",
                }}
              >
                <div className="lableInputDiv">
                  <b>
                    Variant Name
                    <span style={{ color: "red" }}>*</span>
                  </b>
                  <Autocomplete
                    Listitem={listOfVariant.map((v: any) => {
                      return { label: v.name, value: v.id };
                    })}
                    noOptionsText="Please select product"
                    // required
                    variant={"outlined"}
                    name={"variant_id"}
                    value={props.values}
                    onBlur={props.handleBlur}
                    onchange={(e: any) => {
                      props.setFieldValue("variant_id", e.value);
                      dispatch(popupOpen(e.value));
                    }}
                    helperText={
                      props.errors.variant_id &&
                      props.touched.variant_id &&
                      props.errors.variant_id
                    }
                    error={props.errors.variant_id && props.touched.variant_id}
                  ></Autocomplete>
                </div>
                <div className="lableInputDiv">
                  <b>
                    Variant Image
                    <span style={{ color: "red" }}>*</span>
                  </b>
                  <label
                    htmlFor={`ImageUpload`}
                    className="variantImageUpload"
                    style={{
                      border:
                        props.touched.image_url && props.errors.image_url
                          ? "1px solid #d32f2f"
                          : undefined,
                    }}
                  >
                    <input
                      type="file"
                      name="image_url"
                      id={`ImageUpload`}
                      hidden
                      accept="image/jpeg,image/jpg,image/png"
                      onChange={async (
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        // const data = await convertToBase64(e.target.files![0]);
                        // setImage(e.target.files![0]);
                        // props.getImage!(e.target.files);
                        props.setFieldTouched("image_url", true);
                        props.setFieldValue("image_url", e.target.files![0]);
                      }}
                    />
                    <img
                      src={uploadImage}
                      srcSet={
                        props.values.image_url
                          ? typeof props.values.image_url === "string"
                            ? props.values.image_url.replace(/\s/g, "%20")
                            : URL?.createObjectURL(props.values.image_url)
                          : uploadImage
                      }
                      // srcSet={
                      //   props.values.image_url
                      //     ? URL.createObjectURL(props.values.image_url)
                      //     : uploadImage
                      // }
                      height={55}
                      width={55}
                    />
                    <span
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: !props.values.image_url
                          ? "rgba(0, 0, 0, 0.25)"
                          : undefined,
                      }}
                    >
                      {props.values.image_url ? (
                        <>
                          {typeof props.values.image_url === "string"
                            ? props.values.image_url.split("/").pop()
                            : props.values.image_url?.name}
                        </>
                      ) : (
                        "Choose variant image"
                      )}
                    </span>
                  </label>
                  <FormHelperText error sx={{ marginLeft: "14px" }}>
                    <ErrorMessage name="image_url" />
                  </FormHelperText>
                </div>
                <div className="lableInputDiv">
                  <b>
                    Available
                    <span style={{ color: "red" }}>*</span>
                  </b>
                  <Autocomplete
                    Listitem={[
                      { label: "Available", value: true },
                      { label: "Unavailable", value: false },
                    ]}
                    // required
                    variant={"outlined"}
                    name={"available"}
                    value={props.values}
                    onBlur={props.handleBlur}
                    onchange={(e: any) => {
                      // console.log(e);

                      props.setFieldValue("available", e.value);
                    }}
                    error={props.errors.available && props.touched.available}
                    helperText={
                      props.errors.available &&
                      props.touched.available &&
                      props.errors.available
                    }
                  ></Autocomplete>
                </div>
                <div className="lableInputDiv">
                  <b>
                    POD Package ID
                    <span style={{ color: "red" }}>*</span>
                  </b>
                  <div style={{ display: "flex" }}>
                    <TextInputField
                      variant="outlined"
                      name="pod_package_id"
                      fullWidth
                      helperText={
                        props.errors.pod_package_id &&
                        props.touched.pod_package_id &&
                        props.errors.pod_package_id
                        // : "Enter your Email."
                      }
                      error={
                        !!(
                          props.errors.pod_package_id &&
                          props.touched.pod_package_id
                        )
                      }
                      // onChange={props.handleChange}

                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.pod_package_id}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "clamp(20rem,30vw,30rem)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5em",
                }}
              >
                <div className="lableInputDiv">
                  <b>
                    Base Cost
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </b>
                  <div style={{ display: "flex" }}>
                    <TextField
                      id="outlined-basic"
                      // required
                      variant="outlined"
                      name="base_cost"
                      type="number"
                      sx={{
                        "& fieldset": {
                          borderRadius: "0px",
                        },
                        width: "100%",
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={Rupee} />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="off"
                      helperText={
                        props.errors.base_cost &&
                        props.touched.base_cost &&
                        props.errors.base_cost
                        // : "Enter your Email."
                      }
                      error={
                        !!(props.errors.base_cost && props.touched.base_cost)
                      }
                      // onChange={props.handleChange}

                      onChange={(e) => {
                        // Remove any non-numeric characters except for one decimal point
                        const sanitizedInput = e.target.value.replace(
                          /[^0-9.]/g,
                          ""
                        );

                        // Ensure only one decimal point exists
                        const decimalCount =
                          sanitizedInput.split(".").length - 1;
                        if (decimalCount > 1) return;

                        // Allow up to 3 digits after the decimal point
                        const decimalPart = sanitizedInput.split(".")[1];
                        if (decimalPart && decimalPart.length > 3) return;

                        props.setFieldValue("base_cost", sanitizedInput);
                      }}
                      onBlur={props.handleBlur}
                      value={props.values.base_cost}
                      // onChange={props.handleChange}
                    />
                  </div>
                </div>
                <div className="lableInputDiv">
                  <b>
                    Price
                    <span style={{ color: "red" }}>*</span>
                  </b>
                  <div style={{ display: "flex" }}>
                    {/* </div> */}
                    <TextField
                      id="outlined-basic"
                      // required
                      variant="outlined"
                      name="price"
                      type="number"
                      sx={{
                        "& fieldset": {
                          borderRadius: "0px",
                        },
                        width: "100%",
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={Rupee} />
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="off"
                      helperText={
                        props.errors.price &&
                        props.touched.price &&
                        props.errors.price
                        // : "Enter your Email."
                      }
                      error={!!(props.errors.price && props.touched.price)}
                      // onChange={props.handleChange}

                      onChange={(event) => {
                        // Remove any non-numeric characters except for one decimal point
                        const sanitizedInput = event.target.value.replace(
                          /[^0-9.]/g,
                          ""
                        );

                        // Ensure only one decimal point exists
                        const decimalCount =
                          sanitizedInput.split(".").length - 1;
                        if (decimalCount > 1) return;

                        // Allow up to 3 digits after the decimal point
                        const decimalPart = sanitizedInput.split(".")[1];
                        if (decimalPart && decimalPart.length > 3) return;

                        props.setFieldValue("price", sanitizedInput);
                      }}
                      onBlur={props.handleBlur}
                      value={props.values.price}
                      // onChange={props.handleChange}
                    />
                  </div>
                </div>
                <div className="lableInputDiv">
                  <b>
                    Compared Price
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </b>
                  <div style={{ display: "flex" }}>
                    <TextField
                      id="outlined-basic"
                      // required
                      variant="outlined"
                      name="compared_price"
                      type="number"
                      sx={{
                        "& fieldset": {
                          borderRadius: "0px",
                          // width: "clamp(10rem, 34vw, 25rem)",
                        },
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={Rupee} />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      autoComplete="off"
                      helperText={
                        props.errors.compared_price &&
                        props.touched.compared_price &&
                        props.errors.compared_price
                        // : "Enter your Email."
                      }
                      error={
                        !!(
                          props.errors.compared_price &&
                          props.touched.compared_price
                        )
                      }
                      // onChange={props.handleChange}

                      onChange={(e) => {
                        // Remove any non-numeric characters except for one decimal point
                        const sanitizedInput = e.target.value.replace(
                          /[^0-9.]/g,
                          ""
                        );

                        // Ensure only one decimal point exists
                        const decimalCount =
                          sanitizedInput.split(".").length - 1;
                        if (decimalCount > 1) return;

                        // Allow up to 3 digits after the decimal point
                        const decimalPart = sanitizedInput.split(".")[1];
                        if (decimalPart && decimalPart.length > 3) return;

                        props.setFieldValue("compared_price", sanitizedInput);
                      }}
                      onBlur={props.handleBlur}
                      value={props.values.compared_price}
                    />
                  </div>
                </div>
                {/* <div className="lableInputDiv">
                  <b>
                    Shipping Level
                    <span style={{ color: "red" }}>*</span>
                  </b>
                  <div style={{ display: "flex" }}>
                    <TextInputField
                      variant="outlined"
                      name="shipping_level"
                      fullWidth
                      helperText={
                        props.errors.shipping_level &&
                        props.touched.shipping_level &&
                        props.errors.shipping_level
                        // : "Enter your Email."
                      }
                      error={
                        !!(
                          props.errors.shipping_level &&
                          props.touched.shipping_level
                        )
                      }
                      // onChange={props.handleChange}

                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.shipping_level}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VariantForm;
