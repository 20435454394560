import { useEffect, useState } from "react";
import { useAppDispatch } from "../../Redux/store/hooks";
import {
  Enter_Input,
  Xaxis,
  Yaxis,
  Angle,
  Width,
  Height,
  Layer_name,
} from "../../Redux/Reducers/Editor_value";
import { useSelector } from "react-redux";
import line from "../accets/textlineborder.svg";
import Question from "../Pages/QuestionsForEditor";

// image editor sub tools
function ImageEdit() {
  const dispatch = useAppDispatch();
  const checkEditPage = useSelector((state: any) => state.DetailEdit);

  const [inputValue, setInputValue] = useState("");
  const [x_axis, setX_axis] = useState<any>();
  const [y_axis, setY_axis] = useState<any>();
  const [angles, setAngle] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [name, setName] = useState<any>("");
  const [xActive, setXActive] = useState(false);
  const [yActive, setYActive] = useState(false);
  const [angleActive, setAngleActive] = useState(false);
  const Xaxiss = (event: any) => {
    const newValue = parseFloat(event.target.value);
    setX_axis(Math.ceil(newValue));
  };
  const Yaxiss = (event: any) => {
    const newValue = parseFloat(event.target.value);
    setY_axis(Math.ceil(newValue));
  };
  const Widthh = (event: any) => {
    const newValue = parseInt(event.target.value, 10);
    setWidth(newValue);
  };
  const Heightt = (event: any) => {
    const newValue = parseInt(event.target.value, 10);
    setHeight(newValue);
  };
  const Angles = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = parseFloat(event.target.value);
    if (isNaN(newValue)) {
      newValue = 0;
    } else if (newValue < 0) {
      newValue = (newValue + 360) % 361;
    } else if (newValue > 0) {
      newValue = newValue % 361;
    }
    setAngle(Math.ceil(newValue));
  };

  useEffect(() => {
    if (!xActive) {
      setX_axis(checkEditPage.x_return);
    }
  }, [checkEditPage.x_return, xActive]);
  useEffect(() => {
    if (!yActive) {
      setY_axis(checkEditPage.y_return);
    }
  }, [checkEditPage.y_return, yActive]);
  useEffect(() => {
    if (!angleActive) {
      setAngle(checkEditPage.angle_return);
    }
  }, [checkEditPage.angle_return, angleActive]);
  useEffect(() => {
    setWidth(checkEditPage.width_return);
  }, [checkEditPage.width_return]);
  useEffect(() => {
    setHeight(checkEditPage.height_return);
  }, [checkEditPage.height_return]);
  useEffect(() => {
    if (angles) {
      dispatch(Angle(angles));
    }
    if (width) {
      dispatch(Width(width));
    }
    if (height) {
      dispatch(Height(height));
    }
  }, [angles, width, height]);
  useEffect(() => {
    if (x_axis == 0) {
      dispatch(Xaxis(0));
    } else if (x_axis) {
      dispatch(Xaxis(x_axis));
    }
  }, [x_axis]);
  useEffect(() => {
    if (y_axis == 0) {
      dispatch(Yaxis(0));
    } else if (y_axis) {
      dispatch(Yaxis(y_axis));
    }
  }, [y_axis]);
  const layernamesave = () => {
    dispatch(Layer_name(name));
    setName("");
  };
  return (
    <>
      <div
        style={{
          color: "#ffffff",
          overflowY: "scroll",
          height: "97vh",
          paddingBottom: "10px",
        }}
        className="scroll"
      >
        <div
          className="texttoolalign"
          style={{ fontSize: "16px", marginTop: "10px" }}
        >
          Image Properties
        </div>
        <img src={line} alt="invalid" width={"100%"} />

        <div className="texttoolalign" style={{ marginBottom: "35px" }}>
          Layer Name
        </div>

        <div>
          <input
            style={{ width: "60%", height: "32px" }}
            type="text"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
            }}
          />
          <button
            onClick={() => {
              layernamesave();
            }}
            style={{ height: "32px" }}
          >
            Submit
          </button>
        </div>
        <img src={line} alt="invalid" width={"100%"} />

        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-around",
            fontSize: "11px",
          }}
        >
          <div>X-Axis</div>
          <div>Y-Axis</div>
          <div>Angle</div>
        </div>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <input
            style={{ width: "20%" }}
            type="number"
            value={x_axis}
            onChange={Xaxiss}
            step="any"
            onFocus={() => setXActive(true)}
            onBlur={() => setXActive(false)}
          />

          <input
            style={{ width: "20%" }}
            type="number"
            value={y_axis}
            onChange={Yaxiss}
            step="any"
            onFocus={() => setYActive(true)}
            onBlur={() => setYActive(false)}
          />

          <input
            style={{ width: "20%" }}
            type="number"
            value={angles}
            onChange={Angles}
            step="any"
            onFocus={() => setAngleActive(true)}
            onBlur={() => setAngleActive(false)}
          />
        </div>
        <img src={line} alt="invalid" width={"100%"} />
        {checkEditPage.questionopen ? <Question></Question> : ""}

        {/* <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-around",
            fontSize: "11px",
          }}
        >
          <div>Layer</div>
          <div></div>
          <div></div>
        </div>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <input
            style={{ width: "20%" }}
            type="text"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
          <div style={{ width: "20%" }}></div>
          <div style={{ width: "20%" }}></div>
        </div> */}

        {/* height and width */}

        {/* <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-around",
            fontSize: "11px",
            width: "100%",
          }}
        >
          <div>Width</div>
          <div>Height</div>
          <div></div>
        </div>
        <div
          style={{
            marginTop: "5px",
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <input
            style={{ width: "20%" }}
            type="number"
            value={width}
            onChange={Widthh}
          />
          <input
            style={{ width: "20%" }}
            type="number"
            value={height}
            onChange={Heightt}
          />

          <div style={{ width: "20%" }}></div>
        </div> */}
      </div>
    </>
  );
}

export default ImageEdit;
