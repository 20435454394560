import "./ImageLibrary.css";
import menu from "../../Pages/Image/menu.png";
import Menus from "../../Component/menu";

import { FunctionComponent, Key, useEffect, useState } from "react";
import CheckboxLabels from "../../Component/Checkbox";
import { Button, IconButton, TextField } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { Image_Upload } from "../../Redux/Reducers/Editor_value";
import { list_image } from "../../Redux/Reducers/ImageLibraries/list_Image_slicer";
import { useAppSelector } from "../../Redux/store/hooks";
import { ImageList } from "../../interfaces/ImageList";
import { list_imagecategory } from "../../Redux/Reducers/Image Category/list_ImageCategory_slicer";
import { ImageCategory } from "../../interfaces/ListofImageCategory";
interface ImageUploadProps {
  setOpen: any;
}

const ImageUpload: FunctionComponent<ImageUploadProps> = (props) => {
  //    filter options
  const dispatch = useDispatch();

  //    States for search and menus
  const [search, setSearch] = useState("");
  const [menus, setMenus] = useState<any>([]);

  //    list of images
  const ImageList = useAppSelector((state) => state?.ListImage?.data);
  const ImageListIsLoading = useAppSelector(
    (state) => state?.ListImage?.isLoading
  );
  //    list of image categories
  const ListImageCategory = useAppSelector(
    (state) => state.ListImageCategory.data
  );
  const category = ListImageCategory.filter(
    (category: ImageCategory) => category.status
  ).map((category: ImageCategory) => {
    return { item: category.name, checked: false };
  });

  useEffect(() => {
    dispatch(list_imagecategory(""));
    dispatch(list_image(""));
  }, []);
  useEffect(() => {
    setMenus(category);
  }, [ListImageCategory]);

  //    chack box values change
  const handleCheckboxChange = (key: any, v: boolean) => {
    const updatedMenus = [...menus];
    updatedMenus[key].checked = v;
    setMenus(updatedMenus);
  };

  //    filterd and searched data
  const filterAndSearchData = ImageList.filter((card: ImageList) => {
    const selectedMenus = menus.filter(
      (menu: { checked: any }) => menu.checked
    );
    if (selectedMenus.length === 0) {
      return true; // No menu item selected, show all cards
    } else {
      return selectedMenus.some(
        (menu: { item: string }) => menu.item === card.category_name
      );
    }
  }).filter((value: ImageList) =>
    value
      .name!.replace(/\s+/g, "")
      .toLowerCase()
      .includes(search.replace(/\s+/g, "").toLowerCase())
  );

  return (
    <>
      <DialogTitle>
        <header className="headerDiv">
          {/*  Title   */}
          <div
            style={{
              fontWeight: "700",
            }}
          >
            Image Library
          </div>
          <div className="headerRight">
            {/*   Filter by category   */}
            <Menus
              Buttonname={<img src={menu} alt="menu" height={16} />}
              size="medium"
              buttonSx={{ borderRadius: "0", minWidth: "0" }}
              Menus={menus.map(
                (
                  menu: {
                    item: string | number | undefined;
                    checked: boolean | undefined;
                  },
                  key: Key | null | undefined
                ) => {
                  return {
                    item: (
                      <div style={{ width: "100%" }} key={key}>
                        <CheckboxLabels
                          label={menu.item}
                          getValue={(v: boolean) => {
                            handleCheckboxChange(key, v);
                          }}
                          defaultChecked={menu.checked}
                        />
                      </div>
                    ),
                  };
                }
              )}
            />

            {/*   Search box   */}
            <TextField
              fullWidth
              id="standard-bare"
              variant="outlined"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="small"
              InputProps={{
                sx: { borderRadius: "0" },
                startAdornment: (
                  <IconButton>
                    <SearchOutlined />
                  </IconButton>
                ),
              }}
            />

            {/*   Reload button  */}
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                borderRadius: "0",
              }}
              onClick={() => {
                setMenus(category);
                setSearch("");
              }}
            >
              Reload
            </Button>
            <Button
              sx={{
                color: "#FFFFFF",
                borderRadius: "0",
                minWidth: "37px",
                backgroundColor: "#000000",
                "&:hover": { backgroundColor: "#000000" },
              }}
              onClick={() => props.setOpen(false)}
            >
              X
            </Button>
          </div>
        </header>
      </DialogTitle>
      <DialogContent sx={{ "&::-webkit-scrollbar": { display: "none" } }}>
        <section>
          {ImageListIsLoading ? (
            <div className="NoDataFound">
              <h1>Loading...</h1>
            </div>
          ) : (
            <>
              {ImageList.length === 0 ? (
                <div className="NoDataFound">
                  <h1>No data found</h1>
                </div>
              ) : (
                <div className="newsectionDiv">
                  {/*  Careds filter and maping functions   */}
                  {filterAndSearchData.map((card: ImageList, index: number) => {
                    return (
                      //  Card contains

                      <div className="newcardDivs" key={card.id}>
                        {/*   Image of card  */}
                        <div className="imageDiv">
                          <img
                            className="image"
                            src={card.image_url}
                            alt="message"
                            // width={"150px"}
                            // height={"100px"}
                          />
                        </div>
                        <div className="cardFooter">
                          {/*   Name and size  */}
                          <div className="cardNameAndSize">
                            <b className="cardName">{card.name}</b>
                            {/* <span>{card.size}</span> */}
                          </div>
                          <div className="cardButton">
                            {/*   Select Button  */}
                            <Button
                              onClick={() => {
                                props.setOpen(false);
                                dispatch(Image_Upload(card.image_url));
                              }}
                              variant="contained"
                              sx={{
                                textTransform: "none",
                                borderRadius: "0",
                              }}
                            >
                              Select
                            </Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </section>
      </DialogContent>
    </>
  );
};
// grid-template-columns: repeat(3, 1fr);
export default ImageUpload;
