import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";

interface ExtendState extends IReduxState<null> {
  isSuccess: boolean;
  isError: boolean;
}
export const AddCampaign = createSlice({
  name: "AddCampaign",
  initialState: {
    isLoading: false,
    data: {},
    isSuccess: false,
    isError: false,
  } as ExtendState,
  reducers: {
    add_Campaign: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_add_Campaign: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
    },
    failure_api_call_add_Campaign: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isError = true;
      // FAILED(action.payload);
    },
    false_success: (state) => {
      state.isSuccess = false;
    },
    false_error: (state) => {
      state.isError = false;
    },
  },
});
export default AddCampaign.reducer;
export const {
  add_Campaign,
  success_api_call_add_Campaign,
  failure_api_call_add_Campaign,
  false_success,
  false_error,
} = AddCampaign.actions;
