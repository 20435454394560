import { FunctionComponent } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
// import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../Pages/Page.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  FormHelperText,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
//
import TextField from "@mui/material/TextField";
import Autocomplete from "./Autocomplete";
import "../App.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "../Pages/Page.css";
import IsLoading from "./isLoading";
import { clearData } from "../Redux/Reducers/ResetPassword/add_ResetPassword_slicer";
import { useAppDispatch } from "../Redux/store/hooks";
// import IsLoading from "./isLoading";
// import { useAppDispatch } from "../redux/store/hooks";
// import { edit_bay } from "../redux/Bay/edit_Bay_slicer";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogsProps {
  sx?: SxProps<Theme> | undefined;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any | number;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onclick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties | undefined;
  getValues?: any;
  closeDio?: any;
  // error?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;

  // errors?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const Dialogreset: FunctionComponent<DialogsProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [val, setVal] = React.useState({});

  //    Popup messages
  const [popup, setPopoup] = React.useState(false);

  //    success dialog
  const [dialog, setDialog] = React.useState(false);

  // console.log(props.closeFunction);
  const { getValues, placeholder, successMessage, isLoading }: any = props;

  const dispatch = useAppDispatch();

  const initialValues: {
    email: "";
  } = {
    email: props?.edit?.email || "",
  };

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    // closeFunction(true);
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setTimeout(() => {
      dispatch(clearData([]));
    }, 1000);
    setOpen(false);
    setDialog(false);
    // dispatch(edit_bay('' as any));
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    setDialog(false);
    setPopoup(false);
  }, [open]);
  // React.useEffect(() => {
  //   if (isLoading) {
  //     setDialog(isLoading);
  //   }
  // }, [isLoading]);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <p
        onClick={handleClickOpen("paper")}
        style={{ cursor: "pointer", color: "#27559c" }}
      >
        Reset password ?
      </p>

      <Dialogs
        open={open}
        // onClose={handleClose}
        // scroll={scroll}
        PaperProps={{
          sx: {
            borderRadius: "0",

            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            fontWeight: "bold",
            backgroundColor: "black",
            color: "white",
          }}
        >
          {props.Dialogtitle}
          <ClearOutlinedIcon
            className="clear"
            onClick={handleClose}
            sx={{ color: "white", float: "right" }}
          ></ClearOutlinedIcon>
        </DialogTitle>
        <DialogContent style={{ maxWidth: "1040px" }}>
          <Formik
            initialValues={initialValues}
            // validationSchema={SignupSchema}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Please enter email")
                .required("Email is required")
                .matches(/^.+@.+\..+$/, "Enter a valid email"),
            })}
            onSubmit={(values) => {
              // getValues(values);
              getValues(values);
              // setDialog(true);
              console.log(values);
            }}
          >
            {(props) => {
              return (
                <Form style={{ margin: "10px" }} id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />
                  {/*     for submit when enter button click     */}
                  <button type="submit" style={{ display: "none" }}>
                    submit
                  </button>

                  <div
                    style={{
                      color: "#000000",
                      paddingBottom: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Enter your email address
                  </div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="email"
                    autoComplete="off"
                    sx={{
                      "& fieldset": {
                        borderRadius: "0px",
                      },
                    }}
                    value={props.values.email}
                    onChange={(e) => {
                      if (e.target.value.length < 50) {
                        props.setFieldValue("email", e.target.value);
                      }
                    }}
                    helperText={
                      props.errors.email && props.touched.email
                        ? props.errors.email
                        : " "
                    }
                    error={
                      props.errors.email && props.touched.email ? true : false
                    }
                    onBlur={props.handleBlur}
                    style={{
                      width: "25rem",
                      height: "50px",
                      margin: " 0px 0px 30px ",
                    }}
                  />

                  <div>
                    <span>
                      {"-> Reset link will be send to your email address"}
                    </span>
                  </div>
                  <DialogActions
                    style={{
                      justifyContent: "center",
                      margin: "20px 0px 0px 0px",
                    }}
                  >
                    <Button
                      // onClick={() => {
                      //   setDialog(true);
                      // }}
                      type="submit"
                      // disabled={!props.isValid}
                      // disabled={
                      //   (Object.keys(props.errors ?? {}).length === 0 &&
                      //     Object.keys(props.touched ?? {}).length === 0) ||
                      //   Object.keys(props.errors ?? {}).length !== 0
                      // }
                      style={{
                        // border: "1px solid black",
                        width: "100px",
                        height: "40px",
                        border: "1px solid black",
                        color: "white",
                        // backgroundColor: "#C00A30",
                        backgroundColor:
                          // (Object.keys(props.errors ?? {}).length === 0 &&
                          //   Object.keys(props.touched ?? {}).length === 0) ||
                          // Object.keys(props.errors ?? {}).length !== 0
                          !props.isValid ? "black" : "black",
                        borderRadius: "0px",
                      }}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                  {!isLoading && (
                    <Dialogs
                      open={Boolean(Object.keys(successMessage).length !== 0)}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <div className="submitPopup">
                            <div
                              style={{
                                fontSize: "20px",
                                textAlign: "center",
                                margin: "0px 40px 0px 40px ",
                              }}
                            >
                              {successMessage.message}
                            </div>
                            <div
                              style={{
                                fontSize: "20px",
                                textAlign: "center",
                                margin: "0px 40px 0px 40px ",
                              }}
                            >
                              {successMessage?.status ? (
                                <img
                                  src={require("../Pages/Image/success.png")}
                                  className="imageSize"
                                  alt="success"
                                />
                              ) : (
                                <img
                                  src={require("../Pages/Image/error.png")}
                                  className="imageSize"
                                  alt="error"
                                />
                              )}
                            </div>
                          </div>
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions sx={{ justifyContent: "center" }}>
                        <Button
                          onClick={handleClose}
                          style={{
                            // backgroundColor: "#1976D2",
                            color: "white",
                            width: "100px",
                            height: "37px",
                            borderRadius: "0px",
                            border: "1px solid #BDBDBD",
                          }}
                          autoFocus
                          sx={{
                            backgroundColor: "black",
                            "&:hover": { backgroundColor: "black" },
                          }}
                        >
                          OK
                        </Button>
                      </DialogActions>
                    </Dialogs>
                  )}
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialogs>
    </>
  );
};

export default Dialogreset;
