//    For Table rows
import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
// import {
//   success_api_call_pageQuestions,
//   failure_api_call_pageQuestions,
// } from "./list_PageQuestions_slicer";
import {
  success_api_call_add_PageQuestions,
  failure_api_call_add_PageQuestions,
} from "./add_PageQuestions_slicer";
// import {
//   failure_api_call_edit_pageQuestions,
//   success_api_call_edit_pageQuestions,
// } from "./edit_PageQuestions_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_set_PageQuestionsID,
  success_api_call_set_PageQuestionsID,
} from "./set_PageQuestionsID_slicer";
import {
  failure_api_call_delete_PageQuestions,
  success_api_call_delete_PageQuestions,
} from "./delete_PageQusetions_slicer";
import {
  failure_api_call_list_PageQuestions,
  success_api_call_list_PageQuestions,
} from "./list_PageQuestions_slicer";
// import {
//   Failure_get_Question_By_Id,
//   Success_get_Question_By_Id,
// } from "./PageQuestions_Slicer";

//    Page id and question id intergration
export function* CreatePageQuestionAPI(e: any) {
  try {
    const response: AxiosResponse = yield call(CreatePageQuestionCall, e);
    yield put(success_api_call_set_PageQuestionsID(response.data));
  } catch (e) {
    yield put(failure_api_call_set_PageQuestionsID(e));
    yield put(expireSignature(e));
    console.log(e);
  }
}
const CreatePageQuestionCall = (a: any) => {
  let token = localStorage.getItem("token");
  // console.log("layerpayload", a);
  // const page_id = [{ page_id: a.payload[0].page_id }];
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/page_questions/`,
    a.payload,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

//    Listout the PageQuestions
const addPageQuestionsCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  // console.log(a.payload);
  // const adminId = JSON.parse(localStorage.getItem("id")!);
  const formData = new FormData();
  formData.append("page_id", a.payload.page_id);
  formData.append("project_id", a.payload.project_id);
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/page_questions/images`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddPageQuestionsApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addPageQuestionsCall, e);

    yield put(success_api_call_add_PageQuestions(response.data.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_PageQuestions(e));
    yield put(expireSignature(e));
  }
}

//    Delete a pageQuestions
export function* deletePageQuestionsApi(e: any) {
  try {
    const response: AxiosResponse = yield call(deletePageQuestionsCall, e);
    yield put(success_api_call_delete_PageQuestions(response.data.data));
  } catch (error) {
    console.log(error);
    yield put(failure_api_call_delete_PageQuestions(error));
  }
}
const deletePageQuestionsCall = (a: any) => {
  const token = localStorage.getItem("token");
  const ids = a.payload;
  const formData = new FormData();
  for (const id in ids) {
    formData.append(id, ids[id]);
  }

  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/page_questions/page`,
    { data: formData, headers: { Authorization: `Bearer ${token}` } }
  );
};
//     getQuestionById
// const getQuestionByIdCall = async (a: any) => {
//   let token = await localStorage.getItem("token");
//   const adminId = JSON.parse(localStorage.getItem("id")!);
//   console.log(a.payload);

//   return Axios.post(
//     `${process.env.REACT_APP_api_url}/v1/api/page_questions/imagesoptions`,
//     a.payload,
//     {
//       headers: { Authorization: `Bearer ${token}` },
//     }
//   );
// };
// export function* getQuestionByIdApi(e: any) {
//   try {
//     const response: AxiosResponse = yield call(getQuestionByIdCall, e);
//     // console.log(response.data);

//     yield put(Success_get_Question_By_Id(response.data.data));
//   } catch (e) {
//     console.log(e);
//     yield put(Failure_get_Question_By_Id(e));
//   }
// }

//Edit pageQuestions

// const editPageQuestionsCall = async (a: any) => {
//   let token = await localStorage.getItem("token");

//   const adminId = JSON.parse(localStorage.getItem("id")!);

//   // const id = a.payload.id;
//   const edit = {
//     id: a.payload.id,
//     name: a.payload.name,
//     active: a.payload.active,
//     updated_by: adminId,
//   };
//   // console.log(edit);

//   return Axios.put(`${process.env.REACT_APP_api_url}/v1/api/pageQuestions`, edit, {
//     headers: { Authorization: `Bearer ${token}` },
//   });
// };
// export function* editPageQuestionsApi(e: any) {
//   try {
//     const response: AxiosResponse = yield call(editPageQuestionsCall, e);
//     // console.log(response.data);

//     yield put(success_api_call_edit_pageQuestions(response.data));
//   } catch (e) {
//     yield put(failure_api_call_edit_pageQuestions(e));
//     yield put(expireSignature(e));
//   }
// }

//  list pageQuestions

const listPageQuestionsCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/question_image_matrix/images`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* listPageQuestionsApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listPageQuestionsCall, e);
    yield put(success_api_call_list_PageQuestions(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_list_PageQuestions(e));
    yield put(expireSignature(e));
  }
}
