import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import {
  failure_api_call_add_font,
  success_api_call_add_font,
} from "./add_Font_slicer";

import {
  failure_api_call_font,
  success_api_call_font,
} from "./list_Font_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";

import {
  failure_api_call_delete_Font,
  success_api_call_delete_Font,
} from "./delete_Font_slicer";
import {
  failure_api_call_edit_Font,
  success_api_call_edit_Font,
} from "./edit_Font_slicer";

//add user
const addFontCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);

  const formData = new FormData();
  formData.append("name", a.payload.name);
  formData.append("file_url", a.payload.file_url);
  formData.append("status", a.payload.status);
  formData.append("font_reference_name", a.payload.font_reference_name);

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/font_library/`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddFontApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addFontCall, e);
    // console.log(response.data);

    yield put(success_api_call_add_font(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_font(e));
    yield put(expireSignature(e));
  }
}

//list font
const listFontCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/font_library/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* listFontApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listFontCall, e);

    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      //   active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    // console`1.log(res);
    yield put(success_api_call_font(res));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_font(e));
    yield put(expireSignature(e));
  }
}

//Edit role

const editFontCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  const adminId = JSON.parse(localStorage.getItem("id")!);

  // const id = a.payload.id;
  const edit = {
    id: a.payload.id,
    status: a.payload.status,
    updated_by: adminId,
  };
  // console.log(edit);

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/font_library/status`,
    edit,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* editFontApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editFontCall, e);
    // console.log(response.data);

    yield put(success_api_call_edit_Font(response.data));
  } catch (e) {
    yield put(failure_api_call_edit_Font(e));
    yield put(expireSignature(e));
  }
}

// Delete
export function* deleteFontApi(e: any) {
  try {
    const response: AxiosResponse = yield call(deleteFontCall, e);
    yield put(success_api_call_delete_Font(response.data.data));
  } catch (error) {
    console.log(error);
    yield put(failure_api_call_delete_Font(error));
  }
}
const deleteFontCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(`${process.env.REACT_APP_api_url}/v1/api/font_library/`, {
    data: formData,
    headers: { Authorization: `Bearer ${token}` },
  });
};
