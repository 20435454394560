import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import {
  failure_api_call_add_Campaign,
  success_api_call_add_Campaign,
} from "./add_Campaign_slicer";
import {
  failure_api_call_Campaign,
  success_api_call_Campaign,
} from "./list_Campaign_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";

import {
  failure_api_call_delete_Campaign,
  success_api_call_delete_Campaign,
} from "./delete_Campaign_slicer";
import {
  failure_api_call_editable_Campaign,
  success_api_call_editable_Campaign,
} from "./editable_Campaign_slicer";
import { PayloadAction } from "@reduxjs/toolkit";
import { update_variant } from "./VariantList_Slicer";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js";
import {
  IImageFile,
  IListUsedVariant,
  IRelaunchCampaign,
} from "../../../interfaces/ICampaign";
import {
  failure_api_call_eidt_Campaign,
  success_api_call_eidt_Campaign,
} from "./edit_Campaign_slicer";
import {
  failure_api_call_launchAndPass_Campaign,
  success_api_call_launchAndPass_Campaign,
  updata_project_Id,
} from "./launch&pass_Campaign_slicer";
import { SUCCESE } from "../../../Editor/utills/common";
import { IAPIResponse } from "../../../interfaces/IReduxState";
import {
  failure_api_call_relaunch_campaign,
  success_api_call_relaunch_campaign,
} from "./relaunch_Campaign_slicer";
import {
  failure_api_call_UsedVariant,
  success_api_call_UsedVariant,
} from "./list_usedvariant_slicer";
import {
  failure_api_call_UsedProjectIds,
  success_api_call_UsedProjectIds,
} from "./list_usedprojectIds_slicer";
// import {
//   failure_api_call_add_user,
//   success_api_call_add_user,
// } from "./add_User_slicer";
// import {
//   failure_api_call_edit_user,
//   success_api_call_edit_user,
// } from "./edit_User_slicer";
// import { failure_api_call_login, success_api_call_login } from "./Login_slicer";

//add campaign
const addCampaignCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);
  console.log(a.payload);

  const formData = new FormData();
  for (const key in a.payload) {
    const values = a.payload[key];

    if (Array.isArray(values)) {
      values.forEach((element) => {
        formData.append(key, element);
      });
    } else {
      formData.append(key, values);
    }
  }
  formData.append("status", "INACTIVE");

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/campaign/`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddCampaignApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addCampaignCall, e);
    // console.log(response.data);
    SUCCESE(response.data.message);
    yield put(success_api_call_add_Campaign(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_Campaign(e));
    yield put(expireSignature(e));
  }
}

//edit campaign
const editCampaignCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);

  const formData = new FormData();
  for (const key in a.payload) {
    const values = a.payload[key];

    if (Array.isArray(values)) {
      values.forEach((element) => {
        formData.append(key, element);
      });
    } else {
      formData.append(key, values);
    }
  }
  formData.append("status", "INACTIVE");

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/campaign/`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* EditCampaignApi(e: any) {
  try {
    const response: AxiosResponse = yield call(editCampaignCall, e);
    console.log(response.data);
    SUCCESE(response.data.message);
    yield put(success_api_call_eidt_Campaign(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_eidt_Campaign(e));
    yield put(expireSignature(e));
  }
}

/*    list campaign     */
const listCampaignCall = () => {
  let token = localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/campaign/list`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* listCampaignApi() {
  try {
    const response: AxiosResponse = yield call(listCampaignCall);
    yield put(success_api_call_Campaign(response?.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_Campaign());
    yield put(expireSignature(e));
  }
}

//    Delete campaign
export function* deleteCampaignApi(e: any) {
  try {
    const response: AxiosResponse = yield call(deleteCampaignCall, e);
    yield put(success_api_call_delete_Campaign(response.data.data));
  } catch (error) {
    console.log(error);
    yield put(failure_api_call_delete_Campaign(error));
  }
}
const deleteCampaignCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/image_library/`,
    { data: formData, headers: { Authorization: `Bearer ${token}` } }
  );
};

/*    editable campaign value     */
const editableCampaignCall = (value: number) => {
  let token = localStorage.getItem("token");

  const formData = new FormData();
  formData.append("campaign_id", JSON.stringify(value));

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/campaign/details`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* editableCampaignApi(action: PayloadAction<number>) {
  try {
    const response: AxiosResponse = yield call(
      editableCampaignCall,
      action.payload
    );

    /*    for editor html formate to draft(editor)     */
    const contentBlock = htmlToDraft(
      response?.data?.data?.campaign?.description as unknown as string
    );
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
      contentBlock.entityMap
    );
    const editorStates = EditorState.createWithContent(contentState);
    /*    product image loop     */
    const images = Array.from(
      { length: 4 },
      (_, index) =>
        response?.data.data.product_images[index] || { images: null }
    );
    response?.data.data.product_images.forEach(
      (obj: IImageFile, index: number) => {
        images[index] = obj;
      }
    );

    const changeResponse = {
      ...response?.data,
      data: {
        ...response.data.data,
        product_images: images,
        campaign: { ...response.data.data.campaign, description: editorStates },
      },
    };

    yield put(success_api_call_editable_Campaign(changeResponse));
    /*    update the variant table     */
    yield put(update_variant(response?.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_editable_Campaign());
    yield put(expireSignature(e));
  }
}

//launch and pass campaign
const LaunchAndPassCampaignCall = (a: any) => {
  const token = localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/campaign/status`,
    a.payload,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
/*    writeProductsCall    */
const writeProductsCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("project_id", a.payload.project_id);
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/shopify/write_products`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* LaunchAndPassCampaignApi(e: any) {
  try {
    if (e.payload.project_id) {
      console.log("in");
      const writeProductResponse: AxiosResponse<IAPIResponse<null>> =
        yield call(writeProductsCall, e);
      if (!writeProductResponse.data.status) {
        yield put(
          success_api_call_launchAndPass_Campaign(writeProductResponse.data)
        );
        return;
      }
    }
    console.log("out");
    const response: AxiosResponse = yield call(LaunchAndPassCampaignCall, e);
    // console.log(response.data);

    console.log("after");
    yield put(success_api_call_launchAndPass_Campaign(response.data));
    yield put(updata_project_Id(null));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_launchAndPass_Campaign(e));
    yield put(expireSignature(e));
  }
}

const RelaunchCampaignApi = (action: PayloadAction<IRelaunchCampaign>) => {
  const token = localStorage.getItem("token");

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/shopify/update_products`,
    { project_id: action.payload },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/x-www-form-urlencoded",
      },
    }
  );
};

export function* RelaunchCampaignCall(
  action: PayloadAction<IRelaunchCampaign>
) {
  try {
    const response: AxiosResponse = yield call(RelaunchCampaignApi, action);
    yield put(success_api_call_relaunch_campaign(response.data));
  } catch (error) {
    yield put(expireSignature(error));
    yield put(failure_api_call_relaunch_campaign(error));
  }
}

/*    list of used variant in campign    */
const UsedVariantApi = async (a: PayloadAction<IListUsedVariant>) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);
  console.log(a.payload);

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/project_pages/cover_variants`,
    null,
    {
      params: a.payload,
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/x-www-form-urlencoded",
      },
    }
  );
};

export function* UsedVariantCall(e: PayloadAction<IListUsedVariant>) {
  try {
    const response: AxiosResponse = yield call(UsedVariantApi, e);
    yield put(success_api_call_UsedVariant(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_UsedVariant(e));
    yield put(expireSignature(e));
  }
}

/*    list of used project ids in campign    */
const UsedProjectIdsCall = async () => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/campaign/project_list`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/x-www-form-urlencoded",
      },
    }
  );
};

export function* UsedProjectIdsAPI() {
  try {
    const response: AxiosResponse = yield call(UsedProjectIdsCall);
    yield put(success_api_call_UsedProjectIds(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_UsedProjectIds(e));
    yield put(expireSignature(e));
  }
}
