import React, { useState, useEffect, useContext, useRef } from "react";
import { FAILED, SUCCESE } from "./utills/common";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SaveJson = (e: any) => {
  const nav = useNavigate();

  const addjsonvalue: any = useSelector(
    (state: any) => state.AddComicsBookPageJsonValue
  );

  useEffect(() => {
    if (e) {
      if (addjsonvalue.data?.length != 0 && addjsonvalue.data.status === true) {
        nav("/Comicbookpages");
        console.log("toast success");

        SUCCESE(addjsonvalue.data.message);
      } else if (addjsonvalue.data.status === false) {
        console.log("failed");

        FAILED(addjsonvalue?.data.message);
      }
    }
  }, [addjsonvalue.data, addjsonvalue.isSuccess]);
};

const Fontload = async (e: any) => {
  if (e?.url) {
    const fontFace = new FontFace(e?.label, `url(${e?.url})`);

    const loadFont = async () => {
      try {
        await fontFace.load();
        document.fonts.add(fontFace);
        // console.log("success load font");
        // editor?.canvas.renderAll();
      } catch (error) {
        console.error("Error loading font:", error);
      }
    };
    loadFont();
  }
};
export { SaveJson, Fontload };
