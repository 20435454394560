import { createSlice } from "@reduxjs/toolkit";
import { IComicsPage } from "../../../interfaces/ComicsPageEdit";

export const EditableCoverValue = createSlice({
  name: "EditableCoverValue",
  initialState: { data: {} as IComicsPage },
  reducers: {
    edit_Cover_Value: (state, action) => {
      return { ...state, data: action.payload };
    },
  },
});

export default EditableCoverValue.reducer;

export const { edit_Cover_Value } = EditableCoverValue.actions;
