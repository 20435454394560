import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

// tostify

const notify = () => toast("Wow so easy!");

const SUCCESE = async (data: any) => {
  toast.success(data, {
    position: toast.POSITION.TOP_RIGHT,
  });
};
const FAILED = async (data: any) => {
  toast.error(data, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export { notify, SUCCESE, FAILED };
