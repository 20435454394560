import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";

interface ExtendState extends IReduxState<null> {
  popup: boolean;
  responsePopup: boolean;
}
export const DeleteImageCategory = createSlice({
  name: "DeleteImageCategory",
  initialState: {
    isLoading: false,
    data: {},
    popup: false,
    responsePopup: false,
  } as ExtendState,
  reducers: {
    delete_ImageCategory: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_delete_ImageCategory: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.responsePopup = true;
    },
    failure_api_call_delete_ImageCategory: (state, action) => {
      state.isLoading = false;
      state.responsePopup = true;
      state.data = action.payload;
    },

    image_category_popup_open: (state) => {
      state.popup = true;
    },
    image_category_popup_close: (state) => {
      state.popup = false;
      state.responsePopup = false;
    },
  },
});
export default DeleteImageCategory.reducer;
export const {
  delete_ImageCategory,
  success_api_call_delete_ImageCategory,
  failure_api_call_delete_ImageCategory,
  image_category_popup_close,
  image_category_popup_open,
} = DeleteImageCategory.actions;
