import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import {
  failure_api_call_OrderManagement,
  success_api_call_OrderManagement,
} from "./list_OrderManagement_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import { IOrderManagement } from "../../../interfaces/IOrderManagment";
import { IAPIResponse } from "../../../interfaces/IReduxState";
import { PayloadAction } from "@reduxjs/toolkit";

export let cancelPreviousRequest: CancelTokenSource | null = null;

/*    list of ordermanagment     */
const OrderManagementCall = (action: PayloadAction<string | null>) => {
  // Cancel the previous request if it exists
  if (cancelPreviousRequest) {
    cancelPreviousRequest.cancel("Operation canceled for update.");
  }

  const token = localStorage.getItem("token");
  // Create a new CancelToken source for this request
  cancelPreviousRequest = Axios.CancelToken.source();

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/shopify/orders`, {
    ...(action.payload && { params: { order_name: action.payload } }),
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cancelToken: cancelPreviousRequest.token,
  });
};

export function* OrderManagementApiCall(action: PayloadAction<string | null>) {
  try {
    const response: AxiosResponse<IAPIResponse<IOrderManagement[]>> =
      yield call(OrderManagementCall, action);
    yield put(success_api_call_OrderManagement(response.data));
  } catch (error) {
    console.log(error);
    if (Axios.isCancel(error)) {
      return;
    }
    yield put(failure_api_call_OrderManagement(error));
    yield put(expireSignature(error));
  }
}
