import { FunctionComponent } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Link } from "react-router-dom";
import { SxProps, Theme } from "@mui/material";

// Menu component

type variant = "text" | "outlined" | "contained" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface MenuProps {
  Menus: any[];
  variant?: variant;
  disabled?: boolean;
  color?: color;
  size?: size;
  Buttonname: any;
  style?: React.CSSProperties;
  buttonSx?: SxProps<Theme>;
}

const Menus: FunctionComponent<MenuProps> = (props) => {
  return (
    <div>
      <PopupState popupId="demo-popup-menu" variant={"popover"}>
        {(popupState: any) => (
          <React.Fragment>
            <Button
              variant={props.variant}
              {...bindTrigger(popupState)}
              color={props.color}
              size={props.size}
              disabled={props.disabled}
              style={props.style}
              sx={props.buttonSx}
            >
              {props.Buttonname}
            </Button>
            <Menu {...bindMenu(popupState)}>
              {props.Menus.map((value: any, index) => {
                return (
                  <MenuItem
                    // onClick={popupState.close}
                    // component={Link}
                    // to={value?.url}
                    key={index}
                  >
                    {value.item}
                  </MenuItem>
                );
              })}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </div>
  );
};

export default Menus;
