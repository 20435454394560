import "./Page.css";

import { FunctionComponent, useRef } from "react";
import React, { useState } from "react";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Editor/Pages/ImageLibrary.css";
import { Breadcrumbs, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckboxLabels from "../Component/CheckboxComponent";
import TextField from "@mui/material/TextField";
import { Formik, Form, FormikProps } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import mesImage from "./Image/Noimge.png";

import * as Yup from "yup";
import Delete from "./Image/Delete.png";
import Edit from "./Image/Edit.png";
import ComicsEdit from "./Image/comicTools.png";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { list_ComicsBookPage } from "../Redux/Reducers/Comics_Book_Page/list_ComicsBookPage_slicer";
import { delete_ComicsBookPage } from "../Redux/Reducers/Comics_Book_Page/delete_ComicsBookPage_slicer";
import { add_ComicsBookPage } from "../Redux/Reducers/Comics_Book_Page/add_ComicsBookPage_slicer";
import SearchBar from "../Component/SearchBar";
import { PageValue } from "../Redux/Reducers/Project_id";
import { add_ComicsBookPage_Size } from "../Redux/Reducers/Comics_Book_Page/comics_page_size";
import { Get_Canvas, Pageclick_Loadjson } from "../Redux/Reducers/Editor_value";
import Reorder from "./Reorder";
import IsLoading from "../Component/isLoading";
import PopupMessage from "../Component/PopupMessage";
import { edit_Page_Value } from "../Redux/Reducers/Comics_Book_Page/editablePageValue";
import { IComicsPage, ICoverForm } from "../interfaces/ComicsPageEdit";
import { edit_Spine_Value } from "../Redux/Reducers/Comics_Book_Page/editableSpineValue";
import { eidt_ComicsBookPage } from "../Redux/Reducers/Comics_Book_Page/edit_ComicsBookPage_slicer";
import { List_textOption } from "../Redux/Reducers/TextOptions/listOfTextOption_Slicer";
import { List_questionID } from "../Redux/Reducers/QuestionID/QuestionID_Slicer";
import { PDF_Genaration } from "../Redux/Reducers/PDF/PDF_genaration_slicer";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import LoadingButtonC from "../Component/LoadingButton";
import {
  close_cover_form,
  open_cover_form,
} from "../Redux/Reducers/Comics_Book_Page/add_Cover_slicer";
import Dialogs from "../Component/Dialog";
import CoverForm from "./CoverForm";
import { edit_Cover_Value } from "../Redux/Reducers/Comics_Book_Page/editableCoverValue";
import { CheckBox } from "@mui/icons-material";

interface ComicbookPagesProps {
  touched?: any;
  errors?: any;
  handleBlur?: any;
  values?: any;
  edit?: any;

  setFieldValue?: (arg0: string, value: any) => void;
  cardId?: number;
}

// this code dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// its is breadcrumbs
const ComicbookPages: FunctionComponent<ComicbookPagesProps> = (props) => {
  //   const location = useLocation();
  //   const queryParams = new URLSearchParams(location.search);
  //   const queryId = queryParams.get("id");
  const navigate = useNavigate();
  const dispatcher = useAppDispatch();

  const { getValues, closeFunction, editusers }: any = props;

  const breadcrumbs = [
    <Link
      key="1"
      color="black"
      onClick={() => navigate("/ComicsBook")}
      underline="none"
      sx={{ cursor: "pointer" }}
    >
      <b className="heading">Comics Book</b>
    </Link>,
    <Link
      underline="none"
      key="1"
      color="inherit"
      onClick={() => navigate("/Comicbookpages")}
      // href="/Compointer
      style={{
        fontWeight: "500",
        fontSize: "15px ",
        lineHeight: "16px ",
        color: "#BDBDBD",
      }}
      sx={{ cursor: "pointer" }}
    >
      Pages
    </Link>,
  ];

  //    userpermission menuList
  const pageName = "Comics Book / Pages";
  const menuList: any = JSON.parse(
    localStorage?.getItem("permission") as string
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageName;
  });

  const MenuOfUserpermission = menuList[index];

  //    Popup messages
  const [popup, setPopoup] = React.useState(false);

  //    success dialog
  const [dialog, setDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(0);

  const listpage = useAppSelector(
    (state) => state?.ListComicsBookPage?.isLoading
  );
  const imageload = useAppSelector(
    (state: any) => state?.Imagegenartion?.isLoading
  );

  // console.log("imageload", imageload);

  // const data = useAppSelector((state) => state?.ListComicsBookPage?.data);
  const data: any = useAppSelector((state) => state?.ListComicsBookPage?.data);
  const Projectid = useAppSelector((state) => state.Projectid);
  const isLoading = useAppSelector(
    (state) => state?.AddComicsBookPage?.isLoading
  );
  const addData: any = useAppSelector(
    (state) => state?.AddComicsBookPage?.data
  );
  console.log("data", data);

  const addDataIsLoading: any = useAppSelector(
    (state) => state?.AddComicsBookPage?.isLoading
  );
  const deleteData = useAppSelector((state) => state.DeleteComicsBookPage.data);
  const deleteIsLoading = useAppSelector(
    (state) => state.DeleteComicsBookPage.isLoading
  );

  //    Reorder Data
  const reorderData: any = useAppSelector((state) => state?.Reorder?.data);

  //    Edit Data
  const editData: any = useAppSelector(
    (state) => state?.EidtComicsBookPage?.data
  );
  const editDataisLoading: boolean = useAppSelector(
    (state) => state?.EidtComicsBookPage?.isLoading
  );

  //    PDF
  const { loadingIDs: PDFGenarationIsLoading } = useAppSelector(
    (state) => state.PDFGenaration
  );

  //    Cover
  const {
    data: AddCoverData,
    Popup: CoverPopup,
    isLoading: AddCoverIsLoading,
  } = useAppSelector((state) => state.AddCover);
  const { data: editCoverData, isLoading: editCoverIsLoading } = useAppSelector(
    (state) => state.EditCover
  );
  const { data: CoverEditableData } = useAppSelector(
    (state) => state.EditableCoverValue
  );

  //    Edit Page Data
  const editPageData: IComicsPage = useAppSelector(
    (state) => state?.EditablePageValue?.data
  );
  console.log("editpageData", editPageData);

  const pdfdata: any = useAppSelector((state) => state?.PDFgeneration);
  const textrepdata: any = useAppSelector(
    (state: any) => state?.Textreplace.data
  );
  // const textrepdata: any = useAppSelector((state) => state?.Textreplace);
  console.log(pdfdata.data.message);

  //    Cover ref
  const CoverRef = useRef<FormikProps<ICoverForm>>(null);

  const initialValues: {
    id: number | undefined;
    project_id: number | undefined;
    name: string;
    index: number | string;
    make_preview: boolean;
  } = {
    id: editPageData.page_id,
    project_id: editPageData.project_id,
    name: editPageData.page_name ?? "",
    index: editPageData.index ?? "",
    make_preview: true,
  };

  React.useEffect(() => {
    dispatcher(
      list_ComicsBookPage(parseInt(localStorage.getItem("Project_Id")!))
    );
    // axios
  }, [deleteData, addData, reorderData, editData, AddCoverData, editCoverData]);

  // onsubmit value get
  const Projectids = Projectid.projectid;
  const pages = false;
  // console.log("projectss", Projectids);

  const [submitvalue, setsubmitvalue] = React.useState<any>({
    name: "",
    index: 0,
    project_id: 0,
    pages: false,
    make_preview: false,
  });

  // console.log("sumit", submitvalue);

  const [myNames, setMyNames] = useState("");

  const filteredCardsCover = data?.cover?.filter((card: any) =>
    card.page_name.toLocaleLowerCase().includes(myNames.toLocaleLowerCase())
  );
  const filteredlulu_cover = data?.lulu_cover?.filter((card: any) =>
    card.page_name.toLocaleLowerCase().includes(myNames.toLocaleLowerCase())
  );
  const filteredCardsPage = data?.page?.filter((card: any) =>
    card.page_name.toLocaleLowerCase().includes(myNames.toLocaleLowerCase())
  );
  const filteredPreviewPage = data?.preview_pages?.filter((card: any) =>
    card.page_name.toLocaleLowerCase().includes(myNames.toLocaleLowerCase())
  );
  // console.log("allNames", filteredCardsCover);

  // this code is dialog for add image
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setDialog(false);
    setOpen(false);
    setTimeout(() => {
      dispatcher(edit_Page_Value({}));
    }, 1000);
  };

  //   console.log("card is ",props.card.id );

  // this code is dialog for comics book is delete

  const [deleteId, setDeleteId] = useState<number | undefined>(0);

  const comicbookdeleteOpen = () => {
    setDeleteDialog(deleteDialog + 0.001);
  };

  /*    check if it's editable value or not    */
  const isEditPageDataEmpty = Object.keys(editPageData).length === 0;
  const status: boolean = !!(isEditPageDataEmpty
    ? addData?.status
    : editData?.status);

  /*    testing open error popup when comics edit click    */
  const [openWarning, setOpenWarning] = useState<boolean>(false);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div className="comiksPage">
        <IsLoading
          isLoading={
            addDataIsLoading ||
            listpage ||
            editDataisLoading ||
            imageload ||
            AddCoverIsLoading ||
            editCoverIsLoading
          }
        />
        <div className="comiksPagesHeader">
          <h2 style={{ margin: "0" }}>
            {localStorage.getItem("Project_Name")}
          </h2>
          <div
            style={{
              display: "flex",
              gap: "1em",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <div>
              {/* <div>
           
              </div> */}
              <div style={{ width: "16rem" }}>
                <SearchBar
                  // name="Serach"
                  getValues={(e) => setMyNames(e.target.value)}
                  value={myNames}
                ></SearchBar>
              </div>
            </div>
            {MenuOfUserpermission?.add && (
              <>
                <LoadingButtonC
                  loading={PDFGenarationIsLoading?.includes(
                    parseInt(localStorage.getItem("Project_Id")!)
                  )}
                  loadingPosition="end"
                  endIcon={<SimCardDownloadIcon />}
                  variant="contained"
                  onClick={() => {
                    const admin: any = {
                      project_id: parseInt(localStorage.getItem("Project_Id")!),
                      type: "ADMIN_PDF",
                    };
                    dispatcher(PDF_Genaration(admin));
                    // SUCCESE("Start Pdf download processing");
                  }}
                  sx={{
                    height: "40px",
                    backgroundColor: "rgb(0, 0, 0)",
                    color: "#FFFFFF",
                    borderRadius: "0px",
                  }}
                >
                  PDF
                </LoadingButtonC>

                <Reorder disabled={data?.is_launched ?? false} />
                <Button
                  variant="contained"
                  sx={{ height: "40px" }}
                  onClick={() => dispatcher(open_cover_form())}
                  disabled={data?.is_launched ?? false}
                >
                  Add New Cover
                </Button>
                {/* <Spain></Spain> */}
              </>
            )}

            {/* <Button
              variant="contained"
              onClick={() => {
                pdfgenerate();
                setPdfopen(true);
              }}
              sx={{
                height: "40px",
                backgroundColor: "rgb(0, 0, 0)",
                color: "#FFFFFF",
                display: "flex",
                textTransform: "none",
                //   fontSize: "13px",
                alignItems: "center",
                borderRadius: "0px",
                justifyContent: "space-evenly",
              }}
            >
              PDF
            </Button> */}
            {/* {
              pdfdata.isLoading ? (
                <IsLoading isLoading={pdfdata.isLoading} />
              ) : pdfdata.isSuccess && pdfopen ? (
                // {pdfdata.isSuccess?"!":""}
                <button
                  style={{
                    width: "145px",
                    height: "40px",
                    backgroundColor: "rgb(0, 0, 0)",
                    color: "#FFFFFF",
                    textTransform: "none",
                    //   fontSize: "13px",
                    alignItems: "center",
                    marginRight: "20px",
                    borderRadius: "0px",
                    justifyContent: "space-evenly",
                  }}
                  onClick={() => (
                    window.open(pdfdata.data.data.filename),
                    setPdfopen(false),
                    setTextauto(false)
                  )}
                >
                  <span>
                    <FileDownloadIcon />
                  </span>
                </button>
              ) : (
                ""
              ) */}
            {/* <a>{pdfdata.data.pdf_url}</a> */}
            {/* } */}
            <div>
              {MenuOfUserpermission?.add && (
                <Button
                  variant="contained"
                  onClick={handleClickOpen}
                  sx={{
                    width: "145px",
                    height: "40px",
                    backgroundColor: "rgb(0, 0, 0)",
                    color: "#FFFFFF",
                    display: "flex",
                    textTransform: "none",
                    //   fontSize: "13px",
                    alignItems: "center",
                    borderRadius: "0px",
                    justifyContent: "space-evenly",
                  }}
                  disabled={data?.is_launched ?? false}
                >
                  Add New Page
                </Button>
              )}

              <Dialog
                sx={{
                  width: "100%",
                  height: "100%",
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      borderRadius: "0px",
                    },
                  },
                }}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Enter a Page Name"),
                    index: Yup.number()
                      .positive("Index should be a positive number")
                      .min(0, "Index must be greater than or equal to 0")
                      .integer("Index must be an integer")
                      .required("Enter the Index of the Page"),
                    make_preview: Yup.boolean(),
                  })}
                  onSubmit={(values, actions) => {
                    // console.log(values);
                    // setsubmitvalue(values);
                    setDialog(true);

                    // console.log(values);
                    // setsubmitvalue(values);
                  }}
                >
                  {({
                    errors,
                    touched,
                    setFieldValue,
                    handleBlur,
                    handleChange,
                    values,
                    resetForm,
                    isValid,
                  }) => {
                    const closeFunction = () => {
                      resetForm();
                      handleClose();
                    };

                    return (
                      <Form autoComplete="off">
                        <DialogTitle
                          sx={{ backgroundColor: "#000000", color: "white" }}
                        >
                          {Object.keys(editPageData).length === 0
                            ? " Add New"
                            : "Edit "}
                          page
                          <ClearOutlinedIcon
                            className="clear"
                            onClick={closeFunction}
                            sx={{ color: "white", float: "right" }}
                          ></ClearOutlinedIcon>
                        </DialogTitle>

                        <DialogContent
                          sx={{ padding: "1.5em 24px 0 !important" }}
                        >
                          <>
                            <div className="lableInputDiv">
                              <b>
                                Page Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </b>
                              <TextField
                                id="outlined-basic"
                                // label="Outlined"
                                variant="outlined"
                                name="name"
                                sx={{
                                  "& fieldset": {
                                    borderRadius: "0px",
                                  },
                                  width: "clamp(20rem,30vw,30rem)",
                                }}
                                value={values.name}
                                onChange={handleChange}
                                helperText={
                                  errors.name && touched.name && errors.name
                                }
                                error={!!(errors.name && touched.name)}
                                onBlur={handleBlur}
                                inputProps={{ maxLength: "25" }}
                              />
                            </div>
                            <div className="lableInputDiv">
                              <b>
                                Index <span style={{ color: "red" }}>*</span>
                              </b>
                              <TextField
                                id="outlined-basic"
                                // label="Outlined"
                                variant="outlined"
                                name="index"
                                sx={{
                                  "& fieldset": {
                                    borderRadius: "0px",
                                  },
                                  width: "clamp(20rem,30vw,30rem)",
                                }}
                                type="number"
                                value={values.index}
                                onChange={handleChange}
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                    // style: { textAlign: "right" },
                                  },
                                }}
                                helperText={
                                  errors.index && touched.index && errors.index
                                }
                                error={!!(errors.index && touched.index)}
                                onBlur={handleBlur}
                              />
                            </div>
                            {/* <div>
                              <CheckboxLabels
                                name="Make Preview"
                                label={"Make Preview"}
                                checked={values.make_preview}
                                onChange={handleChange}
                              /> */}
                            {/* <input type="checkbox" value={"true"}></input>{" "}
                              <span>Make Preview</span> */}
                            {/* </div> */}
                          </>
                        </DialogContent>
                        <DialogActions
                          sx={{
                            justifyContent: "center",
                            gap: "0.5em",
                            padding: "1.5em",
                          }}
                        >
                          <Button
                            variant="outlined"
                            onClick={closeFunction}
                            sx={{
                              width: "110px",
                              height: "40px",

                              // fontSize: "13px",
                              // alignItems: "center",
                              // marginRight: "20px",
                              borderRadius: "0px",
                            }}
                          >
                            CANCEL
                          </Button>
                          <Button
                            onClick={() => {
                              setPopoup(false);
                            }}
                            type="submit"
                            // disabled={!isValid}
                            // disabled={
                            //   (Object.keys(props.errors ?? {}).length === 0 &&
                            //     Object.keys(props.touched ?? {}).length === 0) ||
                            //   Object.keys(props.errors ?? {}).length !== 0
                            // }
                            style={{
                              width: "100px",
                              height: "40px",
                              border: "1px solid black",
                              color: "white",
                              // backgroundColor: "#C00A30",
                              backgroundColor: "black",
                              borderRadius: "0px",
                            }}
                          >
                            SAVE
                          </Button>
                        </DialogActions>
                        {(Object.keys(editPageData).length === 0
                          ? !isLoading
                          : !editDataisLoading) && (
                          <Dialog
                            open={dialog}
                            // onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            PaperProps={{
                              sx: {
                                borderRadius: "0",
                                // height: height || "80vh",
                                "&::-webkit-scrollbar": { display: "none" },
                              },
                            }}
                          >
                            <DialogContent
                              sx={{ padding: "1.5em 1.5em 0 !important" }}
                            >
                              {!popup ? (
                                <DialogContentText id="alert-dialog-description">
                                  <div className="submitPopup">
                                    <div
                                      style={{
                                        fontSize: "16px",
                                        textAlign: "center",
                                        color: "black",
                                      }}
                                    >
                                      Are you sure you want to proceed?
                                      {/* Submitted Successfully */}
                                    </div>
                                  </div>
                                </DialogContentText>
                              ) : (
                                // isLoading && (
                                <DialogContentText id="alert-dialog-description">
                                  <div className="submitPopup">
                                    <div
                                      style={{
                                        fontSize: "16px",
                                        textAlign: "center",
                                        margin: "0px 40px 0px 40px ",
                                        color: "black",
                                      }}
                                    >
                                      {Object.keys(editPageData).length === 0
                                        ? addData?.message
                                        : editData?.message}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        textAlign: "center",
                                        margin: "0px 40px 0px 40px ",
                                      }}
                                    >
                                      {status ? (
                                        <img
                                          src={require("./Image/success.png")}
                                          className="imageSize"
                                          alt="success"
                                        />
                                      ) : (
                                        <img
                                          src={require("./Image/error.png")}
                                          className="imageSize"
                                          alt="error"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </DialogContentText>
                                // )
                              )}
                            </DialogContent>

                            {!popup ? (
                              <DialogActions
                                sx={{
                                  justifyContent: "center",
                                  gap: "1em",
                                  padding: "1.5em",
                                }}
                              >
                                <Button
                                  onClick={() => setDialog(false)}
                                  style={{
                                    padding: "15px",
                                    // backgroundColor: "#1976D2",
                                    color: "black",
                                    width: "100px",
                                    height: "37px",
                                    borderRadius: "0px",
                                    border: "1px solid black",
                                  }}
                                  sx={{
                                    backgroundColor: "white",
                                    "&:hover": { backgroundColor: "white" },
                                  }}
                                >
                                  NO
                                </Button>
                                <Button
                                  form="form"
                                  type="submit"
                                  onClick={() => {
                                    // props.handleSubmit();
                                    const submitted = {
                                      ...submitvalue,
                                      Projectids,
                                      pages,
                                    };
                                    // console.log("submitted", submitted);
                                    Object.keys(editPageData).length === 0
                                      ? dispatcher(
                                          add_ComicsBookPage({
                                            ...values,
                                            type: "PAGE",
                                          })
                                        )
                                      : dispatcher(eidt_ComicsBookPage(values));

                                    setPopoup(true);
                                    // setTimeout(() => setPopoup(true), 100);
                                  }}
                                  style={{
                                    // backgroundColor: "#1976D2",
                                    color: "white",
                                    width: "100px",
                                    height: "37px",
                                    borderRadius: "0px",
                                    border: "1px solid black",
                                  }}
                                  autoFocus
                                  sx={{
                                    backgroundColor: "black",
                                    "&:hover": { backgroundColor: "black" },
                                  }}
                                >
                                  YES
                                </Button>
                              </DialogActions>
                            ) : (
                              // !isLoading && (
                              <DialogActions
                                sx={{
                                  justifyContent: "center",
                                  gap: "1em",
                                  padding: "1.5em",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    closeFunction(); // setSelectedFile(null);
                                  }}
                                  style={{
                                    // backgroundColor: "#1976D2",
                                    color: "white",
                                    width: "100px",
                                    height: "37px",
                                    borderRadius: "0px",
                                    border: "1px solid #BDBDBD",
                                  }}
                                  autoFocus
                                  sx={{
                                    backgroundColor: "black",
                                    "&:hover": { backgroundColor: "black" },
                                  }}
                                >
                                  OK
                                </Button>
                              </DialogActions>
                            )}
                          </Dialog>
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              </Dialog>
            </div>
          </div>
        </div>
        {/* (
          <div className="NoDataFound">
            <h1></h1>
          </div>
        ) : */}
        {data?.page?.length === 0 &&
        data?.spine?.length === 0 &&
        data?.cover?.length === 0 ? (
          <div className="NoDataFound">
            <h1>{listpage ? "Loading..." : "No data found"}</h1>
          </div>
        ) : (
          <>
            <div className="cardsDiv">
              <div className="cardHead">Covers</div>
              <div className="comicsBookDiv">
                {filteredCardsCover?.map((card: any, index: number) => {
                  return (
                    //  Card contains

                    <div key={card.page_id} className="comicsBookPageCard">
                      <div>
                        {card.index} {card.page_name}
                      </div>
                      <div className="PagesImageDiv">
                        {card.image_url ? (
                          <img
                            loading="lazy"
                            src={card.image_url}
                            alt="message"
                            style={{ height: "215px" }}
                          />
                        ) : (
                          <img
                            src={mesImage}
                            alt="message"
                            style={{ height: "215px" }}
                          />
                        )}
                      </div>
                      <div>
                        <span style={{ cursor: "pointer" }}>
                          <img
                            onClick={() => {
                              dispatcher(Get_Canvas(""));
                              dispatcher(Pageclick_Loadjson(true));

                              dispatcher(PageValue(card));
                              dispatcher(
                                List_textOption({
                                  project_id: parseInt(
                                    localStorage.getItem("Project_Id")!
                                  ),
                                  q_type: "T",
                                })
                              );
                              dispatcher(
                                List_questionID({
                                  project_id: parseInt(
                                    localStorage.getItem("Project_Id")!
                                  ),
                                  q_type: "I",
                                })
                              );

                              dispatcher(Pageclick_Loadjson(true));

                              if (data?.is_launched) {
                                setOpenWarning(true);
                              } else if (!data?.is_launched) {
                                dispatcher(add_ComicsBookPage_Size(card));
                                navigate("/Comictools");
                              }

                              localStorage.setItem("Page_Id", card.page_id);
                            }}
                            src={ComicsEdit}
                            alt="ComicTools"
                            width={24}
                            height={24}
                          />
                        </span>
                        {/* {MenuOfUserpermission?.edit && (
                          <span
                            style={{
                              cursor: "pointer",
                              marginLeft: "1rem",
                            }}
                            onClick={() => {
                              // handleClickOpen();
                              if (data?.is_launched) {
                                setOpenWarning(true);
                              } else if (!data?.is_launched) {
                                dispatcher(edit_Spine_Value(card));
                              }
                            }}
                          >
                            <img src={Edit} alt="Edit" width={24} height={24} />
                          </span>
                        )} */}

                        {/* <span style={{ marginLeft: "1rem" }}>
                          <span
                            onClick={() => {
                              if (data?.is_launched) {
                                setOpenWarning(true);
                              } else if (!data?.is_launched) {
                                comicbookdeleteOpen();
                                setDeleteId(card.page_id);
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={Delete}
                              //   width="20px"
                              //   height="30px"
                              alt="Delete"
                              width={24}
                              height={24}
                            />

                            
                          </span>
                        </span> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/*     Covers     */}
            <div className="cardsDiv">
              <div className="cardHead">lulucovers</div>
              <div className="comicsBookDiv">
                {filteredlulu_cover?.map((card: any, index: number) => {
                  return (
                    //  Card contains

                    <div key={card.page_id} className="comicsBookPageCard">
                      {/* Image of card */}

                      <div>
                        {card.index} {card.page_name}
                      </div>
                      <div>
                        {card.image_url ? (
                          <img
                            src={card.image_url}
                            loading="lazy"
                            alt="message"
                            style={{
                              width:
                                localStorage.getItem("page_orientation") ===
                                "LANDSCAPE"
                                  ? "200px"
                                  : "180px",
                              height:
                                localStorage.getItem("page_orientation") ===
                                "LANDSCAPE"
                                  ? "150px"
                                  : "215px",
                            }}
                          />
                        ) : (
                          <img
                            src={mesImage}
                            alt="message"
                            style={{ width: "180px", height: "215px" }}
                          />
                        )}
                      </div>
                      <div>
                        <span style={{ cursor: "pointer" }}>
                          <img
                            onClick={() => {
                              dispatcher(Get_Canvas(""));
                              dispatcher(Pageclick_Loadjson(true));
                              dispatcher(PageValue(card));
                              dispatcher(
                                List_textOption({
                                  project_id: parseInt(
                                    localStorage.getItem("Project_Id")!
                                  ),
                                  q_type: "T",
                                })
                              );
                              dispatcher(
                                List_questionID({
                                  project_id: parseInt(
                                    localStorage.getItem("Project_Id")!
                                  ),
                                  q_type: "I",
                                })
                              );

                              if (data?.is_launched) {
                                setOpenWarning(true);
                              } else if (!data?.is_launched) {
                                dispatcher(add_ComicsBookPage_Size(card));
                                navigate("/Comictools");
                              }

                              localStorage.setItem("Page_Id", card.page_id);
                            }}
                            src={ComicsEdit}
                            alt="ComicTools"
                            width={24}
                            height={24}
                          />
                        </span>
                        {MenuOfUserpermission?.edit && (
                          <span
                            style={{
                              cursor: "pointer",
                              marginLeft: "1rem",
                            }}
                            onClick={() => {
                              if (data?.is_launched) {
                                setOpenWarning(true);
                              } else if (!data?.is_launched) {
                                dispatcher(open_cover_form());
                                dispatcher(edit_Cover_Value(card));
                              }
                            }}
                          >
                            <img src={Edit} alt="Edit" width={24} height={24} />
                          </span>
                        )}
                        <span style={{ marginLeft: "1rem" }}>
                          <span
                            onClick={() => {
                              if (data?.is_launched) {
                                setOpenWarning(true);
                              } else if (!data?.is_launched) {
                                comicbookdeleteOpen();
                                setDeleteId(card.page_id);
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={Delete}
                              width={24}
                              height={24}
                              alt="Delete"
                            />
                            {/* <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon> */}
                          </span>
                        </span>
                      </div>

                      {/*     cover delete     */}
                      {/* <span style={{ marginLeft: "10px" }}>
                            <span
                              onClick={() => {
                                comicbookdeleteOpen();
                                setDeleteId(card.id);
                                console.log(card);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={Delete}
                                //   width="20px"
                                //   height="30px"
                                alt="Delete"
                              />
                            </span>
                          </span> */}
                    </div>
                  );
                })}
              </div>
            </div>

            {/*     Spines */}

            {/*     Pages    */}
            <div className="cardsDiv">
              <div className="cardHead">Pages</div>
              <div className="comicsBookDiv">
                {filteredCardsPage?.map((card: any, index: number) => {
                  return (
                    //  Card contains

                    <div key={card.page_id} className="comicsBookPageCard">
                      {/* Image of card */}

                      <div>
                        {card.index}: {card.page_name}
                      </div>
                      <div>
                        {card.image_url ? (
                          <img
                            loading="lazy"
                            src={card.image_url}
                            alt="message"
                            style={{
                              width:
                                localStorage.getItem("page_orientation") ===
                                "LANDSCAPE"
                                  ? "200px"
                                  : "180px",
                              height:
                                localStorage.getItem("page_orientation") ===
                                "LANDSCAPE"
                                  ? "150px"
                                  : "215px",
                            }}
                          />
                        ) : (
                          <img
                            src={mesImage}
                            alt="message"
                            style={{ width: "180px", height: "215px" }}
                          />
                        )}
                      </div>
                      <div>
                        <span style={{ cursor: "pointer" }}>
                          <img
                            onClick={() => {
                              dispatcher(PageValue(card));
                              dispatcher(
                                List_textOption({
                                  project_id: parseInt(
                                    localStorage.getItem("Project_Id")!
                                  ),
                                  q_type: "T",
                                })
                              );
                              dispatcher(
                                List_questionID({
                                  project_id: parseInt(
                                    localStorage.getItem("Project_Id")!
                                  ),
                                  q_type: "I",
                                })
                              );

                              if (data?.is_launched) {
                                setOpenWarning(true);
                              } else if (!data?.is_launched) {
                                dispatcher(add_ComicsBookPage_Size(card));
                                navigate("/Comictools");
                              }

                              dispatcher(Pageclick_Loadjson(true));
                              // console.log("tools", card);
                              localStorage.setItem("CardValue", card);
                              localStorage.setItem("Page_Id", card.page_id);
                            }}
                            src={ComicsEdit}
                            alt="ComicTools"
                            width={24}
                            height={24}
                          />
                        </span>
                        {MenuOfUserpermission?.edit && (
                          <span
                            style={{
                              cursor: "pointer",
                              marginLeft: "1rem",
                            }}
                            onClick={() => {
                              if (data?.is_launched) {
                                setOpenWarning(true);
                              } else if (!data?.is_launched) {
                                handleClickOpen();
                                dispatcher(edit_Page_Value(card));
                              }
                            }}
                          >
                            <img src={Edit} alt="Edit" width={24} height={24} />
                          </span>
                        )}
                        <span style={{ marginLeft: "1rem" }}>
                          <span
                            onClick={() => {
                              if (data?.is_launched) {
                                setOpenWarning(true);
                              } else if (!data?.is_launched) {
                                comicbookdeleteOpen();
                                setDeleteId(card.page_id);
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={Delete}
                              width={24}
                              height={24}
                              alt="Delete"
                            />
                            {/* <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon> */}
                          </span>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/*preview pages*/}
            <div className="cardsDiv">
              <div className="cardHead">Preview Pages</div>
              <div className="comicsBookDiv">
                {filteredPreviewPage?.map((card: any, index: number) => {
                  return (
                    //  Card contains

                    <div key={card.page_id} className="comicsBookPageCard">
                      {/* Image of card */}

                      <div>
                        {card.index}: {card.page_name}
                      </div>
                      <div>
                        {card.image_url ? (
                          <img
                            loading="lazy"
                            src={card.image_url}
                            alt="message"
                            style={{
                              width:
                                localStorage.getItem("page_orientation") ===
                                "LANDSCAPE"
                                  ? "200px"
                                  : "180px",
                              height:
                                localStorage.getItem("page_orientation") ===
                                "LANDSCAPE"
                                  ? "150px"
                                  : "215px",
                            }}
                          />
                        ) : (
                          <img
                            src={mesImage}
                            alt="message"
                            style={{ width: "180px", height: "215px" }}
                          />
                        )}
                      </div>
                      <div>
                        <span style={{ cursor: "pointer" }}>
                          <img
                            onClick={() => {
                              dispatcher(PageValue(card));
                              dispatcher(
                                List_textOption({
                                  project_id: parseInt(
                                    localStorage.getItem("Project_Id")!
                                  ),
                                  q_type: "T",
                                })
                              );
                              dispatcher(
                                List_questionID({
                                  project_id: parseInt(
                                    localStorage.getItem("Project_Id")!
                                  ),
                                  q_type: "I",
                                })
                              );

                              if (data?.is_launched) {
                                setOpenWarning(true);
                              } else if (!data?.is_launched) {
                                dispatcher(add_ComicsBookPage_Size(card));
                                navigate("/Comictools");
                              }

                              dispatcher(Pageclick_Loadjson(true));
                              // console.log("tools", card);
                              localStorage.setItem("CardValue", card);
                              localStorage.setItem("Page_Id", card.page_id);
                            }}
                            src={ComicsEdit}
                            alt="ComicTools"
                            width={24}
                            height={24}
                          />
                        </span>
                        {/* {MenuOfUserpermission?.edit && (
                          <span
                            style={{
                              cursor: "pointer",
                              marginLeft: "1rem",
                            }}
                            onClick={() => {
                              if (data?.is_launched) {
                                setOpenWarning(true);
                              } else if (!data?.is_launched) {
                                handleClickOpen();
                                dispatcher(edit_Page_Value(card));
                              }
                            }}
                          >
                            <img src={Edit} alt="Edit" width={24} height={24} />
                          </span>
                        )} */}
                        {/* <span style={{ marginLeft: "1rem" }}>
                          <span
                            onClick={() => {
                              if (data?.is_launched) {
                                setOpenWarning(true);
                              } else if (!data?.is_launched) {
                                comicbookdeleteOpen();
                                setDeleteId(card.page_id);
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={Delete}
                              width={24}
                              height={24}
                              alt="Delete"
                            />
                          </span>
                        </span> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        <PopupMessage
          successMessage={deleteData}
          open={deleteDialog}
          Dialogtitle={"Are you sure you want to delete?"}
          submitFunction={() => dispatcher(delete_ComicsBookPage(deleteId))}
          isLoading={deleteIsLoading}
          closeFunction={() => null}
        />
      </div>

      {/*     Delete popup     */}
      <Dialogs
        open={openWarning}
        setOpen={setOpenWarning}
        maxWidth="xs"
        sx={{ width: "auto" }}
        Dialog_Content={
          <>
            Project available in shopify, Can not be modified.
            {/* Selected project was launched in the shopify. Inactive the project,
            if you want to edit. */}
            <img
              src={require("../Pages/Image/error.png")}
              className="imageSize"
              alt="error"
            />
          </>
        }
        Dialog_Content_sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "1.5em 1.5em 0 !important",
          fontSize: "16px",
          textAlign: "center",
        }}
        Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
        Dialog_Actions={
          <Button
            variant="contained"
            onClick={() => {
              setOpenWarning(false);
            }}
            sx={{
              width: "110px",
              height: "40px",
              backgroundColor: "rgb(0, 0, 0)",
              color: "#FFFFFF",
              display: "flex",
              borderRadius: "0px",
            }}
          >
            OK
          </Button>
        }
      />

      <Dialogs
        maxWidth={"md"}
        sx={{ width: "auto" }}
        open={CoverPopup}
        setOpen={() => dispatcher(close_cover_form())}
        Dialog_Title_sx={{ bgcolor: "black", color: "white" }}
        Dialog_Title={
          <>
            <span>
              {Object.keys(CoverEditableData).length === 0
                ? "Add new "
                : "Edit "}
              cover
            </span>
            <ClearOutlinedIcon
              className="clear"
              onClick={() => {
                dispatcher(close_cover_form());
              }}
              sx={{ color: "white", float: "right" }}
            />
          </>
        }
        Dialog_Content_sx={{
          padding: "1.5em 24px 0!important",
          fontSize: "16px",
        }}
        Dialog_Content={<CoverForm formik={CoverRef} />}
        Dialog_Actions_sx={{ gap: "0.5em", padding: "1.5em" }}
        Dialog_Actions={
          <>
            <Button
              variant="outlined"
              onClick={() => {
                dispatcher(close_cover_form());
              }}
              sx={{
                width: "110px",
                height: "40px",
                borderRadius: "0px",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                CoverRef.current?.handleSubmit();
              }}
              sx={{
                width: "110px",
                height: "40px",
                backgroundColor: "rgb(0, 0, 0)",
                color: "#FFFFFF",
                display: "flex",
                fontSize: "13px",
                alignItems: "center",

                borderRadius: "0px",
                justifyContent: "space-evenly",
              }}
            >
              Save
            </Button>
          </>
        }
      />

      {/* <Dialog
        sx={{
          width: "100%",
          height: "100%",
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: "0px",
              width: "30%",
              // backgroundColor: "gray",
            },
          },
        }}
        open={comicbookopen}
        TransitionComponent={Transition}
        keepMounted
        // onClose={comicsbookdeleteClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#000000",
            color: "white",
          }}
        >
          {"Delete"}

          <ClearOutlinedIcon
            className="clear"
            onClick={comicsbookdeleteClose}
            sx={{ color: "white", float: "right" }}
          ></ClearOutlinedIcon>
        </DialogTitle>
        <DialogContent sx={{}}>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{ paddingTop: "40px", color: "black", textAlign: "center" }}
          >
            Are you sure want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "25px",
          }}
        >
          <Button
            variant="outlined"
            onClick={comicsbookdeleteClose}
            sx={{
              width: "110px",
              height: "40px",
              marginRight: "20px",
              // fontSize: "13px",
              // alignItems: "center",
              // marginRight: "20px",
              borderRadius: "0px",
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              dispatcher(delete_ComicsBookPage(deleteId));
              setPopoup(true);
              comicsbookdeleteClose();
            }}
            sx={{
              width: "110px",
              height: "40px",
              backgroundColor: "rgb(0, 0, 0)",
              color: "#FFFFFF",
              display: "flex",
              fontSize: "13px",
              alignItems: "center",

              borderRadius: "0px",
              justifyContent: "space-evenly",
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default ComicbookPages;
