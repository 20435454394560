import { createSlice } from "@reduxjs/toolkit";
import { ImageQuestions } from "../../../interfaces/IImageQuestions";

export const QuestionOptions = createSlice({
  name: "QuestionOptions",
  initialState: {
    isLoading: false,
    data: {},
  } as ImageQuestions,
  reducers: {
    Clear_data: (state) => {
      state.data = {};
    },
    List_questionOptions: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    Success_list: (state, action) => {
      return {
        ...state,
        isSuccess: action.payload,
        isLoading: false,
        isError: null,
      };
    },
    Failure_list: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
        isSuccess: null,
      };
      //   state.isSuccess = false;
    },
    Get_Question_By_Id: (state, _) => {
      state.isLoading = true;
    },
    Success_get_Question_By_Id: (state, action) => {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isError: null,
      };
    },
    Failure_get_Question_By_Id: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
        isSuccess: null,
      };
    },
    Text_Question_By_Id: (state, _) => {
      state.isLoading = true;
    },
    Success_Text_Question_By_Id: (state, action) => {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isError: null,
      };
    },
    Failure_Text_Question_By_Id: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
        isSuccess: null,
      };
    },
  },
});
export default QuestionOptions.reducer;
export const {
  List_questionOptions,
  Success_list,
  Failure_list,
  Get_Question_By_Id,
  Success_get_Question_By_Id,
  Failure_get_Question_By_Id,
  Clear_data,
  Text_Question_By_Id,
  Success_Text_Question_By_Id,
  Failure_Text_Question_By_Id,
} = QuestionOptions.actions;
