import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { AutoMode, Edit } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { jsPDF } from "jspdf";
import back_image from "../Editor/accets/desktop-wallpaper-white-plain-background-dirty-white.jpg";
import { FAILED, SUCCESE } from "./utills/common";
import { SaveJson, Fontload } from "./render";
import "../Editor/textarea.css";
import {
  CustomFont,
  Edit_option,
  Enter_Input,
  Get_Canvas,
  Get_Index,
  Image_Upload,
  ObjectSelecterValue,
  ObjectSelection_boolean,
  clickCurve,
  Savee,
  SelectedClr,
  fontFamily,
  fontselectedValue,
  textAlignment,
  textBold,
  textUpdate,
  textcolor,
  textitalic,
  textvalue,
  updataColor,
  flipCurve,
  updateFlip,
  curving,
  updateCurving,
  textunderline,
  updateCurve,
  Pageclick_Loadjson,
  Capital,
  CapitalReply,
  Small,
  Xreturn,
  Yreturn,
  Xaxis,
  Yaxis,
  Anglereturn,
  Widthreturn,
  Heightreturn,
  textPTS,
  curveinput,
  Layer_name,
  Edit_text,
  UpLayer,
  DownLayer,
  TopLayer,
  EndLayer,
  Jsonid_Dropdown_return,
  Jsonid_Dropdown,
  NoImage,
  UpdatedImage,
  QuestionOpen,
  Unselect,
  Get_Index2,
  LineReturn,
  Textquestion_id,
  Height,
  Angle,
  Layer_id,
  Space,
  SpaceReturn,
  AutoScaleBool,
  AutoScaleBool_Return,
} from "../Redux/Reducers/Editor_value";
import { red } from "@mui/material/colors";
import { AlignGuidelines } from "fabric-guideline-plugin";

import Ruler from "@scena/react-ruler";
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Box } from "@material-ui/core";
import { add_ComicsBookPage_jsonvalue } from "../Redux/Reducers/Comics_Book_Page/comics_page_jsonvalue";
import { useNavigate } from "react-router-dom";
import background_image from "./accets/backgrndimg.jpg";
import noimageavailable from "./accets/noimageavailable.png";
import { check } from "prettier";
import {
  clearExtraData,
  listDropdownById,
} from "../Redux/Reducers/TextOptions/TextOptions_Slicer";
import {
  clearListPageQuestions,
  list_PageQuestions,
} from "../Redux/Reducers/PageQuestions/list_PageQuestions_slicer";
import { cleartextOptionsList } from "../Redux/Reducers/TextOptions/textOptionsList_Slicer";
import { update_questionIds } from "../Redux/Reducers/TextOptions/listOfTextOption_Slicer";
import { delete_matrixQuestionLayer } from "../Redux/Reducers/QuestionImages/delete_matrixQuestionLayer_slicer";

const EditFabric = (props: any) => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [prop, setprop] = useState<any>();

  const { selectedObjects, editor, onReady } = useFabricJSEditor();
  const [history, setHistory] = useState<any[]>([]);
  const [canvasJson, setCanvasJson] = useState<any>(null);
  const [toast, setToast] = useState(false);
  const [selectedObjectData, setSelectedObjectData] = useState(null);
  const [selectedObjectIndex, setSelectedObjectIndex] = useState("");
  const [isFontLoaded, setIsFontLoaded] = useState(false);
  const [scale1, setScale1] = useState(0);
  const [scale2, setScale2] = useState(0);
  const [jsonData, setJsonData] = useState<any>(null);
  const [imageSrc, setImageSrc] = useState<string>("");
  const [render, setRender] = useState(true);
  const [canvasState, setCanvasState] = useState(null);

  const [dataa, setdataa] = useState<any>([]);
  const [dataa1, setdataa1] = useState<any>([]);
  const [canvasData, setCanvasData] = useState<any>([]);
  const [undoStack, setUndoStack] = useState<any[]>([]);
  const [redoStack, setRedoStack] = useState<any[]>([]);
  // const [portwidthh, setPortwidth] = useState(360);
  // const [porthighh, setPorthigh] = useState(530);
  // const [landwidthh, setLandwidth] = useState(590);
  // const [landhighh, setLandhigh] = useState(360);
  const size_value = props.data[0];
  // console.log(size_value);
  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  const widthRatio = windowHeight / windowWidth;
  const scaledWidthhsq = (size_value.width * windowWidth) / 24.54;
  const scaledHeighttsq =
    (size_value.height * windowWidth * widthRatio) / 12.44;
  const scaleWidth = windowWidth / size_value.width;
  const scaleHeight = windowHeight / size_value.height;
  const scale = Math.min(scaleWidth, scaleHeight);
  const scaledWidthh = size_value.width * scale;
  const scaledHeightt = size_value.height * scale;
  const scawdth =
    size_value.width == size_value.height ? scaledWidthhsq : scaledWidthh;
  const scahgh =
    size_value.width == size_value.height ? scaledWidthhsq : scaledHeightt;
  // const scaledWidth = scawdth / 1.2;
  // const scaledHeight = scahgh / 1.2;
  const scaledWidth =
    size_value.type != "LULU_COVER" ? scawdth / 1.4 : scawdth / 1.1;
  const scaledHeight =
    size_value.type != "LULU_COVER" ? scahgh / 1.4 : scahgh / 1.1;
  // const width_ratio = 0.9;
  // const height_ratio = 1.85;
  // const scaledHeight = widthRatio * windowHeight;
  // const widthvaluee = windowHeight / windowWidth;
  // const wv = props.data[0].type == "LULU_COVER" ? 1.85 : 1.9;
  // const widthvalue = widthvaluee / wv;
  // const widthvalue = widthvaluee / height_ratio;
  // const scaledWidth = widthvalue * windowWidth;
  // console.log("orig acaled", scaledWidthh, scaledHeightt);

  // console.log("scaled", scaledWidth, scaledHeight);
  const scw = scaledWidth / 2.7;
  const sch = scaledHeight / 2.7;
  const [portwidthh, setPortwidth] = useState(scaledWidth);
  const [porthighh, setPorthigh] = useState(scaledHeight);
  const [landwidthh, setLandwidth] = useState(scaledHeight);
  const [landhighh, setLandhigh] = useState(scaledWidth);
  const checkEditPage: any = useSelector((state: any) => state.DetailEdit);
  const [zoom, setZoom] = useState(false);
  const [fontrender, setFontrender] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [initialPosition, setInitialPosition] = useState({ left: 0, top: 0 });
  const [uniquelayer_id, setUniqueLayer_id] = useState("");
  const [text, setText] = useState(checkEditPage.pageclick_loadjson);
  const pageevalue: any = useSelector((state: any) => state.Projectid);
  const pagejson = pageevalue.pagesvalue;
  const prevCenterRef: any = useRef(null);
  const data: {
    field_id: number;
    file_url: string;
    id: number;
    name: string;
    status: boolean;
  }[] = useSelector((state: any) => state?.ListFont?.data);

  useEffect(() => {
    setprop(props);
  }, [props]);

  const units = 300; // drag left....unit value
  // console.log("chekfabricjs", checkEditPage);

  // console.log("size_value", size_value);

  // zoom wheel editor
  // const minimumValue: any = 100; //width
  // const minimumValue2 = 280; //height
  // const minlandwidth = 330;
  // const minlandheight = 100;

  useEffect(() => {
    const testidDiv = document.getElementById("testid");
    // if (testidDiv && !zoom) {
    if (testidDiv) {
      const handleMouseWheel = (event: any) => {
        const delta = event.deltaY;

        const increment = 45;
        const increment2 = 30;
        if (size_value.position.toLocaleLowerCase() === "portrait") {
          if (delta > 0) {
            if (porthighh >= sch) {
              setPortwidth((prevPortwidth) =>
                Math.max(prevPortwidth - increment2, scaledWidth)
              );
              setPorthigh((prevPorthigh) =>
                Math.max(prevPorthigh - increment, scaledHeight)
              );
            }
          } else {
            setPortwidth((prevPortwidth) => prevPortwidth + increment2);
            setPorthigh((prevPorthigh) => prevPorthigh + increment);
          }
        } else {
          // console.log("sch", landhighh, sch);
          if (delta > 0) {
            if (landhighh >= sch) {
              setLandwidth((prevLandwidth) =>
                Math.max(prevLandwidth - increment, scaledHeight)
              );
              setLandhigh((prevLandhigh) =>
                Math.max(prevLandhigh - increment2, scaledWidth)
              );
            }
          } else {
            setLandwidth((prevLandwidth) => prevLandwidth + increment);
            setLandhigh((prevLandhigh) => prevLandhigh + increment2);
          }
        }
      };

      testidDiv.addEventListener("wheel", handleMouseWheel);
      return () => {
        testidDiv.removeEventListener("wheel", handleMouseWheel);
      };
    }
  }, [zoom, landhighh, porthighh]);
  // drag screen
  useEffect(() => {
    const selectedObject = editor?.canvas.getActiveObject();

    if (zoom && editor && editor.canvas && !selectedObject) {
      let panning = false;

      const handleMouseUp = () => {
        editor.canvas.selection = false;

        panning = false;
      };

      const handleMouseDown = () => {
        editor.canvas.selection = false;

        panning = true;
      };

      const handleMouseMove = (e: any) => {
        if (panning && e && e.e) {
          const units = 10;

          const delta = new fabric.Point(e.e.movementX, e.e.movementY);
          editor.canvas.relativePan(delta);
          editor.canvas.renderAll();
        }

        // }
      };

      editor?.canvas.on("mouse:up", handleMouseUp);
      editor?.canvas.on("mouse:down", handleMouseDown);
      editor?.canvas.on("mouse:move", handleMouseMove);

      return () => {
        editor?.canvas.off("mouse:up", handleMouseUp);
        editor?.canvas.off("mouse:down", handleMouseDown);
        editor?.canvas.off("mouse:move", handleMouseMove);
      };
    }
  });
  //  page size
  const pagesize: any = {
    position: size_value.position.toLocaleLowerCase(),
    screenwidth:
      size_value.position.toLocaleLowerCase() === "portrait"
        ? portwidthh
        : landwidthh,
    spainwidth: 400,
    screenheight:
      size_value.position.toLocaleLowerCase() === "portrait"
        ? porthighh
        : landhighh,
    widthh:
      size_value.position.toLocaleLowerCase() === "portrait"
        ? size_value.width
        : size_value.height,
    spain_width: size_value.spine_width,
    heightt:
      size_value.position.toLocaleLowerCase() === "portrait"
        ? size_value.height
        : size_value.width,
    dpi: size_value.dpi,
    spain: size_value.is_spine,
    spainposition: "portrait",
    pagetype: size_value.type,
    index: size_value.index,
    bleedmargin: size_value.bleed_margin,
  };
  // console.log("landhighh", landhighh);

  const dpi = pagesize.dpi;
  const blledheight = pagesize.bleedmargin * 2;
  // const height = (pagesize.heightt + blledheight) * dpi + "px";

  // const width = (pagesize.widthh + blledheight) * dpi + "px";

  const _height = (pagesize.heightt + blledheight) * dpi + "px";
  const _width = (pagesize.widthh + blledheight) * dpi + "px";
  const width =
    pagesize.pagetype === "LULU_COVER" ? pagesize.widthh * dpi + "px" : _width;

  const height =
    pagesize.pagetype === "LULU_COVER"
      ? pagesize.heightt * dpi + "px"
      : _height;

  const spainwidt = pagesize.spainwidth;
  const condwdth =
    pagesize.pagetype === "SPINE" ? spainwidt : pagesize.screenwidth;
  const spainwdth: any =
    // pagesize.pagetype === "SPINE" ? pagesize.spain_width * dpi + "px" : width;
    pagesize.pagetype === "PAGE" ||
    pagesize.pagetype === "LULU_COVER" ||
    pagesize.pagetype === "PREVIEW"
      ? width
      : pagesize.pagetype === "COVER"
      ? (pagesize.widthh + pagesize.bleedmargin) * dpi + "px"
      : "";

  const height1 = size_value.height == size_value.width ? spainwdth : height;
  // json value set state in intial stage
  useEffect(() => {
    if (pagejson) {
      const jsonDataa = JSON.parse(pagejson.template);

      const data = jsonDataa;
      setJsonData(data);
    }
  }, []);
  useEffect(() => {
    if (pagejson.template == null && editor && editor.canvas) {
      editor.canvas.setBackgroundColor("white", (img: any) => {
        editor?.canvas.renderAll.bind(editor.canvas);
      });
    }
  });
  function generateUniqueId() {
    return Math.random().toString(36).substring(2, 22);
  }
  // Initial json value load and preview
  useEffect(() => {
    if (checkEditPage.pageclick_loadjson) {
      dispatch(Get_Canvas(""));
      dispatch(ObjectSelecterValue(""));
      dispatch(Edit_text(""));
    }
  }, [checkEditPage.pageclick_loadjson]);

  function activateAllObjects() {
    const canvas = editor?.canvas;
    if (canvas) {
      canvas.discardActiveObject();
      var sel = new fabric.ActiveSelection(canvas.getObjects(), {
        canvas: canvas,
      });
      canvas.setActiveObject(sel);

      canvas.requestRenderAll();
    }
    // diselect();
  }
  // initial load json
  useEffect(() => {
    if (editor && jsonData && text && checkEditPage.pageclick_loadjson) {
      dispatch(Get_Canvas(""));
      const loadFonts = async () => {
        const fontPromises = jsonData.objects
          .filter((e: any) => e.type === "textbox")
          .map(async (e: any) => {
            const fontFace = new FontFace(e.fontFamily, `url(${e.fontUrl})`);

            try {
              await fontFace.load();
              document.fonts.add(fontFace);
              return true; // Font loaded successfully
            } catch (error) {
              console.error("Error loading font:", error);
              return false; // Font failed to load
            }
          });

        const results = await Promise.all(fontPromises);
        const allFontsLoaded = results.every((result) => result); // Check if all fonts loaded successfully

        if (allFontsLoaded) {
          setIsFontLoaded(true);
          editor.canvas.renderAll(); // Render canvas after all fonts are loaded
          editor.canvas.requestRenderAll();
        } else {
          setIsFontLoaded(false);
        }
      };

      loadFonts();
      // if (isFontLoaded) {
      editor.canvas.loadFromJSON(jsonData, () => {
        // Your existing code to handle canvas load
        editor.canvas.renderAll();
        setJsonData("");
        setImageSrc("");
        setText(false);
        dispatch(Pageclick_Loadjson(false));
        activateAllObjects();
      });
      // }
    }
  }, [editor, pageevalue.pagesvalue]);
  // set index selectedobject in state
  useEffect(() => {
    setSelectedObjectIndex(checkEditPage.isIndexx);
    // dispatch(Get_Index2(""));
  }, [checkEditPage.isIndexx]);
  // bleed margin
  useEffect(() => {
    if (editor && editor.canvas && pagesize.pagetype != "LULU_COVER") {
      editor.canvas.getObjects("line").forEach(function (line) {
        editor.canvas.remove(line);
      });
      const bleedMargin = pagesize.bleedmargin * dpi;

      const newWidth = editor.canvas.getWidth();
      const newHeight = editor.canvas.getHeight();

      const topLine = new fabric.Line(
        [bleedMargin, bleedMargin, newWidth - bleedMargin, bleedMargin],
        {
          stroke: "red",
          strokeWidth: 2,
          name: "line",
        }
      );

      const bottomLine = new fabric.Line(
        [
          bleedMargin,
          newHeight - bleedMargin,
          newWidth - bleedMargin,
          newHeight - bleedMargin,
        ],
        {
          stroke: "red",
          strokeWidth: 2,
          name: "line",
        }
      );

      const leftLine = new fabric.Line(
        [bleedMargin, bleedMargin, bleedMargin, newHeight - bleedMargin],
        {
          stroke: "red",
          strokeWidth: 2,
          name: "line",
        }
      );

      const rightLine = new fabric.Line(
        [
          newWidth - bleedMargin,
          bleedMargin,
          newWidth - bleedMargin,
          newHeight - bleedMargin,
        ],
        {
          stroke: "red",
          strokeWidth: 2,
          name: "line",
        }
      );

      {
        // editor.canvas.add(topLine, bottomLine, leftLine, rightLine);

        pagesize.pagetype === "SPINE"
          ? editor.canvas.add(topLine, bottomLine)
          : pagesize.pagetype === "PAGE" ||
            pagesize.pagetype === "LULU_COVER" ||
            pagesize.pagetype === "PREVIEW"
          ? editor.canvas.add(topLine, bottomLine, leftLine, rightLine)
          : pagesize.pagetype === "COVER" && pagesize.index == 1
          ? editor.canvas.add(topLine, bottomLine, rightLine)
          : editor.canvas.add(topLine, bottomLine, leftLine);
      }

      editor.canvas.renderAll();
    }
  }, [editor]);
  // font load
  useEffect(() => {
    // if (checkEditPage.iscustomfont) {
    // console.log(checkEditPage.iscustomfont);

    const fontFace = new FontFace(
      checkEditPage.iscustomfont?.value,
      `url(${checkEditPage.iscustomfont?.url})`
    );

    //      Load custom font for apply the styles
    const loadFont = async () => {
      try {
        await fontFace.load();
        document.fonts.add(fontFace);
        editor?.canvas.renderAll();
        setIsFontLoaded(true);
      } catch (error) {
        // console.error("Error loading font:", error);
        setIsFontLoaded(false);
      }
    };
    loadFont();
    // }
  }, [checkEditPage.iscustomfont]);
  // font family apply
  useEffect(() => {
    if (checkEditPage.iscustomfont) {
      const selectedObject: any = editor?.canvas.getActiveObject();
      if (selectedObject?.type === "textbox") {
        selectedObject.set({
          fontFamily: checkEditPage.iscustomfont.value,
          fontUrl: checkEditPage.iscustomfont.url,
        });
        dispatch(fontFamily(checkEditPage.iscustomfont.value));
        editor?.canvas.renderAll();
      }
    }
  }, [checkEditPage.iscustomfont]);
  // text create
  useEffect(() => {
    if (checkEditPage.istextPTS === "T" && checkEditPage.isupdated === "") {
      const textObject = new fabric.Textbox("Default Text", {
        left: 0,
        top: 0,
        fontSize: 49,
        fill: "#000000",
        selectable: true,
        fontFamily: "Arial, sans-serif",
        fontUrl: "",
        // splitByGrapheme: true,
        strokeWidth: 10,
        width: 350,
        auto_height: 0,
        // height: 300,
        // centeredRotation: true,
        textAlign: "left",
        textAlignVertical: "bottom",
        charSpacing: 50,
        angle: 0,
        scaleX: 1,
        scaleY: 1,
        visible: true,
        unique_id: generateUniqueId(),
        question_id: [],
        autoScaleY: false,
        isResize: true,
        lockUniScaling: false,
        preserveObjectStacking: true,
        uniScaleTransform: true,
        auto_scaling: false,
        // customFont: {
        //   family: "Arial",
        //   source: "",
        //   weight: "normal",
        //   style: "normal",
        // },
      } as any);
      textObject.name = "Text";

      editor?.canvas.add(textObject);
      editor?.canvas.renderAll();
      editor?.canvas.setActiveObject(textObject);
      dispatch(update_questionIds([]));
      dispatch(CapitalReply(false));
      dispatch(Capital(false));
      dispatch(textPTS(""));
      dispatch(AutoScaleBool_Return(false));
      dispatch(Pageclick_Loadjson(false));
      setSelectedIds([]);
      // dispatch(Angle(0));
      // dispatch(Height(0));

      setRender(!render);
      // dispatch(curveinput(textObject.text));
      // dispatch(Anglereturn(Math.round(0)));
    }
  }, [checkEditPage.isInput, checkEditPage.istextPTS]);
  useEffect(() => {
    const selectedObject: any = editor?.canvas?.getActiveObject();
    if (selectedObject?.type === "textbox" && checkEditPage.space) {
      // console.log("checkEditPage.Space", checkEditPage.space);

      selectedObject.set({
        charSpacing: checkEditPage.space,
      });
    }
  }, [checkEditPage.space]);

  // image upload
  useEffect(() => {
    if (checkEditPage.isImage) {
      const tt = checkEditPage.isImage;
      const oldUrl = tt;
      // const newUrl = oldUrl.replace("https://", "http://");

      fabric.Image.fromURL(
        oldUrl,
        (image) => {
          image.set({
            left: 0,
            top: 0,
            selectable: true,
            name: "image",
            unique_id: generateUniqueId(),
            visible: true,
          } as any);
          dispatch(Pageclick_Loadjson(false));
          setRender(!render);
          editor?.canvas.add(image);
        }
        // { crossOrigin: "anonymous" }
      );
      dispatch(Image_Upload(""));
      editor?.canvas.renderAll();
    }
  }, [checkEditPage.isImage]);
  // noimage
  useEffect(() => {
    if (checkEditPage.noimage) {
      fabric.Image.fromURL(
        // ` https://comicsdev.s3.ap-south-1.amazonaws.com/pages/noimageavailable.png`,
        `https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019`,
        (image) => {
          // image.scaleToWidth(200);
          // image.scaleToHeight(200);
          image.set({
            left: 0,
            top: 0,
            selectable: true,
            name: "image",
            unique_id: generateUniqueId(),
            visible: true,
            question_id: "",
            angle: 0,
          } as any);
          dispatch(Pageclick_Loadjson(false));
          setRender(!render);
          editor?.canvas.add(image);
        },
        { crossOrigin: "" }
      );
      dispatch(NoImage(false));
      dispatch(Image_Upload(""));
      editor?.canvas.renderAll();
    }
  }, [checkEditPage.noimage]);
  // update image
  useEffect(() => {
    if (editor && editor.canvas && checkEditPage.updateimage) {
      const selectedObject: any = editor.canvas.getActiveObject();

      if (selectedObject && selectedObject.type === "image") {
        const tt = checkEditPage.updateimage.imageurl;

        selectedObject.setSrc(tt, () => {
          editor.canvas.renderAll();
        });
        selectedObject.set({
          question_id: checkEditPage.updateimage.questionid,
        });
        editor.canvas.renderAll();

        // const updateurl = oldUrl.replace("https://", "http://");
        // fabric.Image.fromURL(oldUrl, (img: any) => {
        //   img.set({
        //     left: selectedObject.left,
        //     top: selectedObject.top,
        //     // width: selectedObject.width,
        //     // height: selectedObject.height,
        //     angle: selectedObject.angle,
        //     selectable: true,
        //     name: "image",
        //     unique_id: selectedObject.unique_id,
        //     visible: true,
        //     question_id: checkEditPage.updateimage.questionid,
        //   });

        //   // Replace the existing object with the new image
        //   editor.canvas.remove(selectedObject);
        //   editor.canvas.add(img);
        //   editor.canvas.setActiveObject(img);
        // });

        dispatch(Edit_option(""));
        dispatch(UpdatedImage(""));
      }
      editor.canvas.renderAll();
    }
  }, [checkEditPage.updateimage]);
  // dispatch x,Y,angle & width
  useEffect(() => {
    if (editor && editor.canvas) {
      const selectedObject: any = editor?.canvas.getActiveObject();
      if (selectedObject) {
        dispatch(ObjectSelecterValue(selectedObject?.type));
        dispatch(Xreturn(selectedObject.left));
        dispatch(Yreturn(selectedObject.top));
        dispatch(Anglereturn(selectedObject.angle));
        dispatch(LineReturn(selectedObject.lineHeight));
        dispatch(fontselectedValue(selectedObject.fontSize));

        // dispatch(SpaceReturn(selectedObject.charSpacing));
        if (selectedObject.type != "group") {
          dispatch(Widthreturn(Math.round(selectedObject.width)));
          dispatch(Heightreturn(selectedObject.auto_height));
          // dispatch(Heightreturn(Math.round(selectedObject.height)));
        }
      }
    }
  }, [editor]);

  // useEffect(() => {
  //   const selectedObject = editor?.canvas.getActiveObject();

  //   if (selectedObject) {
  //     selectedObject.on("modified", function () {
  //       dispatch(Xreturn(selectedObject.left));
  //       dispatch(Yreturn(selectedObject.top));
  //     });
  //   }
  // }, [editor]);
  // height adjust text
  useEffect(() => {
    const selectedObject = editor?.canvas.getActiveObject() as any;

    if (selectedObject) {
      selectedObject.set({
        height: checkEditPage.height,
        auto_height: checkEditPage.height,
      }); // Set the desired height
      editor?.canvas.renderAll();
      // dispatch(Heightreturn(0));
    }
  }, [checkEditPage.height]);
  // stop scale
  useEffect(() => {
    const selectedObject: any = editor?.canvas.getActiveObject();

    let isResizing = false;
    let startY: any;
    let initialHeight: any;
    if (selectedObject && selectedObject?.type === "textbox") {
      selectedObject.on("mousedown", function (e: any) {
        if (e.target.__corner == "mb") {
          isResizing = true;
          startY = e.pointer.y;
          initialHeight = selectedObject.height;
        }
      });
      editor?.canvas.on("mouse:move", function (e: any) {
        if (isResizing) {
          const deltaY = e.pointer.y - startY;
          const newHeight = initialHeight + deltaY;

          selectedObject.set({
            height: newHeight,
            auto_height: newHeight,

            scaledHeight: false,
            AutoMode: false,
          });
          editor.canvas.renderAll(); // Render the canvas to apply the changes
          // dispatch(Heightreturn(newHeight));
        }
      });
      editor?.canvas.on("mouse:up", function (e) {
        isResizing = false;
      });
    }
  }, [editor]);
  //mt,mb false,true
  useEffect(() => {
    const selectedObject = editor?.canvas.getActiveObject() as fabric.Object;
    if (selectedObject?.type === "image") {
      selectedObject.setControlVisible("mb", true);
      selectedObject.setControlVisible("mt", true);
    } else if (selectedObject) {
      selectedObject.setControlVisible("mb", false);
      selectedObject.setControlVisible("mt", false);
    }
    editor?.canvas.renderAll();
  }, [editor]);
  // height auto adjust
  useEffect(() => {
    const handleObjectModified = (e: any) => {
      const selectedObject = editor?.canvas.getActiveObject() as any;
      if (selectedObject?.height && selectedObject.type === "textbox") {
        const dd = checkEditPage.height > 60 ? checkEditPage.height : 10000;
        if (selectedObject.auto_height > 0 && selectedObject?.height > dd) {
          selectedObject.set({
            height: checkEditPage.height
              ? checkEditPage.height
              : selectedObject.height,
            fontSize: selectedObject?.fontSize - 1,
            inverted: true,
          } as any);
          dispatch(fontselectedValue(selectedObject.fontSize));

          editor?.canvas.renderAll(); // Make sure to re-render the canvas
        }
      }
    };
    if (editor) {
      editor.canvas.on("after:render", handleObjectModified);
    }
    return () => {
      if (editor) {
        editor.canvas.off("after:render", handleObjectModified);
      }
    };
  }, [editor, checkEditPage.height, uniquelayer_id]);

  // guidelines
  useEffect(() => {
    const handleObjectSelection = () => {
      const guideline = new AlignGuidelines({
        canvas: editor?.canvas,
        aligningOptions: {
          lineColor: "green",
          lineWidth: 2,
          lineMargin: 2,
        },
      } as any);
      guideline.init();
    };

    const handleObjectDeselection = () => {};

    editor?.canvas.on("selection:created", handleObjectSelection);
    editor?.canvas.on("selection:updated", handleObjectSelection);
    editor?.canvas.on("selection:cleared", handleObjectDeselection);

    return () => {
      editor?.canvas.off("selection:created", handleObjectSelection);
      editor?.canvas.off("selection:updated", handleObjectSelection);
      editor?.canvas.off("selection:cleared", handleObjectDeselection);
    };
  }, [editor]);
  // modified setjson value
  useEffect(() => {
    const selectedObject: any = editor?.canvas.getActiveObject();
    // console.log("uniqueid", selectedObject);

    if (selectedObject) {
      setUniqueLayer_id(selectedObject.unique_id);
      selectedObject.on("modified", function () {
        const json = editor?.canvas.toJSON();
        if (json) {
          const jsonValue = JSON.stringify(json);
          const jsonData = JSON.parse(jsonValue);
          setCanvasJson(jsonData);
        }
      });
    }
  }, [editor]);

  useEffect(() => {
    if (editor && editor.canvas) {
      const selectedObject: any = editor.canvas.getActiveObject();

      if (selectedObject && checkEditPage.autoscalebool != null) {
        console.log("auroenter", checkEditPage.autoscalebool);

        selectedObject.set({
          auto_scaling: checkEditPage.autoscalebool,
        });
        dispatch(AutoScaleBool(null));
      }
    }
  }, [checkEditPage.autoscalebool]);

  useEffect(() => {
    if (editor && editor.canvas) {
      const selectedObject: any = editor.canvas.getActiveObject();
      if (selectedObject) {
        if (selectedObject?.type === "textbox") {
          console.log("selected", selectedObject.auto_scaling);
          dispatch(AutoScaleBool_Return(selectedObject.auto_scaling));
        }
      }
    }
  }, [uniquelayer_id, checkEditPage.autoscalebool]);
  // selected object text and curve
  useEffect(() => {
    if (editor && editor.canvas) {
      const selectedObject: any = editor.canvas.getActiveObject();

      if (selectedObject) {
        // console.log("selectedobject", selectedObject);
        // const dd = selectedObject.getCenterPoint();
        setSelectedObjectData(selectedObject?.type);
        if (selectedObject?.type === "image") {
          dispatch(QuestionOpen(false));
          dispatch(Layer_id(selectedObject.unique_id));

          if (selectedObject.question_id != undefined) {
            dispatch(QuestionOpen(true));
          }
          dispatch(ObjectSelecterValue("empty"));
          dispatch(ObjectSelecterValue(selectedObject?.type));

          if (selectedObject.question_id) {
            dispatch(list_PageQuestions(selectedObject.question_id));
          } else if (selectedObject.question_id === "") {
            dispatch(clearListPageQuestions());
          }
        }
        if (selectedObject?.type === "textbox") {
          dispatch(Layer_id(selectedObject.unique_id));

          // setInitialPosition({
          //   left: dd.left,
          //   top: dd.top,
          // });
          // dispatch(ObjectSelecterValue("empty"));
          // setTimeout(() => {
          // dispatch(ObjectSelecterValue(selectedObject?.type));
          // }, 10);
          // dispatch(ObjectSelecterValue(selectedObject?.type));
          dispatch(textvalue(selectedObject.text));
          dispatch(updataColor(selectedObject.fill));
          dispatch(SelectedClr(selectedObject.fill));
          dispatch(SpaceReturn(selectedObject.charSpacing));
          dispatch(fontselectedValue(selectedObject.fontSize));

          dispatch(clickCurve(false));
          if (selectedObject?.question_id?.length > 0) {
            setSelectedIds(selectedObject?.question_id);
          }

          if (selectedObject.question_id?.length > 0) {
            dispatch(Textquestion_id(selectedObject.question_id));
            dispatch(update_questionIds(selectedObject.question_id));
            // dispatch(listDropdownById(selectedObject.question_id));
          } else {
            dispatch(Textquestion_id([]));
            dispatch(update_questionIds([]));
            dispatch(clearExtraData());
            dispatch(cleartextOptionsList());
          }
          const fontselected = selectedObject.fontFamily;
          selectedObject.set({
            height: selectedObject.auto_height,
            isResize: true,
            text: selectedObject.text,
            name: selectedObject.name,
            unique_id: selectedObject.unique_id,
            question_id: selectedObject.question_id,
            fontStyle: checkEditPage.isItalic
              ? checkEditPage.isItalic === "italic"
                ? "italic"
                : checkEditPage.isItalic === "null"
                ? ""
                : selectedObject.fontStyle
              : selectedObject.fontStyle,
            fontWeight: checkEditPage.isBold
              ? checkEditPage.isBold
              : selectedObject.fontWeight,
            fill: checkEditPage.iscolor
              ? checkEditPage.iscolor
              : selectedObject.fill,
            underline: checkEditPage.isUnderline
              ? checkEditPage.isUnderline === "true"
                ? true
                : checkEditPage.isUnderline === "null"
                ? ""
                : selectedObject.underline
              : selectedObject.underline,
            fontFamily: checkEditPage.iscustomfont
              ? checkEditPage.iscustomfont.value
              : fontselected == fontselected
              ? fontselected
              : checkEditPage.iscustomfont.value,
            textAlign: checkEditPage.istextAlignment
              ? checkEditPage.istextAlignment
              : selectedObject.textAlign,
            lineHeight: selectedObject.lineHeight,
            editable: true,
            selectable: true,
            charSpacing: selectedObject.charSpacing,
            angle: selectedObject.angle,
          });

          dispatch(textcolor(""));
          dispatch(textunderline(""));
          dispatch(textitalic(""));
          dispatch(CustomFont(""));
          dispatch(textBold(""));
          dispatch(textAlignment(""));
          dispatch(CapitalReply(false));
          dispatch(Layer_name(""));
          dispatch(SelectedClr(""));

          // dispatch(Xaxis(null));
          // dispatch(Yaxis(null));
        }

        if (selectedObject?.type === "group") {
          dispatch(ObjectSelecterValue(""));

          // dispatch(ObjectSelecterValue(selectedObject?.type));

          const textObjects = selectedObject?.getObjects();
          // console.log(" group 1077 selected object apply style", textObjects);
          dispatch(fontselectedValue(textObjects[0]?.fontSize));
          dispatch(updataColor(textObjects[0]?.fill));
          dispatch(SelectedClr(textObjects[0]?.fill));
          dispatch(clickCurve(true));

          // console.log(
          //   "selectedObject.storeAngleForpreview",
          //   selectedObject.storeAngleForpreview
          // );

          // dispatch(updateFlip(textObjects[0]?.angle > 0 ? true : false));
          dispatch(updateFlip(selectedObject.storeFlipForpreview));
          dispatch(updateCurve(true));
          dispatch(
            updateCurving(
              selectedObject.storeAngleForpreview
              // ? selectedObject.storeAngleForpreview
              // : 1
            )
          );

          let textValue = [];
          for (let i = 0; i < textObjects.length; i++) {
            const textObject = textObjects[i];

            const fontselected = textObject.fontFamily;
            textObject.set({
              name: selectedObject.name,
              unique_id: selectedObject.unique_id,
              fontStyle: checkEditPage.isItalic
                ? checkEditPage.isItalic === "italic"
                  ? "italic"
                  : checkEditPage.isItalic === "null"
                  ? ""
                  : textObject.fontStyle
                : textObject.fontStyle,
              fontWeight: checkEditPage.isBold
                ? checkEditPage.isBold
                : textObject.fontWeight,
              fill: checkEditPage.iscolor
                ? checkEditPage.iscolor
                : textObject.fill,
              underline: checkEditPage.isUnderline
                ? checkEditPage.isUnderline === "true"
                  ? true
                  : checkEditPage.isUnderline === "null"
                  ? ""
                  : textObject.underline
                : textObject.underline,
              fontFamily: checkEditPage.iscustomfont
                ? checkEditPage.iscustomfont.value
                : fontselected == fontselected
                ? fontselected
                : checkEditPage.iscustomfont.value,
              editable: false,
              selectable: true,
            });
            textValue.push(textObject.text);
            //
          }

          dispatch(textvalue(textValue.join("")));
          // dispatch(curveinput(selectedObject.text));
          setRender(!render);
          // editor?.canvas.renderAll();
          dispatch(textcolor(""));
          dispatch(textunderline(""));
          dispatch(textitalic(""));
          dispatch(CustomFont(""));
          dispatch(textBold(""));
          dispatch(textAlignment(""));
          dispatch(SelectedClr(""));
        }
      }
    }
  }, [
    checkEditPage.isItalic,
    checkEditPage.isBold,
    checkEditPage.iscolor,
    checkEditPage.isUnderline,
    checkEditPage.isObjectsele_boolean,
    checkEditPage.layer_name,
    checkEditPage.istextAlignment,
    checkEditPage.isselecclr,
    checkEditPage.isrender,
    checkEditPage.iscustomfont,
    checkEditPage.custom,
    checkEditPage.capitalreply,
    // checkEditPage.x_return,
    uniquelayer_id,
  ]);
  // text questionid
  useEffect(() => {
    const selectedObject: any = editor?.canvas.getActiveObject();

    if (
      selectedObject &&
      checkEditPage.jsonid_dropdown &&
      (selectedObject.type === "textbox" || selectedObject.type === "image")
    ) {
      var newIds: any = [...selectedIds, checkEditPage.jsonid_dropdown];

      const counts: any = {};
      newIds.forEach((item: any) => {
        counts[item] = (counts[item] || 0) + 1;
      });

      const duplicates = Object.keys(counts)
        .filter((item) => counts[item] > 1)
        .map((item) => parseInt(item));
      if (duplicates.length != 0) {
        const datea = newIds.filter((e: any) => e != duplicates);
        selectedObject.set({
          question_id: datea,
        } as any);
        dispatch(update_questionIds(datea));

        setSelectedIds(datea);
        dispatch(Jsonid_Dropdown(""));
      } else {
        selectedObject.set({
          question_id: newIds,
        } as any);
        dispatch(update_questionIds(newIds));

        dispatch(Jsonid_Dropdown(""));
        setSelectedIds(newIds);
      }
    }
  }, [checkEditPage.jsonid_dropdown, selectedIds]);
  // curve text disable function curve to normal text
  useEffect(() => {
    if (editor && editor.canvas && checkEditPage.isupdated === "updatedd") {
      const selectedObject: any = editor.canvas.getActiveObject();
      if (selectedObject && selectedObject?.getObjects()?.length > 0) {
        console.log(
          "curve to normal",
          // selectedObject,
          // checkEditPage.isselecttextvalue,
          // selectedObject?.left,
          // selectedObject?.top,
          selectedObject?.getObjects()[0]?.fontSize
        );

        editor.canvas.remove(selectedObject);
        setSelectedObjectData(selectedObject?.type);
        const textObject = new fabric.Textbox(checkEditPage.isselecttextvalue, {
          name: selectedObject.name,
          unique_id: selectedObject.unique_id,
          question_id: selectedObject.question_id,
          left: selectedObject?.left,
          top: selectedObject?.top,
          // splitByGrapheme: true,
          fontStyle: selectedObject?.getObjects()[0]?.fontStyle,
          fontSize: selectedObject?.getObjects()[0]?.fontSize,
          fill: selectedObject?.getObjects()[0]?.fill,
          selectable: true,
          fontFamily:
            selectedObject?.getObjects()[0]?.fontFamily ?? "Arial, sans-serif",
          width: 300,
          strokeWidth: 10,
          textAlign: selectedObject?.getObjects()[0]?.textAlign ?? "left",
          fontWeight: selectedObject?.getObjects()[0]?.fontWeight ?? "normal",
          editable: true,
          underline: selectedObject?.getObjects()[0]?.underline ?? "",
        } as any);
        editor?.canvas.add(textObject);
        editor?.canvas.renderAll();
        editor?.canvas.setActiveObject(textObject);
        dispatch(textUpdate(""));
        dispatch(updateFlip(false));
        dispatch(updateCurving(1));
      }
    }
  }, [checkEditPage.isupdated, checkEditPage.isselecttextvalue]);
  // create curve test
  useEffect(() => {
    if (checkEditPage.isCurve && !checkEditPage.pageclick_loadjson) {
      if (editor && editor.canvas) {
        const selectedObject: any = editor.canvas.getActiveObject();
        dispatch(ObjectSelecterValue(""));
        dispatch(ObjectSelecterValue(selectedObject?.type));
        console.log(
          "curving 932 create curve",
          checkEditPage.isselecttextvalue
        );

        if (checkEditPage.isselecttextvalue) {
          editor.canvas.remove(selectedObject);
          //    give the copy values here
          const copyText = checkEditPage.isselecttextvalue;

          const fontSize = checkEditPage.isfontsize;
          const flip =
            checkEditPage.isobjectvalue === "group"
              ? checkEditPage.isFlipCurve
              : false;
          const angle =
            checkEditPage.isobjectvalue === "group"
              ? checkEditPage.isCurving
              : 1;

          const curveAngle = angle / 2; // The angle by which the text will be curved
          const textLength = copyText.length;
          const halfTextLength = textLength / 2;
          const anglePerCharacter = curveAngle / (halfTextLength - 1);

          // Calculate the total width of the text by summing the width of each character
          let totalTextWidth = 0;
          for (let i = 0; i < textLength; i++) {
            const char = copyText[i];
            const sampleChar = new fabric.Text(char, {
              fontSize: fontSize,
              fontFamily: "Arial",
            });
            totalTextWidth += sampleChar.width!;
          }

          // Calculate the maximum width of a single character in the text
          const maxCharWidth = totalTextWidth / textLength;

          // Calculate the required radius to fit the entire text along the desired curved path without extra kerning space
          const desiredRadius =
            maxCharWidth /
            Math.sin(fabric.util.degreesToRadians(anglePerCharacter / 2));

          const textObjects = [];

          // Loop through the characters and calculate their positions on the circular path
          for (let i = 0; i < textLength; i++) {
            const charIndexFromCenter = i - halfTextLength + 0.5; // Offset by 0.5 to make center character at 0 degree

            const calculatedAngle = charIndexFromCenter * anglePerCharacter;

            const xPos =
              desiredRadius *
              Math.sin(fabric.util.degreesToRadians(calculatedAngle)); // x position based on angle and radius

            // Calculate yPos to create a curve without increasing the diameter
            let curveOffset =
              desiredRadius -
              desiredRadius *
                Math.cos(fabric.util.degreesToRadians(calculatedAngle));

            let finalAngle = calculatedAngle;
            if (flip) {
              // Flip the text to face inward
              curveOffset = -curveOffset;
              finalAngle = 180 - calculatedAngle; // Apply the mirror effect for the angle when f lipped
            }

            const yPos = curveOffset;

            const textObject = new fabric.Text(copyText[i], {
              left: selectedObject.left + xPos, // Add the x position to the initial left position
              top: selectedObject.top + yPos, // Add the y position to the initial top position
              originX: "center", // Set originX to center for correct rotation around the center of the character
              originY: "center", // Set originY to center for correct rotation around the center of the character
              fontSize: fontSize ? fontSize : selectedObject.fontSize,
              fontFamily:
                selectedObject.type === "group"
                  ? selectedObject?.getObjects()[0]?.fontFamily ?? "Arial"
                  : selectedObject.fontFamily,
              fill:
                selectedObject.type === "group"
                  ? selectedObject?.getObjects()[0]?.fill
                  : selectedObject.fill,
              fontWeight:
                selectedObject.type === "group"
                  ? selectedObject?.getObjects()[0]?.fontWeight ?? ""
                  : selectedObject.fontWeight,
              fontStyle:
                selectedObject.type === "group"
                  ? selectedObject?.getObjects()[0]?.fontStyle ?? ""
                  : selectedObject.fontStyle,
              underline:
                selectedObject.type === "group"
                  ? selectedObject?.getObjects()[0]?.underline ?? ""
                  : selectedObject.underline,
              // underline: true,
              angle: finalAngle,
              scaleX: flip ? -1 : 1,
              scaleY: flip ? -1 : 1,
            });

            textObjects.push(textObject);
          }

          const group = new fabric.Group(textObjects, {
            name: selectedObject.name,
            left: selectedObject.left,
            top: selectedObject.top,
            unique_id: selectedObject.unique_id,
            selectable: true,
            storeAngleForpreview:
              checkEditPage.isobjectvalue === "group"
                ? checkEditPage.isCurving
                : 1, // Your custom number here
            // storeFlipForpreview:
            //   checkEditPage.isobjectvalue === "group"
            //     ? checkEditPage.isFlipCurve
            //     : false, // Your custom number here
          } as any);

          editor?.canvas.add(group);
          editor?.canvas.renderAll();
          editor?.canvas.setActiveObject(group);
        }
      }
    }
  }, [
    // checkEditPage.isInput,
    checkEditPage.isCurving,
    checkEditPage.isfontsize,
    checkEditPage.isCurve,
    checkEditPage.isFlipCurve,
    // checkEditPage.isInput,
    // checkEditPage.isUpdateCurving,

    // checkEditPage.curveinputValue,
  ]);

  // text small and capital
  useEffect(() => {
    if (editor && editor.canvas) {
      const selectedObject: any = editor.canvas.getActiveObject();

      if (selectedObject?.type === "textbox") {
        if (checkEditPage.capital || checkEditPage.small) {
          const originalText = selectedObject.text;
          const capitalizedText = originalText.toUpperCase();
          const smalltext = originalText.toLowerCase();
          if (checkEditPage.capital) {
            selectedObject.set({
              text: capitalizedText,
            });
            dispatch(Capital(false));
          } else if (checkEditPage.small) {
            selectedObject.set({
              text: smalltext,
            });
            dispatch(Small(false));
          }
        }
      } else if (selectedObject?.type === "group") {
        const textObjects = selectedObject?.getObjects();
        for (let i = 0; i < textObjects.length; i++) {
          if (checkEditPage.capital || checkEditPage.small) {
            const textObject = textObjects[i];
            const capitalizedText = textObject.text.toUpperCase();
            const smalltext = textObject.text.toLowerCase();
            if (checkEditPage.capital) {
              textObject.set({
                text: capitalizedText,
              });
              dispatch(Capital(false));
            } else if (checkEditPage.small) {
              textObject.set({
                text: smalltext,
              });
              dispatch(Small(false));
            }
          }
        }
      }
    }
  }, [checkEditPage.capital, checkEditPage.capitalreply, checkEditPage.small]);

  // selected object image width
  useEffect(() => {
    if (editor && editor.canvas) {
      const selectedObject: any = editor.canvas.getActiveObject();
      if (selectedObject) {
        if (selectedObject?.type === "image" && checkEditPage.width) {
          selectedObject.set({
            width: checkEditPage.width,
          });
        }
      }
    }
  }, [checkEditPage.width]);

  // layername
  useEffect(() => {
    if (editor && editor.canvas) {
      const selectedObject: any = editor.canvas.getActiveObject();
      if (selectedObject) {
        if (checkEditPage.layer_name) {
          selectedObject.set({
            name: checkEditPage.layer_name,
          });
        }
      }
    }
  }, [checkEditPage.layer_name]);

  //x,y,fontsize & width
  useEffect(() => {
    if (editor && editor.canvas) {
      const selectedObject: any = editor.canvas.getActiveObject();
      if (selectedObject) {
        if (
          selectedObject?.type === "image" &&
          (checkEditPage.x_axis || checkEditPage.y_axis || checkEditPage.width)
        ) {
          selectedObject.set({
            left: checkEditPage.x_axis,
            top: checkEditPage.y_axis,
            // width: checkEditPage.width,
          });
        }
        if (
          selectedObject?.type === "textbox" &&
          (checkEditPage.x_axis ||
            checkEditPage.y_axis ||
            checkEditPage.isfontsize ||
            checkEditPage.angle ||
            checkEditPage.width ||
            checkEditPage.line)
        ) {
          selectedObject.set({
            left: checkEditPage.x_axis,
            top: checkEditPage.y_axis,
            fontSize: checkEditPage.isfontsize,
            width: checkEditPage.width,
            lineHeight: checkEditPage.line,
            // height: checkEditPage.height,
            // scaleY: checkEditPage.height / selectedObject.height,
          });
        }
        if (
          selectedObject?.type === "group" &&
          (checkEditPage.x_axis ||
            checkEditPage.y_axis ||
            checkEditPage.isfontsize ||
            checkEditPage.width)
        ) {
          selectedObject.set({
            left: checkEditPage.x_axis,
            top: checkEditPage.y_axis,
            fontSize: checkEditPage.isfontsize,
            // width: checkEditPage.width,
            // height: checkEditPage.height,
            // scaleY: checkEditPage.height / selectedObject.height,
          });
        }
      }
    }
  }, [
    checkEditPage.x_axis,
    checkEditPage.y_axis,
    checkEditPage.isfontsize,
    checkEditPage.width,
    checkEditPage.line,
    // checkEditPage.height,
  ]);
  // angle layer
  useEffect(() => {
    if (editor && editor.canvas) {
      const selectedObject: any = editor.canvas.getActiveObject();
      if (selectedObject) {
        const center = selectedObject.getCenterPoint();

        if (checkEditPage.angle) {
          prevCenterRef.current = { x: center.x, y: center.y };
          selectedObject.set("angle", checkEditPage.angle);
          selectedObject.set({
            left: center.x,
            top: center.y,
            originX: "center",
            originY: "center",
          });
          editor.canvas.renderAll();
          // }
        }
      }
    }
  }, [checkEditPage.angle]);

  // Toplayer
  useEffect(() => {
    if (checkEditPage.toplayer) {
      const selectedObject = editor?.canvas.getActiveObject();
      if (selectedObject) {
        selectedObject.bringToFront();
        editor?.canvas.renderAll();
      }
      setRender(!render);

      dispatch(TopLayer(false));
    }
  }, [checkEditPage.toplayer]);
  // endlayer
  useEffect(() => {
    if (checkEditPage.endlayer) {
      const selectedObject = editor?.canvas.getActiveObject();
      if (selectedObject) {
        selectedObject.sendToBack();
        editor?.canvas.renderAll();
      }
      setRender(!render);

      dispatch(EndLayer(false));
    }
  }, [checkEditPage.endlayer]);
  // layer downward
  useEffect(() => {
    if (checkEditPage.downlayer) {
      const selectedObject = editor?.canvas.getActiveObject();

      if (selectedObject) {
        editor?.canvas.sendBackwards(selectedObject);
        editor?.canvas.renderAll();

        setRender(!render);
      }
      dispatch(DownLayer(false));
    }
  }, [checkEditPage.downlayer]);
  // layer upward
  useEffect(() => {
    if (checkEditPage.uplayer) {
      const selectedObject = editor?.canvas.getActiveObject();

      if (selectedObject) {
        editor?.canvas.bringForward(selectedObject);
        editor?.canvas.renderAll();

        setRender(!render);
      }
      dispatch(UpLayer(false));
    }
  }, [checkEditPage.uplayer]);
  function resetCanvasPosition() {
    const initialZoom = 1; // Set this to your desired initial zoom level
    const initialPan = { x: 0, y: 0 }; // Set this to your desired initial pan position

    editor?.canvas.setZoom(initialZoom);
    editor?.canvas.absolutePan(initialPan);
  }
  // objects active or deactive
  useEffect(() => {
    if (!checkEditPage.unselect) {
      editor?.canvas.forEachObject((obj) => {
        obj.selectable = true;
      });
    } else {
      editor?.canvas.discardActiveObject();
      editor?.canvas.forEachObject((obj) => {
        obj.selectable = false;
      });
    }
  }, [checkEditPage.unselect]);
  // set background color
  // useEffect(() => {
  //   if (editor && editor.canvas) {
  //     editor.canvas.setBackgroundColor(checkEditPage.backg_colr, (img: any) => {
  //       editor?.canvas.renderAll.bind(editor.canvas);
  //     });
  //   }
  // }, [checkEditPage.backg_colr]);
  // undo,redo,delete,left,right,top,bottom

  // Call the function to activate all objects

  useEffect(() => {
    // delete
    if (checkEditPage.isOption === "delete") {
      editor?.canvas.clear();
      setSelectedIds([]);
      dispatch(Edit_option("null"));
      dispatch(Get_Canvas(""));
      setRender(!render);
      setUndoStack([]);
      setRedoStack([]);
      setCanvasData([]);
    }
    if (checkEditPage.isOption === "deleted") {
      const selectedObject: any = editor?.canvas.getActiveObject();
      // console.log(selectedObject);
      // if (selectedObject?.unique_id) {
      //   dispatch(delete_matrixQuestionLayer(selectedObject.unique_id));
      // }

      const deletelayer = editor?.deleteSelected();

      setHistory([...history, selectedObject]);
      setSelectedIds([]);
      dispatch(Edit_option("null"));
    }
    if (checkEditPage.isOption === "reset") {
      resetCanvasPosition();
      dispatch(Edit_option("null"));
    }

    if (checkEditPage.isOption === "deactive") {
      editor?.canvas.discardActiveObject();

      dispatch(Edit_option("null"));
    }
    // undo
    // if (checkEditPage.isOption === "undo") {
    //   console.log(editor?.canvas._objects);

    //   const his: any = editor?.canvas._objects.pop();
    //   if (his) {
    //     setHistory([...history, his]);
    //   }

    //   setRender(!render);
    //   dispatch(Edit_option("null"));
    // }

    if (checkEditPage.isOption === "undo") {
      editor?.canvas.discardActiveObject();

      // undo()
      const numToSkip = 4;
      for (let i = 0; i < numToSkip; i++) {
        const skippedObject = editor?.canvas._objects.pop();
      }

      const his = editor?.canvas._objects.pop(); // This is now the fifth-to-last element

      if (his) {
        setHistory([...history, his]);
      }

      setRender(!render);
      dispatch(Edit_option("null"));
    }

    // redo
    if (checkEditPage.isOption === "redo") {
      // redo()
      if (history.length > 0) {
        editor?.canvas.add(history.pop());
      }
      setRender(!render);
      dispatch(Edit_option("null"));
    }
    if (checkEditPage.isOption === "left") {
      // const units = 300;
      const delta = new fabric.Point(-units, 0);
      editor?.canvas.relativePan(delta);

      setRender(!render);
      dispatch(Edit_option("null"));
    }
    if (checkEditPage.isOption === "right") {
      // const units = 300;
      const delta = new fabric.Point(units, 0);
      editor?.canvas.relativePan(delta);
      dispatch(Edit_option("null"));
      setRender(!render);
    }
    if (checkEditPage.isOption === "top") {
      // const units = 300;
      const delta = new fabric.Point(0, -units);
      editor?.canvas.relativePan(delta);
      dispatch(Edit_option("null"));

      setRender(!render);
    }
    if (checkEditPage.isOption === "bottom") {
      // const units = 500;
      const delta = new fabric.Point(0, units);
      editor?.canvas.relativePan(delta);
      dispatch(Edit_option("null"));

      setRender(!render);
    }

    if (checkEditPage.isOption === "toggle") {
      resetCanvasPosition();
      editor?.canvas.forEachObject((obj) => {
        obj.selectable = true;
      });
      dispatch(Edit_option("null"));
      if (zoom) {
        setPortwidth(scaledWidth);
        setPorthigh(scaledHeight);
        setLandwidth(scaledHeight);
        setLandhigh(scaledWidth);
      }
      setZoom(!zoom);
      setRender(!render);
    }
    if (editor && editor.canvas) {
      editor.canvas.setBackgroundColor("white", (img: any) => {
        editor?.canvas.renderAll.bind(editor.canvas);
      });
    }

    // image
    // set background color

    // set background image
    // if (editor && editor.canvas) {
    //   fabric.Image.fromURL(background_image, (img) => {
    //     img.scaleToWidth(pagesize.widthh * 100 + 40); // Set width
    //     img.scaleToHeight(pagesize.heightt * 100 + 40); // Set height

    //     editor.canvas.setBackgroundImage(
    //       img,
    //       editor.canvas.renderAll.bind(editor.canvas)
    //     );
    //   });
    // }

    // add image
    // if (checkEditPage.isEdit === "image") {
    //   if (checkEditPage.isImage) {
    //     fabric.Image.fromURL(
    //       newUrl,
    //       (image) => {
    //         image.scaleToWidth(200);
    //         image.scaleToHeight(200);
    //         editor?.canvas.add(image);
    //       },
    //       { crossOrigin: "" }
    //     );
    //     editor?.canvas.renderAll();

    //     dispatch(Image_Upload(""));
    //   }
    // }
    // editor?.canvas.forEachObject((obj) => {
    //   obj.set("selectable", true);
    // });
    // editor?.canvas.on("object:selected", handleObjectSelected);
    editor?.canvas.renderAll();
  }, [checkEditPage.isOption, editor]);

  // selecter corner and line styles
  useEffect(() => {
    if (editor && editor.canvas) {
      const selectedObject: any = editor.canvas.getActiveObject();
      if (selectedObject) {
        // console.log("selecttrue");

        // dispatch(ObjectSelecterValue(selectedObject.type));
        selectedObject.setOptions({
          borderColor: "blue",
          hasRotatingPoint: true,
          perPixelTargetFind: false,
          transparentCorners: false,
          selectionLineWidth: 50,
          cornerStrokeColor: "black",
          borderWidth: 3,
          cornerSize: 30,
        });
        dispatch(Get_Index(selectedObject.unique_id));
        dispatch(ObjectSelection_boolean(true));
        editor.canvas.requestRenderAll();
      } else {
        dispatch(Height(0));
        dispatch(Get_Index(""));
        // console.log("selectfalse");

        dispatch(ObjectSelecterValue(""));

        // dispatch(Get_Index2(""));
        dispatch(ObjectSelection_boolean(false));
        // dispatch(Edit_text(""));
        // dispatch(Edit_option(""));
        dispatch(fontFamily(""));
        dispatch(CustomFont(""));
        setIsFontLoaded(false);
      }
    }
  }, [editor]);

  // get canvas for layer
  useEffect(() => {
    const json = editor?.canvas.toJSON();

    const fulljson: any = editor?.canvas.getObjects();

    const ff: any = editor?.canvas.toDatalessJSON();
    // console.log("ff", ff);

    if (ff && ff.objects && ff.objects.length > 0) {
      ff.objects.forEach((obj: any, index: any) => {
        ff.objects[index].name = fulljson[index].name
          ? fulljson[index].name
          : "null";

        ff.objects[index].unique_id = fulljson[index].unique_id;
        ff.objects[index].question_id = fulljson[index].question_id;
        ff.objects[index].auto_scaling = fulljson[index].auto_scaling;
        if (fulljson[index].storeAngleForpreview) {
          ff.objects[index].storeAngleForpreview =
            fulljson[index].storeAngleForpreview;
        }
      });

      const jsonValueee = JSON.stringify(ff);
      // console.log("jsonValueee", jsonValueee);

      const parsedObject = JSON.parse(jsonValueee);

      if (Array.isArray(parsedObject.objects)) {
        parsedObject.objects.reverse();
      }
      const reversedJsonValueee = JSON.stringify(parsedObject);

      dispatch(Get_Canvas(reversedJsonValueee));
    }
  }, [
    checkEditPage.isEdit,
    checkEditPage.isrender,
    checkEditPage.isobjectvalue,
    checkEditPage.iscustomfont,
    checkEditPage.isfontFamily,
    pageevalue,
    text,
    checkEditPage.layer_name,
    render,
    checkEditPage.autoscalebool,
    // editor,
  ]);
  // selected object unique id based
  useEffect(() => {
    if (editor && editor.canvas && selectedObjectIndex) {
      const selectedObject: any = editor.canvas.getActiveObject();
      // setSelectedIds([]);
      // if (selectedObject?.question_id?.length > 0) {
      //   console.log(
      //     "selectedObject.question_id111",
      //     selectedObject.question_id
      //   );
      //   dispatch(listDropdownById(selectedObject.question_id));
      // } else {
      //   dispatch(clearExtraData());
      //   dispatch(cleartextOptionsList());
      // }
      const objects = editor.canvas.getObjects();
      let obje = null; // Define obje here

      objects.forEach((obj: any) => {
        if (obj.unique_id === selectedObjectIndex) {
          obj.set("selectable", true);
          obje = obj; // Assign the matching object to obje
        }
      });

      editor.canvas.discardActiveObject();
      if (obje) {
        editor.canvas.setActiveObject(obje);
      }
      editor.canvas.requestRenderAll();
      dispatch(Get_Index2(""));
    }
  }, [selectedObjectIndex]);
  // undo redo
  const handleObjectMoving = () => {
    const fulljson: any = editor?.canvas.getObjects();

    const ff: any = editor?.canvas.toDatalessJSON();
    if (ff && ff.objects && ff.objects.length > 0) {
      ff.objects.forEach((obj: any, index: any) => {
        ff.objects[index].name = fulljson[index].name
          ? fulljson[index].name
          : "null";

        ff.objects[index].unique_id = fulljson[index].unique_id;
        ff.objects[index].question_id = fulljson[index].question_id;
        ff.objects[index].auto_scaling = fulljson[index].auto_scaling;
        if (fulljson[index].storeAngleForpreview) {
          ff.objects[index].storeAngleForpreview =
            fulljson[index].storeAngleForpreview;
        }
      });
    }

    const filteredJson = {
      ...ff,
      // objects: ff.objects.filter((obj: any) => obj.type !== "line"),
    };
    setCanvasData(JSON.stringify(filteredJson));
  };
  useEffect(() => {
    if (editor) {
      editor?.canvas.on("object:added", handleObjectMoving);
      editor?.canvas.on("object:modified", handleObjectMoving);
    }
  }, [editor]);

  useEffect(() => {
    if (canvasData) {
      // console.log("undotesting", canvasData);
      setUndoStack((prevUndoStack) => [...prevUndoStack, canvasData]);
    }
  }, [canvasData]);

  const undo = async () => {
    if (undoStack.length > 1) {
      const prevState = undoStack[undoStack.length - 2]; // Get previous state
      const lastState = undoStack.slice(0, -1);

      setRedoStack([canvasData, ...redoStack]);
      setCanvasData(prevState);
      setUndoStack(lastState);
      editor?.canvas?.loadFromJSON(prevState, () => {
        editor?.canvas?.renderAll();
      });
      setUndoStack(lastState);
      editor?.canvas?.renderAll();
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const nextState = redoStack[0]; // Get next state from redo stack
      const updatedRedoStack = redoStack.slice(1); // Remove next state from redo stack
      setRedoStack(updatedRedoStack);
      setUndoStack((prevUndoStack) => [...prevUndoStack, canvasData]);
      setCanvasData(nextState); // Update canvas data to the next state
      editor?.canvas?.loadFromJSON(nextState, () => {
        editor?.canvas?.renderAll();
      });
    }
  };
  //download pdf concept
  function generatePDF(dataURL: any) {
    const pdf = new jsPDF({
      orientation: pagesize.spain ? pagesize.spainposition : pagesize.position,
      unit: "px",
    });

    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = pdf.internal.pageSize.getHeight();

    pdf.addImage(dataURL, "PNG", 0, 0, imgWidth, imgHeight);

    pdf.save("canvas_image.pdf");
  }
  useEffect(() => {
    if (checkEditPage.issavee == true) {
      if (editor && editor.canvas && editor?.canvas._objects.length > 0) {
        // editor.canvas.setDimensions({ width: 852, height: 1194 });

        // const dataURL = editor.canvas.toDataURL({
        //   format: "png",
        //   quality: 1,
        // });

        // const downloadLink = document.createElement("a");
        // downloadLink.href = dataURL;
        // downloadLink.download = "canvas_image.png";

        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);

        const fulljson: any = editor?.canvas.getObjects();
        editor?.canvas.discardActiveObject();

        const ff: any = editor?.canvas.toDatalessJSON();
        if (ff && ff.objects && ff.objects.length > 0) {
          ff.objects.forEach((obj: any, index: any) => {
            ff.objects[index].name = fulljson[index].name
              ? fulljson[index].name
              : "null";
            ff.objects[index].unique_id = fulljson[index].unique_id;
            ff.objects[index].question_id = fulljson[index].question_id;
            ff.objects[index].auto_height = fulljson[index].auto_height;
            ff.objects[index].auto_scaling = fulljson[index].auto_scaling;
            if (fulljson[index].storeAngleForpreview) {
              ff.objects[index].storeAngleForpreview =
                fulljson[index].storeAngleForpreview;
            }
            ff.objects[index].fontUrl = fulljson[index].fontUrl;
          });

          const jsonValueee = JSON.stringify(ff);

          let jsonObject = JSON.parse(jsonValueee);

          jsonObject.objects = jsonObject.objects.filter(
            (obj: any) => obj.type !== "line"
          );

          //No image available restrict conditions
          const filterimage = jsonObject.objects.filter(
            (obj: any) => obj.type == "image" && obj?.question_id?.length == 0
          );
          if (filterimage.length != 0) {
            // const ima = filterimage.map((e: any) => {
            //   return e.name;
            // });
            FAILED(
              filterimage[0].name + " " + "layer should map question option"
            );
            dispatch(Savee(false));

            return;
          }
          // generatePDF(dataURL); // Call the PDF generation method

          let updatedJson = JSON.stringify(jsonObject);
          // dispatch(Get_Canvas(jsonValueee));
          const fr: any = {
            project_id: pagejson.project_id,
            page_id: pagejson.page_id,
            template: updatedJson,
            base64image: "",
            width: spainwdth,
            height: height,
            // image_url: blob,
          };

          // generatePDF(base64Data);

          dispatch(add_ComicsBookPage_jsonvalue(fr));
        }
        dispatch(Savee(false));
        setToast(true);
      }
    }
  }, [checkEditPage.issavee]);

  SaveJson(toast);

  //  drag and drop layer front and back
  // useEffect(() => {
  //   if (editor && editor.canvas && editor.canvas._objects.length > 1) {
  //     const selectedObject = editor.canvas.getActiveObject();
  //     const objects = editor.canvas._objects.reverse();
  //     if (selectedObject) {
  //       const selectedIndex = objects.indexOf(selectedObject);
  //       console.log("selectedIndex", selectedIndex);

  //       if (checkEditPage.isDragDrop) {
  //         console.log("checkEditPage.isDragDrop", checkEditPage.isDragDrop);
  //         // drop 3 > drag 2
  //         if (
  //           checkEditPage.isDragDrop[0].drop > checkEditPage.isDragDrop[0].drag
  //         ) {
  //           if (selectedIndex < objects.length - 1) {
  //             console.log("movedtest");

  //             const calcadd = checkEditPage.isDragDrop[0].drop - selectedIndex;
  //             editor.canvas.bringForward(selectedObject);
  //             setRender(!render);
  //           }
  //         }
  //         // drop 0 < drag 2
  //         if (
  //           checkEditPage.isDragDrop[0].drop < checkEditPage.isDragDrop[0].drag
  //         ) {
  //           if (selectedIndex > 0) {
  //             console.log("moved>0");

  //             const calcadd = selectedIndex - checkEditPage.isDragDrop[0].drop;

  //             editor.canvas.moveTo(selectedObject, selectedIndex + 1);
  //             setRender(!render);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }, [checkEditPage.isDragDrop]);

  // calculate scale value
  //
  useEffect(() => {
    if (editor && editor.canvas) {
      const scaleX = condwdth / ((pagesize.widthh + blledheight) * dpi);
      const scaleY1 =
        pagesize.screenheight / ((pagesize.heightt + blledheight) * dpi);
      setScale1(scaleX);
      // const scaleY = pagesize.widthh == pagesize.heightt ? scaleX : scaleY1;
      setScale2(scaleY1);
    }
  });
  // console.log(spainwdth, height, scale1, scale2);

  return (
    <>
      <div id="testid">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            width: "100%",
            height: "100%",

            transition: "transform 0.3s ease",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "7.2%",
              left: "22%",
              height: "92.8%",
              zIndex: 1,
              overflow: "hidden",
            }}
          >
            <Ruler
              textFormat={(num) => `${num} `}
              direction="start"
              textAlign="center"
              mainLineSize="3"
              textOffset={[0, 5]}
              segment={8}
              style={{
                width: "35px",
                height: "100%",
              }}
              type="vertical"
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "7%",
              left: "22%",
              width: "76%",
              height: "30px",
              zIndex: 1,
              overflow: "hidden",
            }}
          >
            <Ruler
              textFormat={(num) => `${num} `}
              // textFormat={(num) => `${num - 150} `}
              direction="start"
              textAlign="center"
              mainLineSize="3"
              textOffset={[5, 0]}
              segment={8}
              style={{
                width: "100%",
                height: "35px",
              }}
              type="horizontal"
            />
          </div>
          <div>
            <style>
              {`.sample-canvas {
                  overflow:hidden;
          width: ${spainwdth} !important;
          height: ${height} !important;
          transform: scale(${scale1},${scale2});
          position: relative;
              }
         
             `}
            </style>

            <FabricJSCanvas className="sample-canvas" onReady={onReady} />
          </div>
        </div>
      </div>
    </>
  );
};
export default EditFabric;
