//    For dropdown list items
import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import {
  Success_list,
  Failure_list,
  Success_get_Question_By_Id,
  Failure_get_Question_By_Id,
  Update_length,
  responseOfListDropdown,
} from "./TextOptions_Slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  Failure_listOption,
  Success_listOption,
} from "./listOfTextOption_Slicer";
import {
  Failure_textOptionsList,
  Success_textOptionsList,
} from "./textOptionsList_Slicer";

//    get text questions by id
const getQuestionByIdCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);

  const { qId, id } = a.payload;

  const formData = new FormData();
  formData.append("question_id", qId);

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/text_options/options`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* getTextQuestionByIdApi(e: any) {
  try {
    const response: AxiosResponse = yield call(getQuestionByIdCall, e);

    yield put(Success_get_Question_By_Id(response.data.data));
    yield put(Update_length({ length: response.data, id: e?.payload.id }));
  } catch (e) {
    console.log(e);
    yield put(Failure_get_Question_By_Id(e));
    yield put(expireSignature(e));
  }
}

//list textOptions
const listTextOptionsCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  const text_type = a.payload.text_type
    ? `&text_type=${a.payload.text_type}`
    : ``;

  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/project_questions/filter?project_id=${a.payload.project_id}&q_type=${a.payload.q_type}${text_type}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* listTextOptionsApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listTextOptionsCall, e);

    // let res = response?.data?.data?.map((x: any, i: any) => ({
    //   ...x,
    //   //   active: x.active ? "Active" : "Inactive",
    //   field_id: i + 1,
    // }));
    yield put(Success_listOption(response.data));
  } catch (e) {
    console.log(e);
    yield put(Failure_listOption());
    yield put(expireSignature(e));
  }
}
//list textOptions
const textOptionsListCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  const optionId = [a.payload];
  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/question_options/options`,
    optionId,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* textOptionsListApi(e: any) {
  try {
    const response: AxiosResponse = yield call(textOptionsListCall, e);

    yield put(Success_textOptionsList(response.data));
    yield put(responseOfListDropdown());
  } catch (e) {
    console.log(e);
    yield put(Failure_textOptionsList(e));
    yield put(responseOfListDropdown());
    yield put(expireSignature(e));
  }
}
