import { take, takeEvery } from "redux-saga/effects";

import {
  AddRoleApi,
  editRoleApi,
  listRoleApi,
} from "../Reducers/Role/Role_api";

import {
  AddUserPermissionApi,
  listAnthorUserPermissionApi,
  listUserPermissionApi,
} from "../Reducers/UserPermission/UserPermission_api";
import { ListMenuItemApi } from "../Reducers/menuItem/menuItem_api";

import {
  AddUserApi,
  LoginApi,
  editUserApi,
  listUserApi,
} from "../Reducers/User-Register&Login/User_API";

import { AddCreatePasswordApi } from "../Reducers/CreatePassword/CreatePassword_api";
import {
  AddFontApi,
  listFontApi,
  deleteFontApi,
  editFontApi,
} from "../Reducers/FontLibraries/FontLibraries_api";
import {
  TextQuestionByIdApi,
  getQuestionByIdApi,
  AddQuestionOptionApi,
  listQuestionOptionApi,
} from "../Reducers/QuestionOption/QuestionOption_api";
import { listQuestionIDApi } from "../Reducers/QuestionID/QuestionID_api";
import {
  AddImageApi,
  listImageApi,
  deleteimageApi,
} from "../Reducers/ImageLibraries/ImageLibraries_api";
import {
  AddImageCategoryApi,
  deleteImageCategoryApi,
  editImageCategoryApi,
  listImageCategoryApi,
} from "../Reducers/Image Category/ImageCategory_api";
import {
  AddQuestionImagesApi,
  deleteMatrixQuestionLayerApi,
} from "../Reducers/QuestionImages/QuestionImages_api";
import {
  AddQuestionApi,
  deleteQuestionApi,
  editQuestionApi,
  listQuestionApi,
} from "../Reducers/QuestionPage/Question_api";
import {
  getTextQuestionByIdApi,
  listTextOptionsApi,
  textOptionsListApi,
} from "../Reducers/TextOptions/TextOptions_api";
import { listProjectCategoryApi } from "../Reducers/Project Category/ProjectCategory_api";
// import { AddPageQuestionsApi } from "../Reducers/PageQuestions/PageQuestions_api";
import {
  AddPageSizeApi,
  editPageSizeApi,
  listPageSizeApi,
} from "../Reducers/Page Size/PageSize_api";
import {
  AddVariantApi,
  editVariantApi,
  listVariantApi,
} from "../Reducers/Variant/Variant_api";
import {
  AddPageQuestionsApi,
  CreatePageQuestionAPI,
  deletePageQuestionsApi,
  listPageQuestionsApi,
} from "../Reducers/PageQuestions/PageQuestions_api";
import {
  AddComicsBookApi,
  deleteComicsBooksApi,
  editComicsBookApi,
  listComicsBookApi,
} from "../Reducers/Comics Book/ComicsBook_api";
import {
  AddComicsBookPageApi,
  AddComicsBookPageSizeApi,
  AddComicsBookPagejsonvalueApi,
  ImagereloadApi,
  EditApi,
  Pdf_Generation_Api,
  ReorderApi,
  deleteComicsBookPageApi,
  listComicsBookPageApi,
  Text_Replace_Api,
  AddCoverApi,
  EditCoverApi,
} from "../Reducers/Comics_Book_Page/ComicsBookPage_api";
import {
  listVariantByIdApi,
  listsVariantApi,
} from "../Reducers/Comics_Booklist/Comicsbookvariant_api";
import { PagesizelistApi } from "../Reducers/Pagesizelist/Pagesizelist_api";
import { AddResetPasswordApi } from "../Reducers/ResetPassword/ResetPassword_api";
import {
  AddCampaignApi,
  EditCampaignApi,
  LaunchAndPassCampaignApi,
  RelaunchCampaignCall,
  UsedProjectIdsAPI,
  UsedVariantCall,
  deleteCampaignApi,
  editableCampaignApi,
  listCampaignApi,
} from "../Reducers/Campaign/Campaign_api";
import { listProjectIDApi } from "../Reducers/ProjectID/ProjectID_api";
import { OrderManagementApiCall } from "../Reducers/OrderManagement/OrderManagement_api";
import {
  GetShopifyUrlApiCall,
  SendURLApiCall,
} from "../Reducers/Shopify/shopify_api";
import {
  CoverPDF_genaration_Api,
  PDF_genaration_Api,
  Customer_PDF_genaration_Api,
} from "../Reducers/PDF/PDF_api";
import {
  LuluPrintAPICall,
  LuluRePrintAPICall,
} from "../Reducers/LuluPrint/LuluPrint_api";
import { AddPreviewPageApi } from "../Reducers/Preview_page/previer_page_api";
export default function* rootSaga() {
  //  User login
  yield takeEvery("User/Login_user", LoginApi);
  //    Role
  yield takeEvery("Role/list_role", listRoleApi);
  yield takeEvery("AddRole/add_role", AddRoleApi);
  yield takeEvery("editRole/edit_role", editRoleApi);

  //    Variant
  yield takeEvery("Variant/list_Variant", listVariantApi);
  yield takeEvery("VariantById/list_VariantById", listVariantByIdApi);

  yield takeEvery("listVariant/list_listVariant", listsVariantApi);
  yield takeEvery("AddVariant/add_Variant", AddVariantApi);
  yield takeEvery("editVariant/edit_Variant", editVariantApi);

  //    PageSize
  yield takeEvery("Pagesizelist/list_Pagesizelist", PagesizelistApi);

  yield takeEvery("PageSize/list_PageSize", listPageSizeApi);
  yield takeEvery("AddPageSize/add_PageSize", AddPageSizeApi);
  yield takeEvery("editPageSize/edit_PageSize", editPageSizeApi);

  //    Question
  yield takeEvery("Questions/list_Question", listQuestionApi);
  yield takeEvery("AddQuestion/add_Question", AddQuestionApi);
  yield takeEvery("editQuestion/edit_Question", editQuestionApi);
  yield takeEvery("DeleteQuestion/deleteQuestion", deleteQuestionApi);

  //    User
  yield takeEvery("User/list_user", listUserApi);
  yield takeEvery("AddUser/add_user", AddUserApi);
  yield takeEvery("editUser/edit_user", editUserApi);

  //    UserPermission
  yield takeEvery(
    "AnthorUserPermission/list_AnthorUserPermission",
    listAnthorUserPermissionApi
  );

  yield takeEvery("UserPermission/list_userPermission", listUserPermissionApi);
  yield takeEvery("AddUserPermission/add_UserPermission", AddUserPermissionApi);

  //  Createpassword
  yield takeEvery("AddCreatePassword/add_CreatePassword", AddCreatePasswordApi);
  // reset password

  yield takeEvery("AddResetPassword/add_AddResetPassword", AddResetPasswordApi);

  //    font
  yield takeEvery("ListFont/list_font", listFontApi);
  yield takeEvery("AddFont/add_font", AddFontApi);
  yield takeEvery("DeleteFont/delete_font", deleteFontApi);
  yield takeEvery("editFont/edit_Font", editFontApi);

  //    QuestionOption
  // yield takeEvery("QuestionOption/list_questionOption", listQuestionOptionApi);
  yield takeEvery(
    "QuestionOptions/List_questionOptions",
    listQuestionOptionApi
  );
  yield takeEvery("AddQuestionOption/add_QuestionOption", AddQuestionOptionApi);
  yield takeEvery("QuestionOptions/Get_Question_By_Id", getQuestionByIdApi);
  yield takeEvery("QuestionOptions/Text_Question_By_Id", TextQuestionByIdApi);

  //    MenuItem
  yield takeEvery("MenuItem/list_menuItem", ListMenuItemApi);

  //    QuestionID
  yield takeEvery("QuestionID/List_questionID", listQuestionIDApi);

  //    List_textOptions (Questions)
  yield takeEvery("TextOption/List_textOption", listTextOptionsApi);
  //    TextOptions (Questions)
  yield takeEvery("TextOptions/Get_Question_By_Id", getTextQuestionByIdApi);
  //    TextOptionsList
  yield takeEvery("TextOptionsList/List_textOptionsList", textOptionsListApi);
  yield takeEvery("TextOptions/listDropdownById", textOptionsListApi);
  //    QuestionImages
  yield takeEvery("QuestionImages/Add_questionImages", AddQuestionImagesApi);
  yield takeEvery(
    "matrixQuestionLayer/delete_matrixQuestionLayer",
    deleteMatrixQuestionLayerApi
  );
  //    Page Questions
  yield takeEvery(
    "SetPageQuestionsId/set_PageQuestionsID",
    CreatePageQuestionAPI
  );
  //Login
  yield takeEvery("Login/login", LoginApi);
  //AddPageQuestions
  yield takeEvery("AddPageQuestions/add_PageQuestions", AddPageQuestionsApi);
  yield takeEvery(
    "DeletePageQuestions/delete_PageQuestions",
    deletePageQuestionsApi
  );
  yield takeEvery("ListPageQuestions/list_PageQuestions", listPageQuestionsApi);
  //    image
  yield takeEvery("ListImage/list_image", listImageApi);
  yield takeEvery("AddImage/add_image", AddImageApi);
  yield takeEvery("Deleteimage/delete_image", deleteimageApi);

  //    comics project
  yield takeEvery("ListComicsBook/list_ComicsBook", listComicsBookApi);
  yield takeEvery("AddComicsBook/add_ComicsBook", AddComicsBookApi);
  yield takeEvery("EditComicsBook/edit_ComicsBook", editComicsBookApi);
  yield takeEvery("DeleteComicsBooks/delete_ComicsBooks", deleteComicsBooksApi);

  //    comics page
  yield takeEvery(
    "ListComicsBookPage/list_ComicsBookPage",
    listComicsBookPageApi
  );
  yield takeEvery(
    "AddComicsBookPageSize/add_ComicsBookPage_Size",
    AddComicsBookPageSizeApi
  );
  yield takeEvery("Textreplace/addTextreplace", Text_Replace_Api);

  yield takeEvery("PDFgeneration/addPDFgeneration", Pdf_Generation_Api);

  yield takeEvery("AddCover/add_Cover", AddCoverApi);
  yield takeEvery("EditCover/edit_Cover", EditCoverApi);

  // PDF
  yield takeEvery(
    "Customer_pdf/Customer_PDF_Genaration",
    Customer_PDF_genaration_Api
  );
  yield takeEvery("PDFGenaration/PDF_Genaration", PDF_genaration_Api);
  yield takeEvery(
    "CoverPDFGenaration/CoverPDF_Genaration",
    CoverPDF_genaration_Api
  );
  yield takeEvery(
    "AddComicsBookPageJsonValue/add_ComicsBookPage_jsonvalue",
    AddComicsBookPagejsonvalueApi
  );
  yield takeEvery("AddComicsBookPageJsonValue/Imagegenartion", ImagereloadApi);
  yield takeEvery("AddComicsBookPage/add_ComicsBookPage", AddComicsBookPageApi);
  yield takeEvery(
    "DeleteComicsBookPage/delete_ComicsBookPage",
    deleteComicsBookPageApi
  );

  yield takeEvery(
    "ListProjectCategory/list_Projectcategory",
    listProjectCategoryApi
  );
  yield takeEvery("Reorder/list_Reorder", ReorderApi);
  yield takeEvery("EidtComicsBookPage/eidt_ComicsBookPage", EditApi);

  //    Image Categories
  yield takeEvery("ListImageCategory/list_imagecategory", listImageCategoryApi);
  yield takeEvery(
    "DeleteImageCategory/delete_ImageCategory",
    deleteImageCategoryApi
  );
  //    ProjectID
  yield takeEvery("ProjectID/List_projectID", listProjectIDApi);

  yield takeEvery(
    "AddListImageCategory/add_ListImageCategory",
    AddImageCategoryApi
  );
  yield takeEvery("editImageCategory/edit_ImageCategory", editImageCategoryApi);

  /*    Campain    */
  yield takeEvery("ListCampaign/list_Campaign", listCampaignApi);
  yield takeEvery("DeleteCampaign/delete_Campaign", deleteCampaignApi);
  yield takeEvery("AddCampaign/add_Campaign", AddCampaignApi);
  yield takeEvery("EidtCampaign/eidt_Campaign", EditCampaignApi);
  yield takeEvery("EditableCampaign/editable_Campaign", editableCampaignApi);
  yield takeEvery(
    "LaunchAndPauseCampaign/launchAndPass_Campaign",
    LaunchAndPassCampaignApi
  );
  yield takeEvery("RelaunchCampaign/relaunch_Campaign", RelaunchCampaignCall);
  yield takeEvery("UsedVariant/list_UsedVariant", UsedVariantCall);
  yield takeEvery("UsedProjectIds/list_UsedProjectIds", UsedProjectIdsAPI);

  /*    Order managment    */
  yield takeEvery(
    "ListOrderManagement/list_OrderManagement",
    OrderManagementApiCall
  );

  /*    LuluPrint    */
  yield takeEvery("LuluPrint/lulu_Print", LuluPrintAPICall);
  yield takeEvery("LuluReprint/lulu_reprint", LuluRePrintAPICall);

  /*    Shapify    */
  yield takeEvery("GetShopifyRedirectUrl/get_url", GetShopifyUrlApiCall);
  yield takeEvery("SendShopifyRedirectUrl/send_url", SendURLApiCall);

  // preview
  yield takeEvery("AddPreviewPage/add_preview_page", AddPreviewPageApi);
}
