import { createSlice } from "@reduxjs/toolkit";

export const editUserPermission = createSlice({
  name: "editUserPermission",
  initialState: {
    isLoading: false,
    isSuccess: false,
    api_completed: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    edit_UserPermission: (state) => {
      state.isLoading = true;
    },
    success_api_call_edit_UserPermission: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_edit_UserPermission: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.api_completed = true;
      state.isSuccess = false;
      // FAILED(action.payload);
    },
    edit_UserPermission_is_success: (state) => {
      state.isSuccess = false;
    },
    edit_UserPermission_complete_api: (state) => {
      state.api_completed = false;
    },
  },
});
export default editUserPermission.reducer;
export const {
  edit_UserPermission,
  success_api_call_edit_UserPermission,
  edit_UserPermission_complete_api,
  failure_api_call_edit_UserPermission,
  edit_UserPermission_is_success,
} = editUserPermission.actions;
