import { SearchOutlined } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { FunctionComponent, useRef, useState } from "react";

interface SearchBarProps {
  getValues?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value?: any;
}

const SearchBar: FunctionComponent<SearchBarProps> = (props) => {
  const { getValues, value } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (getValues) {
      getValues(e);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      inputRef.current?.blur();
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <TextField
      fullWidth
      id="standard-bare"
      variant="outlined"
      placeholder="Search..."
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      size="small"
      onFocus={handleFocus}
      onBlur={handleBlur}
      sx={{
        border: "1px solid #d5d5d5",
        "&:hover": {
          borderColor: "#d5d5d5",
        },
        "&:focus": {
          borderColor: "#d5d5d5",
          borderWidth: 1,
        },
      }}
      InputProps={{
        sx: {
          borderRadius: "0px",
          backgroundColor: "#F5F5F5",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", // Remove the input border
          },
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
      }}
      inputRef={inputRef}
    />
  );
};

export default SearchBar;
