import { createSlice } from "@reduxjs/toolkit";
import { States } from "../../../interfaces/Slicers";

export const QuestionID = createSlice({
  name: "QuestionID",
  initialState: {
    isLoading: false,
    isSuccess: null,
    isError: null,
    data: [],
  } as States,
  reducers: {
    List_questionID: (state, _) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    Success_list: (state, action) => {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isError: null,
      };
    },
    Failure_list: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
        isSuccess: null,
      };
    },
    clearQuestionId: (state) => {
      state.data = [];
    },
  },
});
export default QuestionID.reducer;
export const { List_questionID, Success_list, Failure_list, clearQuestionId } =
  QuestionID.actions;
