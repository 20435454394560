import { createSlice } from "@reduxjs/toolkit";

export const AddVariant = createSlice({
  name: "AddVariant",
  initialState: {
    api_completed: false,
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    add_Variant: (state) => {
      state.isLoading = true;
    },
    success_api_call_add_Variant: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.api_completed = true;
      state.isFirst = true;
      // SUCCESE(action.payload.message);
    },
    failure_api_call_add_Variant: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isSuccess = false;
      state.api_completed = true;
      // FAILED(action.payload);
    },
    add_Variant_is_success: (state, action) => {
      state.isSuccess = false;
    },
    create_Variant_complete_api: (state, action) => {
      state.api_completed = false;
    },
  },
});
export default AddVariant.reducer;
export const {
  add_Variant,
  success_api_call_add_Variant,
  failure_api_call_add_Variant,
  create_Variant_complete_api,
  add_Variant_is_success,
} = AddVariant.actions;
