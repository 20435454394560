import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";
import {
  failure_api_call_add_CreatePassword,
  success_api_call_add_CreatePassword,
} from "./add_CreatePassword_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";

const addCreatePasswordCall = async (a: any) => {
  // console.log(add);
  // let token = await localStorage.getItem("token");
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const token = urlParams.get("verify_token");

  const adminId = JSON.parse(localStorage.getItem("id")!);
  const add = {
    password: a.payload.setpassword,
    email: email,
    token: token,
    updated_by: adminId,
    created_by: adminId,
  };

  return Axios.put(`${process.env.REACT_APP_api_url}/v1/api/user/verify`, add, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* AddCreatePasswordApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addCreatePasswordCall, e);
    // console.log(response.data);

    yield put(success_api_call_add_CreatePassword(response.data));
  } catch (e) {
    yield put(failure_api_call_add_CreatePassword(e));
    yield put(expireSignature(e));
  }
}
