import { FunctionComponent } from "react";
import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../Editor/Pages/ImageLibrary.css";

import { useNavigate } from "react-router-dom";

import {
  Breadcrumbs,
  Button,
  Chip,
  FormHelperText,
  Stack,
} from "@mui/material";
import "./Page.css";
import Dialogs from "@mui/material/Dialog";

import TextField from "@mui/material/TextField";
import { Formik, Form, ErrorMessage } from "formik";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import mesImage from "./Image/Noimge.png";
import * as Yup from "yup";
import Slider from "@mui/material/Slider";

import Autocomplete from "../Component/Autocomplete";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchBar from "../Component/SearchBar";

import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import IsLoading from "../Component/isLoading";
import { list_ComicsBook } from "../Redux/Reducers/Comics Book/list_ComicsBook_slicer";
import { useDispatch } from "react-redux";
import {
  Projectid_render,
  Projectname_render,
} from "../Redux/Reducers/Project_id";
import { add_ComicsBook } from "../Redux/Reducers/Comics Book/add_ComicsBook_slicer";
import { delete_ComicsBooks } from "../Redux/Reducers/Comics Book/delete_ComicsBook_slicer";
import { list_listVariant } from "../Redux/Reducers/Comics_Booklist/list_Variantlist_slicer";
import { list_Pagesizelist } from "../Redux/Reducers/Pagesizelist/Pagesizelist_slicer";
import MultiSelect from "../Component/MultiSelect";
import Edit from "./Image/Edit.png";
import { editable_value } from "../Redux/Reducers/Comics Book/editableValue";
import { IComicsBookEdit } from "../interfaces/ComicsBookEdit";
import { edit_ComicsBook } from "../Redux/Reducers/Comics Book/edit_ComicsBook_slicer";

interface ComicsBookProps {
  touched: any;
  errors: any;
  handleBlur: any;
  values: any;
  setFieldValue(arg0: string, value: any): unknown;
}

// this code dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// its is breadcrumbs

const ComicsBook: FunctionComponent<ComicsBookProps> = (props) => {
  const navigate = useNavigate();
  const dispatcher = useAppDispatch();
  const dispatch = useDispatch();

  const breadcrumbs = [
    <Link
      key="1"
      color="black"
      sx={{ cursor: "pointer" }}
      onClick={() => navigate("/ComicsBook")}
      underline="none"
    >
      <b className="heading">Comics Book</b>
    </Link>,
  ];

  //    userpermission menuList
  const pageName = "Comics Book";
  const menuList: any = JSON.parse(
    localStorage?.getItem("permission") as string
  );
  const index: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageName;
  });

  const MenuOfUserpermission = menuList[index];

  //    userpermission menuList for comics pages
  const pageButtonName = "Comics Book / Pages";
  const pageIndex: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === pageButtonName;
  });

  const MenuOfUserpermissionPage = menuList[pageIndex];

  //    userpermission menuList for comics question page
  const questionPageButtonName = "Comics Book / Project Question";
  const questionPageIndex: number = menuList.findIndex((indexId: any) => {
    return indexId.menuname === questionPageButtonName;
  });

  const MenuOfUserpermissionquestionPage = menuList[questionPageIndex];

  //  font search option
  const [filteredfonts, setFiltered] = useState("");

  //    filter variant list
  const [variantList, setVariantList] = useState([]);

  // this code is dialog for add image
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDialog(false);
    dispatch(editable_value({}));
  };

  const handleClickEdit = (card: any) => {
    handleClickOpen();
    dispatch(editable_value(card));
  };

  //    Popup messages
  const [popup, setPopoup] = React.useState(false);
  const [deletepopup, setDeletePopoup] = React.useState(false);
  // console.log(popup);

  //    success dialog
  const [dialog, setDialog] = React.useState(false);
  const pagesizedata = useAppSelector((state) => state?.Pagesizelist.data);
  const list = useAppSelector((state) => state?.listVariant.data);

  const data = useAppSelector((state) => state?.ListComicsBook?.data);
  const comicIsLoading = useAppSelector(
    (state) => state?.ListComicsBook?.isLoading
  );
  const isLoading = useAppSelector((state) => state?.AddComicsBook?.isLoading);
  const addData: any = useAppSelector((state) => state?.AddComicsBook?.data);
  const editData: any = useAppSelector((state) => state?.EditComicsBook?.data);
  const editDataIsLoading: boolean = useAppSelector(
    (state) => state?.EditComicsBook?.isLoading
  );
  const deleteData = useAppSelector((state) => state.DeleteComicsBooks.data);

  const addDataIsLoading: any = useAppSelector(
    (state) => state?.AddComicsBook?.isLoading
  );
  const editAbleValue: IComicsBookEdit = useAppSelector(
    (state) => state?.EditableValueComicsBook.data
  );

  const fixedOptions = [23, 24];

  // filter list
  useEffect(() => {
    setVariantList(list);
  }, [list]);

  React.useEffect(() => {
    dispatcher(list_ComicsBook(""));
    dispatcher(list_Pagesizelist(""));
    dispatcher(list_listVariant(""));
  }, [deleteData, addData, editData]);

  const initialValues: {
    id: number | undefined;
    is_editable: boolean;
    name: string;
    description: string;
    page_size_id: number | null;
    page_orientation: string;
    dpi: number;
    preview_dpi: number;
    variant_id: { label: string; value: number }[];
  } = {
    id: editAbleValue?.project_id,
    is_editable: editAbleValue?.is_editable ?? true,
    name: editAbleValue?.project_name ?? "",
    description: editAbleValue?.description ?? "",
    page_size_id: editAbleValue?.page_size_id ?? null,
    page_orientation: editAbleValue?.page_orientation ?? "",
    dpi: editAbleValue?.dpi ?? 0,
    preview_dpi: editAbleValue?.preview_dpi ?? 0,

    variant_id:
      variantList
        ?.map((value: { name: string; id: number }) => {
          return { label: value.name, value: value.id };
        })
        ?.filter((value) =>
          editAbleValue?.variant_ids?.map((id) => id)?.includes(value.value)
        ) ?? [],

    // [
    //   ...(variantList
    //     ?.map((value: { name: string; id: number }) => {
    //       return {
    //         label: value.name,
    //         value: value.id,
    //       };
    //     })
    //     ?.filter((value) =>
    //       fixedOptions?.map((id) => id)?.includes(value.value)
    //     ) ?? []),
    //   ...(variantList
    //     ?.map((value: { name: string; id: number }) => {
    //       return { label: value.name, value: value.id };
    //     })
    //     ?.filter((value) =>
    //       editAbleValue?.variant_ids?.map((id) => id)?.includes(value.value)
    //     )
    //     .filter((value) =>
    //       fixedOptions?.map((id) => id)?.includes(value.value)
    //     ) ?? []),
    // ],
  };

  const [submitvalue, setsubmitvalue] = React.useState<any>({
    name: "",
    index: 0,
    project_id: 0,
  });
  // this code search option
  const [myNames, setMyNames] = useState("");

  const allNames = data.filter((card: any) =>
    card.project_name.toLocaleLowerCase().includes(myNames.toLocaleLowerCase())
  );
  // console.log("all", allNames);

  // comics book
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // this code is dialog for comics book is delete
  const [comicbookopen, comicbooksetOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(0);

  const comicbookdeleteOpen = () => {
    comicbooksetOpen(true);
  };

  const comicsbookdeleteClose = () => {
    comicbooksetOpen(false);
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <Stack spacing={10} className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div className="fontscrollbar">
        <IsLoading
          isLoading={comicIsLoading || addDataIsLoading || editDataIsLoading}
        />
        <div
          style={{
            display: "flex",
            // margin: "10px 30px",
            top: "0",
            position: "sticky",
            backgroundColor: "white",
            zIndex: "1",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "1em",
          }}
        >
          {/* <h2 style={{ paddingLeft: "20px", paddingTop: "20px" }}></h2> */}

          <div
            style={{
              display: "flex",
              gap: "1em",
            }}
          >
            <div style={{}}>
              <div style={{ width: "16rem" }}>
                <SearchBar
                  // name="Serach"
                  getValues={(e) => setMyNames(e.target.value)}
                  value={myNames}
                ></SearchBar>
              </div>
            </div>
            {MenuOfUserpermission?.add && (
              <Button
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                  width: "170px",
                  height: "37px",
                  backgroundColor: "rgb(0, 0, 0)",
                  color: "#FFFFFF",
                  display: "flex",
                  fontSize: "13px",
                  alignItems: "center",
                  borderRadius: "0px",
                  justifyContent: "space-evenly",
                }}
              >
                Add New Comics
              </Button>
            )}
            <Dialogs
              //
              maxWidth={"lg"}
              sx={{
                width: "100%",
                height: "100%",
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    borderRadius: "0px",
                  },
                },
              }}
              open={open}
              TransitionComponent={Transition}
              keepMounted
              // onClose={handleClose}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <div>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Please enter Project Name"),
                    description: Yup.string().required(
                      "Please enter Description"
                    ),
                    page_size_id: Yup.number().required(
                      "Please select a Page Dimension"
                    ),
                    page_orientation: Yup.string().required(
                      "Please select a Page Orientation"
                    ),
                    dpi: Yup.number()
                      .required()
                      .min(100, "Please Select DPI Value")
                      .max(800),
                    preview_dpi: Yup.number()
                      .required()
                      .min(80, "Please Select Preview DPI Value")
                      .max(150),

                    variant_id: Yup.array()
                      .of(
                        Yup.object().shape({
                          label: Yup.string(),
                          value: Yup.string(),
                        })
                      )
                      .min(1, "Please select a Variants")
                      .required("Please select a Variants"),
                  })}
                  onSubmit={(values: any) => {
                    // setsubmitvalue(values);
                    setDialog(true);
                    // console.log("onsubmit", values);
                  }}
                >
                  {(props) => {
                    const closeFunction = () => {
                      props.resetForm();
                      handleClose();
                    };

                    return (
                      <Form
                        autoComplete="off"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <DialogTitle
                          sx={{
                            backgroundColor: "#000000",
                            color: "white",
                            width: "100%",
                          }}
                        >
                          {Object.keys(editAbleValue).length === 0
                            ? "Add "
                            : "Edit "}
                          Comics Book
                          <ClearOutlinedIcon
                            className="clear"
                            onClick={() => closeFunction()}
                            sx={{ color: "white", float: "right" }}
                          ></ClearOutlinedIcon>
                        </DialogTitle>
                        <DialogContent
                          sx={{
                            "&::-webkit-scrollbar": { display: "none" },
                            padding: "1.5em 24px 0!important",
                          }}
                        >
                          <div style={{ display: "flex", gap: "1em" }}>
                            <div>
                              <div className="lableInputDiv">
                                <b
                                  style={{
                                    color: "#000000",
                                  }}
                                >
                                  Project Name
                                  <span style={{ color: "red" }}>*</span>
                                </b>

                                <TextField
                                  id="outlined-basic"
                                  // label="FontName"
                                  variant="outlined"
                                  name="name"
                                  autoComplete="off"
                                  sx={{
                                    "& fieldset": {
                                      borderRadius: "0px",
                                    },
                                    width: "clamp(20rem,30vw,30rem)",
                                  }}
                                  value={props.values.name}
                                  // inputProps={{ maxLength: 25 }}
                                  onChange={props.handleChange}
                                  // onChange={(e) => {
                                  //   if (e.target.value.length < 15) {
                                  //     props.setFieldValue("name", e.target.value);
                                  //   }
                                  // }}
                                  helperText={
                                    props.errors.name &&
                                    props.touched.name &&
                                    (props.errors.name as React.ReactNode)
                                  }
                                  error={
                                    !!(props.errors.name && props.touched.name)
                                  }
                                  onBlur={props.handleBlur}
                                />
                              </div>
                              <div className="lableInputDiv">
                                <b
                                  style={{
                                    color: "#000000",
                                  }}
                                >
                                  Description
                                  <span style={{ color: "red" }}>*</span>
                                </b>
                                <TextField
                                  id="outlined-basic"
                                  // required
                                  variant="outlined"
                                  name="description"
                                  sx={{
                                    "& fieldset": {
                                      borderRadius: "0px",
                                    },
                                    width: "clamp(20rem,30vw,30rem)",
                                  }}
                                  autoComplete="off"
                                  helperText={
                                    props.errors.description &&
                                    props.touched.description &&
                                    (props.errors
                                      .description as React.ReactNode)
                                    // : "Enter your Email."
                                  }
                                  error={
                                    !!(
                                      props.errors.description &&
                                      props.touched.description
                                    )
                                  }
                                  onChange={props.handleChange}
                                  // onChange={(e) => {
                                  //   if (e.target.value.length < 100) {
                                  //     props.setFieldValue(
                                  //       "description",
                                  //       e.target.value
                                  //     );
                                  //   }
                                  // }}
                                  inputProps={{ maxLength: 100 }}
                                  onBlur={props.handleBlur}
                                  value={props.values.description}
                                />
                              </div>
                              <div className="lableInputDiv">
                                <b
                                  style={{
                                    color: "#000000",
                                  }}
                                >
                                  Variant{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </b>

                                <MultiSelect
                                  options={variantList.map((v: any) => {
                                    return { label: v.name, value: v.id };
                                  })}
                                  disableClearable
                                  name={"variant_id"}
                                  value={props.values.variant_id}
                                  getValue={(
                                    value: {
                                      label: string | number;
                                      value: string | number;
                                    }[]
                                  ) => {
                                    props.setFieldValue("variant_id", value);
                                    // props.setFieldValue("variant_id", [
                                    //   ...(variantList
                                    //     ?.map(
                                    //       (value: {
                                    //         name: string;
                                    //         id: number;
                                    //       }) => {
                                    //         return {
                                    //           label: value.name,
                                    //           value: value.id,
                                    //         };
                                    //       }
                                    //     )
                                    //     ?.filter((value) =>
                                    //       fixedOptions
                                    //         ?.map((id) => id)
                                    //         ?.includes(value.value)
                                    //     ) ?? []),
                                    //   ...value.filter(
                                    //     (option) =>
                                    //       fixedOptions.indexOf(
                                    //         Number(option.value)
                                    //       ) === -1
                                    //   ),
                                    // ]);
                                  }}
                                  // disabled={!props.values.is_editable}
                                  renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                      <Chip
                                        label={option.label}
                                        {...getTagProps({ index })}
                                        // disabled={
                                        //   fixedOptions.indexOf(
                                        //     Number(option.value)
                                        //   ) !== -1
                                        // }
                                        deleteIcon={<></>}
                                      />
                                    ))
                                  }
                                  onBlur={props.handleBlur}
                                  sx={{
                                    width: "clamp(20rem,30vw,30rem)",
                                    borderRadius: "0",
                                  }}
                                  inputSx={{
                                    borderRadius: "0",
                                    "& fieldset": {
                                      borderRadius: "0px",
                                    },
                                  }}
                                  helperText={
                                    props.errors.variant_id &&
                                    props.touched.variant_id &&
                                    props.errors.variant_id
                                  }
                                  error={
                                    props.errors.variant_id &&
                                    props.touched.variant_id
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                width: "clamp(20rem,30vw,30rem)",
                              }}
                            >
                              <div className="lableInputDiv">
                                <b
                                  style={{
                                    color: "#000000",
                                  }}
                                >
                                  Page Orientation
                                  <span style={{ color: "red" }}>*</span>
                                </b>

                                <Autocomplete
                                  Listitem={[
                                    { label: "Portrait", value: "PORTRAIT" },
                                    { label: "Landscape", value: "LANDSCAPE" },
                                  ]}
                                  // width={200}
                                  // required
                                  variant={"outlined"}
                                  name={"page_orientation"}
                                  value={props.values}
                                  onBlur={props.handleBlur}
                                  onchange={(e: any) => {
                                    // console.log(e);

                                    props.setFieldValue(
                                      "page_orientation",
                                      e.value
                                    );
                                  }}
                                  disabled={!props.values.is_editable}
                                  sx={{
                                    width: "clamp(20rem,30vw,30rem)",
                                  }}
                                  error={
                                    props.errors.page_orientation &&
                                    props.touched.page_orientation
                                  }
                                  helperText={
                                    props.errors.page_orientation &&
                                    props.touched.page_orientation &&
                                    props.errors.page_orientation
                                  }
                                ></Autocomplete>
                              </div>
                              <div className="lableInputDiv">
                                <b
                                  style={{
                                    color: "#000000",
                                    // marginTop: Boolean(
                                    //   props.errors.page_orientation &&
                                    //     props.touched.page_orientation
                                    // )
                                    //   ? "0"
                                    //   : "25px",
                                  }}
                                >
                                  Page Dimension
                                  <span style={{ color: "red" }}>*</span>
                                </b>
                                <Autocomplete
                                  Listitem={pagesizedata.map((v: any) => {
                                    return { label: v.name, value: v.id };
                                  })}
                                  sx={{
                                    width: "clamp(20rem,30vw,30rem)",
                                  }}
                                  // width={200}
                                  // required
                                  variant={"outlined"}
                                  name={"page_size_id"}
                                  value={props.values}
                                  onBlur={props.handleBlur}
                                  disabled={!props.values.is_editable}
                                  onchange={(e: any) => {
                                    // console.log(e);

                                    props.setFieldValue(
                                      "page_size_id",
                                      e.value
                                    );
                                  }}
                                  error={
                                    props.errors.page_size_id &&
                                    props.touched.page_size_id
                                  }
                                  helperText={
                                    props.errors.page_size_id &&
                                    props.touched.page_size_id &&
                                    props.errors.page_size_id
                                  }
                                ></Autocomplete>
                              </div>
                              {/* {props.errors.page_size_id &&
                              props.touched.page_size_id ? (
                                <FormHelperText
                                  style={{
                                    marginLeft: "13px",
                                    // marginTop: "10px",
                                  }}
                                  error
                                >
                                  {props.errors.page_size_id}
                                </FormHelperText>
                              ) : null} */}
                              <div className="lableInputDiv">
                                <b
                                  style={{
                                    color: "#000000",
                                    // marginTop: Boolean(
                                    //   props.errors.page_size_id &&
                                    //     props.touched.page_size_id
                                    // )
                                    //   ? "0"
                                    //   : "25px",
                                  }}
                                >
                                  DPI <span style={{ color: "red" }}>*</span>
                                </b>

                                <Stack
                                  spacing={5}
                                  direction="row"
                                  sx={{ p: "0.8em" }}
                                  alignItems="center"
                                >
                                  <Slider
                                    aria-label="Volume"
                                    valueLabelDisplay={
                                      !props.values.is_editable ? "on" : "auto"
                                    }
                                    name="dpi" // Make sure to provide the name attribute
                                    min={100}
                                    max={800}
                                    step={1}
                                    sx={{
                                      ".MuiSlider-valueLabelOpen:before": {
                                        bottom: !props.values.is_editable
                                          ? "100%!important"
                                          : undefined,
                                      },
                                      ".MuiSlider-valueLabelOpen": {
                                        top: !props.values.is_editable
                                          ? "55px!important"
                                          : undefined,
                                      },
                                    }}
                                    value={props.values.dpi}
                                    onChange={(e: any) => {
                                      const newValue = e.target.value;
                                      props.setFieldValue("dpi", newValue);
                                      // }
                                    }}
                                    onBlur={props.handleBlur}
                                    disabled={!props.values.is_editable}
                                  />
                                </Stack>
                                <FormHelperText error>
                                  <ErrorMessage name="dpi" />
                                </FormHelperText>
                              </div>
                              <div className="lableInputDiv">
                                <b
                                  style={{
                                    color: "#000000",
                                    // marginTop: Boolean(
                                    //   props.errors.page_size_id &&
                                    //     props.touched.page_size_id
                                    // )
                                    //   ? "0"
                                    //   : "25px",
                                  }}
                                >
                                  Preview DPI{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </b>

                                <Stack
                                  spacing={5}
                                  direction="row"
                                  sx={{ p: "0.8em" }}
                                  alignItems="center"
                                >
                                  <Slider
                                    aria-label="Volume"
                                    valueLabelDisplay={
                                      !props.values.is_editable ? "on" : "auto"
                                    }
                                    name="preview_dpi" // Make sure to provide the name attribute
                                    min={80}
                                    max={150}
                                    step={1}
                                    sx={{
                                      ".MuiSlider-valueLabelOpen:before": {
                                        bottom: !props.values.is_editable
                                          ? "100%!important"
                                          : undefined,
                                      },
                                      ".MuiSlider-valueLabelOpen": {
                                        top: !props.values.is_editable
                                          ? "55px!important"
                                          : undefined,
                                      },
                                    }}
                                    defaultValue={100}
                                    value={props.values.preview_dpi}
                                    onChange={(e: any) => {
                                      const newValue = e.target.value;
                                      props.setFieldValue(
                                        "preview_dpi",
                                        newValue
                                      );
                                      // }
                                    }}
                                    onBlur={props.handleBlur}
                                    disabled={!props.values.is_editable}
                                  />
                                </Stack>
                                <FormHelperText error>
                                  <ErrorMessage name="preview_dpi" />
                                </FormHelperText>
                              </div>
                              {/* <Autocomplete
                              Listitem={list.map((v: any) => {
                                return { label: v.name };
                              })}
                              // width={200}
                              // required
                              variant={"outlined"}
                              name={"variant_id"}
                              value={props.values}
                              onBlur={props.handleBlur}
                              onchange={(e: any) => {
                                // console.log(e);

                                props.setFieldValue("variant_id", e.label);
                              }}
                              style={{
                                // paddingBottom: "27px",
                                width: "clamp(20rem,30vw,30rem)",
                                height: "70px",
                                // borderBottom: "10px",
                                // margin: " 0px 0px 10px ",
                              }}
                              error={
                                props.errors.variant_id &&
                                props.touched.variant_id
                              }
                            ></Autocomplete>
                            {props.errors.variant_id &&
                            props.touched.variant_id ? (
                              <FormHelperText
                                style={{ marginLeft: "13px" }}
                                error
                              >
                                Enter the name of the variant
                              </FormHelperText>
                            ) : null} */}
                            </div>
                          </div>
                        </DialogContent>
                        <DialogActions
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "0.5em",
                            padding: "1.5em",
                          }}
                        >
                          <Button
                            // className="clear"
                            onClick={() => closeFunction()}
                            sx={{
                              width: "110px",
                              height: "40px",
                              color: "#000000",
                              display: "flex",
                              fontSize: "13px",
                              alignItems: "center",
                              borderRadius: "0px",
                              border: "1px solid black",
                              justifyContent: "space-evenly",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            onClick={() => {
                              setPopoup(false);
                            }}
                            type="submit"
                            // disabled
                            // disabled={!props.isValid}
                            style={{
                              width: "100px",
                              height: "40px",
                              border: "1px solid black",
                              color: "white",
                              // backgroundColor: "#C00A30",
                              backgroundColor: "black",
                              borderRadius: "0px",
                            }}
                          >
                            SAVE
                          </Button>
                        </DialogActions>
                        {(Object.keys(editAbleValue).length === 0
                          ? !isLoading
                          : !editDataIsLoading) && (
                          <Dialogs
                            open={dialog}
                            // onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            PaperProps={{
                              sx: {
                                borderRadius: "0",
                                // height: height || "80vh",
                                "&::-webkit-scrollbar": { display: "none" },
                              },
                            }}
                          >
                            <DialogContent
                              sx={{ padding: "1.5em 1.5em 0 !important" }}
                            >
                              {!popup ? (
                                <DialogContentText id="alert-dialog-description">
                                  <div className="submitPopup">
                                    <div
                                      style={{
                                        fontSize: "16px",
                                        textAlign: "center",
                                        color: "black",
                                      }}
                                    >
                                      Are you sure you want to proceed?
                                      {/* Submitted Successfully */}
                                    </div>
                                  </div>
                                </DialogContentText>
                              ) : (
                                // isLoading && (
                                <DialogContentText id="alert-dialog-description">
                                  <div className="submitPopup">
                                    <div
                                      style={{
                                        fontSize: "16px",
                                        textAlign: "center",
                                        color: "black",
                                        margin: "0px 40px 0px 40px ",
                                      }}
                                    >
                                      {Object.keys(editAbleValue).length === 0
                                        ? addData?.message
                                        : editData?.message}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        textAlign: "center",
                                        margin: "0px 40px 0px 40px ",
                                      }}
                                    >
                                      {(
                                        Object.keys(editAbleValue).length === 0
                                          ? addData?.status
                                          : editData?.status
                                      ) ? (
                                        <img
                                          src={require("./Image/success.png")}
                                          className="imageSize"
                                          alt="success"
                                        />
                                      ) : (
                                        <img
                                          src={require("./Image/error.png")}
                                          className="imageSize"
                                          alt="error"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </DialogContentText>
                                // )
                              )}
                            </DialogContent>

                            {!popup ? (
                              <DialogActions
                                sx={{
                                  justifyContent: "center",
                                  gap: "0.5em",
                                  padding: "1.5em",
                                }}
                              >
                                <Button
                                  onClick={() => setDialog(false)}
                                  style={{
                                    padding: "15px",
                                    // backgroundColor: "#1976D2",
                                    color: "black",
                                    width: "100px",
                                    height: "37px",
                                    borderRadius: "0px",
                                    border: "1px solid black",
                                  }}
                                  sx={{
                                    backgroundColor: "white",
                                    "&:hover": { backgroundColor: "white" },
                                  }}
                                >
                                  NO
                                </Button>
                                <Button
                                  form="form"
                                  type="submit"
                                  onClick={() => {
                                    Object.keys(editAbleValue).length === 0
                                      ? dispatcher(add_ComicsBook(props.values))
                                      : dispatcher(
                                          edit_ComicsBook(props.values)
                                        );
                                    setPopoup(true);
                                  }}
                                  style={{
                                    // backgroundColor: "#1976D2",
                                    color: "white",
                                    width: "100px",
                                    height: "37px",
                                    borderRadius: "0px",
                                    border: "1px solid black",
                                  }}
                                  autoFocus
                                  sx={{
                                    backgroundColor: "black",
                                    "&:hover": { backgroundColor: "black" },
                                  }}
                                >
                                  YES
                                </Button>
                              </DialogActions>
                            ) : (
                              // !props.isLoading && (
                              <DialogActions
                                sx={{
                                  justifyContent: "center",
                                  gap: "0.5em",
                                  padding: "1.5em",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    (Object.keys(editAbleValue).length === 0
                                      ? addData?.status
                                      : editData?.status) && setOpen(false);
                                    (Object.keys(editAbleValue).length !== 0
                                      ? addData?.status
                                      : editData?.status) && setPopoup(false);
                                    setDialog(false);
                                  }}
                                  style={{
                                    // backgroundColor: "#1976D2",
                                    color: "white",
                                    width: "100px",
                                    height: "37px",
                                    borderRadius: "0px",
                                    border: "1px solid #BDBDBD",
                                  }}
                                  autoFocus
                                  sx={{
                                    backgroundColor: "black",
                                    "&:hover": { backgroundColor: "black" },
                                  }}
                                >
                                  OK
                                </Button>
                              </DialogActions>
                              // )
                            )}
                          </Dialogs>
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Dialogs>
          </div>
        </div>
        {data.length === 0 ? (
          <div className="NoDataFound">
            <h1>{comicIsLoading ? "Loading..." : "No data found"}</h1>
          </div>
        ) : (
          <div className="sectionDiv">
            {allNames.map((card: any, index: number) => {
              return (
                <div className="cardDiv" key={card.project_id}>
                  <div className="edit">
                    {MenuOfUserpermission?.edit && (
                      <img
                        src={Edit}
                        alt="Edit"
                        width={24}
                        height={24}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickEdit(card)}
                      />
                    )}
                  </div>
                  <div>
                    {card.image_url ? (
                      <img
                        src={card.image_url}
                        alt="Images"
                        style={{
                          width:
                            card.page_orientation === "LANDSCAPE"
                              ? "200px"
                              : "180px",
                          height:
                            card.page_orientation === "LANDSCAPE"
                              ? "150px"
                              : "215px",
                        }}
                        loading="lazy"
                      />
                    ) : (
                      <img
                        src={mesImage}
                        alt="No Image Available"
                        style={{ width: "180px", height: "215px" }}
                        loading="lazy"
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "0.5em",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "contents" }}>
                      <span className="Comicsname">{card.project_name}</span>

                      {/* <span className="ComicsDiscerption">
                      {card.project_description}
                    </span> */}
                    </div>
                    <div
                      style={{ display: "flex", gap: "0.5em", width: "100%" }}
                    >
                      {MenuOfUserpermissionPage.view && (
                        <Button
                          sx={{
                            color: "white",
                            borderRadius: "0px",
                            backgroundColor: "black",
                            "&:hover": { backgroundColor: "black" },
                          }}
                          fullWidth
                          size="small"
                          onClick={() => {
                            dispatcher(Projectid_render(card));
                            localStorage.setItem(
                              "page_orientation",
                              card.page_orientation
                            );
                            localStorage.setItem("Project_Id", card.project_id);
                            localStorage.setItem(
                              "Project_Name",
                              card.project_name
                            );
                            // dispatch(list_ComicsBookPage);
                            navigate("/Comicbookpages");
                            //   );
                            // }
                          }}
                        >
                          Pages
                        </Button>
                      )}
                      {MenuOfUserpermissionquestionPage.view && (
                        <Button
                          sx={{
                            color: "white",
                            borderRadius: "0px",
                            backgroundColor: "black",
                            "&:hover": { backgroundColor: "black" },
                          }}
                          size="small"
                          fullWidth
                          onClick={() => {
                            navigate("/Question");
                            localStorage.setItem("Project_Id", card.project_id);
                            localStorage.setItem(
                              "Project_Name",
                              card.project_name
                            );

                            // dispatch(list_Question(card.id));
                            dispatch(Projectname_render(card.project_name));
                          }}
                        >
                          Questions
                        </Button>
                      )}
                    </div>
                  </div>
                  {/* <span
                    onClick={() => {
                      comicbookdeleteOpen();
                      setDeleteId(card.id);
                    }}
                    style={{
                      cursor: "pointer",
                      marginRight: "-180px",
                      // float: "right",
                    }}
                  >
                    <DeleteOutlineOutlinedIcon
                      sx={{
                        color: "black",

                        "&:hover": {
                          color: "white",
                          backgroundColor: "black",
                          borderRadius: "50%",
                        },
                      }}
                    ></DeleteOutlineOutlinedIcon>
                  </span> */}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Dialogs
        sx={{
          width: "100%",
          height: "100%",
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: "0px",
              width: "30%",
              // backgroundColor: "gray",
            },
          },
        }}
        open={comicbookopen}
        TransitionComponent={Transition}
        keepMounted
        // onClose={comicsbookdeleteClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#000000",
            color: "white",
          }}
        >
          {"Delete"}

          <ClearOutlinedIcon
            className="clear"
            onClick={comicsbookdeleteClose}
            sx={{ color: "white", float: "right" }}
          ></ClearOutlinedIcon>
        </DialogTitle>
        <DialogContent sx={{}}>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{ paddingTop: "40px", color: "black", textAlign: "center" }}
          >
            Are you sure want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "25px",
          }}
        >
          <Button
            variant="outlined"
            onClick={comicsbookdeleteClose}
            sx={{
              width: "110px",
              height: "40px",
              marginRight: "20px",
              // fontSize: "13px",
              // alignItems: "center",
              // marginRight: "20px",
              borderRadius: "0px",
            }}
          >
            NO
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(delete_ComicsBooks(deleteId));
              setDeletePopoup(true);
              comicsbookdeleteClose();
            }}
            sx={{
              width: "110px",
              height: "40px",
              backgroundColor: "rgb(0, 0, 0)",
              color: "#FFFFFF",
              display: "flex",
              fontSize: "13px",
              alignItems: "center",
              borderRadius: "0px",
              justifyContent: "space-evenly",
            }}
          >
            YES
          </Button>
        </DialogActions>
      </Dialogs>
    </div>
  );
};

export default ComicsBook;
