import { createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";

interface ExtendState extends IReduxState<null> {
  Popup: boolean;
  MessagePopup: boolean;
  ConfirmationPopup: boolean;
}
export const AddCover = createSlice({
  name: "AddCover",
  initialState: {
    isLoading: false,
    data: {},
    Popup: false,
    MessagePopup: false,
    ConfirmationPopup: false,
  } as ExtendState,
  reducers: {
    add_Cover: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_add_Cover: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.MessagePopup = true;
    },
    failure_api_call_add_Cover: (state, action) => {
      state.MessagePopup = true;
      state.isLoading = false;
      state.data = action.payload;
    },
    clear_add_Cover: (state) => {
      state.data = {};
    },

    open_cover_form: (state) => {
      state.Popup = true;
    },
    close_cover_form: (state) => {
      state.Popup = false;
    },

    open_confirmation_popup: (state) => {
      state.ConfirmationPopup = true;
    },
    close_confirmation_popup: (state) => {
      state.ConfirmationPopup = false;
    },

    open_messagePopup_popup: (state) => {
      state.MessagePopup = true;
    },
    close_messagePopup_popup: (state) => {
      state.MessagePopup = false;
    },

    false_addcover_popups: (state) => {
      state.MessagePopup = false;
      state.ConfirmationPopup = false;
      state.Popup = false;
    },
  },
});
export default AddCover.reducer;
export const {
  add_Cover,
  success_api_call_add_Cover,
  failure_api_call_add_Cover,
  clear_add_Cover,
  open_cover_form,
  close_cover_form,
  open_confirmation_popup,
  close_confirmation_popup,
  open_messagePopup_popup,
  close_messagePopup_popup,
  false_addcover_popups,
} = AddCover.actions;
