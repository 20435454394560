import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";

interface ExtendInterface extends IReduxState<number[]> {
  popupOpen: boolean;
}
export const UsedProjectIds = createSlice({
  name: "UsedProjectIds",
  initialState: {
    isLoading: false,
    popupOpen: false,
    data: {},
  } as ExtendInterface,
  reducers: {
    list_UsedProjectIds: (state) => {
      state.isLoading = true;
    },
    success_api_call_UsedProjectIds: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_call_UsedProjectIds: (state, _) => {
      state.isLoading = false;
    },
    popupOpen_UsedProjectIds: (state, action: PayloadAction<number>) => {
      state.popupOpen = state.data?.data?.includes(action.payload) ?? false;
    },
    closePopup_UsedProjectIds: (state) => {
      state.popupOpen = false;
    },
  },
});
export default UsedProjectIds.reducer;
export const {
  list_UsedProjectIds,
  success_api_call_UsedProjectIds,
  failure_api_call_UsedProjectIds,
  popupOpen_UsedProjectIds,
  closePopup_UsedProjectIds,
} = UsedProjectIds.actions;
