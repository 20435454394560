import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";

interface ExtendInterface extends IReduxState<number[]> {
  popupOpen: boolean;
}
export const UsedVariant = createSlice({
  name: "UsedVariant",
  initialState: {
    isLoading: false,
    popupOpen: false,
    data: {},
  } as ExtendInterface,
  reducers: {
    list_UsedVariant: (state, _) => {
      state.isLoading = true;
    },
    success_api_call_UsedVariant: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    failure_api_call_UsedVariant: (state, _) => {
      state.isLoading = false;
    },
    popupOpen: (state, action: PayloadAction<number>) => {
      state.popupOpen = !state.data?.data?.includes(action.payload) ?? false;
    },
    closePopup: (state) => {
      state.popupOpen = false;
    },
  },
});
export default UsedVariant.reducer;
export const {
  list_UsedVariant,
  success_api_call_UsedVariant,
  failure_api_call_UsedVariant,
  popupOpen,
  closePopup,
} = UsedVariant.actions;
