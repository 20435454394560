import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_Pagesizelist,
  success_api_call_Pagesizelist,
} from "./Pagesizelist_slicer";

//list PageSize
const PagesizelistCall = async (a: any) => {
  let token = await localStorage.getItem("token");

  return Axios.get(`${process.env.REACT_APP_api_url}/v1/api/page_size/list`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export function* PagesizelistApi(e: any) {
  try {
    const response: AxiosResponse = yield call(PagesizelistCall, e);

    let res = response?.data?.data?.map((x: any, i: any) => ({
      ...x,
      // active: x.active ? "Active" : "Inactive",
      field_id: i + 1,
    }));
    yield put(success_api_call_Pagesizelist(res));
  } catch (e) {
    yield put(failure_api_call_Pagesizelist(e));
    yield put(expireSignature(e));
  }
}
