import { call, put } from "redux-saga/effects";
import Axios, { AxiosResponse } from "axios";

import {
  failure_api_call_add_ComicsBookPage,
  success_api_call_add_ComicsBookPage,
} from "./add_ComicsBookPage_slicer";

import {
  failure_api_call_ComicsBookPage,
  success_api_call_ComicsBookPage,
} from "./list_ComicsBookPage_slicer";
import { expireSignature } from "../ExpireSignature/ExpireSignature_slicer";
import {
  failure_api_call_delete_ComicsBookPage,
  success_api_call_delete_ComicsBookPage,
} from "./delete_ComicsBookPage_slicer";
import {
  success_api_call_add_ComicsBookPage_jsonvalue,
  failure_api_call_add_ComicsBookPage_jsonvalue,
  Imagegenartion,
} from "./comics_page_jsonvalue";
import {
  success_api_call_add_ComicsBookPage_Size,
  failure_api_call_add_ComicsBookPage_Size,
} from "./comics_page_size";
import {
  failure_api_call_Reorder,
  success_api_call_Reorder,
} from "./Reorder_slicer";
import {
  failure_api_call_eidt_ComicsBookPage,
  success_api_call_eidt_ComicsBookPage,
} from "./edit_ComicsBookPage_slicer";
import {
  failure_api_call_PDFgeneration,
  success_api_call_PDFgeneration,
} from "./pdfgeneration";
import {
  success_api_call_textreplace,
  failure_api_call_textreplace,
} from "./text_replace";
import {
  failure_api_call_add_Cover,
  open_messagePopup_popup,
  success_api_call_add_Cover,
} from "./add_Cover_slicer";
import { PayloadAction } from "@reduxjs/toolkit";
import { ICoverForm } from "../../../interfaces/ComicsPageEdit";
import {
  failure_api_call_edit_Cover,
  success_api_call_edit_Cover,
} from "./edit_Cover_slicer";
// import {
//   failure_api_call_add_user,
//   success_api_call_add_user,
// } from "./add_User_slicer";
// import {
//   failure_api_call_edit_user,
//   success_api_call_edit_user,
// } from "./edit_User_slicer";
// import { failure_api_call_login, success_api_call_login } from "./Login_slicer";

//add Spine
const addComicsBookPageCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  let projectID = localStorage?.getItem("Project_Id");
  const adminId = JSON.parse(localStorage.getItem("id")!);
  // console.log("api call ", a.payload);
  // console.log("adminid", adminId);

  const add = {
    ...a.payload,
    project_id: parseInt(projectID!),
  };

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/project_pages/
    `,
    add,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddComicsBookPageApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addComicsBookPageCall, e);
    // console.log(response.data);

    yield put(success_api_call_add_ComicsBookPage(response.data));
  } catch (e) {
    yield put(failure_api_call_add_ComicsBookPage(e));
    yield put(expireSignature(e));
  }
}

//list font
const listComicsBookPageCall = async (a: any) => {
  let token = await localStorage.getItem("token");
  const formData = new FormData();
  formData.append("project_id", a.payload);

  const dataa = formData;

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/project_pages/project_id`,
    dataa,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* listComicsBookPageApi(e: any) {
  try {
    const response: AxiosResponse = yield call(listComicsBookPageCall, e);

    // let res = response?.data?.data?.map((x: any, i: any) => ({
    //   ...x,
    //   //   active: x.active ? "Active" : "Inactive",
    //   field_id: i + 1,
    // }));
    // yield put(success_api_call_ComicsBookPage(res));
    yield put(success_api_call_ComicsBookPage(response?.data?.data));
  } catch (e) {
    yield put(failure_api_call_ComicsBookPage(e));
    yield put(expireSignature(e));
  }
}

//    Delete a  comics book page
export function* deleteComicsBookPageApi(e: any) {
  try {
    const response: AxiosResponse = yield call(deleteComicsBookPageCall, e);
    yield put(success_api_call_delete_ComicsBookPage(response.data));
  } catch (error) {
    console.log(error);
    yield put(failure_api_call_delete_ComicsBookPage(error));
  }
}
const deleteComicsBookPageCall = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("id", a.payload);
  return Axios.delete(
    `${process.env.REACT_APP_api_url}/v1/api/project_pages/`,
    { data: formData, headers: { Authorization: `Bearer ${token}` } }
  );
};

const imagegeneration = (a: any) => {
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("project_id", a.payload.project_id);
  formData.append("page_id", a.payload.page_id);

  const add = formData;
  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/project_pages/image
    `,
    add,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* ImagereloadApi(e: any) {
  try {
    const response: AxiosResponse = yield call(imagegeneration, e);
    console.log(response);

    yield put(Imagegenartion("successdata"));
  } catch {}
}

const addComicsBookPageCall_jsonvalue = async (a: any) => {
  let token = await localStorage.getItem("token");
  const adminId = JSON.parse(localStorage.getItem("id")!);

  // const base64Image = a.payload.image_url.split(",")[1];
  // const blob = new Blob([atob(base64Image)], { type: "image/jpeg" });
  const formData = new FormData();
  formData.append("project_id", a.payload.project_id);
  formData.append("page_id", a.payload.page_id);
  formData.append("template", a.payload.template);
  formData.append("base64image", a.payload.base64image);
  formData.append("width", a.payload.width);
  formData.append("height", a.payload.height);

  const add = formData;
  const data = {
    project_id: a.payload.project_id,
    page_id: a.payload.page_id,
    template: a.payload.template,
    base64image: a.payload.base64image,
    width: a.payload.width,
    height: a.payload.height,
  };
  return Axios.post(
    `${process.env.REACT_APP_nodeurl}/template/json_value
    `,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export function* AddComicsBookPagejsonvalueApi(e: any) {
  try {
    const response: AxiosResponse = yield call(
      addComicsBookPageCall_jsonvalue,
      e
    );
    // console.log("e", e);
    // console.log(response.data);
    // ImagereloadApi(e);
    // imagegeneration(e);
    yield put(success_api_call_add_ComicsBookPage_jsonvalue(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_ComicsBookPage_jsonvalue(e));
    yield put(expireSignature(e));
  }
}

const addComicsBookPageCall_Size = async (a: any) => {
  let token = await localStorage.getItem("token");
  const formData = new FormData();
  formData.append("project_id", a.payload.project_id);
  formData.append("page_id", a.payload.page_id);

  const add = formData;
  // console.log(add);

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/project_pages/positions
    `,
    add,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddComicsBookPageSizeApi(e: any) {
  try {
    const response: AxiosResponse = yield call(addComicsBookPageCall_Size, e);
    // console.log(response.data);

    yield put(success_api_call_add_ComicsBookPage_Size(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_add_ComicsBookPage_Size(e));
    yield put(expireSignature(e));
  }
}

//    Reorder pages

const ReorderApiCall = (a: any) => {
  let token = localStorage.getItem("token");
  // console.log(a.payload);
  const arrayOfId = a.payload.arrOfValues.map(
    (value: { page_id: number }) => value.page_id
  );
  const arrayOfIndex = a.payload.arrOfValues.map(
    (value: { index: number }) => value.index
  );
  // console.log({ id: arrayOfId, index: arrayOfIndex });

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/project_pages/pageindex`,
    { id: arrayOfId, index: arrayOfIndex },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export function* ReorderApi(e: any) {
  try {
    const response: AxiosResponse = yield call(ReorderApiCall, e);
    yield put(success_api_call_Reorder(response.data));
  } catch (e) {
    yield put(failure_api_call_Reorder(e));
    yield put(expireSignature(e));
  }
}
//    Edit pages

const EditApiCall = (a: any) => {
  let token = localStorage.getItem("token");
  const formData = new FormData();
  for (const key in a.payload) {
    formData.append(key, a.payload[key]);
  }
  // formData.append("id", a.payload.id);
  // formData.append("project_id", a.payload.project_id);
  // formData.append("name", a.payload.name);
  // formData.append("index", a.payload.index);
  // formData.append("spine_width", a.payload.spine_width);
  // formData.append("variant_id", a.payload.variant_id);

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/project_pages/`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export function* EditApi(e: any) {
  try {
    const response: AxiosResponse = yield call(EditApiCall, e);
    yield put(success_api_call_eidt_ComicsBookPage(response.data));
  } catch (e) {
    yield put(failure_api_call_eidt_ComicsBookPage(e));
    yield put(expireSignature(e));
  }
}

const pdf_generationapi = async (a: any) => {
  let token = await localStorage.getItem("token");
  const add = a.payload;
  const num = parseInt(add);
  console.log(num);
  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/pdf/generate?project_id=${num}
    `,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* Pdf_Generation_Api(e: any) {
  try {
    const response: AxiosResponse = yield call(pdf_generationapi, e);
    // console.log(response.data);

    yield put(success_api_call_PDFgeneration(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_PDFgeneration(e));
    yield put(expireSignature(e));
  }
}

const text_replace = async (a: any) => {
  let token = await localStorage.getItem("token");
  const add = a.payload;
  const num = parseInt(add);
  return Axios.get(
    `${process.env.REACT_APP_api_url}/v1/api/options/image?project_id=${num}
    `,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* Text_Replace_Api(e: any) {
  try {
    const response: AxiosResponse = yield call(text_replace, e);
    console.log(response.data);

    yield put(success_api_call_textreplace(response.data));
  } catch (e) {
    console.log(e);
    yield put(failure_api_call_textreplace(e));
    yield put(expireSignature(e));
  }
}

//add Cover
const addCoverCall = (aciton: PayloadAction<ICoverForm>) => {
  let token = localStorage.getItem("token");

  const payload = {
    ...aciton.payload,
    project_id: parseInt(localStorage.getItem("Project_Id")!),
  };

  return Axios.post(
    `${process.env.REACT_APP_api_url}/v1/api/project_pages/cover`,
    payload,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* AddCoverApi(event: PayloadAction<ICoverForm>) {
  try {
    const response: AxiosResponse = yield call(addCoverCall, event);
    yield put(success_api_call_add_Cover(response.data));
  } catch (error) {
    yield put(failure_api_call_add_Cover(error));
    yield put(expireSignature(error));
  }
}

//edit Cover
const editCoverCall = (action: PayloadAction<ICoverForm>) => {
  let token = localStorage.getItem("token");

  const payload: {
    project_id: number;
    id?: number | null | undefined;
    name?: string | null | undefined;
    variant_id?: number | null | undefined;
    cover_width?: number | null | undefined;
    cover_height?: number | null | undefined;
    [key: string]: number | string | null | undefined; // Index signature
  } = {
    ...action.payload,
    project_id: parseInt(localStorage.getItem("Project_Id")!),
  };

  const formData = new FormData();

  for (const key in payload) {
    formData.append(key, String(payload[key]));
  }

  return Axios.put(
    `${process.env.REACT_APP_api_url}/v1/api/project_pages/cover`,
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};
export function* EditCoverApi(event: PayloadAction<ICoverForm>) {
  try {
    const response: AxiosResponse = yield call(editCoverCall, event);
    yield put(success_api_call_edit_Cover(response.data));
    yield put(open_messagePopup_popup());
  } catch (error) {
    yield put(failure_api_call_edit_Cover(error));
    yield put(expireSignature(error));
    yield put(open_messagePopup_popup());
  }
}
