import { createSlice } from "@reduxjs/toolkit";

export const VariantById = createSlice({
  name: "VariantById",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isFirst: true,
    data: [],
  },
  reducers: {
    list_VariantById: (state, _) => {
      state.data = [];
      state.isLoading = true;
    },
    success_api_call_list_VariantById: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isFirst = true;
    },
    failure_api_call_list_VariantById: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    list_is_first: (state, action) => {
      state.isFirst = false;
    },
    clear_VariantById: (state) => {
      state.data = [];
    },
  },
});
export default VariantById.reducer;
export const {
  list_VariantById,
  success_api_call_list_VariantById,
  failure_api_call_list_VariantById,
  list_is_first,
  clear_VariantById,
} = VariantById.actions;
