import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IReduxState } from "../../../interfaces/IReduxState";
import { FAILED, SUCCESE } from "../../../Editor/utills/common";
import { IPDF } from "../../../interfaces/IPDF";

interface IExtends extends IReduxState<string> {
  loadingIDs?: (number | string)[];
}
export const Customer_pdf = createSlice({
  name: "Customer_pdf",
  initialState: {
    isLoading: false,
    data: {},
    loadingIDs: [],
  } as IExtends,
  reducers: {
    Customer_PDF_Genaration: (state, action: PayloadAction<IPDF>) => {
      state.loadingIDs?.push(
        action.payload.customization_id ?? action.payload.project_id!
      );
      state.isLoading = true;
    },
    success_api_call_cus_PDFGenaration: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      SUCCESE(action.payload.message);
    },
    failure_api_call_cus_PDFGenaration: (state, action) => {
      state.data = action.payload;
      FAILED(action.payload.message);
    },
    stop_loaderr: (state, action: PayloadAction<IPDF>) => {
      state.isLoading = false;
      const id = action.payload.customization_id ?? action.payload.project_id!;

      const copyState = state.loadingIDs;
      const index = copyState?.indexOf(id);
      copyState?.splice(index!, 1);

      copyState?.filter((ids) => ids !== id);
      state.loadingIDs = copyState;
    },
  },
});
export default Customer_pdf.reducer;
export const {
  Customer_PDF_Genaration,
  success_api_call_cus_PDFGenaration,
  failure_api_call_cus_PDFGenaration,
  stop_loaderr,
} = Customer_pdf.actions;
