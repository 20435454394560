import "./New_Campaign.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { FunctionComponent } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";

interface EditorProps {
  getValues: (value: EditorState) => void;
  value: EditorState;
  readOnly?: boolean;
}

const EditorComponent: FunctionComponent<EditorProps> = (props) => {
  const { getValues, value, readOnly } = props;

  // const [editorState, setEditorState] = useState<EditorState>(() =>
  //   EditorState.createEmpty()
  // );
  // useEffect(() => {
  //   const contentBlock = htmlToDraft(value);
  //   if (contentBlock) {
  //     const contentState = ContentState.createFromBlockArray(
  //       contentBlock.contentBlocks,
  //       contentBlock.entityMap
  //     );
  //     const editorState = EditorState.createWithContent(contentState);
  //     setEditorState(editorState);
  //   }
  // }, [value]);

  // useEffect(() => {
  //   const rawContentState = convertToRaw(editorState.getCurrentContent());
  //   const html = draftToHtml(rawContentState);
  //   getValues(html);
  // }, [editorState]);
  // console.log("render");

  return (
    <>
      <Editor
        editorState={value}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName editorDiv"
        editorClassName="editorClassName editorBody"
        onEditorStateChange={getValues}
        readOnly={readOnly}
      />
    </>
  );
};

export default EditorComponent;
