import { FunctionComponent } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialogs, { DialogProps } from "@mui/material/Dialog";
// import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../Pages/Page.css";
import Correct from "../Pages/Image/Correctimage.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  FormHelperText,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
//
import TextField from "@mui/material/TextField";
import Autocomplete from "./Autocomplete";
import "../App.css";
import {
  Formik,
  Form,
  FieldArray,
  getIn,
  ErrorMessage,
  FormikProps,
  FormikValues,
} from "formik";
import * as Yup from "yup";
import "../Pages/Page.css";
import IsLoading from "./isLoading";
import { useAppDispatch, useAppSelector } from "../Redux/store/hooks";
import { list_Projectcategory } from "../Redux/Reducers/Project Category/list_ProjectCategory_slicer";
// import IsLoading from "./isLoading";
// import { useAppDispatch } from "../redux/store/hooks";
// import { edit_bay } from "../redux/Bay/edit_Bay_slicer";
// import uploadImage from "../Pages/Image/uploadimage.png";
import uploadImage from "../Pages/Image/UploadCloudImage.png";
import ClearIcon from "@mui/icons-material/Clear";
import CheckboxLabels from "./CheckboxComponent";
import Select from "./Select";

// Dialog Component

type variant = "text" | "contained" | "outlined" | undefined;
type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;
type size = "small" | "medium" | "large" | undefined;
interface DialogsProps {
  sx?: SxProps<Theme> | undefined;
  Buttondialog?: any;
  Dialogtitle?: any;
  Dialogcontenttext?: any;
  Buttonname?: any | number;
  variant?: variant;
  disabled?: boolean;
  url?: string;
  vertical?: string | number;
  horizontal?: string | number;
  onclick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  color?: color;
  size?: size;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  style?: React.CSSProperties | undefined;
  getValues?: any;
  closeDio?: any;
  // error?: any;
  edit?: any;
  editzone?: any;
  editbay?: any;
  editservice?: any;

  // errors?: any;
  placeholder?: string;
  textfield?: boolean;
  successMessage?: any;
  isLoading?: boolean;
  DialogtitleForSuccessMessage?: any;
}

const DialogQuestion: FunctionComponent<DialogsProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  // option question page

  const [showSecondTextField, setShowSecondTextField] = React.useState(false);

  //    Popup messages
  const [popup, setPopoup] = React.useState(false);

  //    success dialog
  const [dialog, setDialog] = React.useState(false);

  // console.log(props.closeFunction);
  const { getValues, placeholder, successMessage, isLoading, edit }: any =
    props;

  //   const dispatch = useAppDispatch();

  //    Ref for form submitting
  const formRef = React.useRef<FormikProps<FormikValues>>(null);

  const ListProjectdata = useAppSelector(
    (state) => state?.ListProjectCategory?.data
  );

  const initialValues: {
    id: number;
    sort_order: number;
    q_type: string;
    description: string;
    text_type: string;
    capitilization: boolean;
    show_in_cart: boolean;
    view_type: string | null;
    Options: {
      keyword: string | null;
      length: number | null;
      options: string | null;
      image_urls: File | string | null;
      default: string | null;
    }[];
  } = {
    id: props?.edit?.question_id,
    sort_order: props?.edit?.order ?? null,
    q_type: props?.edit?.q_type ?? "",
    description: props?.edit?.question ?? "",
    text_type: props?.edit?.text_type ?? "",
    capitilization: props?.edit?.capitilization ?? false,
    show_in_cart: props?.edit?.show_in_cart ?? false,
    view_type: props?.edit?.view_type ?? null,
    Options: props?.edit?.Options ?? [
      {
        options: null,
        image_urls: null,
        keyword: null,
        length: null,
        default: null,
      },
    ],
  };

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    // closeFunction(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDialog(false);
    // dispatch(edit_bay('' as any));
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    setDialog(false);
    setPopoup(false);
  }, [open]);
  React.useEffect(() => {
    if (isLoading) {
      setDialog(isLoading);
    }
  }, [isLoading]);

  /*    view type list     */
  const viewList = [
    { label: "Radio", value: "RADIO" },
    { label: "Options", value: "OPTIONS" },
  ];

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        color={props.color}
        size={props.size}
        startIcon
        disabled={props.disabled}
        variant={props.variant}
        endIcon
        sx={props.sx}
      >
        {props.Buttonname}
      </Button>

      <Dialogs
        open={open}
        //
        maxWidth={"xl"}
        PaperProps={{
          sx: {
            borderRadius: "0",
            "&::-webkit-scrollbar": { display: "none" },
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            fontWeight: "bold",
            backgroundColor: "black",
            color: "white",
          }}
        >
          {props.Dialogtitle}
          <ClearOutlinedIcon
            className="clear"
            onClick={handleClose}
            sx={{ color: "white", float: "right" }}
          ></ClearOutlinedIcon>
        </DialogTitle>
        <DialogContent
          //
          sx={{
            "&::-webkit-scrollbar": { display: "none" },
            padding: "1.5em 24px 0 !important",
          }}
        >
          <Formik
            initialValues={initialValues}
            innerRef={formRef}
            // validationSchema={SignupSchema}
            validationSchema={Yup.object().shape({
              q_type: Yup.string().required("Please select a question type"),
              description: Yup.string().required("Please enter question"),
              sort_order: Yup.number()
                .positive("Order should be a positive number")
                .min(1, "Order must be greater than or equal to 1")
                .integer("Order must be an integer")
                .required("Enter the Order of the Question"),

              text_type: Yup.string()
                .nullable()
                .transform((value) => null)
                .when("q_type", {
                  is: "T",
                  then: () =>
                    Yup.string().required("Please select a text type"),
                  // otherwise:()=>Yup.string()
                }),
              view_type: Yup.string()
                .nullable()
                .when("q_type", {
                  is: "I",
                  then: () => Yup.string().required("Please select view type"),
                }),
              // keyword: Yup.string().when("q_type", {
              //   is: "T",
              //   then: () => Yup.string().required("Please enter keyword"),
              // }),
              // length: Yup.number()
              //   .nullable()
              //   .when("q_type", {
              //     is: "T",
              //     then: () =>
              //       Yup.number()
              //         .positive()
              //         .integer()
              //         .min(1, "Length must be greater than 1")
              //         .required("Please enter length"),
              //   }),
              Options: Yup.array().when("q_type", {
                is: "I",
                then: () =>
                  Yup.array()
                    .of(
                      Yup.object().shape({
                        options: Yup.string()
                          .nullable()
                          .required("Please enter Option."),
                        image_urls: Yup.mixed()
                          .test(
                            "fileSize",
                            "Please choose a 250x250 pixel image.",
                            (value) => {
                              if (!value) return true; // Skip validation if no image selected

                              return new Promise((resolve) => {
                                const image = new Image();

                                image.src =
                                  typeof value === "string"
                                    ? value.replace(/\s/g, "%20")
                                    : URL?.createObjectURL(
                                        value as Blob | MediaSource
                                      );

                                image.onload = () => {
                                  const isValid =
                                    image.width === 250 && image.height === 250;
                                  if (isValid) {
                                    resolve(true);
                                  } else {
                                    resolve(false);
                                  }
                                  resolve(isValid);
                                };
                              });
                            }
                          )
                          .test(
                            "max-length",
                            "File name must be less than or equal to 35 characters",
                            (value: any) => {
                              if (!value) return true; // Skip validation if value is not provided
                              if (typeof value === "string") return true; // Skip validation if value is not provided
                              const fileName = value.name.length;
                              return fileName <= 35;
                            }
                          )
                          .test(
                            "type",
                            "Only the following formats are accepted: .jpeg, .jpg, and .png",
                            (value: any) => {
                              if (typeof value === "string") return true; // Skip validation if value is not provided

                              return (
                                value &&
                                (value.type === "image/jpeg" ||
                                  value.type === "image/png" ||
                                  value.type === "image/jpg")
                              );
                            }
                          )

                          .required("Upload image."),
                      })
                    )
                    .min(1),
                otherwise: () =>
                  Yup.array().when("text_type", {
                    is: "P",
                    then: () =>
                      Yup.array()
                        .of(
                          Yup.object().shape({
                            // options: Yup.string().required(
                            //   "Please enter Option."
                            // ),
                            length: Yup.number()
                              .nullable()
                              .positive()
                              .integer()
                              .min(1, "Length must be greater than 1")
                              .required("Please enter length"),
                          })
                        )
                        .min(1),
                    otherwise: () =>
                      Yup.array()
                        .of(
                          Yup.object().shape({
                            // options: Yup.string().required(
                            //   "Please enter Option."
                            // ),
                            default: Yup.string()
                              .max(
                                Yup.ref("length"),
                                ({ max }) =>
                                  `Maximun length you mentioned was ${max}`
                              )
                              .required("Please enter default"),
                            keyword: Yup.string().required(
                              "Please enter keyword"
                            ),
                            length: Yup.number()
                              .positive()
                              .nullable()
                              .integer()
                              .min(1, "Length must be greater than 1")
                              .required("Please enter length"),
                          })
                        )
                        .min(1),
                  }),
              }),
            })}
            onSubmit={() => {
              // getValues(values);
              setDialog(true);
            }}
          >
            {(props) => {
              console.log(props.errors);

              return (
                <Form id="form" autoComplete="off">
                  <IsLoading isLoading={isLoading} />
                  {/*     for submit when enter button click     */}
                  <button type="submit" style={{ display: "none" }}>
                    submit
                  </button>

                  <div
                    style={{ display: "flex", gap: "0 1em", flexWrap: "wrap" }}
                  >
                    <div className="lableInputDiv">
                      <b>
                        Question <span style={{ color: "red" }}>*</span>
                      </b>
                      <TextField
                        id="outlined-basic"
                        // required
                        variant="outlined"
                        name="description"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                          },
                          width: "clamp(10rem,20vw,25rem)",
                        }}
                        autoComplete="off"
                        helperText={
                          !!(
                            props.errors.description &&
                            props.touched.description
                          ) && (props.errors.description as React.ReactNode)
                          // : "Enter your Email."
                        }
                        error={
                          !!(
                            props.errors.description &&
                            props.touched.description
                          )
                        }
                        // onChange={props.handleChange}
                        onChange={(e) => {
                          if (e.target.value.length < 100) {
                            props.setFieldValue("description", e.target.value);
                          }
                        }}
                        onBlur={props.handleBlur}
                        value={props.values.description}
                        // onChange={props.handleChange}
                      />{" "}
                    </div>
                    <div className="lableInputDiv">
                      <b>
                        Question Type <span style={{ color: "red" }}>*</span>
                      </b>
                      <Autocomplete
                        Listitem={[
                          { label: "Text", value: "T" },
                          { label: "Image", value: "I" },
                        ]}
                        // width={200}
                        // required
                        variant={"outlined"}
                        name={"q_type"}
                        value={props.values}
                        onBlur={props.handleBlur}
                        onchange={(e: any) => {
                          // console.log(e);

                          props.setFieldValue("q_type", e.value);
                          props.setFieldValue("Options", [
                            {
                              options: null,
                              image_urls: null,
                              keyword: null,
                              length: null,
                              default: null,
                            },
                          ]);
                        }}
                        sx={{ width: "clamp(10rem,20vw,25rem)" }}
                        error={props.errors.q_type && props.touched.q_type}
                        helperText={
                          props.errors.q_type &&
                          props.touched.q_type &&
                          props.errors.q_type
                        }
                      ></Autocomplete>
                    </div>
                    {props.values.q_type === "I" && (
                      <div>
                        <b>
                          View Type <span style={{ color: "red" }}>*</span>
                        </b>
                        <Select
                          Listitem={viewList}
                          sx={{ width: "clamp(10rem,20vw,25rem)" }}
                          name="view_type"
                          value={
                            viewList.filter(
                              (val) => val.value === props.values.view_type
                            )[0] ?? null
                          }
                          disableClearable
                          onchange={(
                            _: null,
                            value: { label: string; value: string }
                          ) => props.setFieldValue("view_type", value?.value)}
                          error={
                            !!(
                              props.errors.view_type && props.touched.view_type
                            )
                          }
                          helperText={
                            props.errors.view_type &&
                            props.touched.view_type &&
                            props.errors.view_type
                          }
                        />
                      </div>
                    )}
                    {props.values.q_type === "T" && (
                      <div className="lableInputDiv">
                        <b>
                          Text Type <span style={{ color: "red" }}>*</span>
                        </b>
                        <Autocomplete
                          Listitem={[
                            { label: "Short Text", value: "T" },
                            { label: "Sentence", value: "S" },
                            { label: "Paragraph", value: "P" },
                          ]}
                          // width={200}
                          // required
                          variant={"outlined"}
                          name={"text_type"}
                          value={props.values}
                          onBlur={props.handleBlur}
                          onchange={(e: any) => {
                            // console.log(e);

                            props.setFieldValue("text_type", e.value);
                          }}
                          sx={{ width: "clamp(10rem,20vw,25rem)" }}
                          error={
                            props.errors.text_type && props.touched.text_type
                          }
                          helperText={
                            props.errors.text_type &&
                            props.touched.text_type &&
                            props.errors.text_type
                          }
                        ></Autocomplete>
                      </div>
                    )}
                  </div>

                  {/*     question order for image     */}
                  {props.values.q_type === "I" && (
                    <div className="lableInputDiv">
                      <b>
                        Question Order <span style={{ color: "red" }}>*</span>
                      </b>
                      <TextField
                        variant="outlined"
                        name="sort_order"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                          },
                          width: "clamp(10rem,20vw,25rem)",
                        }}
                        autoComplete="off"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 0,
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          },
                        }}
                        helperText={
                          !!(
                            props.errors.sort_order && props.touched.sort_order
                          ) && (props.errors.sort_order as React.ReactNode)
                          // : "Enter your Email."
                        }
                        error={
                          !!(
                            props.errors.sort_order && props.touched.sort_order
                          )
                        }
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.sort_order}
                      />
                    </div>
                  )}

                  {/* {props.values.q_type === "T" && (
                    <div style={{ display: "flex", gap: "1em" }}>
                      <div className="lableInputDiv">
                        <div
                          style={{
                            color: "#000000",
                            paddingBottom: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          Keyword <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          variant="outlined"
                          name={"keyword"}
                          sx={{
                            "& fieldset": {
                              borderRadius: "0px",
                            },
                          }}
                          autoComplete="off"
                          helperText={
                            props.errors.keyword &&
                            props.touched.keyword &&
                            (props.errors.keyword as React.ReactNode)
                          }
                          error={
                            !!(props.touched.keyword && props.errors.keyword)
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.keyword}
                          style={{
                            width: "clamp(10rem,20vw,25rem)",
                          }}
                        />
                      </div>
                      <div className="lableInputDiv">
                        <div
                          style={{
                            color: "#000000",
                            paddingBottom: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          Length
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <TextField
                          id="outlined-basic"
                          type="number"
                          variant="outlined"
                          name={"length"}
                          sx={{
                            "& fieldset": {
                              borderRadius: "0px",
                            },
                          }}
                          autoComplete="off"
                          inputProps={{
                            maxLength: 25,
                          }}
                          helperText={
                            props.errors.length &&
                            props.touched.length &&
                            (props.errors.length as React.ReactNode)
                          }
                          error={
                            !!(props.errors.length && props.touched.length)
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.length}
                          style={{
                            width: "clamp(10rem,20vw,25rem)",
                          }}
                        />
                      </div>
                    </div>
                  )} */}

                  {/*       /*    commads for future update    */}
                  {/* {props.values.q_type === "I" ? ( */}
                  {props.values.q_type === "I" || !!props.values.text_type ? (
                    <FieldArray
                      name={"Options"}
                      render={(arrayHelpers) => {
                        return (
                          <>
                            {props.values.Options &&
                            props.values.Options.length > 0 ? (
                              <>
                                {/*     Mapping the options    */}
                                {props.values.Options.map(
                                  (
                                    value: {
                                      options: string;
                                      image_urls: File | string;
                                      /*    commads for future update    */
                                      keyword: string;
                                      default: string;
                                      length: number;
                                    },
                                    index: number
                                  ) => {
                                    /*    Options    */
                                    const options = `Options[${index}].options`;
                                    const touchedOptions = getIn(
                                      props.touched,
                                      options
                                    );
                                    const errorOptions = getIn(
                                      props.errors,
                                      options
                                    );

                                    /*    image    */
                                    const image_urls = `Options[${index}].image_urls`;
                                    const touchedimage_urls = getIn(
                                      props.touched,
                                      image_urls
                                    );
                                    const errorimage_urls = getIn(
                                      props.errors,
                                      image_urls
                                    );

                                    /*    commads for future update    */
                                    /*    length    */
                                    const length = `Options[${index}].length`;
                                    const touchedlength = getIn(
                                      props.touched,
                                      length
                                    );
                                    const errorlength = getIn(
                                      props.errors,
                                      length
                                    );

                                    /*    commads for future update    */
                                    /*    keyword    */
                                    const keyword = `Options[${index}].keyword`;
                                    const touchedkeyword = getIn(
                                      props.touched,
                                      keyword
                                    );
                                    const errorkeyword = getIn(
                                      props.errors,
                                      keyword
                                    );

                                    /*    default    */
                                    const defaults = `Options[${index}].default`;
                                    const toucheddefault = getIn(
                                      props.touched,
                                      defaults
                                    );
                                    const errordefault = getIn(
                                      props.errors,
                                      defaults
                                    );

                                    return (
                                      <div key={index} className="OptionDiv">
                                        {props.values.q_type === "I" && (
                                          <div className="lableInputDiv">
                                            <b>
                                              Option
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </b>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "1em",
                                              }}
                                            >
                                              <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                name={options}
                                                placeholder={`Option ${
                                                  index + 1
                                                }`}
                                                fullWidth
                                                sx={{
                                                  "& fieldset": {
                                                    borderRadius: "0px",
                                                  },
                                                  width:
                                                    "clamp(10rem,20vw,25rem)",
                                                }}
                                                autoComplete="off"
                                                error={
                                                  !!(
                                                    touchedOptions &&
                                                    errorOptions
                                                  )
                                                }
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={value.options}
                                              />

                                              <label
                                                htmlFor={image_urls}
                                                style={{
                                                  border:
                                                    touchedimage_urls &&
                                                    errorimage_urls
                                                      ? "1px solid #d32f2f"
                                                      : "1px solid rgba(0, 0, 0, 0.23)",
                                                  display: "flex",
                                                }}
                                              >
                                                <input
                                                  type="file"
                                                  name={image_urls}
                                                  id={image_urls}
                                                  hidden
                                                  accept="image/jpeg,image/jpg,image/png"
                                                  onBlur={props.handleBlur}
                                                  onChange={async (
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                  ) => {
                                                    props.setFieldTouched(
                                                      image_urls,
                                                      true
                                                    );
                                                    props.setFieldValue(
                                                      image_urls,
                                                      e.target.files![0]
                                                    );
                                                  }}
                                                />
                                                <img
                                                  src={uploadImage}
                                                  srcSet={
                                                    typeof value?.image_urls ===
                                                    "string"
                                                      ? value.image_urls.replace(
                                                          /\s/g,
                                                          "%20"
                                                        )
                                                      : value?.image_urls &&
                                                        URL?.createObjectURL(
                                                          value?.image_urls
                                                        )
                                                  }
                                                  alt="upload"
                                                  height={55}
                                                  width={55}
                                                />
                                              </label>
                                              {props.values.q_type === "I" && (
                                                <ClearIcon
                                                  sx={{
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                      color: "#d32f2f",
                                                    },
                                                  }}
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  }
                                                />
                                              )}
                                            </div>
                                            <FormHelperText
                                              error
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                flexWrap: "wrap",
                                                width: "calc(100% - 3em)",
                                                margin: "auto",
                                              }}
                                            >
                                              <span>
                                                <ErrorMessage name={options} />
                                              </span>
                                              <span>
                                                <ErrorMessage
                                                  name={image_urls}
                                                />
                                              </span>
                                            </FormHelperText>
                                          </div>
                                        )}

                                        {/*       /*    commads for future update    */}
                                        {props.values.q_type === "T" && (
                                          <>
                                            <div className="lableInputDiv">
                                              <b>
                                                Length
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>
                                              </b>
                                              <TextField
                                                id="outlined-basic"
                                                type="number"
                                                variant="outlined"
                                                name={length}
                                                sx={{
                                                  "& fieldset": {
                                                    borderRadius: "0px",
                                                  },
                                                  width:
                                                    "clamp(10rem,20vw,25rem)",
                                                }}
                                                autoComplete="off"
                                                inputProps={{
                                                  maxLength: 25,
                                                }}
                                                helperText={
                                                  errorlength &&
                                                  touchedlength &&
                                                  errorlength
                                                }
                                                error={
                                                  !!(
                                                    errorlength && touchedlength
                                                  )
                                                }
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                value={value.length}
                                              />
                                            </div>
                                            {/* type empty is without type used for ignore below conditions   */}
                                            {props.values.text_type !==
                                              "empty" && (
                                              <>
                                                <div className="lableInputDiv">
                                                  <b>
                                                    Keyword{" "}
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      *
                                                    </span>
                                                  </b>
                                                  <TextField
                                                    variant="outlined"
                                                    name={keyword}
                                                    sx={{
                                                      "& fieldset": {
                                                        borderRadius: "0px",
                                                      },
                                                      width:
                                                        "clamp(10rem,20vw,25rem)",
                                                    }}
                                                    autoComplete="off"
                                                    helperText={
                                                      errorkeyword &&
                                                      touchedkeyword &&
                                                      errorkeyword
                                                    }
                                                    error={
                                                      !!(
                                                        touchedkeyword &&
                                                        errorkeyword
                                                      )
                                                    }
                                                    InputProps={{
                                                      inputProps: {
                                                        pattern: "[A-Za-z]*",
                                                      },
                                                    }}
                                                    onChange={(event) => {
                                                      const sanitizedValue =
                                                        event.target.value.replace(
                                                          /[^A-Za-z]/g,
                                                          ""
                                                        );
                                                      props.setFieldValue(
                                                        keyword,
                                                        sanitizedValue
                                                      );
                                                    }}
                                                    onBlur={props.handleBlur}
                                                    value={value.keyword}
                                                  />
                                                </div>
                                                <div className="lableInputDiv">
                                                  <b>
                                                    Default
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      *
                                                    </span>
                                                  </b>
                                                  <TextField
                                                    variant="outlined"
                                                    name={defaults}
                                                    sx={{
                                                      "& fieldset": {
                                                        borderRadius: "0px",
                                                      },
                                                      width:
                                                        "clamp(10rem,20vw,25rem)",
                                                    }}
                                                    autoComplete="off"
                                                    helperText={
                                                      errordefault &&
                                                      toucheddefault &&
                                                      errordefault
                                                    }
                                                    error={
                                                      !!(
                                                        toucheddefault &&
                                                        errordefault
                                                      )
                                                    }
                                                    // InputProps={{
                                                    //   inputProps: {
                                                    //     pattern: "[A-Za-z]*",
                                                    //   },
                                                    // }}
                                                    onChange={
                                                      props.handleChange
                                                    }
                                                    onBlur={props.handleBlur}
                                                    value={value.default}
                                                  />
                                                </div>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            ) : null}
                            {props.values.q_type === "I" && (
                              <Button
                                sx={{
                                  // width: "100px",
                                  height: "40px",
                                  border: "1px solid black",
                                  color: "white",
                                  backgroundColor: "black",
                                  borderRadius: "0px",
                                  "&:hover": {
                                    backgroundColor: "black",
                                  },
                                }}
                                onClick={() =>
                                  arrayHelpers.push({
                                    options: null,
                                    image_urls: null,
                                    keyword: null,
                                    length: null,
                                    default: null,
                                  })
                                }
                              >
                                Add options
                              </Button>
                            )}
                          </>
                        );
                      }}
                    />
                  ) : null}
                  {props.errors.Options && props.values.Options.length === 0 ? (
                    <FormHelperText error>Please enter option</FormHelperText>
                  ) : null}

                  {/*     question order for text    */}
                  {props.values.q_type === "T" && !!props.values.text_type && (
                    <div className="lableInputDiv">
                      <b>
                        Question Order <span style={{ color: "red" }}>*</span>
                      </b>
                      <TextField
                        variant="outlined"
                        name="sort_order"
                        sx={{
                          "& fieldset": {
                            borderRadius: "0px",
                          },
                          width: "clamp(10rem,20vw,25rem)",
                        }}
                        autoComplete="off"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 0,
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          },
                        }}
                        helperText={
                          !!(
                            props.errors.sort_order && props.touched.sort_order
                          ) && (props.errors.sort_order as React.ReactNode)
                          // : "Enter your Email."
                        }
                        error={
                          !!(
                            props.errors.sort_order && props.touched.sort_order
                          )
                        }
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.sort_order}
                      />
                    </div>
                  )}

                  {(props.values.q_type === "I" ||
                    !!props.values.text_type) && (
                    <div
                      style={{
                        display: "flex",
                        position: "sticky",
                        backgroundColor: "white",
                        bottom: "0",
                        left: "0",
                        flexWrap: "wrap",
                      }}
                    >
                      <CheckboxLabels
                        name="show_in_cart"
                        label={"Show in cart"}
                        checked={props.values.show_in_cart}
                        onChange={(event) => {
                          props.setFieldValue(
                            "show_in_cart",
                            event.target.checked
                          );
                        }}
                      />
                      {!!props.values.text_type && (
                        <CheckboxLabels
                          name="capitilization"
                          label={"Capitilization"}
                          checked={props.values.capitilization}
                          onChange={(event) => {
                            props.setFieldValue(
                              "capitilization",
                              event.target.checked
                            );
                          }}
                        />
                      )}
                    </div>
                  )}

                  {/* <br></br> */}

                  {/* {props.values.q_type === "T" &&
                  (props.values.text_type === "S" ||
                    props.values.text_type === "T" ||
                    props.values.text_type === "P") ? (
                  ) : (
                    ""
                  )} */}

                  {!isLoading && (
                    <Dialogs
                      open={!isLoading ? dialog : false}
                      // onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      PaperProps={{
                        sx: {
                          borderRadius: "0",
                          // height: height || "80vh",
                          "&::-webkit-scrollbar": { display: "none" },
                        },
                      }}
                    >
                      <DialogContent
                        sx={{ padding: "1.5em 1.5em 0 !important" }}
                      >
                        {!popup ? (
                          <DialogContentText id="alert-dialog-description">
                            <div className="submitPopup">
                              <div
                                style={{
                                  fontSize: "16px",
                                  textAlign: "center",
                                  color: "black",
                                }}
                              >
                                Are you sure you want to proceed?
                                {/* Submitted Successfully */}
                              </div>
                            </div>
                          </DialogContentText>
                        ) : (
                          // isLoading && (
                          <DialogContentText id="alert-dialog-description">
                            <div className="submitPopup">
                              <div
                                style={{
                                  fontSize: "16px",
                                  textAlign: "center",
                                  color: "black",
                                  margin: "0px 40px 0px 40px ",
                                }}
                              >
                                {successMessage.message}
                              </div>
                              <div
                                style={{
                                  fontSize: "16px",
                                  textAlign: "center",
                                  margin: "0px 40px 0px 40px ",
                                }}
                              >
                                {successMessage?.status ? (
                                  <img
                                    src={require("../Pages/Image/success.png")}
                                    className="imageSize"
                                    alt="success"
                                  />
                                ) : (
                                  <>
                                    {successMessage?.message
                                      ?.toLowerCase()
                                      .includes("already exists") ? (
                                      // console.log("exisite")
                                      <img
                                        src={require("../Pages/Image/mdi_file-alert-outline.png")}
                                        className="imageSize"
                                        alt="error"
                                      />
                                    ) : (
                                      <img
                                        src={require("../Pages/Image/error.png")}
                                        className="imageSize"
                                        alt="error"
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </DialogContentText>
                          // )
                        )}
                      </DialogContent>

                      {!popup ? (
                        <DialogActions
                          sx={{
                            justifyContent: "center",
                            gap: "0.5em",
                            padding: "1.5em",
                          }}
                        >
                          <Button
                            onClick={() => setDialog(false)}
                            style={{
                              padding: "15px",
                              // backgroundColor: "#1976D2",
                              color: "black",
                              width: "100px",
                              height: "37px",
                              borderRadius: "0px",
                              border: "1px solid black",
                            }}
                            sx={{
                              backgroundColor: "white",
                              "&:hover": { backgroundColor: "white" },
                            }}
                          >
                            NO
                          </Button>
                          <Button
                            form="form"
                            type="submit"
                            onClick={() => {
                              // props.handleSubmit();
                              // setTimeout(() => setPopoup(true), 1000);
                              getValues(props.values);
                              setPopoup(true);
                            }}
                            style={{
                              // backgroundColor: "#1976D2",
                              color: "white",
                              width: "100px",
                              height: "37px",
                              borderRadius: "0px",
                              border: "1px solid black",
                            }}
                            autoFocus
                            sx={{
                              backgroundColor: "black",
                              "&:hover": { backgroundColor: "black" },
                            }}
                          >
                            YES
                          </Button>
                        </DialogActions>
                      ) : (
                        // !props.isLoading && (
                        <DialogActions sx={{ justifyContent: "center" }}>
                          <Button
                            onClick={handleClose}
                            style={{
                              // backgroundColor: "#1976D2",
                              color: "white",
                              width: "100px",
                              height: "37px",
                              borderRadius: "0px",
                              border: "1px solid #BDBDBD",
                            }}
                            autoFocus
                            sx={{
                              backgroundColor: "black",
                              "&:hover": { backgroundColor: "black" },
                            }}
                          >
                            OK
                          </Button>
                        </DialogActions>
                        // )
                      )}
                    </Dialogs>
                  )}
                  {/*     for submit when enter button click     */}
                  <button type="submit" style={{ display: "none" }}>
                    submit
                  </button>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            gap: "0.5em",
            p: "0 1.5em 1.5em",
            // padding: "0 1.5em 1.5em",
          }}
        >
          <Button
            onClick={handleClose}
            // disabled={!props.isValid}
            style={{
              width: "100px",
              height: "40px",
              border: "1px solid black",
              color: "black",
              backgroundColor: "white",
              borderRadius: "0px",
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              formRef.current!.handleSubmit();
            }}
            style={{
              // border: "1px solid black",
              width: "100px",
              height: "40px",
              border: "1px solid black",
              color: "white",
              // backgroundColor: "#C00A30",
              backgroundColor: "black",
              borderRadius: "0px",
            }}
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialogs>
    </>
  );
};

export default DialogQuestion;
