import { createSlice } from "@reduxjs/toolkit";
import { MatrixOptions } from "../../../interfaces/IImageQuestions";

export const QuestionImages = createSlice({
  name: "QuestionImages",
  initialState: {
    isLoading: false,
    openPopup: false,
    data: {},
  } as MatrixOptions,
  reducers: {
    List_questionImages: (state, _) => {
      state.isLoading = true;
      // return {
      //   ...state,
      //   isLoading: true,
      // };
    },
    Success_list: (state, action) => {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    },
    Failure_list: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    Add_questionImages: (state, _) => {
      state.isLoading = true;
    },
    Add_QuestionImages_success: (state, action) => {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        openPopup: true,
      };
    },

    Add_QuestionImages_failure: (state, action) => {
      state.isLoading = false;
      state.openPopup = true;
    },
    clearData: (state) => {
      state.data = {};
    },
    closePopup: (state) => {
      state.openPopup = false;
    },
  },
});
export default QuestionImages.reducer;
export const {
  List_questionImages,
  Success_list,
  Failure_list,
  Add_QuestionImages_failure,
  Add_QuestionImages_success,
  Add_questionImages,
  clearData,
  closePopup,
} = QuestionImages.actions;
