import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICapaignVariantForm } from "../../../Pages/CampaignVariantForm";
import { IEditableCapmaign } from "../../../interfaces/ICampaign";
import { IAPIResponse } from "../../../interfaces/IReduxState";

export const VariantList = createSlice({
  name: "VariantList",
  initialState: {
    variants: [] as ICapaignVariantForm[],
    popup: false,
    editValue: {} as ICapaignVariantForm,
    deleteId: null as null | number,
    deletePopup: false,
    errorMessage: false,
  },
  reducers: {
    add_variant: (state, action: PayloadAction<ICapaignVariantForm>) => {
      const copyState = state.variants;
      copyState.push(action.payload);
      const variants = copyState.map(
        (variant: ICapaignVariantForm, index: number) => ({
          ...variant,
          field_id: index,
        })
      );
      state.variants = variants;
      state.popup = false;
    },
    edit_variant: (state, action: PayloadAction<ICapaignVariantForm>) => {
      const copyState = state.variants;
      copyState[action.payload.field_id!] = action.payload;
      state.variants = copyState;
      state.popup = false;
    },
    delete_variant: (state) => {
      const copyState = state.variants.filter(
        (variant) => variant.field_id !== state.deleteId
      );
      state.variants = copyState;
      state.deletePopup = false;
    },
    update_variant: (
      state,
      action: PayloadAction<IAPIResponse<IEditableCapmaign>>
    ) => {
      state.variants = (action.payload.data?.variant ?? []).map(
        (variant: ICapaignVariantForm, index: number) => ({
          ...variant,
          field_id: index,
        })
      );
    },
    /*    Popup for edit variant    */
    popup_open: (state) => {
      state.popup = true;
    },
    popup_close: (state) => {
      state.popup = false;
      state.editValue = {};
    },
    /*    editable value     */
    editable_variant: (state, action) => {
      state.editValue = action.payload;
    },
    /*    deleteId     */
    deleteable_variant: (state, action) => {
      state.deleteId = action.payload;
    },
    /*    Popup for delete variant     */
    delete_popup_open: (state) => {
      state.deletePopup = true;
    },
    delete_popup_close: (state) => {
      state.deletePopup = false;
    },
    /*    clear variant list     */
    clear_variant_list: (state) => {
      state.variants = [];
    },
    open_error_message: (state) => {
      state.errorMessage = true;
    },
    close_error_message: (state) => {
      state.errorMessage = false;
    },
  },
});

export default VariantList.reducer;
export const {
  delete_variant,
  edit_variant,
  add_variant,
  update_variant,
  popup_open,
  popup_close,
  editable_variant,
  deleteable_variant,
  delete_popup_open,
  delete_popup_close,
  clear_variant_list,
  close_error_message,
  open_error_message,
} = VariantList.actions;
