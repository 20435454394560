import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import { FunctionComponent } from "react";

interface DialogsProps {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  Dialog_Title?: string | React.ReactNode;
  Dialog_Content?: any;
  Dialog_Actions?: any;
  DialogBody?: any;
  height?: string;
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs";
  fullScreenEnable?: boolean;
  Dialog_Title_sx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
  Dialog_Content_sx?: SxProps<Theme>;
  Dialog_Actions_sx?: SxProps<Theme>;
}

const Dialogs: FunctionComponent<DialogsProps> = (props) => {
  //      dispatch props
  const {
    open,
    setOpen,
    Dialog_Title,
    Dialog_Content,
    Dialog_Actions,
    DialogBody,
    height,
    maxWidth,
    fullScreenEnable,
    Dialog_Title_sx,
    Dialog_Content_sx,
    Dialog_Actions_sx,
    sx,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreenEnable ? fullScreen : false}
      fullWidth
      maxWidth={maxWidth}
      open={open ?? false}
      // onClose={() => setOpen!(false)}
      PaperProps={{
        sx: {
          ...sx,
          borderRadius: "0",
          // height: height || "80vh",
          "&::-webkit-scrollbar": { display: "none" },
        },
      }}
      aria-labelledby="responsive-dialog-title"
    >
      {DialogBody}
      {!DialogBody && (
        <>
          {Dialog_Title && (
            <DialogTitle sx={Dialog_Title_sx}>{Dialog_Title} </DialogTitle>
          )}
          {Dialog_Content && (
            <DialogContent
              sx={{
                ...Dialog_Content_sx,
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              {Dialog_Content}
            </DialogContent>
          )}
          {Dialog_Actions && (
            <DialogActions
              sx={{ ...Dialog_Actions_sx, justifyContent: "center" }}
            >
              {Dialog_Actions}
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  );
};

export default Dialogs;
